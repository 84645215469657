import React, { useContext, useEffect, useRef } from 'react';
import LeftBlock from '../Home/LeftBlock/LeftBlock';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { ApiContext } from '../UseContext/ApiCall';


const HostLive = ({ className }) => {

  const containerRef = useRef(null);

  const { 
    loggedInUser, liveStart, liveEnd, 
  } = useContext(ApiContext);

  console.log('loggedInUser : ', loggedInUser);

  const initZegoCloud = (element) => {
    if (!loggedInUser) {
      console.error('Missing loggedInUser');
      return;
    };
  
    const appID = 1632643095;
    const serverSecret = 'fcb29e34aa8bba2eecf90cd9f3cdc81d';
    const roomId = `${loggedInUser.userId}-${Math.random().toString(36).substring(2, 8)}`;

    console.log('roomid : ', roomId);
  
    // Generate the kit token for Host
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomId,
      loggedInUser.userId,
      loggedInUser.username || 'Host'
    );
  
    // Load the ZEGOCLOUD UI as a Host
    const zegoInstance = ZegoUIKitPrebuilt.create(kitToken);

    if (zegoInstance && typeof zegoInstance.joinRoom === 'function') {    
      zegoInstance.joinRoom({
        container: element,
        scenario: {
          mode: ZegoUIKitPrebuilt.LiveStreaming,
          config: {
            role: 'Host',
          },
        },
        onLiveStart: () => {
          liveStart(roomId);
        },
        onLiveEnd: () => {
          liveEnd(roomId);
        },
      });      
    } else {
      console.error('Failed to create ZegoUIKitPrebuilt instance');
    };
  };
  

  useEffect(() => {
    if (containerRef.current) {
      initZegoCloud(containerRef.current);
    };
  }, [loggedInUser]);


  return (
    <section className={`section-main ${className || ''}`}>
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div className="col-lg-3 d-none d-lg-block shadow z-1">
            <LeftBlock />
          </div>

          <div className="col-lg-9 pt-5 overflow-y-scroll scrollbar-hidden px-3" style={{ background: 'lightblue', height: '100vh' }}>
            <h3 className="pt-4 text-center fw-bold">Host Live Stream</h3>

            <div id="zego-container" style={{ width: '100%', height: '600px' }} ref={containerRef}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HostLive;