import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CContainer } from "@coreui/react";
import { BsBagCheck, BsTrash } from "react-icons/bs";
import { profileContext } from "../../../UseContext/ProfileContext";
import { IoMdAddCircle, IoMdHome, IoMdLocate } from "react-icons/io";
import { places, skillsCategory, socialMedia } from "../../../Data/EducationData";

import BioPop from "../../../Popup/Profile/ProfilePopUp/BioPopup";
import WorkPopup from "../../../Popup/Profile/ProfilePopUp/WorkPopup";
import HangoutEdit from "../../../Popup/Profile/EditPopup/HangoutEdit";
import HobbyPopup from "../../../Popup/Profile/ProfilePopUp/HobbyPopup";
import ZodiacSign from "../../../Popup/Profile/ProfilePopUp/ZodiacSign";
import SkillsPopup from "../../../Popup/Profile/ProfilePopUp/SkillsPopup";
import EditWorkPopup from "../../../Popup/Profile/EditPopup/WorkEditPopoup";
import PlacesLivedPopup from "../../../Popup/Profile/ProfilePopUp/PlacesLivedPopup";
import PlacesEditPopup from "../../../Popup/Profile/EditPopup/PlaceEditpopup";
import EditSkillsPopup from "../../../Popup/Profile/EditPopup/SkillsEditPopup";
import ContactDetail from "../../../Popup/Profile/ProfilePopUp/ContactDetailPopup";
import EducationEditPopup from "../../../Popup/Profile/EditPopup/EducationEditpopup";
import MosthangoutsPopup from "../../../Popup/Profile/ProfilePopUp/MosthangoutsPopup";
import PersonalDetailPopup from "../../../Popup/Profile/ProfilePopUp/PersonalDetailPopup";
import EducationDetailPopup from "../../../Popup/Profile/ProfilePopUp/EducationDetailPopup";
import OtherSocialMediaPopup from "../../../Popup/Profile/ProfilePopUp/OtherSocialMediaPopup";
import OtherSocialMediaEdit from "../../../Popup/Profile/EditPopup/OthersocialMediaEdit";
import { createInspiration, deletRelation, deleteEducationalDetails, deleteFavFood, deleteFavMovie, deleteHangoutDetails, deleteInspiration, deleteOneAddress, deleteSkillDetails, deleteSocialDetails, deleteWorkDetails, getAddressDetails, getEducationDetails, getFavFood, getFavMovie, getHangoutDetails, getHobby, getInspiration, getRelation, getSkillDetails, getSocialDetails, getWorkDetails, myProfile, updateInspiration } from "../../../UseContext/ApiHelpers";
import { AwsFileUrl, BASEAPIURL, BASEURL, BASE_URL_STATIC } from "../../../../../config";
import MoviePopup from "../../../Popup/Profile/ProfilePopUp/MoviePopup";
import MovieEditPopup from "../../../Popup/Profile/EditPopup/MovieEditPopup";
import { ApiContext } from "../../../UseContext/ApiCall";
import FoodPopup from "../../../Popup/Profile/ProfilePopUp/FoodPopup";
import FoodEditPopup from "../../../Popup/Profile/EditPopup/FoodEditPopup";
import AddRelationPop from "../../../Popup/Profile/ProfilePopUp/AddRelationPop";
import AddEditRelationPop from "../../../Popup/Profile/EditPopup/AddEditRelationPop";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import DeleteProfileData from "../../../Button/DeleteProfileData";

const ProfileUserEditMain = () => {

  const { userProfile, setUserProfile, bioInput, setBioInput, address, setAddress, educationDetails, setEducationDetails, social, setSocial, hangoutDetails, setHangoutDetails, zodiacVal, setzodiacVal, workData, setWorkData, skillData, setSkillData, selectedHobbies, setSelectedHobbies, movieDetails, setMovieDetails, foodDetails, setfoodDetails, relationsData, setRelationsData } = useContext(profileContext);

  const { config, handleAcceptRelation } = useContext(ApiContext)

  const { paramsUserId } = useParams()

  const loggedInUserId = paramsUserId


  const [searchList, setsearchList] = useState([])
  const [selectInspiration, setselectInspiration] = useState("")
  const [inspirationList, setinspirationList] = useState([])
  const [inpirationId, setInspirationId] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [customInspiration, setCustomInspiration] = useState("")
  const [delOpen, setDelOpen] = useState(false)
  const [showInspiration, setShowInspiration] = useState(false)

  const handleSelectInspiration = (e) => {
    setselectInspiration(e)
    setsearchList([])
  }

  const handleSearch = async (e) => {
    setErrMsg("")
    if (e.target.value.length > 0) {
      const res = await axios.post(`${BASEAPIURL}/user/getalluser?page=1&sort=1&search=${e.target.value}`, { type: 'emilo' }, config)
      setsearchList(res.data.users)
      setCustomInspiration(e.target.value)
    }
  };

  const inputRef = useRef(null)

  const handleSaveInspiration = (action) => {
    if (inputRef.current && inputRef.current.value === "") {
      setErrMsg("Please enter inspiration")
    }
    else {
      if (action === "create") {
        createInspiration((selectInspiration === "" ? customInspiration : selectInspiration.userId), config).then((res) => {
          inputRef.current.value = ""
          toast.success(res.message)
          getInspiration(loggedInUserId).then((res) => {
            setinspirationList(res)
            setShowInspiration(false)
          })
        })
      } else {
        updateInspiration(inpirationId, selectInspiration === "" ? customInspiration : selectInspiration.userId, config).then((res) => {
          inputRef.current.value = ""
          toast.success(res.message)
          getInspiration(loggedInUserId).then((res) => {
            setinspirationList(res)
            setShowInspiration(false)
          })
        })

      }
    }
    setselectInspiration("");
    setCustomInspiration("")
  }

  const handleAccept = async (id) => {
    await handleAcceptRelation(id, setRelationsData)
  }


  useEffect(() => {
    document.addEventListener("mousedown", handleFocusOut);
    return () => {
      document.removeEventListener("mousedown", handleFocusOut);
    };
  }, []);

  const handleFocusOut = (event) => {
    if (!searchBarRef.current || !searchBarRef.current.contains(event.target)) {
      setsearchList([])
    }
  };
  const searchBarRef = useRef();

  // ------------------------- Personal Details ----------------------------------------------------

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);

  };
  const handleopen = () => {
    setShow(true);
  };

  // ------------------------- Contact ----------------------------------------------------

  const [open, setOpen] = useState(false);
  const closehandle = () => {
    setOpen(false);
    // alert(1);
  };
  const openhandle = () => {
    setOpen(true);
  };

  // ------------------------- Places ----------------------------------------------------

  const [openPlaces, setOpenPlaces] = useState(false);
  const [openEditPlaces, setOpenEditPlaces] = useState(false);
  const [addressId, setAddressId] = useState();
  const [addObj, setAddObj] = useState({})

  const handleclosePlaces = () => {
    setOpenPlaces(false);
    // alert(1);
  };

  const handleopenPlaces = () => {
    setOpenPlaces(true);
  };



  // ------------------------- Education ----------------------------------------------------

  const [openEducation, setopenEducation] = useState(false);
  const [educationId, setEducationId] = useState();
  const [eduObj, setEduObj] = useState({})
  const [editEducation, setEditEducation] = useState(false);

  const handlecloseEducation = () => {
    setopenEducation(false);
    // alert(1);
  };
  const handleopenEducation = () => {
    setopenEducation(true);
  };


  // ------------------------- Social Accounts ----------------------------------------------------
  const [socialId, setSocialId] = useState();
  const [OtherSoc, setSocialObj] = useState({})
  const [openEditSocial, setOpenEditSocail] = useState(false);
  const [openSocial, setOpenSocial] = useState(false);
  const handlecloseSocial = () => {
    setOpenSocial(false);
    // alert(1);
  };
  const handleopenSocial = () => {
    setOpenSocial(true);
  };


  // ------------------------- Hangout ----------------------------------------------------
  const [hangoutId, setHangoutId] = useState();
  const [openEditHangout, setOpenEditHangout] = useState(false);
  const [hangObj, setHangObj] = useState({})

  const [openHangOut, sethangOut] = useState(false);
  const handleClosehangOut = () => {
    sethangOut(false);
    // alert(1);
  };
  const handleOpenhangOut = () => {
    sethangOut(true);
  };


  // --------------------------- Zodiac----------------------------------------------
  const [openZodiac, setZodiac] = useState(false);

  const handleCloseZodiac = () => {
    setZodiac(false);
    // alert(1);
  };
  const handleOpenZodiac = () => {
    setZodiac(true);
  };


  // ------------------------- Work ----------------------------------------------------
  const [openWork, setWork] = useState(false);
  const [openEditWork, setOpenEditWork] = useState(false);
  const [workId, setWorkId] = useState();
  const [workObj, setworkObj] = useState({})
  const handleCloseWork = () => {
    setWork(false);
    // alert(1);
  };
  const handleOpenWork = () => {
    setWork(true);
  };


  // ------------------------- Skills ----------------------------------------------------

  const [openSkills, setSkills] = useState(false);
  const [openEditSkills, setOpenEditSkills] = useState(false);
  const [skillsId, setSkillsId] = useState();
  const [skillObj, setskillObj] = useState({})
  const handleCloseSkills = () => {
    setSkills(false);
    // alert(1);
  };
  const handleOpenSkills = () => {
    setSkills(true);
  };

  // ------------------------- Bio ----------------------------------------------------

  const [bioOpen, setBioOpen] = useState(false);
  const handlebioClose = () => {
    setBioOpen(false);
    // alert(1);
  };
  const handlebioOpen = () => {
    setBioOpen(true);
  };

  // ------------------------- Hobbies ----------------------------------------------------
  const [hobbyOpen, setHobbyOpen] = useState(false);

  const handleHobbyOpen = () => {
    setHobbyOpen(true);
  };

  const handleHobbyClose = () => {
    setHobbyOpen(false);
  };



  // ------------------- Movies --------------------

  const [movieId, setMovieId] = useState();
  const [openMovies, setMovies] = useState(false);
  const [openEditMovies, setOpenEditMovies] = useState(false);
  const [movieObj, setMovieObj] = useState({})


  // ------------------- Foods --------------------

  const [openFoods, setFoods] = useState(false);
  const [foodId, setfoodId] = useState();
  const [openEditFood, setOpenEditFood] = useState(false);
  const [foodObj, setfoodObj] = useState({})


  // ----------------- Realtions --------------------

  const [openRelation, setRelation] = useState(false);
  const [relationId, setrelationId] = useState();
  const [openEditRelation, setOpenEditRelation] = useState(false);
  const [relationObj, setrelationObj] = useState({})


  useMemo(() => {
    myProfile(config).then((res) => {
      setzodiacVal(res.zodiac);
      setUserProfile(res);
      setBioInput(res.bio);
    });
    getHobby(loggedInUserId).then((res) => setSelectedHobbies(res));
    getHangoutDetails(loggedInUserId).then((res) => setHangoutDetails(res));
    getEducationDetails(loggedInUserId).then((res) => setEducationDetails(res));
    getSocialDetails(loggedInUserId).then((res) => setSocial(res));
    getWorkDetails(loggedInUserId).then((res) => {
      setWorkData(res);
    });
    getSkillDetails(loggedInUserId).then((res) => setSkillData(res));
    getFavMovie(loggedInUserId).then(res => { setMovieDetails(res); })
    getFavFood(loggedInUserId).then(res => { setfoodDetails(res) })
    getRelation(loggedInUserId).then(res => {
      setRelationsData(res)
    })
    getAddressDetails(loggedInUserId).then((res) => { setAddress(res); });

    getInspiration(loggedInUserId).then((res) => {
      setinspirationList(res)
    })
  }, [loggedInUserId]);

  return (
    <>
      <section className="">

        {inpirationId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={deleteInspiration}
          entityId={inpirationId}
          fetchData={() => getInspiration(loggedInUserId).then(setinspirationList)}
        />}

        {addressId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={deleteOneAddress}
          entityId={addressId}
          fetchData={() => getAddressDetails(loggedInUserId).then(setAddress)}
        />}

        {educationId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={deleteEducationalDetails}
          entityId={educationId}
          fetchData={() => getEducationDetails(loggedInUserId).then(setEducationDetails)}
        />}

        {workId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={deleteWorkDetails}
          entityId={workId}
          fetchData={() => getWorkDetails(loggedInUserId).then(setWorkData)}
        />}

        {skillsId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={deleteSkillDetails}
          entityId={skillsId}
          fetchData={() => getSkillDetails(loggedInUserId).then(setSkillData)}
        />}

        {hangoutId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={deleteHangoutDetails}
          entityId={hangoutId}
          fetchData={() => getHangoutDetails(loggedInUserId).then(setHangoutDetails)}
        />}

        {socialId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={deleteSocialDetails}
          entityId={socialId}
          fetchData={() => getSocialDetails(loggedInUserId).then(setSocial)}
        />}

        {relationId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={deletRelation}
          entityId={relationId}
          fetchData={() => getRelation(loggedInUserId).then(setRelationsData)}
        />}


        {/* {relationId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={handleReject}
          entityId={relationId}
          fetchData={() => getRelation(loggedInUserId).then(setRelationsData)}
        />} */}

        {movieId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={deleteFavMovie}
          entityId={movieId}
          fetchData={() => getFavMovie(loggedInUserId).then(setMovieDetails)}
        />}

        {foodId && <DeleteProfileData
          delOpen={delOpen}
          delClose={() => setDelOpen(false)}
          onDelete={deleteFavFood}
          entityId={foodId}
          fetchData={() => getFavFood(loggedInUserId).then(setfoodDetails)}
        />}


        <BioPop show={bioOpen} close={handlebioClose} userProfile={userProfile} />

        <CContainer>
          <div className="profile-flex">
            <div
              className="col-lg-12"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <button
                type="submit"
                name="submit"
                value="Save"
                className="d-none"
              >
                <img src={`${BASE_URL_STATIC}/icon/save.svg`} alt="" />
              </button>
            </div>
          </div>

          <div className="profile-flex" style={{ flexDirection: "column" }}>
            <div
              className="col-lg-12"
              style={{ fontSize: "20px" }}
            >
              <span>Bio</span>
            </div>
            <div className="col-lg-12" >
              <span className="d-flex px-3 justify-content-between align-items-center">
                <small className="text-muted p-2 text-truncate">
                  {bioInput}
                </small>

                <img
                  src={`${BASE_URL_STATIC}/icon/pen.svg`}
                  alt="img"
                  onClick={handlebioOpen}
                  style={{
                    background: "#E8E8E8",
                    padding: "5px",
                    width: "22px",
                    height: "22px",
                    borderRadius: "30%",
                    cursor: "pointer"
                  }}
                />
              </span>
              <div
                style={{
                  background: "#E8E8E8",
                  height: "0.5px",
                  marginRight: "20px",
                }}
              ></div>
            </div>
          </div>

          <div className="profile-flex">
            <div
              className="col-lg-12 mt-4"
              style={{ display: "flex", justifyContent: "end" }}
            >
            </div>
          </div>

          <div className="profile-flex" style={{ flexDirection: "column" }}>
            <div
              className="col-lg-12"
              style={{ fontSize: "20px" }}
            >
              <span>Inspiration</span>
            </div>

            {(showInspiration || (inspirationList && inspirationList.length < 1)) && <div className="container profile_div" style={{ width: "100%" }}>
              {selectInspiration === "" ?
                <div className="d-flex flex-column col-8 position-relative">
                  <input ref={inputRef} className="profile_search" type="text" placeholder="Enter or search" maxLength={30} onChange={handleSearch} />
                  <small className="ps-2 position-absolute text-danger" style={{ bottom: "-10px" }}>{errMsg}</small>
                </div>
                :
                <p className="col-lg-6 profile_user_name">
                  <span>
                    {selectInspiration.fname}
                  </span>
                  <span className="">
                    <IoClose onClick={() => { setselectInspiration("") }} />
                  </span>
                </p>}

              {inspirationList && inspirationList.length < 1 && <p className="text-primary col-4 text-end" style={{ cursor: "pointer" }} onClick={() => handleSaveInspiration('create')} >save</p>}

              {inspirationList && inspirationList.length > 0 && <p className="text-primary col-4 text-end" >
                <small onClick={() => handleSaveInspiration('update')} style={{ cursor: "pointer" }}>update</small>
                <small onClick={() => setShowInspiration(false)} style={{ cursor: "pointer" }} className="text-danger ms-2">cancel</small>
              </p>}

              <div ref={searchBarRef} className="inspiration_list_div">

                {searchList && searchList.length > 0 && <ul className="inspiration_list">
                  {searchList.map((e, i) => (
                    <li key={i} onClick={() => handleSelectInspiration(e)}>
                      <p>
                        <span className="inspiration_img"><img src={`${BASE_URL_STATIC}/dummy.png`} alt="" /></span>
                        <span>
                          {e.fname}
                        </span>
                      </p>
                      <hr className="profile_hr my-2" />
                    </li>
                  ))}
                </ul>}
              </div>
            </div>}

            <div className="col-12 d-flex flex-column gap-2 m-2 px-4 my-3">
              {inspirationList && inspirationList.slice(0, 1).map((e, i) => (
                <div key={i}>

                  <div className="d-flex justify-content-between gap-5 align-items-center my-2">
                    <div className="d-flex justify-content-start gap-2 align-items-center" style={{ cursor: "pointer", fontSize: "14px" }}>

                      <img className="ins_icon" src={`${BASE_URL_STATIC}/inspiration.svg`} alt="" />

                      {e.findUser && e.findUser.length > 0 ? <span>{e.findUser && e.findUser[0].fname}</span> : <span>{e.create && e.create.inspiredBy}</span>}
                    </div>

                    <div className="d-flex justify-content-between gap-2 align-items-center">
                      {e.findUser && e.findUser.length > 0 && <span className="ins_link">
                        <Link to={`${BASEURL}/profile/${e.findUser[0]._id}`}>
                          view profile
                        </Link>
                      </span>}
                      <span onClick={() => { setShowInspiration(true); setInspirationId(e.create && e.create._id) }}>
                        <img
                          src={`${BASE_URL_STATIC}/icon/pen.svg`}
                          alt="img"
                          style={{
                            width: "13px",
                            borderRadius: "5%",
                            cursor: "pointer"
                          }}
                        />
                      </span>
                      <IoClose onClick={() => {
                        setInspirationId(e.create && e.create._id)
                        setDelOpen(true);
                      }}
                        style={{ cursor: "pointer" }} />
                    </div>
                  </div>

                  <hr className="profile_hr" />
                </div>
              ))}
            </div>

          </div>

          {/* <hr /> */}
          <div className="row mt-4 mb-2">
            <div
              className="col-lg-6"
              style={{ fontSize: "20px" }}
            >
              <span>Profile</span>
            </div>

            <div
              className="col-lg-6"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <img src={`${BASE_URL_STATIC}/icon/setting.svg`} alt="" width={18} />
            </div>
          </div>

          <div style={{ background: "#E8E8E8", height: "0.5px" }}></div>



          <PersonalDetailPopup show={show} handleClose={handleClose} userProfile={userProfile} />
          <CContainer className="form-group accordion " id="accordionExample">
            <div className="d-flex justify-content-between align-items-center  accordion-item" >

              {/* <div className="col-lg-12"> */}
              <div className="accordion-header me-auto" id="headingOne">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",
                  }}
                >
                  <b style={{ padding: "10px" }}>Personal Info</b>
                </span>
              </div>
              <div >

                <span onClick={handleopen} className="px-2">

                  <img
                    src={`${BASE_URL_STATIC}/icon/pen.svg`}
                    alt=""
                    style={{
                      background: "#E8E8E8",
                      padding: "5px",
                      width: "22px",
                      height: "22px",
                      borderRadius: "30%",
                      cursor: "pointer"
                    }}
                  />

                </span>

              </div>

            </div>
            <CContainer id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
              {userProfile !== "" && <div className="container p-3 accordion-body ">

                <div className="form-group">
                  <div className="profile-flex">
                    <span className="d-flex" style={{ marginRight: "5px" }}>
                      <img src={`${BASE_URL_STATIC}/icon/name.svg`} alt="" />
                    </span>
                    <small style={{ padding: "10px" }}>
                      <label style={{ fontSize: "15px" }}>Name</label>

                      <p className="user_data">
                        {userProfile && userProfile.fname}
                      </p>
                      {userProfile && userProfile.username && <p className="user_data">
                        <i className="text-primary">{userProfile.username}</i>
                      </p>}
                    </small>
                  </div>
                </div>
                <hr className="profile_hr" />
                <div className="form-group">
                  <div
                    className="profile-flex"
                  >
                    <span className="d-flex" style={{ marginRight: "5px" }}>
                      <img src={`${BASE_URL_STATIC}/icon/dob.svg`} alt="" />
                    </span>
                    <small style={{ padding: "10px" }}>
                      <label style={{ fontSize: "15px" }}>DOB</label>
                      <p className="user_data">
                        {userProfile && userProfile.dob && new Date(userProfile.dob).toDateString()}
                      </p>
                    </small>
                  </div>
                </div>
                <hr className="profile_hr" />

                <div className="form-group">
                  <div
                    className="profile-flex"

                  >
                    <span className="d-flex" style={{ marginRight: "5px" }}>
                      <img src={`${BASE_URL_STATIC}/icon/gender.svg`} alt="" />
                    </span>
                    <small style={{ padding: "10px" }}>
                      <label style={{ fontSize: "15px" }}>Gender</label>
                      <p className="user_data">
                        {userProfile && userProfile.gender && userProfile.gender}
                      </p>
                    </small>
                  </div>
                </div>
                <hr className="profile_hr" />

                <div className="form-group">
                  <div
                    className="profile-flex"

                  >
                    <span className="d-flex" style={{ marginRight: "5px" }}>
                      <img src={`${BASE_URL_STATIC}/icon/married.svg`} alt="" />
                    </span>
                    <small style={{ padding: "10px" }}>
                      <label style={{ fontSize: "15px" }}>Martial status</label>
                      <p className="user_data">
                        {userProfile && userProfile.maritial}
                      </p>
                    </small>
                  </div>
                </div>
                <hr className="profile_hr" />

                {userProfile && userProfile.spouse &&
                  <>
                    <div className="form-group">
                      <div
                        className="profile-flex"

                      >
                        <span className="d-flex" style={{
                          marginRight: "5px", height: "31px",
                          maxHeight: "50px", opacity: "0.6"
                        }}>
                          <img src={`${BASE_URL_STATIC}/icon/spouse.svg`} alt="ee" />
                        </span>
                        <small style={{ padding: "10px" }}>
                          <label style={{ fontSize: "15px" }}>Spouse Name</label>
                          <p className="user_data">
                            {userProfile.spouse}
                          </p>
                        </small>
                      </div>
                    </div>
                    <hr className="profile_hr" />
                  </>

                }

                {userProfile && userProfile.maritualDate &&
                  <>
                    <div className="form-group">
                      <div
                        className="profile-flex"

                      >
                        <span className="d-flex" style={{ marginRight: "5px", height: "20px", opacity: "0.6" }}>
                          <img src={`${BASE_URL_STATIC}/icon/maritial_date.svg`} alt="ee" />
                        </span>
                        <span style={{ padding: "10px" }}>
                          <label style={{ fontSize: "15px" }}>Marriage Date</label>
                          <p className="user_data">
                            {userProfile.maritualDate && new Date(userProfile.maritualDate).toDateString()}
                          </p>
                        </span>
                      </div>
                    </div>

                    <hr className="profile_hr" />
                  </>

                }



              </div>}

            </CContainer>
          </CContainer>

          <ContactDetail open={open} closeHandle={closehandle} />
          <CContainer className="form-group accordion " id="accordionExample">
            <div className="d-flex justify-content-between align-items-center accordion-item">
              {/* <div className="col-lg-12"> */}
              <div className="accordion-header me-auto" id="headingTwo">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",
                  }}
                >
                  <b style={{ padding: "10px" }}>Contact Info</b>
                </span>
              </div>
              <div >
                <span className="px-2" onClick={openhandle}>
                  <span
                    style={{
                      display: "grid",
                      justifyItems: "end",
                      fontSize: "15px",

                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src={`${BASE_URL_STATIC}/icon/pen.svg`}
                      alt=""
                      style={{
                        background: "#E8E8E8",
                        padding: "5px",
                        width: "22px",
                        height: "22px",
                        borderRadius: "30%",
                        cursor: "pointer"
                      }}
                    />
                  </span>
                </span>
              </div>
              {/* </div> */}
            </div>
            <CContainer id="collapseTwo" className="accordion-collapse collapse " aria-labelledby="headingTwo" data-bs-parent="#accordionExample" >
              <div className="container p-3 accordion-body">

                <div className="form-group">
                  <div
                    className="profile-flex"
                  >
                    <span className="d-flex me-2">
                      <img src={`${BASE_URL_STATIC}/icon/mobile.svg`} alt="" />
                    </span>
                    <small style={{ padding: "10px", minWidth: "200px" }}>
                      <label style={{ fontSize: "15px" }}>Mobile</label>
                      <p style={{ fontSize: "14px" }}>
                        {userProfile && userProfile.mobile}
                      </p>
                    </small>
                  </div>
                </div>
                <hr className="profile_hr" />

                <div className="form-group">
                  <div
                    className="profile-flex"
                  >
                    <span className="d-flex me-2" >
                      <img src={`${BASE_URL_STATIC}/icon/mail.svg`} alt="" />
                    </span>
                    <span style={{ padding: "10px", minWidth: "200px" }}>
                      <label style={{ fontSize: "15px" }}>Email</label>
                      <p style={{ fontSize: "14px" }}>
                        {userProfile && userProfile.email}
                      </p>
                    </span>
                  </div>
                </div>
                <hr className="profile_hr" />
              </div>

            </CContainer>
          </CContainer>

          <PlacesLivedPopup open={openPlaces} close={handleclosePlaces} />
          <PlacesEditPopup open={openEditPlaces} close={() => setOpenEditPlaces(false)} addressId={addressId} addObj={addObj} />
          <CContainer className="accordion form-group" id="accordionExample">

            <div className="d-flex justify-content-between align-items-center accordion-item">
              <div className="accordion-header me-auto" id="headingThree">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",

                    fontWeight: "bold",
                    padding: "2px 10px",
                  }}
                >
                  Places Lived
                </span>
              </div>

              <span style={{ paddingRight: "10px" }}>
                {address && address.length >= 2 ? (
                  ""
                ) : (
                  <IoMdAddCircle style={{ cursor: "pointer" }} size={20} onClick={handleopenPlaces} />
                )}
              </span>
            </div>

            <CContainer id="collapseThree" className="accordion-collapse collapse " aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
              <div className="container p-3 accordion-body ">
                {address && address.length > 0
                  ? address.map((address, index) => (
                    <div className="form-group" key={index}>
                      <div
                        className="flex my-2 align-items-start" style={{ marginRight: "-10px" }}
                      >
                        <div style={{ maxWidth: "79%" }}
                          className="d-flex align-items-start justify-content-start"
                        >
                          <span
                            className="d-flex "
                            style={{ margin: "17px 0px" }}
                          >
                            {address.addressType === "permanent" ? (
                              <IoMdHome size={25} color="gray" />
                            ) : (
                              <IoMdLocate size={25} color="gray" />
                            )}
                          </span>
                          <span style={{ padding: "0px 10px" }}>
                            <label style={{ fontSize: "15px" }}>
                              {address.addressType}
                            </label>
                            <div>
                              { }
                              <div>
                                <div className="flex justify-content-start gap-1" style={{ fontSize: "14px" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    {address.city}
                                  </span>
                                  (<span>{address.state}</span>
                                  <span>{address.country}</span>)
                                </div>
                                <div>
                                  <small
                                    style={{
                                      color: "gray",
                                    }}
                                  >
                                    {address.aboutPlace}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>

                        <div className="flex">
                          <img
                            src={`${BASE_URL_STATIC}/icon/pen.svg`}
                            alt="img"
                            onClick={() => {
                              setOpenEditPlaces(true);
                              setAddressId(address._id);
                              setAddObj(address)
                            }}
                            style={{
                              background: "#E8E8E8",
                              padding: "5px",
                              width: "22px",
                              height: "22px",
                              borderRadius: "30%",
                              cursor: "pointer"
                            }}
                          />

                          <BsTrash style={{ cursor: "pointer" }}
                            onClick={() => { setAddressId(address._id); setDelOpen(true); }}
                            size={15}
                          />

                        </div>
                      </div>
                    </div>
                  ))
                  : ""}
              </div>
            </CContainer>
          </CContainer>

          <EducationDetailPopup open={openEducation} close={handlecloseEducation} loggedInUserId={loggedInUserId} />
          <EducationEditPopup open={editEducation} loggedInUserId={loggedInUserId} close={() => setEditEducation(false)} eduObj={eduObj} educationId={educationId} />
          <CContainer className="accordion form-groups" id="accordionExample">
            <div className="p-1 px-2 d-flex justify-content-between align-items-center accordion-item">
              <div className="accordion-header me-auto" id="headingFour">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Education
                </span>
              </div>
              <span
                className=" pe-0"
                style={{ cursor: "pointer" }}
                onClick={handleopenEducation}
              >
                <IoMdAddCircle style={{ cursor: "pointer" }} size={20} />
              </span>
            </div>
            <CContainer id="collapseFour" className="accordion-collapse collapse " aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div className="container p-3 accordion-body ">
                {Array.isArray(educationDetails) ?
                  educationDetails.map((e, i) => (
                    <div className="form-group" key={i}>
                      <div className="flex my-2 align-items-start" style={{ marginRight: "-10px" }} >

                        <div style={{ maxWidth: "79%" }}
                          className="d-flex align-items-start justify-content-start"
                        >
                          {e.schColl === "school" ? (
                            <span
                              className="d-flex me-2 mt-2"
                              style={{ marginRight: "5px" }}
                            >
                              <img src={`${BASE_URL_STATIC}/icon/highschool.svg`} alt="" />
                            </span>
                          ) : (
                            <span
                              className="d-flex"
                              style={{ marginRight: "5px" }}
                            >
                              <img src={`${BASE_URL_STATIC}/icon/college.svg`} alt="" />
                            </span>
                          )}

                          <span className="d-flex flex-column" style={{ padding: "0px 10px" }} >
                            <label style={{ fontSize: "12px", marginBottom: "5px" }}>
                              {e.schColl} &nbsp;{" "}
                              {new Date(e.startDate).getFullYear()} -{" "}
                              {new Date(e.endDate).getFullYear()}
                            </label>
                            <span style={{ fontSize: "14px" }}><b>{e.degree}</b> </span>
                            <span style={{ fontSize: "12px", color: "gray" }}>{e.instituteName}</span>
                          </span>
                        </div>
                        <div className="flex">
                          <img
                            src={`${BASE_URL_STATIC}/icon/pen.svg`}
                            alt="img"
                            onClick={() => {
                              setEditEducation(true);
                              setEducationId(e._id);
                              setEduObj(e)
                            }}
                            style={{
                              background: "#E8E8E8",
                              padding: "5px",
                              width: "22px",
                              height: "22px",
                              borderRadius: "30%",
                              cursor: "pointer"
                            }}
                          />

                          <BsTrash style={{ cursor: "pointer" }}
                            size={15}
                            onClick={() => {
                              setEducationId(e._id)
                              setDelOpen(true)
                            }}
                          />
                        </div>
                      </div>

                      <hr className="profile_hr" />
                    </div>
                  )
                  ) : ""}
              </div>
            </CContainer>
          </CContainer>

          <WorkPopup open={openWork} close={handleCloseWork} loggedInUserId={loggedInUserId} />
          <EditWorkPopup workId={workId} open={openEditWork} loggedInUserId={loggedInUserId} workObj={workObj} close={() => setOpenEditWork(false)} />
          <CContainer className="accordion form-group" id="accordionExample">
            <div className="d-flex justify-content-between align-items-center accordion-item">
              <div className="accordion-header me-auto" id="headingEight">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",

                    fontWeight: "bold",
                    padding: "2px 10px",
                  }}
                >
                  Work
                </span>
              </div>
              <span
                onClick={handleOpenWork} className="px-2"
                style={{
                  display: "grid",
                  justifyItems: "end",
                  fontSize: "15px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >

                <IoMdAddCircle style={{ cursor: "pointer" }} size={20} />
              </span>
            </div>
            <CContainer id="collapseEight" className="accordion-collapse collapse " aria-labelledby="headingEight" data-bs-parent="#accordionExample">
              <div className="container p-3 accordion-body">
                {Array.isArray(workData)
                  ? workData.map((e, i) => (
                    <div className="form-group" key={i}>
                      <div className="flex my-2 align-items-start" style={{ marginRight: "-10px" }}>
                        <div style={{ maxWidth: "79%" }}
                          className="d-flex align-items-start justify-content-start"
                        >
                          <span
                            className="d-flex me-2 mt-2"
                          >
                            <BsBagCheck size={25} color="#808080" />
                          </span>

                          <div className="px-8 d-flex flex-column align-items-start">

                            <small style={{ fontSize: "15px", display: `${e.workType === "Homemaker" ? 'none' : 'block'} ` }}>
                              <b> {e.workType !== "Professional" && e.jobTitle} </b>
                              {e.workType !== "Professional" && e.jobTitle && "at "}
                              <b>{e.companyName}</b>{" "}
                              &#40;{e.workType}&#41;
                            </small>



                            <small style={{ display: `${e.workType !== "Homemaker" ? 'none' : 'block'}` }}> <b> {e.workType} </b></small>

                            <div style={{ display: `${e.workType === "Homemaker" ? 'none' : 'block'}` }}>
                              <small className="fs-12">
                                {new Date(e.startDate).getFullYear()}
                              </small>{" "}
                              <small>-</small>{" "}
                              <small className="fs-12">
                                {e.currentlyWorking === "false" ? new Date(e.endDate).getFullYear() : "present"}
                              </small> {e.campanyLocation && <small className="fs-6">&#xb7; {" "} </small>}
                              <small style={{ display: `${e.workType === "Homemaker" ? 'none' : 'inline'}` }}>{e.campanyLocation}</small>
                            </div>

                            <small className="mt-2">{e.aboutCompany}</small>

                          </div>
                        </div>

                        <div className="flex">
                          <img
                            src={`${BASE_URL_STATIC}/icon/pen.svg`}
                            alt="img"
                            onClick={() => {
                              setOpenEditWork(true);
                              setWorkId(e._id);
                              setworkObj(e)
                            }}
                            style={{
                              background: "#E8E8E8",
                              padding: "5px",
                              width: "22px",
                              height: "22px",
                              borderRadius: "30%",
                              cursor: "pointer"
                            }}
                          />

                          <BsTrash style={{ cursor: "pointer" }}
                            size={15}
                            onClick={() => {
                              setDelOpen(true)
                              setWorkId(e._id)
                            }}
                          />
                        </div>
                      </div>

                      <hr className="profile_hr" />
                    </div>
                  ))
                  : ""}
              </div>
            </CContainer>
          </CContainer>

          <SkillsPopup open={openSkills} close={handleCloseSkills} loggedInUserId={loggedInUserId} />
          <EditSkillsPopup skillObj={skillObj} open={openEditSkills} close={() => setOpenEditSkills(false)} skillsId={skillsId} />
          <CContainer className="accordion form-group" id="accordionExample">
            <div className="d-flex justify-content-between align-items-center accordion-item">
              <div className="accordion-header me-auto" id="headingEleven">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",

                    fontWeight: "bold",
                    padding: "2px 10px",
                  }}
                >
                  Skills
                </span>
              </div>
              <span
                onClick={handleOpenSkills} className="px-2"
                style={{
                  display: "grid",
                  justifyItems: "end",
                  fontSize: "15px",

                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                <IoMdAddCircle style={{ cursor: "pointer" }} size={20} />
              </span>
            </div>
            <CContainer id="collapseEleven" className="accordion-collapse collapse " aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
              <div className="container p-3 accordion-body">
                {Array.isArray(skillData)
                  ? skillData.map((e, i) => (
                    <div key={i} >
                      <div className="flex my-2" style={{ marginRight: "-10px" }}>
                        {skillsCategory.map((item, ie) => {
                          // Check if category_name exists and then compare to e.skillCategory
                          if (item.category_name && e.skillCategory && item.category_name.toLowerCase() === e.skillCategory.toLowerCase()) {
                            return (
                              <div key={ie} className="flex" style={{ justifyContent: "start" }}>
                                <span className="d-flex" style={{ marginRight: "5px", fontSize: "22px", color: "#808080a8" }}>
                                  {item.icon}
                                </span>
                                <span>
                                  <small className="fs-12">{e.skillCategory}</small>
                                  <p style={{ fontSize: '14px' }}>
                                    {e.skillLevel === 1
                                      ? "Beginner"
                                      : e.skillLevel === 2
                                        ? "Intermediate"
                                        : e.skillLevel === 3
                                          ? "Expert"
                                          : ""}
                                    <span style={{ fontWeight: "bold", margin: "0px 5px" }}>
                                      {e.skillName}
                                    </span>
                                  </p>
                                </span>
                              </div>
                            );
                          }
                          return null;
                        })}

                        <div className="flex">
                          <img
                            src={`${BASE_URL_STATIC}/icon/pen.svg`}
                            alt="img"
                            onClick={() => {
                              setOpenEditSkills(true);
                              setSkillsId(e._id);
                              setskillObj(e);
                            }}
                            style={{
                              background: "#E8E8E8",
                              padding: "5px",
                              width: "22px",
                              height: "22px",
                              borderRadius: "30%",
                              cursor: "pointer"
                            }}
                          />

                          <BsTrash style={{ cursor: "pointer" }} size={15}
                            onClick={() => {
                              setDelOpen(true);
                              setSkillsId(e._id);
                            }}
                          />
                        </div>
                      </div>

                    </div>
                  ))
                  : null}
              </div>
            </CContainer>
          </CContainer>

          <HobbyPopup show={hobbyOpen} close={handleHobbyClose} hobbyObj={selectedHobbies} />
          <CContainer className="accordion form-group" id="accordionExample">

            <div className="d-flex justify-content-between align-items-center accordion-item">
              <div className="accordion-header me-auto" id="headingTwelve">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="true" aria-controls="collapseTwelve"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",

                    fontWeight: "bold",
                    padding: "2px 10px",
                  }}
                >
                  Hobby
                </span>
              </div>

              <span onClick={handleHobbyOpen} className="px-2">
                <img
                  src={`${BASE_URL_STATIC}/icon/pen.svg`}
                  alt=""
                  style={{
                    background: "#E8E8E8",
                    padding: "5px",
                    width: "22px",
                    height: "22px",
                    borderRadius: "30%",
                    cursor: "pointer"
                  }}
                />
              </span>
            </div>

            <CContainer id="collapseTwelve" className="accordion-collapse collapse " aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
              <div className="container hobby_conatiner accordion-body ">
                {Array.isArray(selectedHobbies) && selectedHobbies.map((e, i) => (
                  <div key={i}>
                    {/* <small>{e.icon}</small> */}
                    <p
                      className="my-2 border bg-muted mx-2 p-2 h6 rounded-top text-sm shadow-lg"
                      key={e._id}
                    >
                      {e.hobbyName}
                    </p>
                  </div>
                ))}
              </div>
            </CContainer>


          </CContainer>

          <MosthangoutsPopup open={openHangOut} close={handleClosehangOut} loggedInUserId={loggedInUserId} />
          <HangoutEdit hangObj={hangObj} open={openEditHangout} close={() => setOpenEditHangout(false)} hangoutId={hangoutId} loggedInUserId={loggedInUserId} />
          <CContainer className="accordion form-group" id="accordionExample">
            <div className="d-flex justify-content-between align-items-center accordion-item">
              <div className="accordion-header me-auto" id="headingSix">
                {/* <div className="col-lg-12"> */}
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",

                    fontWeight: "bold",
                    padding: "2px 10px",
                  }}
                >
                  Most Hangout Places
                </span>
              </div>

              <span className="px-2"
                onClick={handleOpenhangOut}
                style={{
                  display: "grid",
                  justifyItems: "end",
                  fontSize: "15px",

                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >

                <IoMdAddCircle style={{ cursor: "pointer" }} size={20} />
              </span>
            </div>
            <CContainer id="collapseSix" className="accordion-collapse collapse " aria-labelledby="headingSix" data-bs-parent="#accordionExample">
              <div className="container p-3 accordion-body">
                {Array.isArray(hangoutDetails) ?
                  hangoutDetails.map((e) => (
                    <div className="form-group" key={e._id}>
                      {places.map((item, i) => {
                        if (item.place === e.placeType) {
                          return (
                            <div className="flex my-2 align-items-start" style={{ marginRight: "-10px" }} key={i}>
                              <div style={{ maxWidth: "79%" }}
                                className="d-flex align-items-start justify-content-start">
                                <span
                                  className="d-flex me-2 mt-2" style={{ color: "#8080809c" }}
                                >
                                  {item.icon}
                                </span>


                                <span style={{ padding: "0px 10px" }}>
                                  <small className="fs-12">
                                    {e.placeType}
                                  </small>
                                  <p style={{ fontSize: "14px" }}> <b> {e.placeName}</b></p>
                                  <small className="fs-12">
                                    {e.placeLocation}
                                  </small>
                                </span>
                              </div>

                              <div className="flex">
                                <img
                                  src={`${BASE_URL_STATIC}/icon/pen.svg`}
                                  alt="img"
                                  onClick={() => {
                                    setOpenEditHangout(true);
                                    setHangoutId(e._id);
                                    setHangObj(e)
                                  }}
                                  style={{
                                    background: "#E8E8E8",
                                    padding: "5px",
                                    width: "22px",
                                    height: "22px",
                                    borderRadius: "30%",
                                    cursor: "pointer"
                                  }}
                                />

                                <BsTrash style={{ cursor: "pointer" }}
                                  size={15}
                                  onClick={() => {
                                    setDelOpen(true)
                                    setHangoutId(e._id);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}

                      <hr className="profile_hr" />
                    </div>
                  )
                  ) : (
                    ""
                  )}
              </div>
            </CContainer>
          </CContainer>

          <ZodiacSign open={openZodiac} close={handleCloseZodiac} val={zodiacVal} config={config} userProfile={userProfile} />
          <CContainer className="accordion form-groups" id="accordionExample">
            <div className="p-1 px-2 d-flex justify-content-between align-items-center accordion-item">
              <div className="accordion-header me-auto" id="headingSeven">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Zodia Sign
                </span>
              </div>
              <span onClick={handleOpenZodiac} >
                <img
                  src={`${BASE_URL_STATIC}/icon/pen.svg`}
                  alt="img"
                  style={{
                    background: "#E8E8E8",
                    padding: "5px",
                    width: "22px",
                    height: "22px",
                    borderRadius: "30%",
                    cursor: "pointer"
                  }}
                />
              </span>
            </div>
            <CContainer id="collapseSeven" className="accordion-collapse collapse " aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
              {zodiacVal && <div className="container accordion-body">
                <div
                  className="d-flex justify-content-start my-2 align-items-center gap-2" style={{ padding: "0px 10px" }}
                >
                  <span className="d-flex" style={{ marginRight: "5px" }}>
                    <img src={`${BASE_URL_STATIC}/icon/zodiac.svg`} alt="" />
                  </span>
                  <span>
                    <small className="fs-12">
                      Astrological Sign
                    </small>
                    <p style={{ fontSize: "14px" }}> <b> {zodiacVal} </b> </p>
                  </span>
                </div>
              </div>}
            </CContainer>
          </CContainer>

          <OtherSocialMediaPopup open={openSocial} close={handlecloseSocial} />
          <OtherSocialMediaEdit open={openEditSocial} close={() => setOpenEditSocail(false)} socialMediaId={socialId} OtherSoc={OtherSoc} />
          <CContainer className="accordion form-groups" id="accordionExample">
            <div className="p-1 px-2 d-flex justify-content-between align-items-center accordion-item">
              <div className="accordion-header me-auto" id="headingFive">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Other Social Media
                </span>
              </div>

              <span style={{ cursor: "pointer" }}>
                <IoMdAddCircle style={{ cursor: "pointer" }} size={20} onClick={handleopenSocial} />
              </span>
            </div>

            <CContainer id="collapseFive" className="accordion-collapse collapse " aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div className="container p-3 accordion-body">
                {Array.isArray(social) ?
                  social.map((e) => (
                    <div className="form-group" key={e._id}>
                      {/* <div className='flex'> */}
                      {socialMedia.map((item, i) => {
                        if (item.name === e.plateform) {
                          return (
                            <div className="flex my-2 align-items-start" style={{ marginRight: "-10px" }} key={i}>
                              <div
                                style={{ maxWidth: "79%" }}
                                key={item.name}
                                className="d-flex align-items-start justify-content-start"
                              >
                                <span
                                  className="d-flex me-2 mt-2"
                                  style={{ color: "#8080809c" }}
                                >
                                  {item.icon}
                                </span>
                                <span style={{ padding: "0px 10px" }}>
                                  <small className="fs-12">
                                    {e.plateform}
                                  </small>

                                  <p style={{ fontSize: "14px" }}>
                                    {e.sociallink === "" ?
                                      <b> {e.userhandle} </b>
                                      :
                                      <Link target="_blank" to={e.sociallink} className="text-dark">
                                        <b> {e.userhandle} </b>
                                      </Link>
                                    }
                                  </p>
                                </span>
                              </div>

                              <div className="flex">
                                <img
                                  src={`${BASE_URL_STATIC}/icon/pen.svg`}
                                  alt="img"
                                  onClick={() => {
                                    setOpenEditSocail(true);
                                    setSocialId(e._id);
                                    setSocialObj(e)
                                  }}
                                  style={{
                                    background: "#E8E8E8",
                                    padding: "5px",
                                    width: "22px",
                                    height: "22px",
                                    borderRadius: "30%",
                                    cursor: "pointer"
                                  }}
                                />

                                <BsTrash style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setDelOpen(true)
                                    setSocialId(e._id)
                                  }}
                                  size={15}
                                />
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                      {/* </div> */}
                      <hr className="profile_hr" />
                    </div>
                  )
                  ) : (
                    ""
                  )}
              </div>
            </CContainer>
          </CContainer>

          {/* Family memeber and Realtionship */}
          <AddRelationPop open={openRelation} close={() => setRelation(false)} />
          <AddEditRelationPop open={openEditRelation} close={() => setOpenEditRelation(false)} relationId={relationId} relationObj={relationObj} />
          <CContainer className="form-group accordion " id="accordionExample">
            <div className="p-1 px-2 d-flex justify-content-between align-items-center  accordion-item">
              <div className="accordion-header me-auto" id="headingFive">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",

                    fontWeight: "bold",
                  }}
                >
                  Family and Relationships
                </span>
              </div>
              <p className="pe-2" onClick={() => setRelation(true)}>
                <span style={{ cursor: "pointer" }}>
                  <IoMdAddCircle style={{ cursor: "pointer" }} size={20} />
                </span>
              </p>

            </div>
            <div id="flush-collapseOne" className="accordion-collapse collapse " aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <div className="row">
                  {Array.isArray(relationsData) && (
                    relationsData.map((item, index) => (
                      <div className="col-lg-6" key={index}>
                        <div className="profile-flex">
                          <div >
                            <span className="d-flex px-3 justify-content-between align-items-center">
                              {item.userId === loggedInUserId ?
                                <div className="flex justify-content-start">
                                  {item.findUser ?
                                    // <img className='image-fluid rounded-pill' style={{ width: "13%" }} src={`${BASE_URL_STATIC}/user/${item.findUser.imageId}`} alt="" />
                                    <img className='image-fluid rounded-pill' style={{ maxWidth: "13%" }}
                                      src={AwsFileUrl + item.secondUserImg}
                                      alt="img"
                                    />
                                    :
                                    <img className='image-fluid rounded-pill' style={{ maxWidth: "13%" }}
                                      src={`${BASE_URL_STATIC}/dummy.png`}
                                      alt="img"
                                    />
                                  }

                                  <p>
                                    <Link className="d-block text-black fs-14" to={`${BASEURL}/profile/${item.secondUserId}`}>{item.secondUserName && item.secondUserName}</Link>

                                    {item.siblingStatus === 0 &&
                                      <span className="text-muted" style={{ fontSize: "13px" }}>
                                        {item.secondUserAs} <small style={{ color: "#008000de" }}>&#40;<i>pending</i>&#41;</small>
                                      </span>}

                                    {item.siblingStatus === 1 &&
                                      <span className="text-muted" style={{ fontSize: "13px" }}>
                                        {item.secondUserAs}
                                      </span>}
                                  </p>
                                </div>
                                :
                                <div className="flex justify-content-start">
                                  {item.findUser && item.findUser.imageId ?
                                    <img className='image-fluid rounded-pill' style={{ maxWidth: "13%" }}
                                      src={AwsFileUrl + item.firstUserImg}
                                      alt="img"
                                    />
                                    :
                                    <img className='image-fluid rounded-pill' style={{ maxWidth: "13%" }}
                                      src={`${BASE_URL_STATIC}/dummy.png`}
                                      alt="img"
                                    />
                                  }

                                  <p>
                                    <Link className="d-block text-black fs-14" to={`${BASEURL}/profile/${item.firstUserId}`}>{item.firstUserName && item.firstUserName}</Link>

                                    {item.siblingStatus === 0 &&
                                      <span className="text-muted" style={{ fontSize: "15px" }}>
                                        {item.firstUserAs} <small style={{ color: "#008000de" }}>&#40;<i>pending</i>&#41;</small>
                                      </span>}

                                    {item.siblingStatus === 1 &&
                                      <span className="text-muted" style={{ fontSize: "15px" }}>
                                        {item.firstUserAs}
                                      </span>}
                                  </p>

                                </div>}

                              <div >
                                {(item.userId !== loggedInUserId && item.siblingStatus === 0) ? <span className="relation_action1"
                                  onClick={
                                    () => {
                                      handleAccept(item._id)
                                    }
                                  }>&#10003;</span>
                                  :
                                  <img
                                    src={`${BASE_URL_STATIC}/icon/pen.svg`}
                                    alt="img"
                                    onClick={() => {
                                      setrelationId(item._id);
                                      setOpenEditRelation(true);
                                      setrelationObj(item)
                                    }}
                                    style={{
                                      background: "rgb(232, 232, 232)",
                                      padding: "5px",
                                      borderRadius: "30%",
                                      cursor: "pointer"
                                    }}
                                  />}

                                {(item.userId !== loggedInUserId && item.siblingStatus === 0) ?
                                  <span className="relation_action2" onClick={() => {
                                    setDelOpen(true);
                                    setrelationId(item._id)
                                  }} >&#10005;</span>
                                  :
                                  <BsTrash className="cp"
                                    // size={10}
                                    onClick={() => {
                                      setDelOpen(true);
                                      setrelationId(item._id)
                                    }}
                                  />}
                              </div>

                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>

            </div>

          </CContainer>

          <MoviePopup open={openMovies} close={() => setMovies(false)} />
          <MovieEditPopup open={openEditMovies} close={() => setOpenEditMovies(false)} movieObj={movieObj} movieId={movieId} />
          <CContainer className="accordion form-group" id="accordionExample">
            <div className="d-flex justify-content-between align-items-center accordion-item">
              <div className="accordion-header me-auto" id="headingNine">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",

                    fontWeight: "bold",
                    padding: "2px 10px",
                  }}
                >
                  Favourite Movie
                </span>
              </div>
              <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine"
                onClick={() => setMovies(true)} className="px-2"
                style={{
                  display: "grid",
                  justifyItems: "end",
                  fontSize: "15px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                <IoMdAddCircle style={{ cursor: "pointer" }} size={20} />
              </span>
            </div>
            <CContainer id="collapseNine" className="accordion-collapse collapse " aria-labelledby="headingNine" data-bs-parent="#accordionExample">
              <div className="container p-3 accordion-body">
                {movieDetails.length > 0
                  ? movieDetails.map((e, i) => (

                    <div className="form-group" key={i}>

                      <div className="flex my-2" style={{ marginRight: "-10px" }}  >
                        <div style={{ maxWidth: "79%" }}
                          className="d-flex align-items-start justify-content-start"
                        >
                          <span
                            className="d-flex"
                            style={{
                              marginRight: "5px",
                              height: "31px",
                              maxHeight: "50px",
                              opacity: "0.6"
                            }}
                          >
                            <img src={`${BASE_URL_STATIC}/icon/fav_movie.svg`} alt="" />
                          </span>
                          <span style={{ padding: "0px 10px" }}>
                            <p style={{ fontSize: "14px" }}>
                              <b>{e.movieName}</b>
                            </p>
                            <small>
                              {e.movieDescription}
                            </small>
                          </span>
                        </div>

                        <div className="flex">
                          <img
                            src={`${BASE_URL_STATIC}/icon/pen.svg`}
                            alt="img"
                            onClick={() => {
                              setOpenEditMovies(true);
                              setMovieId(e._id);
                              setMovieObj(e)
                            }}
                            style={{
                              background: "#E8E8E8",
                              padding: "5px",
                              width: "22px",
                              height: "22px",
                              borderRadius: "30%",
                              cursor: "pointer",

                            }}
                          />

                          <BsTrash
                            style={{ cursor: "pointer" }}
                            size={15}
                            onClick={() => {
                              setDelOpen(true);
                              setMovieId(e._id)
                            }}
                          />
                        </div>
                      </div>

                      <hr className="profile_hr" />
                    </div>

                  ))
                  : ""}
              </div>
            </CContainer>
          </CContainer>

          <FoodPopup open={openFoods} close={() => setFoods(false)} />
          <FoodEditPopup open={openEditFood} close={() => setOpenEditFood(false)} foodObj={foodObj} foodId={foodId} />
          <CContainer className="accordion form-group" id="accordionExample">
            <div className="d-flex justify-content-between align-items-center accordion-item">
              <div className="accordion-header me-auto" id="headingTen">
                <span type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen"
                  style={{
                    background: "#F4F4F4",
                    borderRadius: "10px",
                    fontSize: "15px",

                    fontWeight: "bold",
                    padding: "2px 10px",
                  }}
                >
                  Favourite Food
                </span>
              </div>
              <span
                onClick={() => setFoods(true)} className="px-2"
                style={{
                  display: "grid",
                  justifyItems: "end",
                  fontSize: "15px",

                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                <IoMdAddCircle style={{ cursor: "pointer" }} size={20} />
              </span>
            </div>
            <CContainer id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
              <div className="container p-3 accordion-body">
                {foodDetails.length > 0
                  ? foodDetails.map((e, i) => (

                    <div key={i}>
                      <div className="flex my-2" style={{ marginRight: "-10px" }}>
                        <div style={{ maxWidth: "79%" }}
                          className="d-flex align-items-start justify-content-start"
                        >
                          <span
                            className="d-flex"
                            style={{
                              marginRight: "5px",
                              height: "31px",
                              maxHeight: "50px",
                              opacity: "0.6"
                            }}
                          >
                            <img src={`${BASE_URL_STATIC}/icon/fav_food.svg`} alt="" />
                          </span>
                          <span style={{ padding: "0px 10px" }} className="set_width">
                            <p style={{ fontSize: "14px" }}>
                              <b>{e.foodName}</b>
                            </p>
                            <small>
                              {e.foodDetail}
                            </small>
                          </span>
                        </div>

                        <div className="flex">
                          <img
                            src={`${BASE_URL_STATIC}/icon/pen.svg`}
                            alt="img"
                            onClick={() => {
                              setOpenEditFood(true);
                              setfoodId(e._id);
                              setfoodObj(e)
                            }}
                            style={{
                              background: "#E8E8E8",
                              padding: "5px",
                              width: "22px",
                              height: "22px",
                              borderRadius: "30%",
                              cursor: "pointer",
                            }}
                          />

                          <BsTrash
                            style={{ cursor: "pointer" }}
                            size={15}
                            onClick={() => {
                              setDelOpen(true);
                              setfoodId(e._id)
                            }}
                          />
                        </div>
                      </div>

                      <hr className="profile_hr" />
                    </div>

                  ))
                  : ""}
              </div>
            </CContainer>
          </CContainer>

        </CContainer>
      </section>
    </>
  );
};

export default ProfileUserEditMain;
