import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import CreateNewPost from "../../ScPopup/CreateNewPost";
import { ScApiContext } from "../../useContext/softCornerContext";
import { add_sc_reaction, get_all_softcorner_posts, getPartnerinfo } from "../../../Emilo/UseContext/ScApiHelpers";
import Slider from "react-slick";
import PostTime from "../../../Emilo/Home/CenterBlock/CenterBlockPosts/PostTime";
import axios from "axios";
import { Button } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from "../../../../config";
import { ApiContext } from "../../../Emilo/UseContext/ApiCall";
import { ReadMoreData, scResponsive } from "../../../Emilo/Data/EducationData";
import OnBoadingPopup from "../../Popup/OnBoadingPopup";
import Loader from "../../../Loader";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { IoIosStarOutline, IoIosStar } from "react-icons/io";
import toast from "react-hot-toast";
import EmojiPicker from "emoji-picker-react";
// import MatchPopup from "../../Popup/MatchPopup";



const CenterBlock = ({ setdisplayCB }) => {

  const [PageContent, setPageContent] = useState("")
  const [loader, setLoader] = useState(false)
  const [emojiPickerState, setEmojiPickerState] = useState({});
  // const [favoritePosts, setFavoritePosts] = useState({});


  const { config, scAllposts, setscAllposts, ReadMore, loggedInUserId } = useContext(ApiContext);


  const emojiRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleFocusOut);
    return () => {
      document.removeEventListener("mousedown", handleFocusOut);
    };
  }, []);

  const handleFocusOut = (event) => {
    if (!emojiRef.current || !emojiRef.current.contains(event.target)) {
      setEmojiPickerState({})
    }
  };

  const videoRefs = useRef([]);

  const handleEmojiPickerToggle = (postId) => {
    setEmojiPickerState((prev) => {
      const newState = {};
      for (let id in prev) {
        newState[id] = id === postId ? !prev[id] : false;
      }
      if (!newState[postId]) {
        newState[postId] = true;
      }
      return newState;
    });
  };

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const video = entry.target;
        if (video.tagName === "VIDEO") {
          if (entry.isIntersecting) {
            video.play(); // Play video when in view
          } else {
            video.pause(); // Pause video when out of view
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Observes within the viewport
      threshold: 0.5, // 50% of the video must be visible to trigger
    });

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    // Cleanup observer on component unmount
    return () => {
      videoRefs.current.forEach((video) => {
        if (video) observer.unobserve(video);
      });
    };
  }, []);

  document.title = "Emilo"

  const { setProfileIndex, setSlidertype, scConfig, setRightBlockUser, isSoftCornerPartnerExist, handleFileChange, posts, createPop, setCreatePop, setmatchOpen, softCornerUsers, setIsSoftCornerPartnerExist, setSoftCornerUsers, setDisableNext
  } = useContext(ScApiContext);

  const inputRef = useRef();

  const check_first_time = localStorage.getItem("is_first_time");

  useMemo(() => {
    let isMatched = localStorage.getItem("isMatched")
    if (isMatched === 1) {
      setmatchOpen(true)
    }
    setLoader(true)
    getPartnerinfo(config).then((res) => {
      if (res.status === 200) {
        setIsSoftCornerPartnerExist(true);
        get_all_softcorner_posts(res.data.partnerUserId, config)
          .then((res) => {
            if (res.status === 200) {
              setscAllposts(res.data.posts);
            } else {
              setscAllposts([]);
            }
            setLoader(false)
          })
      }
      else if (res.status === 201) {
        setLoader(false)
        getSoftCornerUsers();
        setIsSoftCornerPartnerExist(false);
      } else {
        setLoader(false)
        setIsSoftCornerPartnerExist(false)
      }
    })
  }, [isSoftCornerPartnerExist])

  const feedslider = {
    autoplay: false,
    arrows: true,
    dots: true,
    slidesToShow: 1,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: scResponsive
  };

  const handleFav = async (postId, action) => {
    await axios.post(`${BASEAPIURL}/post/postEvent`, { postId, eventType: action }, config)
      .then(res => {
        toast.success(res.data.message)
        setscAllposts((prev) => {
          return prev.map(post => {
            if (post._id === postId) {
              return { ...post, isfavorite: action === "remove" ? 0 : 1 };
            }
            return post;
          });
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleReaction = (emoji) => {

    setscAllposts((prev) =>
      prev.map((post) =>
        post._id === emoji.postId
          ? {
            ...post,
            reactions: post.reactions.some(
              (reaction) => reaction.reactionBy === loggedInUserId
            )
              ? post.reactions.map((reaction) =>
                reaction.reactionBy === loggedInUserId
                  ? { ...reaction, reactionType: emoji.emoji }
                  : reaction
              )
              : [
                ...post.reactions,
                { reactionType: emoji.emoji, reactionBy: loggedInUserId },
              ],
          }
          : post
      )
    );

    add_sc_reaction(emoji.emoji, emoji.postId, config)

    // Optionally close the emoji picker after selecting an emoji
    setEmojiPickerState((prev) => ({
      ...prev,
      [emoji.postId]: false,
    }));
  };

  // To get softcorner user list
  const getSoftCornerUsers = async () => {
    await axios.post(`${BASEAPIURL}/softcorner/scuserlist`, {}, scConfig)
      .then(res => {
        setSoftCornerUsers(res.data.message);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // To handle not intrested user of suggestion list
  const handleNotIntrestedUser = async (type, userId, scConfig) => {

    if (type === "sc") {
      setSoftCornerUsers((prev) => prev.filter((user) => user.emiloId !== userId));
    }

    try {
      await axios.post(`${BASEAPIURL}/softcorner/notintuser`, { userId }, scConfig);
    } catch (err) {
      console.error(err);
    }

  };

  // To add my profile view to other views
  const addProfileViews = async (type, otherUserId, config) => {
    // console.log(otherUserId);

    await axios.post(`${BASEAPIURL}/softcorner/createprofileviewsc`, { type, viewUserId: otherUserId }, config)
      .then(res => {
        return null;
      })
      .catch(err => {
        console.log(err);
      });
  };

  if (loader) {
    return (
      <Loader />
    )
  }

  return (
    <div className="position-relative">
      {/* <ScTextPostPopup  PageContent={PageContent} setPageContent={setPageContent}  /> */}

      {/* onboarding popup */}
      {check_first_time === "yes" && <OnBoadingPopup />}

      {/* create new post popup */}
      <CreateNewPost open={createPop} setCreatePop={setCreatePop} posts={posts} close={() => setCreatePop(!createPop)} />

      {/* create new post popup */}
      {/* <MatchPopup open={matchOpen} close={() => setmatchOpen(false)} /> */}


      <section className="vh-100 overflow-y-scroll scrollbar-hidden py-5 pb-lg-3">
        {
          isSoftCornerPartnerExist
          ?
          <>
            {/* Jb partner add kr diya h */}
            <div className="sc-cb-div-1 pt-4" >
              <form>
                <label htmlFor="save">
                  <div className="sc-postBox">
                    {PageContent.length > 0 ?
                      <div className="text-editor" style={{ marginTop: "42px" }}>
                        <div dangerouslySetInnerHTML={{ __html: PageContent }}></div>
                        <Button className="btn-1" ><CiEdit size={20} /></Button>
                        <Button className="btn-2" onClick={() => setPageContent("")}><IoClose size={20} /></Button>
                      </div>
                      :
                      <form className='image-wrap' id='image-wrap'>
                        <input type="text" readOnly className="cnp-textArea" name="createPostText" id="createPostText"
                          style={{
                            // backgroundImage: backgroundImage,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '437px 198px',
                          }}
                          cols="30" rows="5" placeholder="save a moment here "></input>
                      </form>
                    }
                  </div>
                </label>
                <label htmlFor="input">
                  <input type="file" id="input" ref={inputRef} multiple style={{ display: "none" }} onChange={(e) => handleFileChange(e)} />
                </label>
              </form>

              <div className="flex gap-3">
                <img onClick={() => inputRef.current.click()} src={`${BASE_URL_STATIC}/create-icon3.png`} alt="create-icon3" height={30} width={30} />
                <img src={`${BASE_URL_STATIC}/search.png`} alt="create-icon3" height={30} width={30} />
              </div>
            </div>

            {/* Div 2 */}
            <div className="col-12 col-md-10 col-lg-9 mx-auto mt-md-5">
              {
                // scAllposts 
                Array.isArray(scAllposts) && scAllposts.length > 0
                ?
                scAllposts.map((post, index) => (
                  <div key={index} className="mb-3 rounded bg-white p-4">
                    {/* Profile */}
                    <div className="sc-cb-div-2-user-1-profile">
                      <div className="flex justify-content-start gap-3">
                        <div className="imgBox status">
                          {post.imageId && (
                            <img
                              src={AwsFileUrl + post.imageId}
                              alt="user-icon1"
                              height={50}
                              width={50}
                            />
                          )}
                        </div>

                        <div className="sc-cb-div-2-user-1-profile-name">
                          <p className="cb-post-head-name">{post.fname}</p>
                          <small>
                            <PostTime date={post.createdAt} />
                          </small>
                        </div>
                      </div>

                      <div className="flex justify-content-start gap-3 position-relative">

                        <span ref={emojiRef} className="position-absolute" style={{ bottom: '47px', right: "-34%", zIndex: 1000 }}>
                          {emojiPickerState[post._id] && (
                            <EmojiPicker
                              onEmojiClick={(e) => handleReaction({ ...e, postId: post._id })}
                              autoFocusSearch={false}
                            />
                          )}
                        </span>

                        <img onClick={() => handleEmojiPickerToggle(post._id)}
                          src={`${BASE_URL_STATIC}/create-icon1.png`}
                          alt="smiley"
                          height={30}
                          width={30}
                        />

                        {post.isfavorite ?
                          <span className="cp fs-4 text-warning" onClick={() => handleFav(post._id, "remove")} >
                            <IoIosStar />
                          </span>
                          :
                          <span className="cp fs-4 text-warning" onClick={() => handleFav(post._id, "favorite")} >
                            <IoIosStarOutline />
                          </span>
                        }

                      </div>
                    </div>

                    {/* Post */}
                    <div className="sc-cb-div-2-user-1-post">

                      {/* post Type */}
                      {post.type === 'post' && <Slider {...feedslider}>
                        {post._id && post.mediaUrls.map((image, index) => (
                          <div key={index}>
                            {image.filetype === 'image' ?
                              <img src={AwsFileUrl + image.url} alt="imageUrl" className="w-100 object-fit-cover" />
                              :
                              <VideoWithObserver src={AwsFileUrl + image.url} />

                            }
                          </div>
                        ))}
                      </Slider>}

                      {/* location type */}
                      {post.type !== 'text' && post.type === "location" &&
                        <Link className="bg-white rounded-2 d-flex flex-column flex-lg-row gap-2 align-items-center p-2" target='_blank' to={`https://maps.google.com/maps?q=${post.lat}%2C${post.lng}&z=17&hl=en`}>
                          <img className="m-0" src={`${AwsFileUrl + post.mediaUrls[0].url}`} alt="post" onError={(e) => e.target.src = `${BASE_URL_STATIC}/cp-1.png`} style={{ maxWidth: "220px" }} />

                          <div className="d-flex flex-column  align-items-start text-start">
                            <p className="fw-bold">City</p>
                            <p className="fs-14">{post.city}</p>

                            <br />

                            <p className="fw-bold">Place</p>
                            <p className="fs-14">{post.place}</p>
                          </div>
                        </Link>
                      }


                    </div>

                    <div className="position-relative mb-4">
                      {Array.isArray(post.reactions) && post.reactions.length > 0 && (
                        <span className="position-absolute start-0 d-flex ">
                          {post.reactions.map((e, i) => (
                            <span key={i}>{e.reactionType}</span>
                          ))}
                        </span>
                      )}
                    </div>

                    {/* Description */}
                    {post.type === 'text' ?
                      <div className="text_post_sc">
                        <ReadMoreData>
                          <div dangerouslySetInnerHTML={{ __html: post.description }}></div>
                        </ReadMoreData>
                      </div>

                      :
                      <div className="text_post_sc">
                        <ReadMore>
                          {post.description}
                        </ReadMore>
                      </div>}


                  </div>
                ))
                :
                <div className="sc-cb-div-2-user-1-blank">
                  <div className="sc-cb-div-2-user-1-blank-img">
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-no-post.svg`} alt="sc-no-post" />
                  </div>

                  <h3 className="sc-cb-div-2-user-1-blank-head">You have no activity : (</h3>
                  <span className="sc-cb-div-2-user-1-blank-msg">Looks like you hasn’t posted anything yet. Post an image to get started</span>
                </div>
              }
            </div>
          </>
          :
          <>
            {/* Jb partner add nhi kiya h */}
            <div className="py-3 pb-lg-0">

              <div className="py-3 py-sm-4">
                <h6 className="trending_info">
                  <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt, qui. Aut ex repellat non sit eum? Corrupti beatae quo nulla tenetur commodi molestiae doloribus sequi consequatur! Commodi animi quis officia.</span>
                  {/* <FontAwesomeIcon icon={faCommentDots} color="#fff" /> */}
                </h6>
              </div>

              <div className="">
                <h6 className="trending_head d-flex gap-2">
                  <img className="" src={`${BASE_URL_STATIC}/SoftCorner/suggestion-1.svg`} alt="suggestion" height={20} width={20} />
                  <span>
                    Suggested Users
                  </span>
                </h6>
              </div>

              <div className="sc-cb-user-cards">
                {
                  softCornerUsers.map((user, index) => (
                    <div className="sc-cb-user-card rounded-4 shadow-sm" key={index}>
                      <div className="position-relative">
                        {/* Profile */}
                        <div className="sc-cb-user-card-profile rounded-4">
                          <img src={`${AwsFileUrl + (user.profileImg.scProfileImg || user.profileImg.imageId)}`} className="h-100 w-100 object-fit-cover rounded-4" alt="user" />
                        </div>

                        {/* Softcorner */}
                        <div className="sc-cb-user-card-icon-1 position-absolute top-100 translate-middle bg-white p-2 rounded-circle shadow-sm cursor-pointer" onClick={() => {
                          addProfileViews("view", user.emiloId, config);
                          setRightBlockUser(user);
                          setProfileIndex(index);
                          setSlidertype("softcorner");
                          setdisplayCB('right');
                          setDisableNext(false)
                        }}>
                          <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} style={{ width: '20px' }} alt="logo" />
                        </div>

                        {/* Close */}
                        <div className="sc-cb-user-card-icon-2 position-absolute top-100 translate-middle bg-white px-2 pt-1 pb-2 rounded-circle shadow-sm cursor-pointer" onClick={() => handleNotIntrestedUser("sc", user.emiloId, scConfig)}>
                          <img src={`/images/icons/cut.svg`} style={{ height: '20px', width: '20px' }} alt="cut" />
                        </div>
                      </div>

                      <p className="mt-4 fw-bold text-center overflow-x-hidden text-nowrap text-truncate mx-auto" style={{ width: '90%' }}>{user.fname}</p>
                      <p className="mb-3 mx-auto overflow-hidden text-center sc-address" style={{ height: '27px', maxWidth: '80%', wordBreak: 'break-all' }}> {user.age} {user.address && "-"} {user.address && user.address}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </>
        }
      </section>


      {/* <div className="sc-loader-head" style={{"zIndex" : 10}}> 
          <div className="sc-loader"></div>
        </div> */}
    </div>
  );
};

const VideoWithObserver = ({ src }) => {
  const { ref, inView } = useInView({
    threshold: 1, // Video plays when 50% visible
  });

  return (
    <video ref={ref} muted controls width="100%" style={{ borderRadius: "15px" }} autoPlay={inView} >
      <source src={src} />
    </video>
  );
};

export default CenterBlock;