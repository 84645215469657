import React, { useContext, useRef, useState } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ApiContext } from '../UseContext/ApiCall';
import { BASE_URL_STATIC } from '../../../config';
import { socialMediaUser } from '../UseContext/ApiHelpers';
import AfterGooglePopup from '../Popup/AfterGooglePopup';

const GoogleLoginButton = () => {

   const [showAfterGooglePopup, setShowAfterGooglePopup] = useState(false);

   const { setLoggedInUserId } = useContext(ApiContext);

   const googleRef = useRef();

   const handleClick = () => {
      if (googleRef.current) {
         googleRef.current.click();
      };
   };
   const currentLocalTime = new Date().toLocaleString()

   // To handle after google popup
   const handleAfterGooglePopup = () => {
      setShowAfterGooglePopup(true);
   };

   const nav = useNavigate();

   const handleSignup = async (decode, googeledata) => {
      const username = decode.email.split("@");
     

      const data = {
         email: decode.email,
         fname: decode.given_name,
         lname: decode.family_name,
         username: username[0],
         password: "Aviansh123$" +currentLocalTime, 
         data :  googeledata
      };

      socialMediaUser(data).then(async (res) => {
         try {
            // console.log('res : ', res.data.user);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("loggedInUserId", res.data && res.data.user._id);
            setLoggedInUserId(res.data && res.data.user._id);

            if (res && res.data.user.gender === '' && res.data.user.dob === null && res.data.user.mobile === null) {
               handleAfterGooglePopup();
            }
            else {
               toast.success(res.data.message);
               nav("/")
            };
         } catch (error) {
            toast.error("failes to login")
         }
      })

   };

   return (
      <>
         {/* After Google Popup */}
         <AfterGooglePopup showAfterGooglePopup={showAfterGooglePopup} setShowAfterGooglePopup={setShowAfterGooglePopup} />

         <div className='google_body'>
            <div className=' google_btn google_btn d-flex align-items-center' onClick={handleClick}>
               <img className='continue-right-select-img me-3' src={`${BASE_URL_STATIC}/icon/google.svg`} alt="google" />
               <span className='continue-right-select-span'>Continue with Google</span>
            </div>

            <div className='google_btn_ui'>
               <GoogleOAuthProvider clientId="802602994771-8ekcf9cpbne6ra7rt9ts2nmhuj11lns5.apps.googleusercontent.com">
                  <GoogleLogin ref={googleRef} auto_select={false} style={{ display: 'none' }} use_fedcm_for_prompt={GoogleLoginButton}
                     onSuccess={credentialResponse => {
                        const decode = jwtDecode(credentialResponse.credential)
                       
                          

                        handleSignup(decode, credentialResponse.credential);
                     }}
                     onError={() => {
                        console.log('Login Failed');
                     }}
                  />
               </GoogleOAuthProvider>
            </div>
         </div>
      </>
   )
}

export default GoogleLoginButton;
