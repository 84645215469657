import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../../UseContext/ApiCall";
import { checkEmail, checkNumberforget, email_otp_send } from "../../../UseContext/ApiHelpers";
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from "../../../../../config";
import axios from "axios";
import toast from "react-hot-toast";

const ForgetPassword = () => {

   const [emailMessage, setEmailMessage] = useState("");
   const [numberMessage, setNumberMessage] = useState(false);
   const [btnDisable, setbtnDisable] = useState(true);
   const [btnDisable2, setbtnDisable2] = useState(true);
   const [UserData, setUserData] = useState([]);
   
   // const [activePhone,setActivePhone] = useState

   
   
   const { phoneNumber, setPhoneNumber, otpEmail, setOtpEmail } = useContext(ApiContext);
  

   const handleRadioChange = (event) => {
      setOtpEmail(event.target.value);
      setbtnDisable2(false); 
   };

   function handleChange(e) {
      const { value } = e.target;
     

      if (value.length === 0 || value === "" || value === null) {
         setEmailMessage(null)
         setNumberMessage(null)
      }
      else if (value.includes("@") && (value.includes(".com") || value.includes(".in") || value.includes(".org"))) {
         checkEmail(value).then((res) => {
            console.log("res", res);
            if (res !== "email already taken") {
               setEmailMessage("sorry! email not registered");
               setbtnDisable(true)
            } else {
               setEmailMessage(null);
               setbtnDisable(false)
               setOtpEmail(value)
            };
         });
      }

      else if (/^\d{10}$/.test(value)) {

         checkNumberforget(`91${value}`).then((res) => {
           console.log('res mobile ', res); 
            if (res !== 1) {
               setNumberMessage("sorry! mobile number not registered");
               setbtnDisable(true)

            } else {
               setNumberMessage(null);
               setbtnDisable(false)
               setPhoneNumber(value)
            };
         });
      }
      else {
         setbtnDisable(true)
      }
   };

   useEffect(() => {
      setbtnDisable(true)
   }, []);

   const nav = useNavigate();

   const sendotp = async (e) => {
      if (e) {
         e.preventDefault();  
       }
      if (phoneNumber !== null) {
         try {
            await axios.post(`${BASEAPIURL}/user/otp-send`, { mobile:  "+91"+phoneNumber }).then((res) => {
               toast.success(res.data.otp)
               nav("/signup/fp-verify-otp")
               setbtnDisable(false)
            })
         } catch (error) {
            toast.error("failed to send otp")
            setbtnDisable(false)
         }
      }

      else {
         email_otp_send(otpEmail).then((res) => {
            toast.success(res)
            nav("/signup/fp-verify-otp")
            setbtnDisable(false)
         }).catch(() => {
            toast.error("failed to send otp")
            setbtnDisable(false)
         })

      }
   };

   const findUser = async () => {
      


      // if (phoneNumber.length >= 12 && !isNaN( phoneNumber)) {
         try {

        
            const response = await axios.post(`${BASEAPIURL}/user/userlistchangepass`, { mobile:  phoneNumber });
           
            setUserData(response.data.user);
            if(response.data.user.length===1){
               sendotp();

            }

         } catch (error) {

            toast.error("Failed to send OTP");
         }
      // } else {
      //    toast.error("Invalid phone number. Please enter a valid number with at least 12 digits.");
      // }
   };
   

   return (
      <div className="row vh-100 mx-auto">
         {/* Left */}
         <section className="d-none d-lg-block col-lg-6 p-0 h-100 ">
            <img className="bg-primary h-100 w-100" src={`${BASE_URL_STATIC}/forget-pass.png`} alt="logo" />
         </section>

         {/* Right */}
         <section className="col-12 col-lg-6 p-0">
            <div className="text-center">
               <img className="login-logo my-4 my-md-4 my-lg-5" src={`${BASE_URL_STATIC}/logo.png`} alt="" srcSet="" />
            </div>

            <form className="w-75 mx-auto" onSubmit={sendotp}>
               <h4 className="eam-rb-header mx-0 my-2 font-weight-bold">Forgot Password</h4>

               <small className="my-2">Please provide the Email /Mobile number associated with your account for password reset.</small>

               <input type="text" className="form-control form-control-lg rounded-pill mt-3" onChange={(e) => {
                  handleChange(e);  // Call the first function
                 // Call the second function
               }} placeholder="Email/mobile" />
               <small className="text-danger ms-2">{emailMessage}</small>
               <small className="text-danger ms-2">{numberMessage}</small> <br />
             
         
                  
          {UserData && UserData.length > 1 &&
    UserData.map((user, index) => (
        <div className="searchList  my-3 d-flex  align-items-center" key={index}>
            <div className="col-12 col-lg-8">
                <div
                    className={` me-2 ms-2 py-2 px-3 mt-1 d-flex box-shadow border  rounded-2 border-1 
                    ${otpEmail === user.email ? 'border-primary text-primary' : 'border-secondary text-black'}`}
                    style={{
                        background: otpEmail === user.email ? 'rgba(13, 110, 253, 0.1)' : 'transparent', 
                        transition: 'all 0.3s ease-in-out' 
                    }}
                >
                  <div className="d-flex">
                    <div className="p-2">
                        <img
                            className="searchImage mr-1"
                            src={user.imageId ? (AwsFileUrl + user.imageId) : `${BASE_URL_STATIC}/dummy.png`}
                            alt="profile"
                            onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`}
                        />
                    </div>

                    <div className="userSearchList mt-1  fw-bold">
                        <span>{user.fname}</span>
                        <div>{user.username ? <span>{user.username}</span> : <span>{user.fname}</span>}</div>
                    </div>
                  </div>

                    <input
                        type="radio"
                        id={user.email}
                        name="UserId"
                        className="cp"
                        value={user.email}
                        aria-label="..."
                        checked={otpEmail === user.email}
                        onChange={handleRadioChange}
                    />
                </div>
            </div>
        </div>
    ))
}





              { UserData && UserData.length === 0 ? <button type="button" onClick={findUser} className="btn btn-primary rounded-pill mt-3 w-100 fs-5" disabled={btnDisable}>Next</button> : <button type="button" onClick={sendotp} className="btn btn-primary rounded-pill w-100 fs-5" disabled={btnDisable2}>Next</button>}
{/*                
               <button type="button" onClick={sendotp} className="btn btn-primary rounded-pill w-100 fs-5" disabled={btnDisable}>Next</button> */}

            </form>
           
         </section>
      </div>
   );
};

export default ForgetPassword;
