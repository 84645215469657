import React, { useRef, useState } from 'react';
import LeftBlock from '../Home/LeftBlock/LeftBlock';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';


const Check = ({ className }) => {

  const [specialDay, setSpecialDay] = useState({
    location: '',
    lat: '',
    lng: '',
  });

  // --- Google Map Plateform ---

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60',
    libraries: ['places'],
  });

  const addAutocompleteRef = useRef(null);

  // Handle autocomplete load while adding
  const handleAddAutocompleteLoad = (autocomplete) => {
    addAutocompleteRef.current = autocomplete;
  };

  // Handle place changed while adding
  const handleAddPlaceChanged = () => {
    const place = addAutocompleteRef.current.getPlace();

    if (place && place.geometry) {
      setSpecialDay((prevState) => ({
        ...prevState,
        location: place.formatted_address,
        lat: place.geometry.location.lat(), 
        lng: place.geometry.location.lng(), 
      }));
    } else {
      alert('Please select a valid location from the suggestions.');
    }
  };


  if (!isLoaded) return <div>Loading...</div>;


  return (
    <>
      <section className={`section-main ${className || ''}`}>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            {/* Left Block */}
            <div className="col-lg-3 d-none d-lg-block shadow z-1">
              <LeftBlock />
            </div>

            {/* Right Block */}
            <div className="col-lg-9 pt-5 overflow-y-scroll scrollbar-hidden px-3" style={{ background: "lightblue", height: '100vh' }}>
              <h3 className='pt-4 text-center fw-bold'>Check</h3>

              {/* Place */}
              <Autocomplete
                className="flex-grow-1"
                onLoad={handleAddAutocompleteLoad}
                onPlaceChanged={handleAddPlaceChanged}
              >
                <input type="search" className="form-control" placeholder="Search places here..." 
                  value={specialDay.location}
                  onChange={(e) => {
                    console.log('e : ', e);
                    
                    setSpecialDay((prev) => ({
                      ...prev,
                      location: e.target.value,
                      lat: '',
                      lng: '',
                    }))
                  }}
                />
              </Autocomplete>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Check;