import React, { useContext, useState, useMemo,  useEffect} from 'react';
import { ApiContext } from '../UseContext/ApiCall';
import PostTime from '../Home/CenterBlock/CenterBlockPosts/PostTime';
// import NotificationNamePopup from '../Popup/Notification/NotificationNamePopup';
import { useNavigate } from 'react-router-dom';
import { AwsFileUrl, BASEAPIURL, BASE_URL_STATIC } from '../../../config';
import { getNotification, getUserStories, seenNotifications } from '../UseContext/ApiHelpers';
import axios from 'axios';
import FollowRequestPopup from './FollowRequestPopup';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ViewStoriesPopup from '../Popup/Home/CenterBlock/Stories/ViewStoriesPopup';
import {messaging2,  onMessage} from '../../../firebaseConfig';



const Notifications = () => {

  // const [showNotificationNamePopup, setShowNotificationNamePopup] = useState(false);
  const [toggleFollow, setToggleFollow] = useState(false)
  const [openFollow, setOpenFollow] = useState(false)
  const [followData, setFollowData] = useState({})


  const { config, isNotificationStopped,
    handleChangeStopAllNotification, notification, setnotification,
    setShowViewStoriesPopup,
    setUserStories,
   
    

    
  } = useContext(ApiContext);

  const nav = useNavigate();

  localStorage.removeItem('showNewNotificationDot');
 

  useMemo(() => {
    if (openFollow === false) {
      getNotification(config).then((res) => {
        setnotification(res)
      })
    }
  }, [openFollow]);




  useEffect(() => {
    // Listen for foreground messages
    onMessage(messaging2, async (payload) => {
      try {
        console.log('Message received xyz:', payload);
        
        // Attempt to fetch notifications
        const res = await getNotification(config);
        setnotification(res); // Assuming `setNotification` is properly defined
  
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    });
  }, []);
  







  const handleAcceptandFollow = async (type, id, userID) => {
    if (type === 'accept') {
      await axios.post(`${BASEAPIURL}/user/acceptefollowersreq`, { followingID: userID }, config);
      setToggleFollow(true);

    } else if (type === 'reject') {
      await axios.post(`${BASEAPIURL}/user/deletefollowersreq`, { followingID: userID }, config);
    }
    seenNotifications(id, config)
    setnotification((prev) => prev.filter((notif) => notif.notifications[0]._id !== id));
  };

  const handleClick = (n) => {
    if (n.targetEvent === 'follow' && n.users.length > 1) {
      setFollowData({ notification: n.notifications, users: n.users });
      setOpenFollow(true)
    }else if(n.targetEvent === 'mention'){
      n.notifications.map((e) => (
        seenNotifications(e._id, config)
      ))
      getUserStories1(n.notifications[0].userID)

    }
    else if (n.targetEvent !== 'follow') {
      n.notifications.map((e) => (
        seenNotifications(e._id, config)
      ))
      nav(`/showCopyLink/${n.targetEventId}`)
    }
  }

  const handleAllRead = () => {
    notification.forEach((e) => {
      e.notifications.forEach((e) => {
        seenNotifications(e._id, config).then(() => {
          setnotification([])
        }).catch((err) => {
          console.log(err);
        })
      })
    })
  }

  async  function getUserStories1(id){
     
    try {
      const data = await getUserStories(id, config);
      console.log("data zxy", data)
      setUserStories(data.stories);
      setShowViewStoriesPopup(true)
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className='notification_head'>

      {/* <StoryforSearch/> */}
      <ViewStoriesPopup/>

      {/* Notification Name Popup */}
      {/* <NotificationNamePopup showNotificationNamePopup={showNotificationNamePopup} setShowNotificationNamePopup={setShowNotificationNamePopup} /> */}

      <FollowRequestPopup setFollowData={setFollowData} config={config} open={openFollow} close={() => setOpenFollow(false)} followData={followData} />
      <h6 className="trending_head pt-lg-5 mt-lg-5">Notifications</h6>
      {notification.length > 0 && <p className='text-end text-primary m-2 cp' onClick={handleAllRead}>
        <span><FontAwesomeIcon icon={faCheck} /></span> Mark all as read
      </p>}
      {
        !isNotificationStopped
          ?
          // <section className='notifications'  onScroll={handleNotificationInfiniteScroll}>

          <section className='notification w-75 m-auto' >

            {/* New Notification */}
            {
              notification
              &&
              notification.map((n, index) => (
                <section className='oldNotification' key={index}>
                  {/* <Link to={`/showCopyLink/${n.targetEventId}`} className='w-100'> */}
                  <section className='flex p-2' onClick={() => handleClick(n)}>
                    <div className='flex justify-content-start gap-2'>
                      <img className='oldNotification-follow-1-img rounded-circle me-2 me-sm-3' src={AwsFileUrl + n.users[0].imageId} alt="profile" />

                      <div className='d-flex flex-column align-items-start' >
                        <p className='fs-14'>{n.description}</p>
                        <span className='fs-14 text-secondary'>
                          <PostTime date={n.notifications[0] && n.notifications[0].createdAt} />
                        </span>
                      </div>
                    </div>

                    {/* {
                      n && n.notifications[0].targetEvent === "mention" ? (
                        <p className='fs-14' onClick={() => getUserStories1(n.notifications[0].userID)}>
                          {n.description}
                        </p>
                      ) : (
                        <button onClick={() => getUserStories1(n.notifications[0].userID)}>
                          View Story
                        </button>
                      )
                    } */}

                    {/* ---------- like ---------- */}

                    {n.notifications[0] && n.notifications[0].targetEvent !== 'follow' && <img className='oldNotification-like-1-post ms-auto' src={AwsFileUrl + n.targetImage} alt="post" />}

                    {/* ---------- Follow ---------- */}

                    {/* <img className='oldNotification-like-1-post ms-auto' src={AwsFileUrl + n.targetImage} alt="post" /> */}

                    {n.notifications.length === 1 && n.notifications[0] && n.notifications[0].targetEvent === 'follow' &&
                      <div>
                        {!toggleFollow &&
                          <div className='flex'>
                            <button type="button" className="followUnfollowBtn" onClick={() => handleAcceptandFollow('accept', n.notifications[0]._id, n.notifications[0].userID)}>
                              Accept
                            </button>

                            <button type="button" className="followUnfollowBtn bg-danger" onClick={() => handleAcceptandFollow('reject', n.notifications[0]._id)}>
                              Reject
                            </button>
                          </div>
                        }
                      </div>
                    }
                    {n.notifications.length > 1 && n.notifications[0] && n.notifications[0].targetEvent === 'follow' &&
                      <div onClick={(e) => e.stopPropagation()}>
                        <button type="button" className="followUnfollowBtn"
                          onClick={() => {
                            setOpenFollow(true);
                            setFollowData({ notification: n.notifications, users: n.users });
                          }}>
                          View
                        </button>
                      </div>
                    }

                    {n.notifications.length > 1 && n.notifications[0] && n.notifications[0].targetEvent === "mention" &&
                      <>

                      </>

                    }

                    {/* <FollowUnfollowButton otherUserId={n.notifications[0].createdAt} /> */}
                  </section>
                  {/* </Link> */}

                </section>
              ))
            }

          </section>

          :
          <section className='notifications-stop'>
            <div>
              <img src={`${BASE_URL_STATIC}/popup-icon2.png`} alt="notification" />
            </div>

            <h6>You have stopped all notifications if you want to see again <span onClick={() => handleChangeStopAllNotification(config)}>click here</span></h6>
          </section>
      }
    </div>
  )
}

export default Notifications;
