import React, { useContext } from 'react';
import { settingContext } from '../../../UseContext/SettingContext';
import { Link } from 'react-router-dom';
import { BASEURL, BASE_URL_STATIC } from '../../../../../config';



const SettingInputUser = ({ settingNav, settingKey, settingType, header, message, commentNote, repliesNote, tagNote }) => {

  const { 
    
    showList, list1, list2, selected, added,

    handleFocus,
    handletBlur,
    handleChange,
    handleSelect,
    handleRemove,
    handleCreate,
    handleDelete,

  } = useContext(settingContext);

  return (
    <>
      <section className={`${message && `mb-3`} px-0`}>
        {header && <h6 className='fw-medium mb-1'>{header}</h6>}

        {/* Selected users */}
        <div className='d-flex align-items-center'>
          <div className={`${selected[settingKey] && selected[settingKey].length !== 0 && `border shadow-sm`} px-0 py-1 rounded-1 me-2 w-100`}>
            <div className={`${selected[settingKey] && `my-1`}`}>
              {
                selected[settingKey] && selected[settingKey].map((user, index) => (
                  <div className='d-inline-block px-3 me-2 mb-2 rounded-1 text-white bg-primary shadow-sm' key={index} onClick={() => handleRemove(settingKey, user)}>
                    <Link to={`${BASEURL}/profile/${user.otherUserId}`} className='cursor-pointer text-white'>{user.otherUserName}</Link>
                    <span className='cursor-pointer ms-3'>x</span>
                  </div>
                ))
              }
            </div>

            <input type="text" className='form-control form-control-sm d-inline' placeholder='Search Followers...'
              onFocus={() => handleFocus(settingNav, settingKey, settingType)} 
              onBlur={() => handletBlur(settingKey)}   
              onChange={(e) => handleChange(e, settingKey)}
            />
          </div>

          <button type="button" className='btn btn-sm btn-primary ms-lg-2' onClick={() => handleCreate(settingNav, settingKey, settingType)} 
            disabled={(!selected[settingKey]) || (selected[settingKey].length === 0)}
          >Add</button>
        </div>

        {message && <small className='form-text'>({message})</small>}

        {commentNote && <small className='form-text'>Note : - You can further filter comments by selecting inappropriate words, phrases, emojis in <a href='#hideWords' className='text-primary cursor-pointer'>Hide Words</a>, to hide comments containing them.</small>}

        {repliesNote && <small className='form-text'>Note : - You can further filter replies by selecting inappropriate words, phrases, emojis in <a href='#hideWords' className='text-primary cursor-pointer'>Hide Words</a>, to hide replies containing them.</small>}

        {tagNote && <small className='form-text'>Note: if you have tagged any person in your post it will be also visible to their audience, if they allow.</small>}

        {/* search user List */}
        <>
          {
            showList[settingKey]
            &&
            <div className='position-relative'>
              {
                (list2[settingKey] && list2[settingKey].length !== 0)
                ?
                <div className='p-2 position-absolute bg-white overflow-y-scroll z-1 rounded-1 shadow' style={{ maxHeight: '267px' }}>
                  {
                    list2[settingKey].map((user, index) => (
                      <div className='d-flex align-items-center mb-2 cursor-pointer' key={index} onMouseDown={(e) => e.preventDefault()} onClick={() => handleSelect(settingKey, user)}>
                        <img className='rounded-circle' src={`${BASE_URL_STATIC}/profile-img1.jpg`} alt="profile" style={{ height: '40px', width: '40px' }} />

                        <div className='mx-3'>
                          <h6 className='fw-semibold'>{user.otherUserName}</h6>
                          <span>{user.otherUserName}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
                :
                (list1[settingKey] && list1[settingKey].length !== 0)
                && 
                <div className='p-2 position-absolute bg-white overflow-y-scroll z-1 rounded-1 shadow' style={{ maxHeight: '267px' }}>
                  {
                    list1[settingKey].map((user, index) => (
                      <div className='d-flex align-items-center mb-2 cursor-pointer' key={index} onMouseDown={(e) => e.preventDefault()} onClick={() => handleSelect(settingKey, user)}>
                        <img className='rounded-circle' src={`${BASE_URL_STATIC}/profile-img1.jpg`} alt="profile" style={{ height: '40px', width: '40px' }} />

                        <div className='mx-3'>
                          <h6 className='fw-semibold'>{user.otherUserName}</h6>
                          <span>{user.otherUserName}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
              }
            </div>
          }
        </>

        {/* Added users */}
        <>
          {
            added[settingKey] && added[settingKey].length !== 0
            &&
            <div className='mt-3 py-1'>
              {
                added[settingKey].map((user, index) => (
                  <div className='d-inline-block px-3 me-2 mb-2 rounded-1 text-white bg-primary shadow-sm' key={index}>
                    <Link to={`${BASEURL}/profile/${user.otherUserId}`} className='cursor-pointer text-white'>{user.otherUserName}</Link>
                    <span className='cursor-pointer ms-3' onClick={() => handleDelete(settingNav, settingKey, settingType, user)}>x</span>
                  </div>
                ))
              }
            </div>
          }
        </>
      </section>
    </>
  )
}

export default SettingInputUser
