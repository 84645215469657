import React, { useEffect, useMemo, useState } from 'react';

const CanvasRecorder = ({ adsPlace }) => {
  function fetchAdsData() {
    console.error('Called Here');
    return [
      { id: 1, img: '', link: '' },
      { id: 2, img: '', link: '' }
    ];
  }
  const visibleTodos = useMemo(() => {
    // ✅ Does not re-run unless adsPlace changed
    return fetchAdsData(adsPlace);
  }, [adsPlace]);

  const [AdsData] = useState(visibleTodos);
  
    function returnConfirmation() {
      alert('2');
    }

    useEffect(() => {
      return () => returnConfirmation();
    }, []);
  
    return (
      <div>{console.error(AdsData)}</div>
    );
  };
  
  export default CanvasRecorder;
  