import React from 'react'
import { Link } from 'react-router-dom';

const AllChatUsers = ({ allUsers, loggedInUserId, setShowUsers }) => {
     console.log("allUsers", allUsers)
   return (
      <div>
         {allUsers && allUsers.length > 0 && <div className='chats-cb-chat-pinned'>
            <div className='chats-cb-chat-pinned-users'>

               {allUsers &&
                  allUsers.map((user, index) => (
                     <div >
                        {user && (user.isGroupChat !== "true") && (
                           <div key={index} className="chats-rb-div">
                              <Link
                                 to={`/chats/${user.chatId}`}
                                 className={`chats-cb-chat-all-user`}
                                 key={index}
                                 onClick={() => setShowUsers(false)}
                              >
                                 <img src={user.profileImg} alt="profile" />
                                 <div className="chats-cb-chat-all-user-nameArea">
                                    <span className="chats-cb-chat-all-user-name">
                                       {user.fname}
                                    </span>
                                    {user.lastMessage && <span className="last-msg">
                                       {user.senderUserId === loggedInUserId
                                          ? "You : "
                                          : user.senderFname + " : "}

                                       {user.lastMessage && user.lastMessage.length > 15
                                          ? user.lastMessage.substr(0, 20) + "..."
                                          : user.lastMessage}
                                    </span>}
                                 </div>
                              </Link>

                              <div className="d-none">

                              </div>
                           </div>

                        )}

                        {user && (user.isGroupChat === "true") && (
                           <div key={index} className="chats-rb-div">
                              <Link
                                 to={`/chats/${user.chatId}`}
                                 className={`chats-cb-chat-all-user `}
                              >
                                 <img src={user.groupProfileImg} alt="profile" />
                                 <div className="chats-cb-chat-all-user-nameArea">
                                    <span className="chats-cb-chat-all-user-name">
                                       {user.groupName}
                                    </span>
                                    {user.lastMessage && <span className="last-msg">
                                       {user.senderUserId === loggedInUserId
                                          ? "You : "
                                          : user.senderFname}
                                       {user.lastMessage && user.lastMessage.length > 15
                                          ? user.lastMessage.substr(0, 20) + "..."
                                          : user.senderFname + " : "}

                                    </span>}
                                 </div>
                              </Link>
                           </div>
                        )}
                     </div>
                  ))}
            </div>
         </div>
         }
      </div>
   )
}

export default AllChatUsers