import React, { useEffect, useState, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { AwsFileUrl, BASE_URL_STATIC } from '../../../config';
import Slider from "react-slick";

const GallaryPopup = ({ open, close, data }) => {
   const [showMore, setShowMore] = useState(false);
   const videosRef = useRef(null);

   useEffect(() => {
      if (data.description && data.description.length > 50) {
         setShowMore(true);
      } else {
         setShowMore(false);
      }
   }, [data]);

   const toggleShowMore = () => {
      setShowMore(!showMore);
   };

   const playPauseVideo = () => {
      const video = videosRef.current;
      if (video.paused) {
         videosRef.current.play();
         console.log("play");
      } else {
         videosRef.current.pause();
         console.log("pause");
      }
   };

   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
   };

   return (
      <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call">
         <Modal.Header closeButton>&nbsp;</Modal.Header>
         <Modal.Body>
            <div className='gallary-post-body'>
               <Slider {...settings}>
                  {data && data.mediaUrls && data.mediaUrls.map((item, index) => (
                     <div key={index}>
                        {item.filetype === "image" ?
                           <img className='gallary-body-img' src={AwsFileUrl + item.url} alt="img" />
                           :
                           <video ref={videosRef} muted onClick={() => playPauseVideo()}>
                              <source src={AwsFileUrl + item.url} />
                           </video>
                        }

                     </div>
                  ))}
               </Slider>

               <div className="gallary-post">
                  <div className='gallary-post-name'>
                     <span>
                        {data &&
                           data.profileImg ? (
                           <img
                              className="gallary-post-img"
                              src={data.profileImg}
                              alt="lb-rectangle-1"
                              height={125}
                              width={125}
                           />
                        ) : (
                           <img
                              className="gallary-post-img"
                              src={`${BASE_URL_STATIC}/dummy.png`}
                              alt="dummy"
                              height={125}
                              width={125}
                           />
                        )
                        }
                     </span>
                     <span>
                        {data && data.fname}
                     </span>
                  </div>

                  <div className='gallary-post-desp'>
                     {showMore
                        ? data.description
                        : data.description && data.description.length > 60
                           ? data.description.substr(0, 60) + "..."
                           : data.description}
                     {data.description && data.description.length > 60 && (
                        <span onClick={toggleShowMore}>
                           {showMore ? " ...Less" : " ...More"}
                        </span>
                     )}
                  </div>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   );
};

export default GallaryPopup;
