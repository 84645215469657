import React,  { useEffect, useState } from "react";
import Routes from "./Routes";
import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonTheme } from "react-loading-skeleton";
import { ApiCall } from "./Components/Emilo/UseContext/ApiCall";
import { ProfileProvider } from "./Components/Emilo/UseContext/ProfileContext";
import { ScApiCall } from "./Components/SoftCorner/useContext/softCornerContext";
import { Toaster } from "react-hot-toast";
import SettingContext from "./Components/Emilo/UseContext/SettingContext";
import BusinessContext from "./Components/Business/UseContext/BusinessContext";
import TwoFactorVerifyPage from "./Components/Emilo/Profile/TwoFactorVerifyPage.js";
import UsageLimitCheck from "./Components/Emilo/Profile/UsageLimitCheck.js";
import { BASEAPIURL, headerUserAPI } from "./config.js";
import axios from "axios";


const App = () => {

  const [TwoFactor, setTwoFactor] = useState(null);
 
  async function CheckUserlogin() {
    try {
      const headers = headerUserAPI(); // Assuming this returns headers
      const response = await axios.post(`${BASEAPIURL}/user/finduserlogin`, { action: 'get', get: new Date() }, { headers: headers });
      let temp = response.data;
      if (temp.data.twoFactored && temp.data.twoFactored !== 0) {
        setTwoFactor(2);  
      } else {
        setTwoFactor(0);
      }
    } catch (error) {
      // console.error(error.message);
      setTwoFactor(3);
      // You could also handle displaying error feedback to the user here
    }
  };

  useEffect(() => {
    CheckUserlogin();
  }, []);

  return (
    <>
      {<UsageLimitCheck setTwoFactor={setTwoFactor} TwoFactor={TwoFactor} />}
      <div style={{ display: TwoFactor !== 'UsageLimitCrossed' ? '' : 'none' }}>
        {TwoFactor !== null && TwoFactor !== 0 && (
        <ApiCall>
          <ScApiCall>
            <ProfileProvider>
              <SettingContext>
                <BusinessContext>
                  <SkeletonTheme baseColor="#E6E6ED" highlightColor="#f4f4f4" width={20} >
                    <Routes />
                    <Toaster />
                  </SkeletonTheme>
                </BusinessContext>
              </SettingContext>
            </ProfileProvider>
          </ScApiCall>
        </ApiCall>
        )}
        {TwoFactor === 0 && <TwoFactorVerifyPage TwoFactor={TwoFactor} setTwoFactor={setTwoFactor} />}
      </div>
    </>
  );
};

export default App;
