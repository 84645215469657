import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const  mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 21.222358, // Default center (use a general area or the first user's lat)
  lng: 81.6538453, // Default center (use a general area or the first user's long)
};

const users = [
  {
    _id: "652cc9d79de6594b428e0908",
    fname: "Avinash",
    imageId: "uploads/652cc9d79de6594b428e0908/uploads/1733732796461-311.webp",
    lat: "21.222358",
    long: "81.6538453",
    distance: 1.2674371399673827
  },
  {
    _id: "677e94d014001bfa594ba452",
    fname: "Avinash",
    imageId: "uploads/652cc9d79de6594b428e0908/uploads/1733732796461-311.webp",
    lat: "21.344",
    long: "71.778",
    distance: 9143.357606574764
  },
 
];

function GooglemapApi() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60',
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={10}
    >
      {users.map((user) => (
        user.lat && user.long && parseFloat(user.lat) !== 0 && parseFloat(user.long) !== 0 ? (
          <Marker
            key={user._id}
            position={{
              lat: parseFloat(user.lat),
              lng: parseFloat(user.long),
            }}
            title={user.fname}
          />
        ) : null
      ))}
    </GoogleMap>
  );
}

export default GooglemapApi;
