import React, { useContext,  useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../UseContext/ApiCall';
import SubReportPost from './SubReportPost';
import { Reportdata } from './ReportApi';


const ReportPost = () => {

 const { showReportPostPopup, setShowReportPostPopup,   } = useContext(ApiContext);
  const [Subreportopen, SetSubreportClose] = useState(false);
  const [value, setValue] = useState("")

  return (
    <>
      <SubReportPost Subreportopen={Subreportopen} SetSubreportClose={SetSubreportClose}  value={value}  />
      <section className='reportPostPopups'>
        <Modal show={showReportPostPopup} onHide={() => setShowReportPostPopup(false)} centered backdrop="static" keyboard={false} className="main-modal" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>
          <Modal.Body className="popupModal-body">
            <section className='reportPostPopup'>
              <h4 className='reportPostPopup-head'>Report Post</h4>

              <p className='text-center my-4'> whay  are   you reporting this  post  ?</p>

              <div className='ml-md-3 pl-md-2'>
                <div className='text-left'>
                  {
                    Object.keys(Reportdata).map((key, index) => (
                      <p
                        key={index}
                        className='mx-4 my-3'
                        onClick={() =>{SetSubreportClose(true); setValue(key)}} // Handle click event
                      >
                        {key} {/* Displaying the key */}
                      </p>
                    ))
                  }
                </div>
              </div>
            </section>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default ReportPost
