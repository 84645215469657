import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { Reportdata } from "./ReportApi";
import SubmiteReportPost from './SubmiteReportPost';


const SubReportPost = ({ Subreportopen, SetSubreportClose, value }) => {
      const [ openReport,  setopenReport]= useState(false)
      const [ subreport , setSubreport] = useState(""); 
      const[ Reports , setReports] = useState("")

 const reportvalues = Object.entries(Reportdata).filter(([key, values]) => key ===  value );

    const data = reportvalues[0];
  useEffect(()=>{
    
    if (data && Array.isArray(data)) {
        const    fulldata  =  Object.entries(data[1]).filter(([key, values]) => key ===  subreport); 
        setReports(fulldata) 
  
    } 


  }, [ subreport])
  


    return (
        <div>
            <SubmiteReportPost openReport={openReport}  setopenReport={setopenReport}  reportvalues={reportvalues} Reports={Reports}  SetSubreportClose={SetSubreportClose}/>
            <section className='reportPostPopups'>
                <Modal show={Subreportopen} onHide={() => SetSubreportClose(false)} centered backdrop="static" keyboard={false} className="main-modal" >
                    <Modal.Header closeButton>&nbsp;</Modal.Header>
                    <Modal.Body className="popupModal-body">
                        {
                            reportvalues.length  > 0 && (
                               <section className='reportPostPopup'>
                                <h4 className='reportPostPopup-head'>{reportvalues[0][0]} Report Post</h4>
                                <p className='text-center my-4'> whay  are   you reporting this  post  ?</p>
    
                                <div className='ml-md-3 pl-md-2'>
                                    <div className='text-left'>
                                        {Object.keys(reportvalues[0][1]).map(item => 
                                        <p key={item} onClick={()=> {setopenReport(true); setSubreport(item) }}>{item.replace("_", " ")}</p>)
                                        }
    
                                    </div>
    
                                </div>
    
    
    
                            </section>)
                        }
                        
                    </Modal.Body>
                </Modal>
            </section>

        </div>
    )
}

export default SubReportPost
