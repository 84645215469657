import React, { useContext, useEffect } from 'react';
import AddSpecialDays from '../Popup/SpecialDays/AddSpecialDays';
import { BASE_URL_STATIC } from '../../../config';
import { ScApiContext } from '../useContext/softCornerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import RightSpecialDays from './RightSpecialDays';
// import { Hearts } from 'react-loader-spinner';


const SpecialDays = () => {

  const {
    setShowAddSpecialDaysPopup,
    specialDay, setSpecialDay,
    specialDays, setSpecialDays, 
    dummy,
    getSpecialDays,
  } = useContext(ScApiContext);

  const formatDate = (inputDateString) => {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr',
      'May', 'Jun', 'Jul', 'Aug',
      'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const inputDate = new Date(inputDateString);

    const month = monthNames[inputDate.getMonth()];
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear();

    const formattedDateString = `${month} ${day}, ${year}`;

    return formattedDateString;
  };

  const handleSearch = (e) => {
    const que = e.target.value
    if (que.length > 0) {
      const filteredSpecialDays = specialDays.filter((specialDay) => {
        return specialDay.title.toLowerCase().includes(que.toLowerCase());
      });
      setSpecialDays(filteredSpecialDays)
    } else {
      setSpecialDays(dummy)
    }
  };

  // handle click add special day button
  const handleAddSpecialDay = () => {
    setShowAddSpecialDaysPopup(true);

    setSpecialDay({
      _id: '',
      title: '',
      category: '',
      categoryOther: '',
      location: '',
      lat: '',
      lng: '',
      date: '',
      time: '',
      setCustomAlert: 'false',
      one_hour: 'false',
      one_day: 'false',
      one_week: 'false',
      one_month: 'false',
      customDate: '',
      customTime: '',    
      note: '',
    });
  };

  // next year same date aane me kitne din bache h
  const handleRemainingDays = (customDate) => {
    // Step 1: Parse the customDate to create a date object
    const startDate = new Date(customDate);
  
    // Step 2: Create a date object for the same date next year
    const nextYearDate = new Date(startDate);
    nextYearDate.setFullYear(startDate.getFullYear() + 1); // Increment year by 1
  
    // Step 3: Calculate the difference in days
    const currentDate = new Date();
    const timeDifference = nextYearDate - currentDate; // Time difference in milliseconds
    const remainingDays = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert milliseconds to days
  
    return remainingDays;
  }

  useEffect(() => {
    getSpecialDays();
  }, []);

  return (
    <>
      {/* Popup */}
      <>
        {/* Add special days popup */}
        <AddSpecialDays />
      </>

      {
        // loader 
        // false
        // ? 
        // <Hearts height="100" width="100" color="#ED1E24" ariaLabel="hearts-loading" wrapperStyle={{}} wrapperClass="gallary-loader" visible={true}  /> 
        // : 
        <section className='sc-specialDays'>
          {/* Center Block */}
          <section className='sc-specialDays-centerBlock mt-5'>
            {/* Div 1 */}
            <div className='sc-specialDays-centerBlock-div-1 row col-10 mx-auto gap-5'>
              <form className='col-8'>
                <label htmlFor="search" className='w-100'>
                  <img src={`${BASE_URL_STATIC}/search.png`} alt="search" height={23} width={25} />
                  <input onChange={handleSearch} className='w-100' type="search" name="search" id="search" placeholder='Search' />
                </label>
              </form>

              <div className='col-3 shadow add_special_days_btn cp' onClick={handleAddSpecialDay}>
                <img src={`${BASE_URL_STATIC}/SoftCorner/sc-addAlbum.png`} alt="sc-addAlbum" height={18} width={18} />
                <span>Add Special Days</span>
              </div>
            </div>

            {/* Div 2 */}
            <div className='row flex-column col-10 mx-auto p-3 gap-3'>
              {
                specialDays && specialDays.length > 0 
                && 
                specialDays.map((value, index) => (
                  <div key={index} className='flex p-4 rounded shadow cp' style={{ backgroundColor: `${value._id === specialDay._id ? "#FF6969" : "#fff"}` }}
                    onClick={() => setSpecialDay(value)}
                  >
                    <div className='flex gap-5' >
                      <FontAwesomeIcon style={{ color: `${value._id === specialDay._id ? "snow" : "#BFBFBF"}` }} icon={faBars} />

                      <div className=''>
                        <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`} alt="sc-sd-heart" height={30} width={30} />
                      </div>

                      <div className='d-flex flex-column align-items-start'>
                        <p className='fs-20' style={{ color: `${value._id === specialDay._id ? "snow" : ""}` }}>{value.title}</p>
                        <small style={{ color: `${value._id === specialDay._id ? "#fdfdfd" : "#2676E1"}` }}>{value.location}</small>
                      </div>
                    </div>

                    <div className='d-flex flex-column align-items-end'>
                      <p className='fs-20 ' style={{ color: `${value._id === specialDay._id ? "snow" : ""}` }}>{handleRemainingDays(value.date)} days left</p>
                      <small style={{ color: `${value._id === specialDay._id ? "#fdfdfd" : "#BFBFBF"}` }}>{formatDate(value.date)}</small>
                    </div>
                  </div>
                ))
              }
            </div>
          </section>

          {/* Right Block */}
          <RightSpecialDays />
        </section>
      }
    </>
  )
}

export default SpecialDays;