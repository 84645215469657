import React, { useState, useContext, useEffect } from 'react';
import { faPenToSquare, faTrashCanArrowUp, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Offcanvas from 'react-bootstrap/Offcanvas';
import GetGroupMemebers from './GetGroupMemebers';
import { ApiContext } from '../../UseContext/ApiCall';
import { AwsFileUrl } from '../../../../config';
import ChatProfileUpdate from './ChatProfileUpdate';
import AddGroupMember from './AddGroupMember';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { deteleGroup } from '../../UseContext/ApiHelpers';
import { useParams } from 'react-router-dom';
import GetMediaFiles from './GetMediaFiles';
import Getdocs from './Getdocs';


const ChatInfo = ({ open, setIsAdded, chatUserList, close, chatProfile, setChatUserList }) => {

   // const [buttonStyle, setButtonStyle] = useState("member");
   const [openProfile, setOpenProfile] = useState(false)
   const [openAddUser, setOpenAddUser] = useState(false)
   const [userAdmin, setUserAdmin] = useState(false)
   // const [userId, setUserId] = useState(false)

   // console.log('a : ', buttonStyle, userId);


   const { config, loggedInUserId } = useContext(ApiContext)


   const groupCreatedTime1 = chatProfile && new Date(chatProfile.createdTime);

   const groupCreatedTime2 = chatProfile && groupCreatedTime1.getDate() + "/" + (groupCreatedTime1.getMonth() + 1) + "/" + groupCreatedTime1.getFullYear();

   // const handleLinks = (item) => {
   //    setButtonStyle(item);
   // };

   useEffect(() => {
      if (chatProfile && chatProfile.fname) {
         // setButtonStyle("media")
      }
   }, [])

   const handleCheck = () => {
      if (chatProfile.isAdmincanMsg === 'true' && chatProfile.iamAdmin === 'true') {
         return false
      }
      else if (chatProfile.isAdmincanMsg === 'true' && chatProfile.iamAdmin === 'false') {
         return true
      }
      else {
         return false
      }
   }

   useEffect(() => {
      const user = chatUserList.find(e => e.userId === loggedInUserId);
      if (user) {
         // setUserId(true)
      } else {
         // setUserId(false)
      }
   }, [chatUserList, loggedInUserId]);

   const { chatId } = useParams()
   const handleDeleteGroup = () => {
      deteleGroup(chatId, config).then(res => {
         if (res.status === 201) {
            window.location.href = "/chats"
         }
      })
   }

   return (
      <div className='chatInfo' >
         <ChatProfileUpdate openProfile={openProfile} closeProfile={() => setOpenProfile(false)} chatProfile={chatProfile} />

         <AddGroupMember setIsAdded={setIsAdded} chatUserList={chatUserList} openAddUser={openAddUser} closeAddUser={() => setOpenAddUser(false)} />

         {
            chatProfile
            &&
            <Offcanvas show={open} onHide={() => close()} placement='end'>
               <Offcanvas.Header>
                  <Offcanvas.Title>
                     {chatProfile.groupName &&
                        <div className='chatInfo-main'>
                           <div className='chatInfo-Header'>
                              <div className='profile_dp'>
                                 <img src={AwsFileUrl + chatProfile.groupProfileImg} alt="" />
                              </div>

                              <div className='d-flex flex-column gap-2'>
                                 <span >{chatProfile.groupName}
                                    {userAdmin && <span className='chatprofile-edit' onClick={() => setOpenProfile(true)}>
                                       <FontAwesomeIcon icon={faPenToSquare} /></span>}
                                    {userAdmin && <span className='chatprofile-add' onClick={() => setOpenAddUser(true)}>
                                       <FontAwesomeIcon icon={faUserPlus} /></span>}
                                 </span>
                                 <small className='fs-12'>Created by - <b>{chatProfile.superAdminName}</b></small>
                                 <small className='fs-12'>Created at - <b>{groupCreatedTime2}</b></small>
                              </div>
                           </div>

                           <div className='chatInfo-desc'>
                              <small>{chatProfile.description}</small>
                           </div>

                        </div>
                     }

                     <div className='container user-chat-profile'>
                        {/* change images after aws started */}
                        {chatProfile.fname && <img src={`${AwsFileUrl + chatProfile.profileImg.imageId}`} alt="" />}

                        {chatProfile.fname && <div>
                           <span>{chatProfile.fname}</span>
                        </div>}
                     </div>

                     {!handleCheck() && chatProfile.isGroupChat !== 'false' && (chatProfile.superAdminId === loggedInUserId) && <div className="leave-group">
                        <Button variant="danger" onClick={() => handleDeleteGroup()}> <FontAwesomeIcon icon={faTrashCanArrowUp} /> Delete group</Button>
                     </div>}

                  </Offcanvas.Title>


               </Offcanvas.Header>
               <Offcanvas.Body >
                  <div className='chatInfo-hr'></div>
                  <div className='chatInfo-body'>

                     {/* chat links */}
                     <Tabs
                        defaultActiveKey={`${chatProfile.isGroupChat ? "member" : "media"}`}
                        id="fill-tab-example"
                        className="mb-3"
                     >
                        {chatProfile.groupName &&
                           <Tab eventKey="member" title={'Members'}>
                              <GetGroupMemebers close={close} chatUserList={chatUserList} setUserAdmin={setUserAdmin} setChatUserList={setChatUserList} config={config} />
                           </Tab>}
                        <Tab eventKey="media" title="Media">
                           <GetMediaFiles chatId={chatId} config={config} />
                        </Tab>
                        <Tab eventKey="docs" title="Docs">
                           <Getdocs chatId={chatId} config={config} />
                        </Tab>

                     </Tabs>

                  </div>
               </Offcanvas.Body>
            </Offcanvas>
         }
      </div>
   );
}

export default ChatInfo;
