import React,{useContext} from 'react';
import { ApiContext } from "../../../../UseContext/ApiCall";
import axios from 'axios';
import { BASEAPIURL } from '../../../../../../config';

const HidePostmessage = ({ids}) => {
 console.log("ids", ids); 


 const {  allDataPost, setallDataPost, Unhide, config} = useContext(ApiContext);
    
 const unhidedata =   Unhide.filter(items => items._id ===  ids);
  console.log("unhidedata", unhidedata)
   

  const handleUnhide = () =>{

    
     const updatedPosts =  allDataPost.map(post => 
        post._id === ids? unhidedata[0] : post
      );
      setallDataPost(updatedPosts)

     axios.post(`${BASEAPIURL}/post/hidepost`, { postId: ids }, config).then(res =>{
        console.log(res.data.status); 
       }).catch(err =>{
        console.log(err); 
     })
    }

    return (
    <>
      <section className='feedBox'>
        <span className='d-block pb-2 cursor-pointer border-bottom'>This is the content of the post. You can hide or show this post message.</span>
        <span className='d-block py-2 cursor-pointer border-bottom' onClick={handleUnhide}> Report Post</span>
        <span className='d-block py-2 cursor-pointer border-bottom' onClick={handleUnhide}> Unfollow {unhidedata[0].fname}</span>
        <span className='d-block py-2 cursor-pointer border-bottom' onClick={handleUnhide}> Mute  {unhidedata[0].fname} </span>
        <span className='d-block pt-2 cursor-pointer' onClick={handleUnhide}> Undo</span>
      </section>
    </>
  );
};

export default HidePostmessage;