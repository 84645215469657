import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, } from "react-router-dom";
import 'react-loading-skeleton/dist/skeleton.css';
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from "../../../config";
import axios from "axios";
import { ApiContext } from "../../Emilo/UseContext/ApiCall";
import '../../../assets/css/softcorner/search-box.css'
import { ScApiContext } from "../useContext/softCornerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CreateNewPostPopup from "../../Emilo/Popup/CreateNewPost/CreateNewPostPopup";
import ScErrorPost from "./ScErrorPost";

const ScHeader = () => {

  const handleLocate = () => {
    window.location.href = "/";
  };

  const [users, setUsers] = useState([])
  const [open, setOpen] = useState(false)
  const [inputValue, setinputValue] = useState("")
  const [errOpen, setErrOpen] = useState(false)


  const { config, socket, socketFun, loggedInUserId, setPostType } = useContext(ApiContext);
  const { setRightBlockUser, btnEnable, setDisableNext } = useContext(ScApiContext);

  const searchBarRef = useRef();

  const handleFocusOut = (event) => {
    if (!searchBarRef.current || !searchBarRef.current.contains(event.target)) {
      setUsers([])
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setinputValue(e.target.value)
    if (e.target.value.length > 0) {
      try {
        const res = await axios.post(`${BASEAPIURL}/user/getalluser?page=1&search=${e.target.value}`, { type: 'softcorner' }, config)
        setUsers(res.data.users)

      } catch (error) {
        return error.response.data;
      }
    } else {
      setUsers([])
      setTimeout(() => {
        setUsers([])
      }, 500);
    }
  };

  const nav = useNavigate();

  const handleSearchAdd = (user) => {
    setDisableNext(true)
    setRightBlockUser(user)
    setUsers([])
    setinputValue("")
    nav('/SoftCorner')
  };

  const urls = ['OnboardingUser', 'OnboardingMobile', 'OnboardingOTP', 'OnboardingImage', 'OnboardingGender', 'OnboardingDOB', 'OnboardingPin', 'checkSoftCornerPin', 'forgotSoftcornerPin', 'chat'];

  useEffect(() => {
    if(!socket) {
      socketFun();
    }; 

    document.addEventListener("mousedown", handleFocusOut);

    return () => {
      document.removeEventListener("mousedown", handleFocusOut);
    };
  }, []);

  useEffect(() => {
    if(socket) {
      socket.emit("online_emilo", loggedInUserId);
    };
  }, [loggedInUserId]);
  
  const handleCreatePost = () => {
    setPostType("scType");
    if (btnEnable) {
      setErrOpen(true)
    } else {
      setOpen(true);
    }
  }

  return (
    <>

      <ScErrorPost open={errOpen} close={() => setErrOpen(false)} />

      <CreateNewPostPopup showCreatePostPopup={open} setShowCreatePostPopup={setOpen} />
      <section className={`${urls.some(url => window.location.href.includes(url)) ? 'd-none' : 'd-flex'} ${window.location.href.includes('chat') && 'd-none d-lg-flex'} justify-content-between align-items-center position-fixed z-2 w-100 mx-auto bg-white shadow-sm p-3 px-md-4 px-lg-5`}>
        {/* <section className={`d-none`}> */}
        {/* Logo */}
        <div className="">
          <img src={`${BASE_URL_STATIC}/logo.png`} className="sc-nav-logo" alt="logo" onClick={() => handleLocate()} />
        </div>

        {/* Search > Mobile */}
        <div className="d-md-none">
          <i className="fa fa-search" style={{ fontSize: '20px' }}></i>
        </div>

        {/* Search > Tab & Laptop */}
        <div className="d-none d-md-flex align-items-center px-3 rounded-pill w-50 position-relative" style={{ boxShadow: '0px 0px 10px #d5d5d5' }}>
          <i className="fa fa-search me-2" style={{ fontSize: '20px' }}></i>
          <input type="search" className="form-control border-0 shadow-none bg-transparent w-100 rounded-pill" value={inputValue} onChange={handleSearch} placeholder="Search Users..." />
          {/* Search > Users List */}
          <div className="">
            {
              (users.length > 0)
              &&
              <div className="list-body" ref={searchBarRef}>
                {
                  users.map((user, i) => {
                    return (
                      <div key={i} className="list border-bottom" onClick={() => handleSearchAdd(user)}>
                        <img src={AwsFileUrl + (user.profileImg.scProfileImg || user.profileImg.imageId)} alt="" width="50px" height="50px" className="rounded-circle" />
                        <div className="flex w-100">
                          <div className="d-flex flex-column ml-3">
                            <span>{user.fname}</span>
                            <small>{user.address}</small>
                          </div>
                          {user.scProfileId && <img src={`${BASE_URL_STATIC}/softcornerLogo.png`} alt="" width='20px' />}
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>
        </div>


        {/* create post button */}
        <button className="create_post_sc_btn" onClick={handleCreatePost}>
          <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
          <span>Create new post</span>
        </button>


        {/* Notification */}
        <Link to="/SoftCorner/notifications" className="">
          <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notification.svg`} alt="" />
          <span></span>
        </Link>

        {/* Chat */}
        <Link to="/SoftCorner/chat" className="">
          <img src={`${BASE_URL_STATIC}/SoftCorner/sc-chat.svg`} alt="" />
        </Link>

        {/* Profile */}
        <Link to="/SoftCorner/Profile" className="">
          <img src={`${BASE_URL_STATIC}/SoftCorner/sc-person.svg`} alt="" />
        </Link>
      </section>
    </>
  );
};

export default ScHeader;
