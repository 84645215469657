import React, { useMemo, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap';
import { RiShareForwardFill } from 'react-icons/ri';
import { useInView } from 'react-intersection-observer';
import { messageSeen } from '../../UseContext/ApiHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faBan } from '@fortawesome/free-solid-svg-icons';
import { AwsFileUrl } from '../../../../config';
import { BsEmojiSmile } from 'react-icons/bs';
import EmojiPicker from 'emoji-picker-react';
import { Link } from 'react-router-dom';
import MsgAudioPlayer from './MsgAudioPlayer';


const MessageCard = ({ reactionShow, elementsRef, highlightIndex, handleScrollToElement, setActiveMsgIndex, setMsgPopup, reactionId, handleDownloadPdf, handleOpenReactions, setOpenReaction, setAllReactions, renderMessageWithLinks, handleReactionPick, optionShowHide, socket, msg, config, formatTime, handleSelect, handleSelectMessage, handleButtonClick, selectMsg, setOpenPopoverId, openPopoverId, loggedInUserId, popover, }) => {

   const { ref, inView } = useInView({
      threshold: 1,
      triggerOnce: true,
   });

   const [seen, setSeen] = useState(false);

   useMemo(() => {
      if (inView && !seen) {
         messageSeen(msg.receiverUserId && msg.receiverUserId[0], msg.chatId, msg.messageId, config).then((res) => {
            setSeen(true);
            socket && socket.emit("message_seen_emilo", {
               senderUserId: msg.senderUserId, receiverUserId: msg.receiverUserId && msg.receiverUserId[0], chatId: msg.chatId, messageId: msg.messageId, isSeen: "1"
            });
         });

      }
   }, [inView, msg]);


   return (
      <div className={`chats-rb-chat-t-box-right ${highlightIndex === msg.messageId ? 'highlight' : ''} `}>
         <OverlayTrigger trigger="click" placement="right" overlay={popover(msg)} show={openPopoverId === msg.createdTime}
            rootClose
            onHide={() => setOpenPopoverId(null)}>

            {msg.isEdited !== '2' ?

               <div ref={ref} className='position-relative show_em_right'>
                  <div
                     style={{
                        backgroundColor: selectMsg.includes(msg.messageId) && "#FFC1C3",
                     }}
                     className='chats-rb-chat-t-box-right-msg'
                     onClick={() => {
                        handleButtonClick(msg.createdTime);
                        if (selectMsg.length > 0) {
                           handleSelect(msg.messageId);
                           handleSelectMessage(msg);
                        }
                     }}
                  >  


                     {reactionId === msg.messageId &&
                        <div ref={reactionShow}>
                           < EmojiPicker
                              className='reaction_picker_right'
                              onEmojiClick={(emoji) => {
                                 handleReactionPick(emoji, msg); // Ensure proper argument passing
                              }}
                              autoFocusSearch={false}
                           />
                        </div>
                     }

                     <div className='chat-rb-msg-right'>
                        <small className='chat-senderFname d-flex justify-content-between gap-3 mb-1'>
                           {msg.senderUserId === loggedInUserId ? "you" : msg.senderFname}
                           {msg.isEdited === '1' && <span className='font-italic text-muted mx-2'>edited</span>}
                        </small>
                        {msg.isForwarded === 'true' && <small className='fwd-symbol'><RiShareForwardFill /></small>}

                        {(msg.replyMessage || msg.replyfileUrl) ?
                           <div onClick={() => handleScrollToElement(msg.replyMessageId)}>
                              {/* types of files */}

                              {/* text messsage */}
                              <div className='reply_box_reply'>

                                 {/* img file */}
                                 {msg && msg.replyfileType === "img_file" &&
                                    <img onClick={(e) => { e.stopPropagation(); setMsgPopup(true); setActiveMsgIndex(msg.messageId) }} src={`${AwsFileUrl + msg.replyfileUrl}`} alt="" style={{ maxWidth: "50px" }} />}


                                 {/* video file */}
                                 {msg && msg.replyfileType === "video_file" &&
                                    <img onClick={(e) => { e.stopPropagation(); setMsgPopup(true); setActiveMsgIndex(msg.messageId) }} src={`${AwsFileUrl + msg.replyThumbnailUrl}`} alt="" style={{ maxWidth: '50px' }} />
                                 }

                                 {/* audio file */}
                                 {msg && msg.replyfileType === "audio_file" &&
                                    <img onClick={(e) => { e.stopPropagation(); setMsgPopup(true); setActiveMsgIndex(msg.messageId) }} src={`${AwsFileUrl + 'images/icon/audio.svg'}`} alt="" style={{ maxWidth: '20px' }} />
                                 }

                                 {/* location */}
                                 {msg && msg.replyfileType === "location" &&
                                    <div onClick={(e) => e.stopPropagation()} className='d-flex flex-column' style={{ maxWidth: "30px" }}>
                                       <img src={AwsFileUrl + 'images/location.png'} width='30px' alt='location' />
                                       {msg.type === 'location' && <span className='fs-12 mt-2'>{msg.message && msg.message.l_address}</span>}
                                    </div>
                                 }

                                 {/* PDF file */}
                                 {msg && msg.replyfileType === "pdf_file" && (
                                    <div className='d-flex justify-content-between align-items-center'>
                                       <img src="/images/icons/chat-pdf.svg" alt="" width={30} />
                                       <span className='fs-12'>{msg.fileName}</span>
                                    </div>
                                 )}

                                 {msg && msg.replyfileType === "contact" && (
                                    <span className='msg_contact_relpy'>
                                       <a href={AwsFileUrl + msg.replyfileUrl} >
                                          <img src={AwsFileUrl + 'default/user.webp'} alt="" />
                                          <small>{msg.message.contactName}</small>
                                       </a>
                                    </span>
                                 )}
                                 <div className='reply-msg'>{msg.replyMessage.length > 50 ? msg.replyMessage.substr(0, 100) + "..." : msg.replyMessage}</div>
                              </div>

                              {/* <span className='fs-12'>{renderMessageWithLinks(msg.message)}</span> */}
                              <div className='reply-box'>
                                 {msg && <span className='fs-12'>{renderMessageWithLinks(msg.message)}</span>}

                              </div>

                              <div className='deliver-time '>
                                 <time>{formatTime(msg.createdTime)}</time>
                              </div>

                           </div>
                           :
                           <div >
                              <div className='reply-box-1' ref={(el) => { (elementsRef.current[msg.replyMessageId || msg.messageId] = el); }}>


                                 {/* types of files */}

                                 {/* text messsage */}

                                 {msg && msg.type === 'text' && <span className='fs-12'>{msg.message}</span>}

                                 {/* img file */}
                                 {msg && msg.type === "img_file" &&
                                    <img onClick={(e) => { e.stopPropagation(); setMsgPopup(true); setActiveMsgIndex(msg.messageId) }} src={`${AwsFileUrl + msg.fileUrl}`} alt="" style={{ maxWidth: "100%" }} />}


                                 {/* video file */}
                                 {msg && msg.type === "video_file" &&
                                    <img onClick={(e) => { e.stopPropagation(); setMsgPopup(true); setActiveMsgIndex(msg.messageId) }} src={`${AwsFileUrl + msg.thumbnail}`} alt="" style={{ maxWidth: '100%' }} />
                                 }

                                 {msg && msg.type === "audio_file" &&
                                     <div onClick={(e) => { e.stopPropagation(); setActiveMsgIndex(msg.messageId) }}>
                                     <MsgAudioPlayer isOnChat={true} fileName={msg.fileName} audioSrc={`${AwsFileUrl + msg.fileUrl}`} />
                                   </div>
                                 }


                                 {/* location */}
                                 {msg && msg.type === "location" &&
                                    <div onClick={(e) => e.stopPropagation()} className='d-flex flex-column' style={{ maxWidth: "200px" }}>
                                       <Link target='_blank' to={`https://maps.google.com/maps?q=${msg.message.lat}%2C${msg.message.lng}&z=17&hl=en`}>
                                          <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMmXcY-Go8LEGF9QR-nS328jtjUCfMTWAzFg&s' alt='location' />
                                       </Link>
                                    </div>
                                 }

                                 {/* PDF file */}
                                 {msg && msg.type === "pdf_file" && (
                                    <div className='d-flex justify-content-between align-items-center'>
                                       <img src="/images/icons/chat-pdf.svg" alt="" width={50} />
                                       <span className='fs-12'>{msg.fileName}</span>
                                       <button className='download_btn' onClick={(e) => { handleDownloadPdf(AwsFileUrl + msg.fileUrl); e.stopPropagation(); }}>
                                          <FontAwesomeIcon icon={faArrowDown} />
                                       </button>
                                    </div>
                                 )}

                                 {msg && msg.type === "contact" && (
                                    <span className='msg_contact' >
                                       <a href={AwsFileUrl + msg.fileUrl} download={msg.message.fileName} >
                                          <img src={AwsFileUrl + 'default/user.webp'} alt="" width='45px' />
                                          <small>{msg.message.contactName}</small>
                                       </a>
                                    </span>
                                 )}

                                 <div className='deliver-time '>
                                    <time>{formatTime(msg.createdTime)}</time>
                                 </div>
                              </div>
                           </div>
                        }


                     </div>
                  </div>

                  <span className='emoji_reaction_right' onClick={() => handleOpenReactions(msg.messageId)}><BsEmojiSmile size={18} /></span>

               </div>

               :
               <div className='chats-rb-chat-t-box-right-msg'>
                  {msg && msg.type !== "location" && <span className='fs-12 fst-italic'><FontAwesomeIcon icon={faBan} /> {renderMessageWithLinks(msg.message)}</span>}
               </div>}

         </OverlayTrigger>

         {msg.reaction && msg.reaction.length > 0 &&
            <div className='reactions_right' onClick={() => { setAllReactions(msg); setOpenReaction(true); }}>
               <span>{msg.reaction.slice(msg.reaction.length - 2).map((r, index) => (
                  <span key={index} className='reaction_right'>{r.reactions}</span>
               ))}</span>
               <span className='reaction_count'>{msg.reaction.length}</span>
            </div>
         }
      </div>
   )
}

export default MessageCard