import React from 'react';


const SoftCornerNotification = () => {
  return (
    <>
      <section className='sc-notifications pt-5 vh-100 overflow-y-scroll scrollbar-hidden mx-auto'>
        <h1 className='pt-4'>Notification Soon...</h1>
      </section>
    </>
  );
};

export default SoftCornerNotification;