import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { ApiContext } from "../UseContext/ApiCall";
import { BASE_URL_STATIC, BASEAPIURL } from "../../../config";
import toast from "react-hot-toast";
import axios from "axios";


function FollowRequest({ delOpen, setDelOpen, otherUserId, setStatus , status}) {
  const {
    allDataPost,
    setallDataPost,
 
    config,
    getFollowerList,
    profileUserId,
    getFollowingList,
  } = useContext(ApiContext);
 const [isFollowing, setIsFollowing] = useState(0);
 const [loader, setLoader] = useState(false);

  const delClose = () => {
    setDelOpen(false);
  };

 const handleDelete = async () => {
   setLoader(true);

    try {
     const res = await axios.post(
       `${BASEAPIURL}/user/followuser`,
       { followingID: otherUserId  ,  action : "unfollow"},
       config
     );
      setStatus(res.data.status);
       setIsFollowing(!isFollowing); // Update follow status
       getFollowerList(profileUserId);
       getFollowingList(profileUserId);
       status === 1 ?   toast.success("Unfollow User"):  toast.success("Cancel Request sent")
       setDelOpen(false)
 
       const updatedPosts = allDataPost.map(post =>
        post.postedBy === otherUserId  ? { ...post, follow: 2 } : post
      );
      setallDataPost(updatedPosts)
      

    
   } catch (err) {
     console.error("Error in follow/unfollow", err);
     toast.error("Failed to follow/unfollow. Please try again.");
   } finally {
     setLoader(false); // Always hide the loader after the process
   }
 };




 

  return (
    <Modal
      show={delOpen}
      onHide={delClose}
      backdrop="static"
      keyboard={false}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center gap-4">
          <div>
            <img src={`${BASE_URL_STATIC}/icon/confirm-delete.svg`} alt="" />
          </div>

          <div>
            <h4 className="text-center">
           {status === 1 ? <b> Unfollow User </b>  : <b> Cancel  Request</b>  }   
            </h4>
            <p className="text-center mt-2 fs-15">
            {status === 1 ? <b>  Are you sure you want to unfollow  ?</b>  : <b>   Are you sure you want to cancel the follow request?</b>  }  
           
            </p>
          </div>

          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "80%" }}
          >
            <Button className="col-5 del-cancel danger" onClick={delClose}>
             No
            </Button>
            <Button className="col-5 del-confim" onClick={handleDelete}>
              {loader ? (
                <div className="del_pop">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                "Yes"
              )}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FollowRequest;
