import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import GooglePlacesAutocomplete from "react-google-places-autocomplete"

const WorkLocation = ({ location, setLocation, defaultLocation , setPlaceId }) => {

  const handlePlaceSelect = async (e) => {
    console.log('location : ', e);

    const add = e.value.terms;
    const addLength = add && add.length;
    setPlaceId(e.value.place_id)

    let newLocation = '';

    if (addLength >= 3) {
      newLocation = add[addLength - 3].value + ", " + add[addLength - 2].value + ", " + add[addLength - 1].value;
    } else if (addLength === 2) {
      newLocation = add[addLength - 2].value + ", " + add[addLength - 1].value;
    } else if (addLength === 1) {
      newLocation = add[addLength - 1].value;
    }
    setLocation(newLocation);
  };

  useEffect(() => {
    setLocation(defaultLocation)
  }, [defaultLocation])

  return (
    <>
      <form className='work_location'>
        {
          location 
          ?
          <p className='location-added'>
            {
              location.length > 60 
              ? 
              <p>{location.substr(0, 60) + "..."}</p> 
              : 
              <p>{location}</p>
            }

            <span style={{ cursor: "pointer" }} onClick={() => setLocation("")}>
              <FontAwesomeIcon icon={faClose} />
            </span>
          </p>
          :
          <GooglePlacesAutocomplete
            apiKey="AIzaSyCclwLbFfRxROyJuuluEBa9v-cu15SEf1U"
            selectProps={{
              onChange: handlePlaceSelect,
              value: location,
              styles: {
                input: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }
            }}
          />
        }
      </form>
    </>
  )
}

export default WorkLocation;