  import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

// ***** Emilo *****

import Login from './Components/Emilo/createUser/login';

import Header from './Components/Emilo/Home/Header/Header';

import Home1 from './Components/Emilo/Home/Home1';
import Home2 from './Components/Emilo/Home/Home2';
import Explore from './Components/Emilo/Explore/Explore';
import Reels from './Components/Emilo/Reels/Reels';
import Memes from './Components/Emilo/Memes/Memes';
import BestClips from './Components/Emilo/BestClips/BestClips'

import EmiloProfile from './Components/Emilo/Profile/Profile';
import ProfilesReels from './Components/Emilo/Profile/ProfileShow/ProfilesReels';
import ProfileBestClips from './Components/Emilo/Profile/ProfileShow/ProfileBestClips';
import ProfilePosts from './Components/Emilo/Profile/ProfileShow/ProfilePosts';
import ProfileVideos from './Components/Emilo/Profile/ProfileShow/ProfileVideos';
import ProfileMemes from './Components/Emilo/Profile/ProfileShow/ProfileMemes';

import ProfileUserEdit from './Components/Emilo/Profile/ProfileUser/ProfileUserEdit/ProfileUserEdit';
import ProfileUserShare from './Components/Emilo/Profile/ProfileUser/ProfileUserShare/ProfileUserShare';

import ProfileUserSetting from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/ProfileUserSetting';
import AccountProfile from "./Components/Emilo/Profile/ProfileUser/ProfileUserSetting/AccountProfile";
import WebNavigation from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/WebNavigation';
import InviteFollow from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/InviteFollow';
import Security from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/Security';
import Privacy from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/Privacy';
import Notification from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/Notification';

import Signup from './Components/Emilo/createUser/multiStepSignup/Signup';
import EmailandMobile from './Components/Emilo/createUser/multiStepSignup/EmailandMobile';
import CodeVerification from './Components/Emilo/createUser/multiStepSignup/CodeVerificationMobile';
import CreatePassword from './Components/Emilo/createUser/multiStepSignup/CreatePassword';
import TwoFactorSetting from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/TwoFactorPage';
import NameRegisteration from './Components/Emilo/createUser/multiStepSignup/NameRegisteration';
import UserName from './Components/Emilo/createUser/multiStepSignup/UserName';

import Favourite from './Components/Emilo/Others/Favourite/Favourite';
import FavouriteReels from './Components/Emilo/Others/Favourite/FavouriteReels';
import FavouriteBestClips from './Components/Emilo/Others/Favourite/FavouriteBestClips';
import FavouriteMemes from './Components/Emilo/Others/Favourite/FavouriteMemes';

import Liked from './Components/Emilo/Others/Liked/Liked';
import LikedPosts from './Components/Emilo/Others/Liked/LikedPosts';
import LikedBestClips from './Components/Emilo/Others/Liked/LikedBestClips';
import LikedMemes from './Components/Emilo/Others/Liked/LikedMemes';
import LikedReels from './Components/Emilo/Others/Liked/LikedReels';

import Tagged from './Components/Emilo/Others/Tagged/Tagged';
import TaggedPosts from './Components/Emilo/Others/Tagged/TaggedPosts';
import TaggedBestClips from './Components/Emilo/Others/Tagged/TaggedBestClips';
import TaggedMemes from './Components/Emilo/Others/Tagged/TaggedMemes';

import Error from './Components/Emilo/Error/Error';
import Loader from './Components/Emilo/Helper/Loader';
import ScrollToTop from './Components/Emilo/Helper/ScrollToTop';

import ShowCopyLink from './Components/Emilo/Popup/ShowCopyLink';
import ReelSharePage from './Components/Emilo/Popup/ReelSharePage';
import MobileHeader from './Components/Emilo/Helper/MobileHeader';
import MobileFooter from './Components/Emilo/Helper/MobileFooter';



// ***** Soft Corner *****


import OnboardingOTP from './Components/SoftCorner/Onboarding/OnboardingOTP';
import OnboardingImage from './Components/SoftCorner/Onboarding/OnboardingImage';
import OnboardingPin from './Components/SoftCorner/Onboarding/OnboardingPin';

import SoftCornerHome from './Components/SoftCorner/Home/Home1';

import Gallary from './Components/SoftCorner/Gallary/Gallary';
import GallaryAlbum from './Components/SoftCorner/Gallary/GallaryAlbum';
import GallaryExplore from './Components/SoftCorner/Gallary/GallaryExplore'

import AddToLifePartner from './Components/SoftCorner/LifePartner/AddToLifePartner';
import ProposeAndGetMarried from './Components/SoftCorner/LifePartner/ProposeAndGetMarried';
import ProposeOption from './Components/SoftCorner/LifePartner/ProposeOption/ProposeOption';
import ProposeOptionMessage from './Components/SoftCorner/LifePartner/ProposeOption/ProposeOptionMessage/ProposeOptionMessage';
import MemePopup from './Components/Emilo/Popup/MemePopup';
import ProposeOptionGifts1 from './Components/SoftCorner/LifePartner/ProposeOption/ProposeOptionGifts/ProposeOptionGifts1';
import ProposeOptionGifts2 from './Components/SoftCorner/LifePartner/ProposeOption/ProposeOptionGifts/ProposeOptionGifts2';

import DatePlanner from './Components/SoftCorner/DatePlanner/DatePlanner';
import SpecialDays from './Components/SoftCorner/SpecialDays/SpecialDays';

import ProfileAddress from './Components/SoftCorner/Profile/ProfileAddress';
import ProfileRemove from './Components/SoftCorner/Profile/ProfileRemove';
import DatePlanner2 from './Components/SoftCorner/DatePlanner/DatePlanner2';
import MemeSharePage from './Components/Emilo/Popup/MemeSharePage';
import BestclipSharePage from './Components/Emilo/Popup/BestClipSharePage';
import CheckSoftCornerPin from './Components/SoftCorner/Onboarding/CheckSoftCornerPin';
// import ScHeader from '../Components/Header/ScHeader';
import ScHeader from '../src/Components/SoftCorner/Header/ScHeader';
import SoftCornerBody from './Components/SoftCorner/SoftCornerBody';
import GallaryFavrouites from './Components/SoftCorner/Gallary/GallaryFavrouites';
import GallaryAlbumPhotos from './Components/SoftCorner/Gallary/GallaryAlbumPhotos';
import GallaryAlbumVideos from './Components/SoftCorner/Gallary/GallaryAlbumVideos';
import FavouritePosts from './Components/Emilo/Others/Favourite/FavouritePosts';
import ChatHome from './Components/SoftCorner/Chat/ChatHome';
import SoftCornerNotification from './Components/SoftCorner/Notifications/SoftCornerNotification';

import Chats from './Components/Emilo/Chats/Chats';
import LoginHistory from './Components/Emilo/Notifications/LoginHistory';
import ARVRVideoEditor from './Components/ArVr/arVr.js';
import ChangePassword from './Components/Emilo/createUser/multiStepSignup/ChangePassword/ChangePassword';
import GoogleMapApi from './Components/Emilo/Button/GoogleMapApi';
import Continue from './Components/Emilo/createUser/Continue';
import ForgetPassword from './Components/Emilo/createUser/multiStepSignup/ForgetPassword/ForgetPassword';
import CreateNewPassword from './Components/Emilo/createUser/multiStepSignup/ForgetPassword/CreateNewPassword';
import VerifyForgetPassword from './Components/Emilo/createUser/multiStepSignup/ForgetPassword/VerifyForgetPassword';
import ChangePassoword1 from './Components/Emilo/createUser/multiStepSignup/ChangePassword/ChangePassoword1';
import VerifyChangePassword from './Components/Emilo/createUser/multiStepSignup/ChangePassword/VerifyChangePassword';
import CodeVerificationEmail from './Components/Emilo/createUser/multiStepSignup/CodeVerificationEmail';
// import FavouritePeople from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/FavouritePeople';
// import BlockedUser from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/BlockedUser';
// import Unfollow from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/Unfollow';
import TermsAndConditions from './Components/Emilo/Others/TermsAndConditions/TermsAndConditions';
import BlockedUsers from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/BlockedUsers';
import RestrictedUsers from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/RestrictedUsers';
import Language from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/Language';
import DeviceAndBrowser from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/DeviceAndBrowser';
import UsageTime from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/UsageTime';
import CellularWifiData from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/CellularWifiData';
import OtherSetting from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/OtherSetting';
import SuggestionSetting from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/SuggestionSetting';
import DeactivateDelete from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/DeactivateDelete';
import AboutUser from './Components/Emilo/Profile/ProfileUser/ProfileUserShare/AboutUser';
import AdsPreferences from './Components/Emilo/Profile/ProfileUser/ProfileUserSetting/AdsPreferences';
import Profile from './Components/SoftCorner/Profile/Profile';
import ProfileVerify from './Components/SoftCorner/Profile/ProfileVerify';
import ProfilePayment from './Components/SoftCorner/Profile/ProfilePayment';
import ProfilePrivacy from './Components/SoftCorner/Profile/ProfilePrivacy';
import ProfileAbout from './Components/SoftCorner/Profile/ProfileAbout';
import ForgotSoftcornerPin from './Components/SoftCorner/Onboarding/ForgotSoftcornerPin';
import ScProtected from './Components/SoftCorner/ScProtected';
import Check from './Components/Emilo/Others/Check';
import BasicInfo from './Components/Business/Onboarding/BasicInfo';
import BusinessHome1 from './Components/Business/Home/BusinessHome1';
import BusinessHome2 from './Components/Business/Home/BusinessHome2';
import Dashboard from './Components/Business/Dashboard/Dashboard';
import Insights from './Components/Business/Insights/Insights';
import Ads from './Components/Business/Ads/Ads';
import BusinessCreatePost from './Components/Business/Ads/BusinessCreatePost';
import BusinessHomeAbout from './Components/Business/Home/BusinessHomeAbout';
import BusinessHomePosts from './Components/Business/Home/BusinessHomePosts';
import BusinessHomeProductAndServices from './Components/Business/Home/BusinessHomeProductAndServices';
import BusinessHomeTagged from './Components/Business/Home/BusinessHomeTagged';
import BusinessHomeReviews from './Components/Business/Home/BusinessHomeReviews';
import BusinessHomeListings from './Components/Business/Home/BusinessHomeListings';
import AccountSetting from './Components/Business/AccountSetting/AccountSetting';
import Events from './Components/Business/Events/Events';
import MyEvents from './Components/Business/Events/MyEvents';
import Event from './Components/Business/Events/Event';
import BusinessBoostPost1 from './Components/Business/Ads/BusinessBoostPost1'
import BusinessBoostPost2 from "./Components/Business/Ads/BusinessBoostPost2"
import SuggestedFlixPage from './Components/Emilo/Reels/SuggestedFlixPage';
import Trending from './Components/Emilo/Popup/Home/Trending/Trending';
import NotificationBody from './Components/Emilo/Notifications/NotificationBody';
import OnboardingUser from './Components/SoftCorner/Onboarding/OnboardingUser.js';
import HostLive from './Components/Emilo/ZegoCloud/HostLive.js';
import AudienceLive from './Components/Emilo/ZegoCloud/AudienceLive.js';
import AdvenceSearch from './Components/Emilo/Explore/AdvenceSearch.js';
// import ZegoCloud from './Components/Emilo/ZegoCloud/ZegoCloud.js';




const RoutesPage = () => {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);


  const ConditionalHeaders = () => {
    const location = useLocation();
    const isSoftCorner = location.pathname.includes("/SoftCorner");
    const isOnboarding = location.pathname.includes("/SoftCorner/Onboarding");
    if (isOnboarding) {
      return null;
    }

    return (
      <>
        {!isSoftCorner && <Header />}
        {isSoftCorner && <ScHeader />}
        {!isSoftCorner && <MobileHeader />}
        {<MobileFooter />}
      </>
    );
  };

  return (
    <>
      {loading && (
        <div className={`loader ${loading ? 'active' : ''}`}>
          <Loader />
        </div>
      )}

      <div className={`visible ${loading === false ? 'active' : ''}`}>
        <Router>
          <ConditionalHeaders />
          <ScrollToTop>
            <Routes>

              {/* ***** Emilo ***** */}

              <Route exact path="/login" element={<Login />} />

              <Route exact path="/signup" element={<Signup />} >
                <Route index exact element={<Continue />} />
                <Route exact path='nameRegisteration' element={<NameRegisteration />} />
                <Route exact path='emailandmobile' element={<EmailandMobile />} />
                <Route path='codeverification' element={<CodeVerification />} />
                <Route path='CodeVerificationEmail' element={<CodeVerificationEmail />} />
                <Route path='createpassword' element={<CreatePassword />} />
                <Route path='twoFactorSetting' element={<TwoFactorSetting />} />
                <Route path='username' element={<UserName />} />

                {/* change password */}
                <Route path='cp-device-selection' element={<ChangePassoword1 />} />
                <Route path='cp-verify-otp' element={<VerifyChangePassword />} />
                <Route path='change-password' element={<ChangePassword />} />

                {/* forget password */}
                <Route path='fp-mobile_email' element={<ForgetPassword />} />
                <Route path='fp-verify-otp' element={<VerifyForgetPassword />} />
                <Route path='fp-create-new-password' element={<CreateNewPassword />} />
              </Route>
  {/* kailash */}

             <Route path='/ee' element={<GoogleMapApi />} />
              <Route exact path="user/video/create" element={<ARVRVideoEditor />} />
              <Route exact path="/" element={<Home1 />} >
                <Route exact index element={<Home2 />} />
                <Route exact path="explore" element={<Explore />} />
                <Route exact path="/search/:searchTerm" element={<AdvenceSearch />} />
                <Route exact path="flix" element={<Reels />} />
                <Route exact path="suggested_flix" element={<SuggestedFlixPage />} />
                <Route exact path="memes" element={<Memes />} />
                <Route exact path='memes/:memeId' element={<MemePopup />} />
                <Route exact path="bestclips" element={<BestClips />} />
                <Route exact path="trendings" element={<Trending />} />
                <Route exact path="notifications" element={<NotificationBody />} />
                <Route exact path="user/logged/history" element={<LoginHistory />} />
                {/* <Route exact path="user/video/create" element={<ARVRVideoEditor />} /> */}
                <Route exact path="chats" element={<Chats />} />
                <Route exact path="chats/:chatId" element={<Chats />} />
                <Route exact path="chats/call" element={<Chats />} />
                <Route exact path="chats/call/:chatUserId" element={<Chats />} />
              </Route>

              <Route exact path="/profile/:paramsUserId/" element={<EmiloProfile />} >
                <Route exact index element={<ProfilePosts />} />
                <Route exact path='profile-reels' element={<ProfilesReels />} />
                <Route exact path='profile-videos' element={<ProfileVideos />} />
                <Route exact path='profile-bestClips' element={<ProfileBestClips />} />
                <Route exact path='profile-memes' element={<ProfileMemes />} />
              </Route>

              <Route exact path="/profile/:paramsUserId/profile-edit" element={<ProfileUserEdit />} />
              <Route exact path="/profile/:paramsUserId/about_user" element={<AboutUser />} />
              <Route exact path="/profile/:paramsUserId/profile-share" element={<ProfileUserShare />} />

              <Route exact path="/profile/:paramsUserId/profile-setting" element={<ProfileUserSetting />}>
                <Route index element={<AccountProfile />} />
                <Route path='account-profile' element={<AccountProfile />} />

                <Route path='web-navigation' element={<WebNavigation />} />
                <Route path='invite-follow' element={<InviteFollow />} />
                <Route path='privacy' element={<Privacy />} />
                <Route path='notification' element={<Notification />} />
                <Route path='blocked-users' element={<BlockedUsers />} />
                <Route path='restricted-users' element={<RestrictedUsers />} />
                <Route path='language' element={<Language />} />
                <Route path='device-browser' element={<DeviceAndBrowser />} />
                <Route path='security' element={<Security />} />
                <Route path='ads-preferences' element={<AdsPreferences />} />
                <Route path='usage-time' element={<UsageTime />} />
                <Route path='suggestion' element={<SuggestionSetting />} />
                <Route path='deactivation-delete' element={<DeactivateDelete />} />
                <Route path='cellular-wifi-data' element={<CellularWifiData />} />
                <Route path='other' element={<OtherSetting />} />

                {/* <Route path='favourites' element={<FavouritePeople />} /> */}
                {/* <Route path='unfollow' element={<Unfollow />} /> */}
                {/* <Route path='block' element={<BlockedUser />} /> */}
              </Route>

              <Route exact path="/showCopyLink/:postId" element={<ShowCopyLink />} />
              <Route exact path="/reelSharePage/:reelId" element={<ReelSharePage />} />
              <Route exact path="/memeSharePage/:memeId" element={<MemeSharePage />} />
              <Route exact path="/bestClipShare/:bestclipId" element={<BestclipSharePage />} />

              <Route exact path="/:paramsUserId/favourite" element={<Favourite />} />
              <Route exact path="/:paramsUserId/favourite-posts" element={<FavouritePosts />} />
              <Route exact path="/:paramsUserId/favourite-reels" element={<FavouriteReels />} />
              <Route exact path="/:paramsUserId/favourite-bestClips" element={<FavouriteBestClips />} />
              <Route exact path="/:paramsUserId/favourite-memes" element={<FavouriteMemes />} />

              <Route exact path="/:paramsUserId/liked" element={<Liked />} />
              <Route exact path="/:paramsUserId/liked-posts" element={<LikedPosts />} />
              <Route exact path="/:paramsUserId/liked-reels" element={<LikedReels />} />
              <Route exact path="/:paramsUserId/liked-bestClips" element={<LikedBestClips />} />
              <Route exact path="/:paramsUserId/liked-memes" element={<LikedMemes />} />

              <Route exact path="/:paramsUserId/tagged" element={<Tagged />} />
              <Route exact path="/:paramsUserId/tagged-PhotosAndVideos" element={<TaggedPosts />} />
              <Route exact path="/:paramsUserId/tagged-bestClips" element={<TaggedBestClips />} />
              <Route exact path="/:paramsUserId/tagged-memes" element={<TaggedMemes />} />

              <Route exact path="termsAndConditions" element={<TermsAndConditions />} />





              {/* ---------- Soft Corner ---------- */}

              {/* Onboarding */}
              <Route exact path="/SoftCorner/OnboardingUser" element={<OnboardingUser />} />
              <Route exact path="/SoftCorner/OnboardingOTP" element={<OnboardingOTP />} />
              <Route exact path="/SoftCorner/OnboardingImage" element={<OnboardingImage />} />
              <Route exact path="/SoftCorner/OnboardingPin" element={<OnboardingPin />} />

              {/* Pin */}
              <Route exact path="/SoftCorner/checkSoftCornerPin" element={<CheckSoftCornerPin />} />
              <Route exact path="/SoftCorner/forgotSoftCornerPin" element={<ForgotSoftcornerPin />} />

              <Route path="/SoftCorner" element={<SoftCornerBody />}>
                <Route index element={<SoftCornerHome />} />

                <Route path="Gallary" element={<ScProtected component={Gallary} />} />
                <Route path="Gallary-Album" element={<ScProtected component={GallaryAlbum} />} />
                <Route path="Gallary-Album/favourites" element={<ScProtected component={GallaryFavrouites} />} />
                <Route path="Gallary-Album/photos" element={<ScProtected component={GallaryAlbumPhotos} />} />
                <Route path="Gallary-Album/videos" element={<ScProtected component={GallaryAlbumVideos} />} />

                <Route path="Gallary-Explore" element={<ScProtected component={GallaryExplore} />} />

                <Route path="DatePlanner-1" element={<ScProtected component={DatePlanner} />} />
                <Route path="DatePlanner-2" element={<ScProtected component={DatePlanner2} />} />

                {/* <Route path="SpecialDays" element={<ScProtected component={SpecialDays} />} /> */}
                <Route path="SpecialDays" element={<SpecialDays />} />

                <Route path="LifePartner" element={<ScProtected component={AddToLifePartner} />} />

                <Route path="Propose&GetMarried" element={<ScProtected component={ProposeAndGetMarried} />} />
                <Route path="ProposeOption" element={<ScProtected component={ProposeOption} />} />
                <Route path="ProposeOption-Message" element={<ScProtected component={ProposeOptionMessage} />} />
                <Route path="ProposeOption-Gifts" element={<ProposeOptionGifts1 />} />
                <Route path="ProposeOption-Gifts/item" element={<ProposeOptionGifts2 />} />

                <Route path="notifications" element={<SoftCornerNotification />} />

                {/* <Route path="chat" element={<ScProtected component={ChatHome} />} /> */}
                <Route path="chat" element={<ChatHome />} />

                <Route path="Profile" element={<Profile />}>
                  <Route index element={<ProfileVerify />} />
                  <Route path="verify" element={<ProfileVerify />} />
                  <Route path="address" element={<ProfileAddress />} />
                  <Route path="payment" element={<ProfilePayment />} />
                  <Route path="remove" element={<ProfileRemove />} />
                  <Route path="privacy" element={<ProfilePrivacy />} />
                  <Route path="about" element={<ProfileAbout />} />
                  {/* <Route path="/recording" element={<Recording/>} /> */}
                </Route>
              </Route>


              {/* ---------- Business Page ---------- */}

              <Route exact path="/business/basic-info" element={<BasicInfo />} />

              <Route path="/business" element={<BusinessHome1 />}>

                {/* ----- Business > Home ----- */}
                <Route path='/business' element={<BusinessHome2 />} >
                  <Route path=":pageId" element={<BusinessHomePosts />} />
                  <Route path="about/:pageId" element={<BusinessHomeAbout />} />
                  <Route path="product-services/:pageId" element={<BusinessHomeProductAndServices />} />
                  <Route path="tagged/:pageId" element={<BusinessHomeTagged />} />
                  <Route path="reviews/:pageId" element={<BusinessHomeReviews />} />
                  <Route path="listings/:pageId" element={<BusinessHomeListings />} />
                </Route>


                <Route exact path="business_chats/:pageId" element={<Chats />} />
                <Route path="dashboard/:pageId" element={<Dashboard />} />
                <Route path="insights/:pageId" element={<Insights />} />
                <Route path="ads/:pageId" element={<Ads />} />
                <Route path="event/:pageId/:eventId" element={<Event />} />
                <Route path="events/:pageId" element={<Events />} />
                <Route path="events1/:pageId" element={<BusinessBoostPost1 />} />
                <Route path="my-events/:pageId" element={<MyEvents />} />
                <Route path="account-setting/:pageId" element={<AccountSetting />} />

                <Route path="create-post" element={<BusinessCreatePost />} />
                <Route path='boost-post-1/:pageId' element={<BusinessBoostPost1 />} />

                <Route path='boost-post-2/:pageId' element={<BusinessBoostPost2 />} />
              </Route>



              {/* ---------- ZegoCloud ---------- */}
              {/* <Route exact path="/zegocloud/:roomId" element={<ZegoCloud />} /> */}
              <Route exact path="/zegocloud/host" element={<HostLive />} />
              <Route exact path="/zegocloud/audience/:roomId" element={<AudienceLive />} />

              <Route exact path="/check" element={<Check />} />

              <Route element={<Error />} />
              <Route exact path="*" element={<Error />} />

            </Routes>
          </ScrollToTop>
        </Router>
      </div>
    </>
  );
};

export default RoutesPage;
