export const Reportdata = {
        Spam: {
          prohibited_actions: [
            "Buying, selling or giving away accounts, roles or permissions",
            "Encouraging people to engage with content under false pretenses",
            "Directing people away from Facebook through the misleading use of links",
            "Impersonating another person"
          ]
        },
        Nudity: {
        
            Adult_Nudity: [
              "Sexual activity",
              "Offering or requesting sexual activity",
              "Female nipples (except breastfeeding, health and acts of protest)",
              "Nudity showing genitals",
              "Sexually explicit language"
            ],
            Sexual_Exploitation: [
              "Content that shows, encourages or coordinates non-consensual sexual acts",
              "Offering or asking for sex or nude images",
              "Sharing or threatening to share intimate images or private sexual conversations without consent",
              "Sexual images taken when the person wasn't aware"
            ],
            Child_Nudity: [
              "Visible children's genitalia, even when covered by transparent clothing",
              "Fully nude buttocks",
              "Uncovered female nipples",
              "No clothes from neck to knee, even if no genitalia or female nipples are showing",
              "Digitally created images of nude minors, toddlers or babies, unless the image is for health or educational purposes"
            ],
            Sharing_private_images: [
              "A friend shares a photo of a near-nude person",
              "Someone shares a friend's nude photo as a meme",
              "Someone shares a nude photo without the person's knowledge"
            ]
          
        },
        Violence: {

            Graphic_Violence: [
              "Threats to commit violence (e.g., targeting a person and mentioning a specific weapon)",
              "Dangerous persons or organizations (e.g., terrorism or a criminal organization)",
              "Extreme graphic violence (e.g., glorifying violence or celebrating suffering)",
              "Another kind of violence (e.g., disturbing images or something else)"
            ],
            Animal_Abuse: [
              "Content showing, admitting to or encouraging harm against animals",
              "Staged animal fights"
            ],
            Sexual_Violence: [
              "Sexual touching without consent",
              "Asking for imagery or descriptions of sexual violence",
              "Threatening to share imagery or descriptions of sexual violence",
              "Mocking victims of sexual violence"
            ]
          
        },
        Child_Abuse: {
        
            Child_Nudity: [
              "Visible children's genitalia, even when covered by transparent clothing",
              "Fully nude buttocks",
              "Uncovered female nipples",
              "No clothes from neck to knee, even if no genitalia or female nipples are showing",
              "Digitally created images of nude minors, toddlers or babies, unless the image is for health or educational purposes"
            ],
            Child_Abuse: [
              "Content that shows nude children",
              "Content that shows sexual activity involving children, sexualizes them, or solicits sexual activities or sexual content from them",
              "Expressions of sexual interest in children",
              "Sexually explicit language alongside images or videos of children",
              "Content that exploits children by showing them sexualized content, arranging meetings or threatening to expose their intimate imagery or conversations",
              "Content concerning physical or non-sexual child abuse"
            ]
          
        },
        Unauthorized_Sales: {
          
            Drugs: [
              "Buying or selling alcohol, tobacco, marijuana or medical drugs",
              "Buying or selling non-medical drugs (e.g., cocaine or heroin)",
              "Using, making or promoting non-medical drugs"
            ],
            Weapons: [
              "Content about buying, selling or giving away guns, gun parts, ammunition or explosives from private individuals",
              "Content that helps or encourages people to make any kind of gun, gun part, ammunition or explosive",
              "Content that mentions or depicts 3D gun printing files"
            ],
            Endangered_Animals: [
              "Content that coordinates or supports poaching or selling endangered animals and their parts"
            ],
            Other_Animals: [
              "The sale or exchange of other animals between private individuals"
            ]
          
        },
        Terrorism: {
          description: "Content that supports or engages in planned acts of violence for political, religious or ideological reasons."
        },
        Eating_Disorder: {
          prohibited_actions: [
            "Promote or encourage eating disorders",
            "Contain instructions for drastic and unhealthy weight loss",
            "Focus on depicting ribs, collar bones, thigh gaps, hips, concave stomach or protruding spine shared in the context of eating disorders",
            "Mock victims or survivors of eating disorders"
          ]
        },
        Hate_Speech: {
        
            Race_or_Ethnicity: [
              "Violent or dehumanizing speech",
              "Statements of inferiority, disgust or contempt",
              "Slurs",
              "Calls for exclusion or segregation"
            ],
            National_Origin: [
              "Violent or dehumanizing speech",
              "Statements of inferiority, disgust or contempt",
              "Slurs",
              "Calls for exclusion or segregation"
            ],
            Religious_Affiliation: [
              "Violent or dehumanizing speech",
              "Statements of inferiority, disgust or contempt",
              "Slurs",
              "Calls for exclusion or segregation"
            ],
            Social_Caste: [
              "Violent or dehumanizing speech",
              "Statements of inferiority, disgust or contempt",
              "Slurs",
              "Calls for exclusion or segregation"
            ],
            Sexual_Orientation: [
              "Violent or dehumanizing speech",
              "Statements of inferiority, disgust or contempt",
              "Slurs",
              "Calls for exclusion or segregation"
            ],
            Sex_or_Gender_Identity: [
              "Violent or dehumanizing speech",
              "Statements of inferiority, disgust or contempt",
              "Slurs",
              "Calls for exclusion or segregation"
            ],
            Disability_or_Disease: [
              "Violent or dehumanizing speech",
              "Statements of inferiority, disgust or contempt",
              "Slurs",
              "Calls for exclusion or segregation"
            ]
          
        },
        False_Information: {
        
            Health: [
              "Content that spreads false medical or health information"
            ],
            Politics: [
              "Content that spreads false political information"
            ],
            Social_Issue: [
              "Content that spreads false information on social issues"
            ]
          
        },
        Suicide_or_Self_Injury: {
          description: "Content that promotes suicide or self-injury. Resources and support options are available."
        },
        Harassment: {
      
            Me: [
              "Content that targets the user directly with harassment or bullying."
            ],
            A_Friend: [
              "Content that targets the user's friend with harassment or bullying."
            ]
          
        },
        Something_Else: {
          
              Intellectual_Property: [
                "Content that infringes on intellectual property rights."
              ],
              Fraud_or_Scam: [
                "Content that deceives or scams people for personal gain.",
                "We only remove content that goes against our standards. We don't allow content where the aim is to deceive people to gain an unfair advantage or to deprive someone of money, property, or legal rights."
              ],
              Mocking_Victims: [
                "Content that mocks or belittles victims of various issues."
              ],
              Bullying: [
                "Content that targets individuals with harmful intent."
              ],
              Sexual_Activity: [
                "Content that depicts sexual activity or solicits sexual acts."
              ],
              Non_Consensual_Intimate_Images: [
                "Content that shares intimate images without consent."
              ],
              Sexual_Exploitation: [
                "Content that exploits individuals for sexual purposes."
              ],
              Harassment: [
                "Content that harasses or bullies individuals."
              ],
              Unauthorized_Sale: [
                "Content that promotes unauthorized sales or illegal transactions."
              ],
              Violence: [
                "Content that promotes or depicts violence.",
                "We only remove content that goes against our Community Standards. We don't allow things such as:",
                "Threats to commit violence (e.g., targeting a person and mentioning a specific weapon)",
                "Dangerous persons or organisations (e.g., terrorism or a criminal organisation)",
                "Extreme graphic violence (e.g., glorifying violence or celebrating suffering)",
                "Another kind of violence (e.g., disturbing images or something else)"
              ],
              Sharing_Private_Images: [
                "Content that shares private images without consent.",
                "Sharing private images can cause serious harm. By reporting it, you can help prevent more harm:",
                "A friend shares a photo of a near-nude person",
                "Someone shares a friend's nude photo as a meme",
                "Someone shares a nude photo without the person's knowledge"
              ],
              Child_Abuse: [
                "We don't allow things like these, even if they're shared to express outrage:",
                "Content that shows nude children",
                "Content that shows sexual activity involving children, sexualises them, or solicits sexual activities or sexual content from them",
                "Expressions of sexual interest in children",
                "Sexually explicit language alongside images or videos of children",
                "Content that exploits children by showing them sexualised content, arranging meetings or threatening to expose their intimate imagery or conversations",
                "Content concerning physical or non-sexual child abuse",
                "We also report suspected cases of child sexual exploitation to authorities around the world."
              ],
              Animal_Abuse: [
                "We don't allow things such as:",
                "Content showing, admitting to or encouraging harm against animals",
                "Staged animal fights",
                "We do allow content about hunting, fishing, religious sacrifice, food preparation and processing, pest control and self-defense."
              ],
              Nudity: [
                "We only remove content that goes against our Community Standards. We don't allow things such as:",
                "Sexual activity",
                "Offering or requesting sexual activity",
                "Female nipples (except breastfeeding, health and acts of protest)",
                "Nudity showing genitals",
                "Sexually explicit language"
              ],
              Suicide_or_Self_Injury: [
                "What to do if someone needs support for suicide or self-injury:",
                "If you are in contact with the person, you might want to recommend a helpline or call one yourself. You can find more information in our resources.",
                "Other steps you can take:",
                "Learn how to talk to Bhaskar about this",
                "See tips from suicide prevention experts about what to say and how to help.",
                "Ask us to look at the post",
                "If you think that we should know about this post, we'd like to take a look at it as soon as possible and offer support to Bhaskar. Your name will be kept confidential.",
                "DONE SEE RESOURCES",
              ],
              Hate_Speech: [
                "We only remove content that directly attacks people based on certain protected characteristics. Direct attacks include things such as:",
                "Violent or dehumanising speech (e.g., comparing all people of a certain race to insects or animals)",
                "Statements of inferiority, disgust or contempt (e.g., suggesting that all people of a certain gender are disgusting)",
                "Slurs (e.g., using harmful racial stereotypes to insult someone)",
                "Calls for exclusion or segregation (e.g., saying that people of a certain religion shouldn't be allowed to vote)"
              ],
              Promoting_Drug_Use: [
                "We only remove content that goes against our standards. We don't allow content that speaks positively of or encourages the use or making of non-medical drugs (e.g., cocaine or heroin)."
              ]
            
          }
        
      };
      
  
