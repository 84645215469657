import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AudienceControlApi } from '../../UseContext/ApiHelpers';
import { ApiContext } from '../../UseContext/ApiCall';

const AudienceClosefriend = ({ showPopupclose, setShowPopupclose, postsPopupData }) => {
  const handleClose = () => setShowPopupclose(false);


  const { config } = useContext(ApiContext)



  const HandleAudience = async (event) => {

    const data = {
      id: postsPopupData._id,
      visibility: event.target.value
    }
    postsPopupData.visibility = event.target.value;

    await AudienceControlApi(data, config)
    setShowPopupclose(false);

  }

  return (
    <div>
      <Modal
        show={showPopupclose}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        className="main-modal"
      >
        <Modal.Header closeButton>

          {/* <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" /> */}
          <h5 className='fw-semibold ms-3'>Audience Control</h5>


        </Modal.Header>

        {/* Modal Body */}
        <Modal.Body className="popupModal-body">


          <div className='row mx-auto'>
            <div className="ms-2 mb-1 col-lg-5">
              <input className="form-check-input" type="radio" id="ps-storyView-public" name="visibility" value='listone'
                checked={postsPopupData.visibility === 'listone'}
                onChange={HandleAudience}
              />
              <label className="form-check-label" htmlFor="ps-storyView-public">listone</label>
            </div>

            <div className="ms-2 mb-1 col-lg-5">
              <input className="form-check-input" type="radio" id="ps-storyView-followers" name="visibility" value='listtwo'
                checked={postsPopupData.visibility === 'listtwo'}
                onChange={HandleAudience}


              />
              <label className="form-check-label" htmlFor="ps-storyView-followers">listtwo</label>
            </div>


          </div>



        </Modal.Body>

      </Modal>

    </div>
  )
}

export default AudienceClosefriend




