
import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";  // Import Bootstrap components
import ProfileImage from "../../../UseContext/ProfileImage";
import { ApiContext } from "../../../UseContext/ApiCall";

function College({ showModal, setShowModal, images, setImages }) {
  const [isEditing, setIsEditing] = useState(false);

  const   {loggedInUserId}= useContext(ApiContext)

  const handleSubmit = () => {
    if (images.length > 0) {
      console.log("Images uploaded:", images);
    } else {
      alert("Please select images first");
    }
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  const handleAddImages = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setImages((prevImages) => [...prevImages, ...fileArray]);
  };

  const getImageStyle = (index) => {
    if (isEditing) {
      return {}; // Uniform size in edit mode
    } else if (images.length === 2 && index === 0) {
      return { gridColumn: "span 2" }; // Custom styling for specific cases
    } else if (images.length >= 5) {
      if (index < 2) return { gridColumn: "span 2" };
      if (index >= 3 && index < 5) return { gridColumn: "span 3" };
    } else {
      return {};
    }
  };

  const getImageHeight = () => {
    return isEditing ? "auto" : "200px"; // Uniform height for edit mode
  };

  return (
    <div>
      {/* File Input */}


      {/* Bootstrap Modal */}

      <Modal show={showModal} onHide={() => setShowModal(false)} keyboard={false} className="create-post" backdrop="static" centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>


          <div className="row">
            <p className={` col-12'  fs-6 text-black fw-bold text-center mb-2`}>Create  Post</p>
          </div>

          <div>
            <div className="userDetail">
              <div className="imgBox">
              <ProfileImage userId={loggedInUserId} height='50' width='50' borderRadius='50%' />
              </div>

              <div className="nameBox">
                <div className="d-flex flex-md-row align-items-center">
                  <span className="fs-6">
                    <span className="fw-bold">kailash Rao </span>
                   
                  </span>
                </div>

                <div className="add-location-div">
                  <i className="fas fa-map-marker-alt" style={{ padding: '3px 3px 3px 0px' }} />
                  <small className="text-truncate" style={{ maxWidth: "300px", cursor: 'pointer' }}>New York</small>
                  <span className="fs-5 cp" style={{ color: 'rgb(207 99 109)' }}> <i className="fas fa-times-circle"></i></span>
                </div>
              </div>
            </div>

            <div className="bg_style">
              <div className="postBox position-relative">
                <div className="text-editor">
                  <textarea
                    rows={11}
                    value="This is a static post content example."
                    onChange={() => { }}
                  ></textarea>
                </div>
                <div className="loader-hw">
                  <div className="loader"></div>
                </div>
              </div>
            </div>

            <div
              className="row row-cols-1 row-cols-sm-2 row-cols-md-3  g-3 mx-2 my-2"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              {/* Display images */}
              {images.slice(0, isEditing ? images.length : 10).map((image, index) => (
                <div
                  key={index}
                  className="col position-relative"
                  style={{ height: getImageHeight() }}
                >
                  <img
                    src={image}
                    alt={`Preview ${index}`}
                    className="w-100 h-100 object-fit-cover rounded"
                    style={getImageStyle(index)}
                  />
                  {/* Watermark for Additional Images */}
                  {index === 5 && images.length > 5 && !isEditing && (
                    <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark bg-opacity-50 d-flex justify-content-center align-items-center text-white fs-1 fw-bold">
                      +{images.length - 5}
                    </div>
                  )}

                  {/* Delete Button */}
                  {isEditing && (
                    <Button
                      variant="danger"
                      size="sm"
                      className="position-absolute top-0 end-0 m-2"
                      onClick={() => removeImage(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </Button>
                  )}
                </div>
              ))}
            </div>






            <div className="text-center row justify-content-center mt-4">
              <button className="text_post_btn col-8">Next</button>
            </div>
          </div>



        </Modal.Body>
        <Modal.Footer>
        
          {!isEditing && (
            <Button variant="success" onClick={handleSubmit}>
              Upload Images
            </Button>
          )}
          <Button variant="warning" onClick={toggleEdit}>
            {isEditing ? "Done Editing" : "Edit"}
          </Button>
          <Button
            variant="primary"
            onClick={() => document.getElementById("add-images-input").click()}
          >
            Add More Images
          </Button>
          {/* Hidden input for adding more images */}
          <input
            type="file"
            accept="image/*"
            multiple
            id="add-images-input"
            onChange={handleAddImages}
            style={{ display: "none" }}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default College;
