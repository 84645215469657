import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { BASEURL, BASE_URL_STATIC } from '../../../../config'
import { ApiContext } from '../../UseContext/ApiCall'

const Navbar = () => {

  const { loggedInUserId } = useContext(ApiContext);

  return (
    <>
      <section className='navbar'>
        {/* Favourite */}
        <Link to={`${BASEURL}/${loggedInUserId}/favourite`} className='navbar-div'>
          <img src={`${BASE_URL_STATIC}/icon/favourite-1.svg`} alt="star" height={40} width={40} />
          <span>Favourite</span>
        </Link>

        {/* <Link to={`${BASEURL}/${loggedInUserId}/liked`} className='navbar-div'>
          <img src={`${BASE_URL_STATIC}/feed-icon2.png`} alt="star" height={40} width={35} />
          <span>Favourite Pepole</span>
        </Link> */}

        {/* Tagged */}
        <Link to={`${BASEURL}/${loggedInUserId}/tagged`} className='navbar-div'>
          <img src={`${BASE_URL_STATIC}/tag.png`} alt="star" height={40} width={40} />
          <span>Tagged</span>
        </Link>

        {/* Liked */}
      
      </section> 
    </>
  )
}

export default Navbar
