import React, { useEffect, useState } from 'react';
import { BASEAPIURL, formatedDateTime, headerUserAPI, showUserIP, showUserLatLong } from '../../../config';
import axios from 'axios';
import ShowAds from '../Ads/showAds.js';
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';


const SavedLogin = () => {
    const [testValue, setTestValue] = useState(0);
    const [loginLogs, setLoginLogs] = useState([]);
    const [filters, setFilters] = useState({ show: false, all: 1 });
    const [single, setSingle] = useState(false);
    async function getLoginLogs(filter) {
        await getLoginLogsAPI(filter);
        setFilters( { ...filters, apiCall: false } );
    }
    async function getLoginLogsAPI(filter) {
        try {
            const headers = headerUserAPI(); // Assuming this returns headers
            const temp = await axios.post(`${BASEAPIURL}/user/getLoginLogs`, { get: new Date(), ...filter }, { headers: headers });
            if (temp && temp.data && temp.data.data && temp.data.data.length > 0) {
                setLoginLogs(temp.data.data);
            } else {
                setLoginLogs([]);
            }
        } catch (error) {
            console.error(error.message);
        }
    }

    async function endUserSession(sessionID) {
        try {
            const headers = headerUserAPI(); // Assuming this returns headers
            await axios.post(`${BASEAPIURL}/user/endUserSession`, { get: new Date(), sessionID }, { headers: headers });
            getLoginLogs(filters);
            setSingle(false);
        } catch (error) {
            console.error(error.message);
            getLoginLogs(filters);
            setSingle(false);
        }
    }

    useEffect(() => {
        getLoginLogsAPI({all: 1});
        return () => {
            // console.error('called');
            // document.head.removeChild(link);
        };
      }, []);

    return (
        <section className="vh-100 overflow-y-scroll scrollbar-hidden py-5">
            <div className="explore-sec my-3 my-sm-5 my-lg-5 d-block">
                <div className='container-fluid'>
                    <ShowAds adsPlace={testValue} />
                    <a
                        href="/"
                        className="d-none"
                        onClick={(e) => {
                            e.preventDefault();
                            setTestValue(1 + testValue);
                        }}
                    >
                        Update Value #{testValue}#
                    </a>
                    <h3 className='mb-3'>
                        Login History
                        <div className='pull-right'>
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setFilters({...filters, show: filters.show ? false : true})
                                }}
                            >
                                <i className='fa fa-search'></i>
                            </a>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </h3>
                    {filters.show && (
                        <form
                            className="card mb-3 boxShadow rounded-0"
                            onSubmit={(e) => {
                                setLoginLogs([]);
                                e.preventDefault();
                                setFilters({ ...filters, apiCall: true });
                                getLoginLogs(filters);
                            }}
                        >
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-md-2 form-group'>
                                        <label>From Date</label>
                                        <input
                                            required
                                            type="date"
                                            name="fromDate"
                                            value={filters.fromDate}
                                            className='form-control'
                                            onChange={(e) => {
                                                setFilters({...filters, [e.target.name]: e.target.value})
                                            }}
                                        />
                                    </div>
                                    <div className='col-md-2 form-group'>
                                        <label>To Date</label>
                                        <input
                                            required
                                            type="date"
                                            name="toDate"
                                            min={filters.fromDate}
                                            value={filters.toDate}
                                            className='form-control'
                                            onChange={(e) => {
                                                setFilters({...filters, [e.target.name]: e.target.value})
                                            }}
                                        />
                                    </div>
                                    <div className='col-md-2 form-group'>
                                        <label>Type</label>
                                        <select
                                            required
                                            name="type"
                                            value={filters.type}
                                            className='form-control'
                                            onChange={(e) => {
                                                setFilters({...filters, [e.target.name]: e.target.value})
                                            }}
                                        >
                                            <option value="0">Select Type</option>
                                            {Array.from(new Set(loginLogs.map(log => log.type))).sort().map((s) => (
                                                <option value={s} key={s}>{s}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-md-2 form-group'>
                                        <label>City</label>
                                        <select
                                            required
                                            name="city"
                                            value={filters.city}
                                            className='form-control'
                                            onChange={(e) => {
                                                setFilters({...filters, [e.target.name]: e.target.value})
                                            }}
                                        >
                                            <option value="0">Select City</option>
                                            {Array.from(new Set(loginLogs.map(log => log.LocCity))).sort().map((s) => (
                                                <option value={s} key={s}>{s}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-md-4 form-group'>
                                        <label>&nbsp;</label>
                                        <br />
                                        <div className='d-flex'>
                                            <button type="submit" name="submit" className='btn btn-outline-success'>
                                                Submit
                                            </button>
                                            &nbsp;
                                            <button
                                                type="reset"
                                                name="reset"
                                                className='btn btn-outline-danger'
                                                onClick={() => {
                                                    setFilters( { show: false, all: 1, apiCall: true } );
                                                    setLoginLogs([]);
                                                    getLoginLogs({ all: 1 });
                                                }}
                                            >
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                    {single && single._id ? (
                        <div className="card boxShadow rounded-0">
                            <div className="card-body">
                                <table className="table table-hover table-bordered">
                                    <tr>
                                        <th>Device</th>
                                        <td>{single.device}</td>
                                    </tr>
                                    <tr>
                                        <th>OS</th>
                                        <td>{single.type}</td>
                                    </tr>
                                    <tr>
                                        <th>City</th>
                                        <td>{single.LocCity}</td>
                                    </tr>
                                    <tr className='d-none'>
                                        <th>Lat Long</th>
                                        <td><div dangerouslySetInnerHTML={{ __html: showUserLatLong(single.LocLat, single.LocLong) }} /></td>
                                    </tr>
                                    <tr>
                                        <th>IP</th>
                                        <td dangerouslySetInnerHTML={{ __html: showUserIP(single.userip) }}></td>
                                    </tr>
                                    <tr>
                                        <th>Started on</th>
                                        <td>{formatedDateTime(single.createdAt)}</td>
                                    </tr>
                                    <tr>
                                        <th>Last Update</th>
                                        <td>{formatedDateTime(single.updatedAt)}</td>
                                    </tr>
                                </table>
                                <div className='d-flex mt-1 justify-content-between'>
                                    <a
                                        href="/"
                                        className='btn btn-sm btn-outline-danger'
                                        style={{ opacity: single.status !== 1 ? 0 : 1 }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            endUserSession(single._id);
                                        }}
                                    >
                                        End Session
                                    </a>
                                    <a
                                        href="/"
                                        className='btn btn-sm btn-outline-secondary'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSingle(false);
                                        }}
                                    >
                                        Close Detail
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card boxShadow rounded-0">
                            <div className="card-body" style={{ maxHeight: '100%', overflow: 'auto' }}>
                                <CTable>
                                    <CTableHead>
                                        <CTableRow>
                                            <CTableHeaderCell>Device</CTableHeaderCell>
                                            <CTableHeaderCell>Type</CTableHeaderCell>
                                            <CTableHeaderCell>City</CTableHeaderCell>
                                            <CTableHeaderCell>IP</CTableHeaderCell>
                                            <CTableHeaderCell>Start on</CTableHeaderCell>
                                            <CTableHeaderCell>Last Update</CTableHeaderCell>
                                            <CTableHeaderCell>Status</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                    
                                        {loginLogs.map((s) => (
                                            <CTableRow
                                                className="p-3 two_facetor_type"
                                                onClick={() => {
                                                    setSingle(s);
                                                }}
                                            >
                                                <CTableDataCell>{s.device}</CTableDataCell>
                                                <CTableDataCell>{s.type}</CTableDataCell>
                                                <CTableDataCell>{s.LocCity}</CTableDataCell>
                                                <CTableDataCell>
                                                    <span dangerouslySetInnerHTML={{ __html: showUserIP(single.userip) }}></span>
                                                </CTableDataCell>
                                                <CTableDataCell>{formatedDateTime(s.createdAt)}</CTableDataCell>
                                                <CTableDataCell>{formatedDateTime(s.updatedAt)}</CTableDataCell>
                                                <CTableDataCell>
                                                    {s.status === 1 ? (
                                                        <span className='btn btn-sm btn-info'>Active</span>
                                                    ) : (
                                                        <span className='btn btn-sm btn-warning'>Ended</span>
                                                    )}
                                                </CTableDataCell>
                                            </CTableRow>
                                        ))}
                                    </CTableBody>
                                </CTable>
                                <div className='text-center'>{filters.apiCall && "Loading Data"}</div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </section>
    );
};

export default SavedLogin;
