import React, { useContext, useState } from 'react'
import PostTime from '../../Home/CenterBlock/CenterBlockPosts/PostTime'
import { deleteEmiloMsg } from '../../UseContext/ApiHelpers';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faTrash } from '@fortawesome/free-solid-svg-icons';
import { RiShareForwardFill } from "react-icons/ri";
import { MdOutlineContentCopy } from "react-icons/md";
import ForwardPop from "./ForwardPop"
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { AwsFileUrl, BASEURL, BASE_URL_STATIC } from '../../../../config';
import { Link } from 'react-router-dom';
import ChatInfo from './ChatInfo';
import { ApiContext } from '../../UseContext/ApiCall';
import VoiceCallGoingPopup from '../../Popup/Chats/Chat/SingleCall/VoiceCallGoingPopup.js'
import VoiceCallComingPopup from '../../Popup/Chats/Chat/SingleCall/VoiceCallComingPopup.js';
import VideoCallGoingPopup from '../../Popup/Chats/Chat/SingleCall/VideoCallGoingPopup.js';
import VideoCallComingPopup from '../../Popup/Chats/Chat/SingleCall/VideoCallComingPopup.js';


const ChatHeader = ({  messageObject, setChatUserList,chatUserList, setmessageObject, selectMsg, setArrivalMsg, setSelectedMsg, onlineStatus, chatProfile, chatId, config }) => {

  const [openFwd, setOpenFwd] = useState(false);

  const [show , setShow] = useState(false) 

  const {
    setShowVoiceCallGoingPopup,
    setShowVideoCallGoingPopup,
  } = useContext(ApiContext);

  const formattedMessages = messageObject.map(({ createdTime, senderFname, message }) => {
    const date = new Date(createdTime);
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
    const formattedTime = `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;

    return `[${formattedDate}, ${formattedTime}] ${senderFname}: ${message}\n`;
  });

  const messageString = formattedMessages.join('\n');

  const handleDelete = () => {
    selectMsg.forEach((messageId, i) => {
      deleteEmiloMsg(messageId, chatId, config)
        .then(() => {
          setSelectedMsg([]);

          setArrivalMsg((msgs) => msgs.filter((msg) => msg.messageId !== messageId));

          // getMessage(chatId, scConfig).then((res) => { setArrivalMsg(res); setSpinner(false) }).catch(() => setSpinner(false));
        })
        .catch((error) => {
          console.error(`Error deleting message ${messageId}:`, error);
        });
    });
  };

  if (selectMsg && selectMsg.length > 0) {
    return (
      <section className='chat-header position-absolute w-100 z-1 bg-white p-2 p-lg-3 d-flex align-items-center shadow-sm'>
        <ForwardPop open={openFwd} setArrivalMsg={setArrivalMsg} messageObject={messageObject} setmessageObject={setmessageObject} setSelectedMsg={setSelectedMsg} close={() => setOpenFwd(false)} />

        <div className='chats-rb-chat-t-header'>
          <div className='delete-header' >
            <div>
              <span onClick={() => setOpenFwd(true)}>
                <RiShareForwardFill />
              </span>
            </div>

            <div>
              <span>
                <CopyToClipboard text={messageString} onCopy={() => { setmessageObject([]); setSelectedMsg([]); toast.success("message copied") }}>
                  <MdOutlineContentCopy />
                </CopyToClipboard>
              </span>
            </div>

            <div className='del'>
              {selectMsg.length > 0 && <Button variant='danger' onClick={handleDelete}> <FontAwesomeIcon icon={faTrash} /> ({selectMsg.length})</Button>}

              {selectMsg.length > 0 && <Button variant='success' onClick={() => setSelectedMsg([])}> <FontAwesomeIcon icon={faClose} /></Button>}
            </div>
          </div>
        </div>
      </section>
    )
  };

  return (
    <>
      {/* Popup */}
      <>
        {/* Voice call going popup */}
        <VoiceCallGoingPopup />

        {/* Voice call coming popup */}
        <VoiceCallComingPopup />

        {/* Video call going popup */}
        <VideoCallGoingPopup />

        {/* Video call coming popup */}
        <VideoCallComingPopup />
      </>

      <ChatInfo setChatUserList={setChatUserList} open={show} close={() => setShow(false)} chatProfile={chatProfile} chatId={chatId} config={config} chatUserList={chatUserList} />

      <section className='chat-header position-absolute w-100 z-1 bg-white p-2 p-lg-3 d-flex align-items-center shadow-sm'>
        {/* Back */}
        <Link to={`${BASEURL}/chats`} className='me-2 d-lg-none'>
          <img className='chat-rb-header-previous' src={`${BASE_URL_STATIC}/icon/previous.svg`} alt="<" />
        </Link>

        {/* Profile */}
        <>
          <img className='ch-profile rounded-circle' src={AwsFileUrl + (chatProfile.groupProfileImg || chatProfile.profileImg.imageId)} alt="profile" onClick={() => setShow(true)} onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`} />
        </>

        {/* Name, online or offline, last seen */}
        <div className='col-5 col-sm-6 col-lg-5 col-xl-6 px-0 ms-2 ms-lg-3 me-auto'>
          <h6  className='text-dark text-nowrap overflow-hidden text-truncate mb-0'>{chatProfile.groupName || chatProfile.fname}</h6>

          {
            !chatProfile.groupName
            &&
            <small className="d-block text-nowrap overflow-hidden text-truncate text-secondary">
              {!chatProfile.groupName && onlineStatus && onlineStatus.onlineStatus === "online" && <span className='online-status'></span>}
              {!chatProfile.groupName && onlineStatus && onlineStatus.onlineStatus === "online" ? onlineStatus.onlineStatus : <>last seen at <PostTime date={onlineStatus && onlineStatus.lastOnlineTime} /> ago</>}
            </small>
          }
        </div>

        <div className='d-flex justify-content-end align-items-center gap-4'>
          {/* Voice Call */}
          <img src={`${BASE_URL_STATIC}/icon/voice-call.svg`} className="cursor-pointer" onClick={() => setShowVoiceCallGoingPopup(true)} alt="voice" />

          {/* Video Call */}
          <img src={`${BASE_URL_STATIC}/icon/video-call.svg`} className="cursor-pointer mx-auto" onClick={() => setShowVideoCallGoingPopup(true)} alt="video" />

          {/* Three Dots */}
          <i className="far fa-ellipsis-v me-2 me-sm-3" />
        </div>
      </section>
    </>

  )
}


export default ChatHeader;
