import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../../UseContext/ApiCall';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';

const VoiceCallComingPopup = () => {
  
  const containerRef = useRef(null);

  const {
    showVoiceCallComingPopup, setShowVoiceCallComingPopup,
    loggedInUser,
    socket,
  } = useContext(ApiContext);

  const [data, setData] = useState({});

  const initZegoCloud = (element) => {
    if (!loggedInUser) {
      console.error('Missing loggedInUser');
      return;
    };

    const appID = 1632643095;
    const serverSecret = 'fcb29e34aa8bba2eecf90cd9f3cdc81d';

    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      data.roomId,
      loggedInUser.userId,
      loggedInUser.username || loggedInUser.fname || 'Participant'
    );

    const zegoInstance = ZegoUIKitPrebuilt.create(kitToken);

    if (zegoInstance && typeof zegoInstance.joinRoom === 'function') {
      console.log('join room created');

      zegoInstance.joinRoom({
        container: element,
        scenario: {
          mode: ZegoUIKitPrebuilt.VoiceCall,
          config: {
            role: 'Participant',
            audio: true,
            video: false,
          },
        },
      });
    } else {
      console.error('Failed to create ZegoUIKitPrebuilt instance');
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      initZegoCloud(containerRef.current, data.roomId, data.hostName);
    };

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = ''; // Clear the container
      };
    };
  }, [data]);

  useEffect(() => {
    if (socket) {
      socket.on('sc-voice-call-start', (data) => {
        console.log('data:', data);
        
        setShowVoiceCallComingPopup(true);
        setData(data);
      });
    };

    return () => {
      socket.off('sc-voice-call-start');
    };
  }, [socket]);

  return (
    <>
      <Modal show={showVoiceCallComingPopup} onHide={() => setShowVoiceCallComingPopup(false)} className="main-modal" size="lg" centered backdrop="static" keyboard={false} >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h6 className='mb-2 text-center'>{data.hostName}Voice calling you..</h6>
            <div id="zego-container" style={{ width: '100%', maxHeight: '600px' }} ref={containerRef}></div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VoiceCallComingPopup;