import React from 'react';
import ReactSlider from 'react-slider';


function Slider({value,   setValue, handleSliderChange}) {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>

      
      <ReactSlider
        min={0}
        max={100}
        value={value}
        onChange={handleSliderChange}
        renderThumb={(props, state) => (
          <div
            {...props}
            style={{
              ...props.style,
              backgroundColor: '#5367F9',
              width: '25px',
              height: '25px',
              borderRadius: '50%',
              border: '2px solid #fff',
            }}
          />
        )}
        renderTrack={(props, state) => (
          <div
            {...props}
            style={{
              ...props.style,
              background: 'linear-gradient(to right, #5367F9, #5367F9)',
              height: '8px',
              borderRadius: '5px',
            }}
          />
        )}
        renderMark={(props, state) => (
          <div
            {...props}
            style={{
              ...props.style,
              backgroundColor: '#5367F9',
              height: '12px',
              width: '2px',
              marginTop: '-2px',
            }}
          />
        )}
        pearling
        minDistance={10} // Minimum distance between thumbs
      />
      
      <p style={{ marginTop: '20px' }}>
        Selected Range: {value[0]} - {value[1]}
      </p>
    </div>
  );
}

export default Slider;
