import React, { useContext, useEffect, useState } from 'react'
import { AwsFileUrl} from '../../../config';
import { addSCPartner, getPartnerinfo, getSoftCornerProfile } from '../../Emilo/UseContext/ScApiHelpers';
import { ScApiContext } from '../useContext/softCornerContext';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import { useNavigate } from 'react-router-dom';

const ProfileRemove = () => {

  const [partnerName, setPartnerName] = useState("")
  const [partnerImage, setpartnerImage] = useState("")  
  const [matchDate, setMatchDate] = useState("0 days")
  const [checkBox, setCheckBox] = useState(false)
  const [partnerId, setPartnerId] = useState("")
  const [btnStatus, setBtnStatus] = useState("Remove")
  const [removeStatus, setRemoveStatus] = useState('')
  const [removeBtn, setRemoveBtn] = useState('success')
  // const [matchDays, setMatchDays] = useState('')
  // const [msg, setMessage] = useState("")

  const { scConfig, setIsSoftCornerPartnerExist, setBtnEnable,setIsMatched } = useContext(ScApiContext)
  const { config } = useContext(ApiContext)


  const loggedInUserId = localStorage.getItem("loggedInUserId");

  const nav = useNavigate()

  useEffect(() => {
    getSoftCornerProfile(loggedInUserId, scConfig).then((res) => {
      setPartnerName(res.partnerName);
      setpartnerImage(res.partnerImage);
      setPartnerId(res.partnerId);
      if (res.matchDate !== null ) {
        setIsMatched(true)
        const currentTime = new Date();
        const matchDate = new Date(res.matchDate);
        currentTime.setUTCHours(0, 0, 0, 0);
        matchDate.setUTCHours(0, 0, 0, 0);
        
        
        // Formatting matchDate
        const formattedMatchDate = matchDate.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        }).replace(/ /g, ' ');
        setMatchDate(formattedMatchDate);
      } else {
        setIsMatched(false)
        // setMatchDays(0);
        setMatchDate("0 days");
      }
    });
    getPartnerinfo(config).then((res) => {
      if (res.data.status !== 2) {
        setBtnEnable(true);
        setIsSoftCornerPartnerExist(false);
        nav("/softcorner/profile")
        if (res.data.removalRequested !== null) {
          setBtnStatus("Cancel")
        } else {
          setBtnStatus("Remove")
        }
      }
      else if (res.data.status === 2) {
        setBtnEnable(false);
        setIsSoftCornerPartnerExist(true);
        if (res.data.removalRequested !== null) {
          setBtnStatus("Cancel")
        } else {
          setBtnStatus("Remove")
        }
      } else {
        setBtnEnable(true);
        setIsSoftCornerPartnerExist(true)
      }
    })
  }, [loggedInUserId]);

  const handleRemove = () => {
    addSCPartner(loggedInUserId, partnerId, btnStatus.toLocaleLowerCase(), scConfig).then((res) => {
      if (res.status === 200) {
        setRemoveBtn('success')
        setRemoveStatus(res.data.message)
      }
      else if (res.status === 201) {
        setBtnStatus("Remove")
        setRemoveBtn('danger')
        setRemoveStatus(res.data.message)
      }
    }).then(() => {
      getPartnerinfo(config).then((res) => {
        if (res.data.status !== 2) {
          setBtnEnable(true);
          setIsSoftCornerPartnerExist(false);
          nav("/softcorner/profile")
          if (res.data.removalRequested !== null) {
            setBtnStatus("Cancel")
          } else {
            setBtnStatus("Remove")
          }
        }
        else if (res.data.status === 2) {
          setBtnEnable(false);
          setIsSoftCornerPartnerExist(true);
          if (res.data.removalRequested !== null) {
            setBtnStatus("Cancel")
          } else {
            setBtnStatus("Remove")
          }
        } else {
          setBtnEnable(true);
          setIsSoftCornerPartnerExist(true)
        }
      })
      localStorage.removeItem("scChatId")
    }).catch((err) => {
      console.log(err);
      setRemoveStatus("")
    })
  }


  return (
    <>
      <section className='vh-100 overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-xl-5 pt-5 pb-5'>
        {/* Profile */}
        <div className='text-center mt-5'>
          {partnerImage && <img src={AwsFileUrl + partnerImage} className='rounded-circle shadow' alt="partner" height={90} width={90} />}
        </div>

        {/* Softcorner & Since */}
        <div className='my-4 d-flex align-items-center justify-content-evenly'>
          <div className=''>
            <h5 className='ps-3'>Soft Corner</h5>
            {partnerName && <p className='shadow px-3 py-2 rounded-pill'>{partnerName}</p>}
          </div>

          <div>
            <h5 className='ps-3'>Since</h5>
            <p className='shadow px-3 py-2 rounded-pill'>{matchDate}</p>
          </div>
        </div>

        {/* Terms & Conditions */}
        <div className='h-50 overflow-y-scroll p-3 rounded-3 shadow'>
          <h4 className='text-center mb-3 fw-semibold'>Terms & Conditions</h4>

          <span className='mb-3'>IF you remove your partner from softcorner, it will effects on your Softcorner chat, partner’s profile timeline, gifted items, and special and planned events:</span>

          <div className='mb-3'>
            <h5 className='fw-semibold d-inline me-2'>Softcorner Chat history :</h5>
            <p className='d-inline'>When you remove the soft corner in a conversation, it will delete the entire conversation history, including all the messages, media, & any other content exchanged between you and your partner. This means that you won’t be able to access or refer back to any of the previous conversations you had with your partner.</p>
          </div>

          <div className='mb-3'>
            <h5 className='fw-semibold d-inline me-2'>Softcorner Profile Timeline:</h5>
            <p className='d-inline'>You can see a collection of shared memories, such as photos, videos, and posts, that you have exchanged with your partner in the softcorner timeline. If you remove the softcorner, it will delete all these memories from your profile timeline. This means that any special moments or memories your partner had shared with you will no longer be visible on your profile.</p>
          </div>
        </div>

        {/* Agree */}
        <form className='d-flex px-3 py-2 rounded-pill my-4'>
          <input type="checkbox" className='me-3' id="tac" name="tac" onChange={(e) => setCheckBox(e.target.checked)} />
          <label htmlFor="tac" className='mb-0'>By clicking the checkbox, you agree with our terms & conditions</label>
        </form>

        {/* Button */}
        <div className='text-center'>
          <button className='btn btn-primary rounded-pill px-5' disabled={!checkBox} onClick={handleRemove}>{btnStatus}</button>
        </div>

        <div className="mt-4">

          {removeStatus.length > 0 && <div class={`alert alert-${removeBtn}`} role="alert">
            {removeStatus}
          </div>}
        </div>

      </section>
    </>
  );
};

export default ProfileRemove;
