import React, { useContext } from 'react'
import { BASE_URL_STATIC } from '../../../config';
import { Link } from 'react-router-dom';
import { ScApiContext } from '../useContext/softCornerContext';
// import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const RightSpecialDays = () => {

  const {
    setShowAddSpecialDaysPopup,
    specialDay,
    deleteSpecialDay,
  } = useContext(ScApiContext);

  const formattedDate = (inputDateString) => {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr',
      'May', 'Jun', 'Jul', 'Aug',
      'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const inputDate = new Date(inputDateString);

    const month = monthNames[inputDate.getMonth()];
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear();

    const formattedDateString = `${month} ${day}, ${year}`;

    return formattedDateString;
  };

  const formatTime = (timeString) => {
    if (!timeString) return ""; // Handle empty input
    const [hours, minutes] = timeString.split(":").map(Number); // Split into hours and minutes
    const period = hours >= 12 ? "PM" : "AM"; // Determine AM/PM
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`; // Format as "h:mm AM/PM"
  };

  const SpecialDayComponent = (specialDay) => {
    // Calculate the number of days since the special day was created
    const daysDifference = Math.floor((Date.now() - new Date(specialDay)) / (1000 * 60 * 60 * 24));

    return daysDifference
  };

  return (
    <>
      {
        specialDay && specialDay._id !== ''
        ?
        <section className='sc-specialDays-rightBlock'>
          {/* Div */}
          <div className='sc-specialDays-rightBlock-div'>
            {/* Div 1 */}
            <div className='sc-specialDays-rightBlock-div-1'>
              <h2>{specialDay.date && formattedDate(specialDay.date)}</h2>

              <div>
                <h4>
                  {
                    specialDay.date && SpecialDayComponent(specialDay.date) && SpecialDayComponent(specialDay.date) < 0 
                    ? 
                    -(SpecialDayComponent(specialDay.date)) + " Days Left" 
                    : 
                    SpecialDayComponent(specialDay.date) + " Days"
                  }
                </h4>
              </div>
            </div>

            <hr className='sc-specialDays-rightBlock-hr' />

            {/* Div 2 */}
            <div className='d-flex align-items-center gap-3 mb-4'>
              <div className='sc-specialDays-rightBlock-div-2-img'>
                <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-coffee.png`} style={{ height: '30px' }} alt="sc-sd-coffee" />
              </div>

              <div className='sc-specialDays-rightBlock-div-2-head'>
                <h5 className='mb-0'>{specialDay.title}</h5>
                <span>{specialDay.category !== 'Other' ? specialDay.category : specialDay.categoryOther}</span>
              </div>
            </div>

            {/* Location */}
            <div className='border rounded-pill px-3 py-2 d-flex align-items-center gap-3 mb-3'>
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} id='location' name='location' />

              <span className='me-auto flex-grow-1'>{specialDay.location}</span>

              <Link to={`https://www.google.com/maps?q=${specialDay.lat},${specialDay.lng}`} target='_blank' style={{ color: '#FF6969' }}>See map</Link>
            </div>

            {/* Date And Time */}
            <div className='d-flex align-items-center gap-3 mb-3'>
              <div className='border rounded-pill px-3 py-2 flex-grow-1'>
                <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} />
                <span className='ms-3'>{formattedDate(specialDay.date)}</span>
              </div>

              <div className='border rounded-pill px-3 py-2 flex-grow-1'>
                <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} alt="sc-sd-repeat" height={20} width={20} />
                <span className='ms-3'>{formatTime(specialDay.time)}</span>
              </div>
            </div>

            {/* Alerts */}
            <div className='mb-4'>
              {
                specialDay.setCustomAlert === 'true'
                ? 
                // Custom
                <>
                  {
                    specialDay.customDate !== '' && specialDay.customTime !== '' 
                    &&
                    <div className='border rounded-pill px-3 py-2 d-flex align-items-center gap-3 mb-3'>
                      <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="icon" />

                      <span className='flex-grow-1'>Alert</span>

                      <span style={{ color: '#FF6969' }}>{formattedDate(specialDay.customDate)} • {formatTime(specialDay.customTime)}</span>
                    </div>
                  }
                </>
                :
                // Default
                <>
                  {/* 1 Hour */}
                  <>
                    {
                      specialDay.one_hour === 'true'
                      &&
                      <div className='border rounded-pill px-3 py-2 d-flex align-items-center gap-3 mb-3'>
                        <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="icon" />

                        <span className='flex-grow-1'>Alert</span>

                        <span style={{ color: '#FF6969' }}>Before 1 Hour</span>
                      </div>
                    }
                  </>

                  {/* 1 Day */}
                  <>
                    {
                      specialDay.one_day === 'true'
                      &&
                      <div className='border rounded-pill px-3 py-2 d-flex align-items-center gap-3 mb-3'>
                        <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="icon" />

                        <span className='flex-grow-1'>Alert</span>

                        <span style={{ color: '#FF6969' }}>Before 1 Day</span>
                      </div>
                    }
                  </>

                  {/* 1 Week */}
                  <>
                    {
                      specialDay.one_week === 'true'
                      &&
                      <div className='border rounded-pill px-3 py-2 d-flex align-items-center gap-3 mb-3'>
                        <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="icon" />

                        <span className='flex-grow-1'>Alert</span>

                        <span style={{ color: '#FF6969' }}>Before 1 Week</span>
                      </div>
                    }
                  </>

                  {/* 1 Month */}
                  <>
                    {
                      specialDay.one_month === 'true'
                      &&
                      <div className='border rounded-pill px-3 py-2 d-flex align-items-center gap-3'>
                        <img src={`${BASE_URL_STATIC}/SoftCorner/sc-sd-repeat.png`} style={{ height: '15px' }} alt="icon" />

                        <span className='flex-grow-1'>Alert</span>

                        <span style={{ color: '#FF6969' }}>Before 1 Month</span>
                      </div>
                    }
                  </>
                </>
              }
            </div>

            {/* Notes */}
            <div className='mb-3'>
              <div className='d-inline-block px-3 py-1 rounded-pill mb-3' style={{ backgroundColor: '#FF6969' }}>
                <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notes.png`} alt="" height={20} width={20} />
                <span className='text-white ms-2'>Notes</span>
              </div>

              <div className='border rounded-4 p-2'>{specialDay.note}</div>
            </div>

            {/* Div 5 */}
            <button type='button' className='btn rounded-pill w-100 mt-3 text-white' style={{ backgroundColor: '#FF6969' }}
              onClick={() => {setShowAddSpecialDaysPopup(true)}}
            >Update</button>

            {/* Div 6 */}
            <button type='button' className='btn btn-danger rounded-pill w-100 mt-3 text-white'
              onClick={deleteSpecialDay}
            >Delete</button>
          </div>
        </section>
        :
        <section className='sc-specialDays-rightBlock'>
          <h1>Ads</h1>
          <h1>Ads</h1>
          <h1>Ads</h1>
          <h1>Ads</h1>
          <h1>Ads</h1>
        </section>
      }
    </>
  )
}

export default RightSpecialDays