import React, { useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../UseContext/ApiCall';
import axios from 'axios';
import { BASEAPIURL } from '../../../../config';
import { profileContext } from '../../UseContext/ProfileContext';


const SubmiteReportPost = ({ openReport, setopenReport, Reports, SetSubreportClose }) => {
    const { setShowReportPostPopup, config, allDataPost, setallDataPost,
        setunHide } = useContext(ApiContext);
    const { profilePostData } = useContext(profileContext);

    const ReportpostV2 = (postId, targetType, userId, reportPost) => {
        setunHide((items) => {
            return [...items, ...allDataPost.filter(item => item._id === postId)]
        })

        axios.post(`${BASEAPIURL}/post/reportPost`, { postId, targetType, userId, reportPost }, config)
            .then(res => console.log(res))
            .catch(err => console.log(err));

        axios.post(`${BASEAPIURL}/post/hidepost`, { postId: postId }, config).then(res => {

            const updatedPosts = allDataPost.map(post =>
                post._id === postId ? { hide: "true", _id: postId } : post
            );
            setallDataPost(updatedPosts);
            setShowReportPostPopup(false);
            SetSubreportClose(false);
            setopenReport(false);
        }

        ).catch(err => {
            console.error(err)
        })

    }


    return (
        <div>
            <section className='reportPostPopups'>
                <Modal show={openReport} onHide={() => setopenReport(false)} centered backdrop="static" keyboard={false} className="main-modal">
                    <Modal.Header closeButton>&nbsp;</Modal.Header>
                    <Modal.Body className="popupModal-body">
                        {Reports && Array.isArray(Reports) && Reports.length > 0 ? (
                            <>
                                <section className='reportPostPopup'>
                                    <h4 className='reportPostPopup-head'>{Reports[0][0]} Report Post</h4>
                                    <p className='text-center my-4'>Why are you reporting this post?</p>

                                    {Array.isArray(Reports[0][1]) && Reports[0][1].map((item, index) => (
                                        <div key={index} >

                                            <p>{item}</p>
                                            <br />
                                        </div>
                                    ))}

                  <button className="btn btn-danger" type="button"  onClick={() => ReportpostV2(profilePostData._id, profilePostData.type, profilePostData.postedBy, Reports[0][0])}>
                                        Report
                                    </button>
                                </section>


                            </>
                        ) : (
                            <p>No reports available.</p>
                        )}

                    </Modal.Body>
                </Modal>
            </section>
        </div>
    );
}

export default SubmiteReportPost;
