import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AudienceControlApi } from '../../UseContext/ApiHelpers';
import { ApiContext } from '../../UseContext/ApiCall';
import AudienceClosefriend from './AudienceClosefriend';






const AudienceControl = ({ showPopupAudience, setShowAudience, postsPopupData, setPostsPopupData }) => {
  const [showPopupclose, setShowPopupclose] = useState(false)

  const { config,    allDataPost, setallDataPost } = useContext(ApiContext)

  // console.log("postsPopupData",  postsPopupData.visibility)
 
  

  

 

  const HandleAudience = async (event) => {
    console.log("event", event.target.value)

    if (event.target.value === "cf"){
      setShowPopupclose(true);
    } 
    else {
        

      const data = {
        id: postsPopupData._id,
        visibility: event.target.value
      }

      postsPopupData.visibility = event.target.value;


     

      
        let updatedPosts = allDataPost.map(post =>
        post._id === postsPopupData._id  ? { ...post,  visibility: event.target.value } : post)
        setallDataPost(updatedPosts)

     

      await AudienceControlApi(data, config)
      setShowAudience(false);

    }


  }
 

  const handleClose = () => setShowAudience(false);

  return (
    <div>
      < AudienceClosefriend showPopupclose={showPopupclose} setShowPopupclose={setShowPopupclose} postsPopupData={postsPopupData} />
      {/* Modal component */}
      <Modal
        show={showPopupAudience}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        className="main-modal"
      >
        <Modal.Header closeButton>

          {/* <img src={`${BASE_URL_STATIC}/icon/audience-control.svg`} alt="audience-control" /> */}
          <h5 className='fw-semibold ms-3'>Audience Control</h5>


        </Modal.Header>

        {/* Modal Body */}
        <Modal.Body className="popupModal-body">


          <div className='row mx-auto'>
            <div className="ms-2 mb-1 col-lg-5">
              <input className="form-check-input" type="radio" id="ps-storyView-public" name="visibility" value='everyone'
                checked={postsPopupData.visibility === 'everyone'}
                onChange={HandleAudience}
              />
              <label className="form-check-label" htmlFor="ps-storyView-public">Public</label>
            </div>

            <div className="ms-2 mb-1 col-lg-5">
              <input className="form-check-input" type="radio" id="ps-storyView-followers" name="visibility" value='private'
                checked={postsPopupData.visibility === 'private'}
                onChange={HandleAudience}


              />
              <label className="form-check-label" htmlFor="ps-storyView-followers">Followers</label>
            </div>

            <div className="ms-2 mb-1 col-lg-5">
              <input className="form-check-input" type="radio" id="ps-storyView-follower" name="visibility" value='cf'
              checked={postsPopupData.visibility === 'listone' || postsPopupData.visibility === 'listtwo'}
              onClick={HandleAudience}



              />
              <label className="form-check-label" htmlFor="ps-storyView-follower">Close Friends</label>
            </div>

            <div className="ms-2 mb-1 col-lg-5">
              <input className="form-check-input" type="radio" id="ps-storyView-fof" name="visibility" value='followers_of_followers'
                checked={postsPopupData.visibility === 'followers_of_followers'}
                onChange={HandleAudience}


              />
              <label className="form-check-label" htmlFor="ps-storyView-fof">Followers of Followers</label>
            </div>


          </div>



        </Modal.Body>

      </Modal>
    </div>
  );
}

export default AudienceControl;
