import React from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { AwsFileUrl, BASEURL } from '../../../config';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 21.222358,
  lng: 81.6538453,
};

function GooglemapApi({ searchList }) {


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60', // Replace this with your real API key
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={10}
    >
      {searchList.map((i) => {
        // Only render markers if lat and lng are valid
        if (i.lat && i.long && parseFloat(i.lat) !== 0 && parseFloat(i.long) !== 0) {
          return (
            <Marker
              key={i._id}
              position={{ lat: parseFloat(i.lat), lng: parseFloat(i.long) }}
              title={i.fname}

              onClick={() => {
                // Replace with your actual link
                window.open(`${BASEURL}/profile/${i._id}`, '_blank');
              }}
            >
              <InfoWindow position={{ lat: parseFloat(i.lat), lng: parseFloat(i.long) }}>
                <div style={{ textAlign: 'center', padding: '5px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}    onClick={() => {
                // Replace with your actual link
                window.open(`${BASEURL}/profile/${i._id}`, '_blank');
              }}>
                  {/* Circular Image */}
                  <img
                    src={AwsFileUrl + i.imageId} // Use the image URL
                    alt={i.fname}
                    style={{
                      width: '50px', // Slightly bigger for better visibility
                      height: '50px', // Same as width for perfect circle
                      borderRadius: '50%', // Keep the image round
                      marginBottom: '15px', // Space below the image
                      objectFit: 'cover', // Ensures image is well cropped within the circle
                    }}
                  />
                  <div style={{ fontWeight: 'bold', color: '#333', fontSize: '14px', marginBottom: '5px' }}>
                    {i.fname} {/* Display name */}
                  </div>
                  {/* Optionally add more information, such as a job title, location, etc. */}
                
                </div>
              </InfoWindow>

            </Marker>

          );
        }

        return null;
      })}
    </GoogleMap>
  );
}

export default GooglemapApi;
