import React, { useContext, useEffect, useRef } from 'react';
import LeftBlock from '../Home/LeftBlock/LeftBlock';
import { useParams } from 'react-router-dom';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { ApiContext } from '../UseContext/ApiCall';


const AudienceLive = ({ className }) => {

  const { roomId } = useParams();

  console.log('roomId : ', roomId);

  const containerRef = useRef(null);

  console.log('containerRef : ', containerRef);

  const { 
    loggedInUser,
  } = useContext(ApiContext);

  const initZegoCloud = (element) => {
    if (!roomId || !loggedInUser) {
      console.error('Missing roomId or loggedInUser');
      return;
    };
    
    const appID = 1632643095; 
    const serverSecret = 'fcb29e34aa8bba2eecf90cd9f3cdc81d'; 

    // Generate the kit token for Audience
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomId,
      loggedInUser.userId,
      loggedInUser.username || 'Audience'
    );

    // Load the ZEGOCLOUD UI as an Audience
    ZegoUIKitPrebuilt.create(kitToken).joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.LiveStreaming,
        config: {
          role: 'Audience',
        },
      },
    });
  };

  useEffect(() => {
    if (containerRef.current) {
      initZegoCloud(containerRef.current);
    };
  }, [roomId, loggedInUser]);

  return (
    <section className={`section-main ${className || ''}`}>
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div className="col-lg-3 d-none d-lg-block shadow z-1">
            <LeftBlock />
          </div>

          <div className="col-lg-9 pt-5 overflow-y-scroll scrollbar-hidden px-3" style={{ background: 'lightblue', height: '100vh' }}>
            <h3 className='pt-4 text-center fw-bold'>Audience Live Stream</h3>
            
            <div id="zego-container" style={{ width: '100%', height: '600px' }} ref={containerRef}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AudienceLive;