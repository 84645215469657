import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getChatReaction } from '../../UseContext/ApiHelpers'
import { ApiContext } from '../../UseContext/ApiCall'
import { AwsFileUrl } from '../../../../config'

const ReactionPopup = ({ open, close, reactions, handleDeleteReaction }) => {

    const { loggedInUserId, config } = useContext(ApiContext)
    const [chatReactions, setchatReactions] = useState([])

    useEffect(() => {
        getChatReaction(reactions.messageId, config).then((res) => {
            setchatReactions(res)
        })
    }, [open,reactions])

    const handleFilterUseer = (reactionId) => {
        setchatReactions(chatReactions.filter((r) => r._id !== reactionId))

    }

    useMemo(() => {
        if (chatReactions.length < 1) {
            close();
        }
    }, [chatReactions])

    return (
        <div>
            <Modal show={open} onHide={close} centered backdrop="static" keyboard={false} className="create-call" >
          <Modal.Header closeButton>&nbsp;</Modal.Header>

                <Modal.Body>
                    <h4 className='text-center'>Reactions</h4>
                    <div className='reaction_popup'>
                        {chatReactions.length > 0 && chatReactions.map((r, i) => (
                            <div className='d-flex justify-content-bewteen gap-3 align-items-center'>
                                <div className='d-flex justify-content-bewteen gap-3 w-100'>
                                    <img className='rounded-circle' src={ AwsFileUrl + r.fileUrl} alt="" width={40} />
                                    <div>
                                        {r.fname && <p className='fs-15'>{r.fname}</p>}
                                        {r.username && <p className='fs-12'>{r.username}</p>}
                                    </div>
                                </div>
                                <div>
                                    {loggedInUserId === r.userId && <FontAwesomeIcon icon={faClose} className='cp' onClick={() => {
                                        handleDeleteReaction(r._id, reactions.messageId);
                                        handleFilterUseer(r._id)
                                    }} />}
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ReactionPopup