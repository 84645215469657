import React, { useState, useEffect, useContext } from 'react';
import ProfileUser from '../../Profile/ProfileUser/ProfileUser';
import Navbar from '../Navbar/Navbar';
import axios from 'axios';
import { ApiContext } from '../../UseContext/ApiCall';

import { AwsFileUrl } from '../../../../config';
import ProfilePostPopup from '../../Popup/Profile/ProfileShow/ProfilePostPopup';

const Tagged = () => {

  const [taggedPosts, setTaggedPosts] = useState([]);
 
   
   const [showPostsPopup, setShowPostsPopup] = useState(false);
   const [postId, setPostId] = useState('');
   const [mainpoastData , setmainpoastData] = useState('')

  const { config,  } = useContext(ApiContext);

  // To get tagged posts
  const getTaggedPosts = ( ) => {
    axios.post('http://localhost:3001/post/getusertag', {postType : "postType"},  config)
    .then(res => {
      console.log("maindata", res.data);
      setTaggedPosts( res.data)
     
    })
    .catch(err => {
      console.log(err);
    });
  };


  useEffect(() =>{
  getTaggedPosts(); 
  }, [])



  return (
    <>
      <section className='tagged'>
        <ProfileUser />
        <Navbar />
       <>
      {/* Profile Best Clip Popup */}
     <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={mainpoastData} postId={postId} />

      <section className='favourite-bestClips'>
        {/* <h1 className='favourite-bestClips-head'>BestClips</h1> */}

        <div className='favourite-bestClips-imgs'>
          {
           taggedPosts&& taggedPosts.map((favouriteBestClip, index) => (
            
            <img className='favourite-bestClips-img' src={AwsFileUrl+(favouriteBestClip.mediaUrls[0].thumbnail || favouriteBestClip.postTumbnailUrl)} alt={favouriteBestClip.tumbnailId} onClick={() =>{setShowPostsPopup(!showPostsPopup); setmainpoastData(favouriteBestClip);  setPostId(favouriteBestClip._id)}}  height={215} width={200} />
            ))
          }
        </div>
      </section>
    </>

      </section> 
    </>
  );
};

export default Tagged;
