import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { matchPartner } from "../../Emilo/UseContext/ScApiHelpers";
import { ApiContext } from "../../Emilo/UseContext/ApiCall";
import { BASEAPIURL } from "../../../config";
import { generateThumbnail } from "../../Emilo/Chats/Chat/generateThumbnail ";
import toast from "react-hot-toast";

export const ScApiContext = createContext();

export const ScApiCall = ({ children }) => {

  const [partnerUserId, setPartnerUserId] = useState();
  const [followStatusLoader, setFollowStatusLoader] = useState(false);
  const [buttonDis, setButtonDis] = useState(false);
  const [listPost, setListPost] = useState([]);
  const [partnerPosts, setPartnerPosts] = useState([]);
  const [createPop, setCreatePop] = useState(false);
  const [posts, setPosts] = useState([]);
  const [disableNext, setDisableNext] = useState(false)
  const [matchOpen, setmatchOpen] = useState(false)
  const [chatId, setChatId] = useState(null);
  // const [partnerImage, setPartnerImage] = useState("");
  const [btnEnable, setBtnEnable] = useState()
  const [profileIndex, setProfileIndex] = useState(-1)
  const [softCornerUsers, setSoftCornerUsers] = useState([]);
  const [isMatched, setIsMatched] = useState(false)

  const [slidertype, setSlidertype] = useState("")
  const [currentCrush, setCurrentCrush] = useState(null)
  const [editMessage, setEditMessage] = useState("")


  const [rightBlockUser, setRightBlockUser] = useState();
  const [isSoftCornerPartnerExist, setIsSoftCornerPartnerExist] = useState(null);

  const { config, socket } = useContext(ApiContext);

  const scConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("SCtoken")}`,
    },
  };

  // setInterval(async () => {
  //   try {

  //     const alertTime = await axios.post("https://emilonode.sjain.io/softcorner/getspecialdayalert" , {} ,  {headers: {
  //       Authorization: `Bearer ${localStorage.getItem("SCtoken")}`,
  //     }})

  //   if(alertTime){
  //     toast.success(alertTime.data.responses.message)
  //   }
  // } catch (error) {

  //   toast.error(" nothing")
  //   }
  // }, 1000)

  const inputRef = useRef();



  // ***** Image Compression ***** 

  const scPostOption = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 175,
    useWebWorker: true,
  };


  // Add Remove button
  const handleAddRemoveButton = async (myUserId, partnerUserId, setbtnLoader, action) => {
    setPartnerUserId(partnerUserId);
    matchPartner(scConfig);
    setbtnLoader(true);
    try {
      await axios.post(`${BASEAPIURL}/softcorner/addSCPartner`, { emiloUserId: myUserId, partnerUserId: partnerUserId, action: action }, scConfig)
        .then(async (res) => {
          setRightBlockUser(true)
          setIsSoftCornerPartnerExist(false)
          setBtnEnable(false)
          // For notification
          axios.post(`${BASEAPIURL}/user/createemnotification`, { userId: partnerUserId, type: 'softcorner' }, config)
            .then(res => {
              let notificationData = {
                type: res.data.type,
                receiverUserId: res.data.receiverUserId && res.data.receiverUserId,
                message: res.data.message && res.data.message,
                createdAt: res.data.createdTime && res.data.createdTime,
              };

              socket.emit("send_message_emilo_notification", notificationData);
              console.log('sc notificationData : ', notificationData);
            })
            .catch(err => {
              console.log(err);
              setBtnEnable(false)
            });
        })
        .catch((err) => {
          setbtnLoader(false);
          setBtnEnable(false)
          setRightBlockUser(false)
          setIsSoftCornerPartnerExist(true)

        });
      // await disableButton(action)

    } catch (error) {
      setIsSoftCornerPartnerExist(true)
      setBtnEnable(false)
      setRightBlockUser(false)
      setbtnLoader(false);

    } finally {
      setRightBlockUser(false)
      setBtnEnable(false)
      setIsSoftCornerPartnerExist(true)
      setbtnLoader(false);
    }
  }

  useEffect(() => {
    disableButton();
  }, []);


  function handleFileChange(e) {
    const selectedFiles = Array.from(e.target.files);
    setPosts(selectedFiles);
    setCreatePop(!createPop);
  };

  const disableButton = async () => {
    const res = await axios.post(
      "https://emilonode.sjain.io/softcorner/disablebutton",
      { partnerUserId },
      scConfig
    );

    // console.log("disableLog", res.data);
    setButtonDis(res.data.disable);
  };

  const fetchAddStatus = async (partnerUserId, setIsAdded) => {
    try {
      setFollowStatusLoader(true);
      await axios
        .post(
          "https://emilonode.sjain.io/softcorner/checkuserinsc",
          { partnerUserId: partnerUserId },
          scConfig
        )
        .then((res) => {
          setIsAdded(res.data.add);

          // if (res.data.add) {
          //   disableButton(res.data.add);
          // }

          setTimeout(() => {
            setFollowStatusLoader(false);
          }, 500);
        });
    } catch (error) {
      setFollowStatusLoader(false);
    }

  };

  // To check is soft corner partner exist or not  
  const checkSoftCornerPartnerExist = (scConfig) => {
    axios.post(`${BASEAPIURL}/softcorner/checkparterhaiyaNnhi`, {}, scConfig)
      .then(res => {
        // console.log("eeee",res.data.added);
        setIsSoftCornerPartnerExist(res.data.added);
      })
      .catch(err => {
        // console.log(err);
      });
  };

  
// export const createSendMessage = async (message, chatId, scConfig) => {
//   const res = await axios.post(`${BASEAPIURL}/softcorner/createchatmessage`, { message, chatId }, scConfig)
//   return res.data
// }

  // create emilo message
  const createSendMessage = async (sendMessage, chatId, file, type, fileName) => {
    let upload_msg_file = null;
    let thumbnailFile = null;
    if (typeof file === 'object') {
      // Generate thumbnail if the file is a video
      if (file.type.startsWith('video/')) {
        try {
          thumbnailFile = await generateThumbnail(file);
        } catch (error) {
          console.error('Error generating thumbnail:', error);
        }
      }
      // Upload file if it is not a thumbnail
      const formData = new FormData();
      formData.append('image', file);
      formData.append('thumbnail', thumbnailFile)
      formData.append('fileType', type)
      try {
        upload_msg_file = await axios.post(`${BASEAPIURL}/user/upload_msg_file`, formData, config);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    const data = {
      chatId: chatId,
      message: type === 'location' ? JSON.stringify(sendMessage) : sendMessage,
      fileName: typeof file === 'object' ? file.name : fileName,
      type: type,
      fileUrl: typeof file === 'object' ? upload_msg_file && upload_msg_file.data.getUrl : file,
      thumbnail: thumbnailFile ? upload_msg_file.data.get_thumbnail_url : null
    };

    try {
      const res = await axios.post(`${BASEAPIURL}/softcorner/createchatmessage`, data, scConfig);
      return res.data;
    } catch (error) {
      console.error('Error creating message:', error);
    }
  };



  // ---------- Special Days ----------

  const [showAddSpecialDaysPopup, setShowAddSpecialDaysPopup] = useState(false);
  const [specialDay, setSpecialDay] = useState({
    _id: '',
    title: '',
    category: '',
    categoryOther: '',
    location: '',
    lat: '',
    lng: '',
    date: '',
    time: '',
    setCustomAlert: 'false',
    one_hour: 'false',
    one_day: 'false',
    one_week: 'false',
    one_month: 'false',
    customDate: '',
    customTime: '',    
    note: '',
  });

  const [specialDays, setSpecialDays] = useState([]);

  console.log('specialDays : ', specialDays);

  const [dummy, setDummy] = useState([]);
  const [loader, setLoader] = useState(false);


  // create special day
  const createSpecialDay = async () => {
    const payload = {
      specialDay,
    };

    await axios.post(`${BASEAPIURL}/softcorner/createSpecialDay`, payload, scConfig)
    .then(res => {
      console.log('res : ', res.data);      

      toast.success(res.data.message);

      setShowAddSpecialDaysPopup(false);

      if(res.data.newSpecialDay) {
        setSpecialDays(prevDays => [res.data.newSpecialDay, ...prevDays]);

        setSpecialDay(res.data.newSpecialDay);
      };

    }).catch(err => {
      console.log('err : ', err);
    });  
  };

  // get special day
  const getSpecialDays = async () => {
    await axios.post(`${BASEAPIURL}/softcorner/getSpecialDays`, {}, scConfig)
    .then(res => {
      console.log('res : ', res.data);

      setSpecialDays(res.data.specialDays);

      if(res.data.specialDays.length > 0) {
        setSpecialDay(res.data.specialDays[0]);
      } else {
        setSpecialDay({
          _id: '',
          title: '',
          category: '',
          categoryOther: '',
          location: '',
          lat: '',
          lng: '',
          date: '',
          time: '',
          setCustomAlert: 'false',
          one_hour: 'false',
          one_day: 'false',
          one_week: 'false',
          one_month: 'false',
          customDate: '',
          customTime: '',    
          note: '',
        });
      };
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // delete special day
  const deleteSpecialDay = async () => {
    await axios.post(`${BASEAPIURL}/softcorner/deleteSpecialDay`, { specialDayId: specialDay._id }, scConfig)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      setSpecialDays(prevDays => prevDays.filter(day => day._id !== specialDay._id));

      if(specialDays.length === 1) {      // agar special days me days nhi h to right block show nhi krna h
        setSpecialDay({
          _id: '',
          title: '',
          category: '',
          categoryOther: '',
          location: '',
          lat: '',
          lng: '',
          date: '',
          time: '',
          setCustomAlert: 'false',
          one_hour: 'false',
          one_day: 'false',
          one_week: 'false',
          one_month: 'false',
          customDate: '',
          customTime: '',    
          note: '',
        });
      } else {                           // agar special days me days h to right block me first index show krna h
        setSpecialDay(specialDays[0]);
      };
    }).catch(err => {
      console.log('err : ', err);
    });
  };






  useEffect(() => {
    checkSoftCornerPartnerExist(scConfig);
  }, []);

  const value = {

    // ---------- Special Days ----------
    showAddSpecialDaysPopup, setShowAddSpecialDaysPopup,
    specialDay, setSpecialDay,
    createSpecialDay,
    deleteSpecialDay,


    specialDays, setSpecialDays,
    dummy, setDummy,
    loader, setLoader,
    getSpecialDays,



    scConfig,
    partnerUserId,
    scPostOption,
    createSendMessage,
    fetchAddStatus,
    createPop, setCreatePop,
    handleAddRemoveButton,
    disableNext, setDisableNext,
    posts, setPosts,
    followStatusLoader,
    setFollowStatusLoader,
    buttonDis,
    setButtonDis,
    partnerPosts,
    setPartnerPosts,
    listPost,
    slidertype, setSlidertype,
    profileIndex, setProfileIndex,
    setListPost,
    btnEnable, setBtnEnable,
    currentCrush, setCurrentCrush,
    softCornerUsers, setSoftCornerUsers, handleFileChange,
    inputRef, chatId, setChatId,
    isMatched, setIsMatched,
    editMessage, setEditMessage,
    rightBlockUser, setRightBlockUser,
    matchOpen, setmatchOpen,
    isSoftCornerPartnerExist, setIsSoftCornerPartnerExist
  };

  return (
    <ScApiContext.Provider value={value}>{children}</ScApiContext.Provider>
  );
};
