import React, { useEffect, useState,  useContext } from "react";
import { getFrames, VideoToFramesMethod,  } from "./VideoFrames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons'
import "./thumbnail.css";
import { ApiContext } from "../UseContext/ApiCall";

export default function GetThumbnail({ file,  status, setStatus, index }) {
   const [images, setImages] = useState([]);
 const {selectedImage, setSelectedImage} =   useContext(ApiContext)

   useEffect(() => {
      onInput(file);
   }, [file]);

   const onInput = async (file) => {
      setImages([]);
      setStatus(true);
      const reader = new FileReader();
      reader.onload = async () => {
         const fileUrl = reader.result;

         const frames = await getFrames(
            fileUrl,
            30,
            VideoToFramesMethod.totalFrames
         );

         const imageFiles = await Promise.all(
            frames.map(async (imageUrl, index) => {
               const response = await fetch(imageUrl);
               const blob = await response.blob();
               return new File([blob], `${now}-${index + 1}.png`, { type: "image/png" });
            })
         );
         setStatus(false);
         setImages(imageFiles);

         // Ensure the first thumbnail (zeroth index) is selected by default for this video
         if (!selectedImage[index]) {
            setSelectedImage((prev) => ({
               ...prev,
               [index]: imageFiles[0], // set zeroth index thumbnail as default
            }));
         }
      };

      reader.readAsDataURL(file);
   };

   const now = new Date().toDateString();

   return (
      <div className="container">
         {status ? (
            <span>
               <FontAwesomeIcon icon={faArrowRotateLeft} spin />
               &nbsp; Generating thumbnail images...
            </span>
         ) : (
            images.length > 0 && (
               <div className="output my-2">
                  {images.map((imageFile, imgIndex) => (
                     <img
                        key={imgIndex}
                        className={`${selectedImage[index] === imageFile && 'shadowe'}`}
                        src={URL.createObjectURL(imageFile)}
                        alt=""
                        onClick={() => {
                           // Update only the selected thumbnail for the current video (index)
                           setSelectedImage((prev) => ({
                              ...prev,
                              [index]: imageFile, // Update the selected image for this video
                           }));
                        }}
                     />
                  ))}
               </div>
            )
         )}
      </div>
   );
}
