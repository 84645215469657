import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import { editMessage, ReplyEmiloMessage } from "../../UseContext/ApiHelpers";
import { AwsFileUrl, BASE_URL_STATIC } from "../../../../config";
import { ApiContext } from "../../UseContext/ApiCall";
import EmojiPicker from "emoji-picker-react";
import MsgAudioPlayer from "./MsgAudioPlayer";

const ChatFooter = ({
  optionShowHide,
  tempUsers,
  typing,
  isEdit,
  setIsEdit,
  setShowusers,
  loggedInUserId,
  showUsers,
  lastMessage,
  // setLastMessage,
  chatId,
  userId,
  setReplyContent,
  sendMessage,
  setSendMessage,
  online,
  handleChange,
  ThreeDots,
  setArrivalMsg,
  config,
  isGroupchat,
  selectedPhotos,
  typerFname,
  socket,
  createEmiloMessage,
  setSelectedPhotos,
  typeChatId,
  setSelectedMsg,
  replyContent, 
  msgType, 
  setMsgType
}) => {

  console.log('sendMessage : ', sendMessage);

  const [openEmoji, setOpenEmoji] = useState(false);
  const [msgLoader, setMsgLoader] = useState(false)

  // To handle emoji picker
  const handleEmojiPick = (e) => {
    setSendMessage((prev) => prev + e.emoji);
  };

  const handleTempusers = (e) => {
    setSendMessage((prev) => {
      const lastAtIndex = prev.lastIndexOf('@');

      const newMessage = prev.substring(0, lastAtIndex) + '@' + (e.username || e.fname);

      return newMessage;
    });

    setShowusers(false);
  };

  // ----- Selected Files ----- 

  const [showFilesOption, setShowFilesOption] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);


  const handleFileClick = () => {
    setShowFilesOption(!showFilesOption);
  };

  // --- Gallary ---

  const gallaryInputRef = useRef();

  // It will open select photo and video field
  const handleGalleryClick = () => {
    if (gallaryInputRef.current) {
      gallaryInputRef.current.click();
    };
  };

  // Here we will get selected files
  const handleGallaryChange = (event) => {
    const files = event.target.files;

    if (files.length > 5) {
      toast.error('You can select only 5 files');
    } else {
      const selectedFilesWithType = Array.from(files).map((file) => {
        let msgType = "";

        // Check file type by MIME type or extension
        if (file.type.startsWith('image/')) {
          msgType = 'img_file';
        } else if (file.type.startsWith('video/')) {
          msgType = 'video_file';
        }

        return { file, msgType };
      });

      setSelectedFiles(selectedFilesWithType);
    }
  };

  // --- Audio ---

  const audioInputRef = useRef();

  // It will open select photo and video field
  const handleAudioClick = () => {
    if (audioInputRef.current) {
      audioInputRef.current.click();
    };
  };

  // Here we will get selected files
  const handleAudioChange = (event) => {
    const files = event.target.files;

    if (files.length > 5) {
      toast.error('You can select only 5 files');
    } else {
      const selectedFilesWithType = Array.from(files).map((file) => {
        let msgType = "";

        // Check file type by MIME type or extension
        if (file.type.includes('audio')) {
          msgType = 'audio_file';
        }

        return { file, msgType };
      });
      setSelectedFiles(selectedFilesWithType);
    };
  };

  // console.log("selected" , selectedFiles)

  // --- Documents ---

  const documentInputRef = useRef();

  // It will open select photo and video field
  const handleDocumentClick = () => {
    if (documentInputRef.current) {
      documentInputRef.current.click();
    };
  };

  // Here we will get selected files
  const handleDocumentChange = (event) => {
    const files = event.target.files;

    if (files.length > 5) {
      toast.error('You can select only 5 files');
    } else {
      const selectedFilesWithType = Array.from(files).map((file) => {
        let msgType = "";

        // Check file type by MIME type or extension
        if (file.type.includes('pdf')) {
          msgType = 'pdf_file';
        }

        return { file, msgType };
      });
      setSelectedFiles(selectedFilesWithType);
    };
  };

  // It will remove selected files (photo, video, audio and document)
  const handleRemoveSelectedFile = (index) => {
    setSelectedFiles((prev) => prev.filter((data, i) => i !== index));
  };

  // ----- Camera -----

  const cameraInputRef = useRef(null);

  // To click on camera icon it will open device camera 
  const handleCameraClick = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    };
  };

  // To handle clicked picture by device camera
  const handleCameraChange = (event) => {
    const file = event.target.files;
    if (file) {
      const selectedFilesWithType = Array.from(file).map((file) => {
        let msgType = "";

        // Check file type by MIME type or extension
        if (file.type.startsWith('image/')) {
          msgType = 'img_file';
        } else if (file.type.startsWith('video/')) {
          msgType = 'video_file';
        }

        return { file, msgType };
      });
      setSelectedFiles(selectedFilesWithType);
    };
  };

  // ----- Record -----

  // const [showRecord, setShowRecord] = useState(false);

  // To handle record click
  // const handleRecordClick = () => {
  //   setShowRecord(!showRecord);
  // };


  // ----- Show/Hide Popup ----- 

  const popupShowHideRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleFocusOut);
    return () => {
      document.removeEventListener("mousedown", handleFocusOut);
    };
  }, []);

  const handleFocusOut = (event) => {
    if (!popupShowHideRef.current || !popupShowHideRef.current.contains(event.target)) {
      setShowFilesOption(false);
      // setSelectedFiles([]);
    };
  };

  useEffect(() => {
    setSelectedPhotos([]);
  }, []);

  useMemo(() => {
    socket && socket.emit("join_room_emilo", chatId);
  }, [chatId]);

  const { setLastMsg, setChatUsers, setPinnedUser, setHideUsers, chatProfile } = useContext(ApiContext);

  // To handle chat submit
  const handleChatSubmit = (e) => {
    e.preventDefault();

    if (!isEdit) {
      if (online) {          // when user is online
        if (replyContent !== null) {
          setMsgLoader(true);

          ReplyEmiloMessage(
            sendMessage,
            replyContent.messageId,
            replyContent.fileUrl,
            replyContent.thumbnail,
            replyContent.type,
            chatId,
            msgType,
            config
          ).then(async (res) => {
            const newMessage = {
              message: res.message,
              senderUserId: res.senderUserId,
              receiverUserId: res.receiverUserId,
              side: res.side,
              createdTime: res.createdTime,
              chatId: res.chatId,
              loading: true,
              messageId: res.messageId,
              replyMessage: '',
              replyfileUrl: res.replyfileUrl,
              replyThumbnailUrl: res.replyThumbnailUrl,
              replyfileType: res.replyfileType,
              replyMessageId: replyContent.messageId,
              senderFname: res.senderFname,
              isSeen: "0",
              type: msgType,
              fileName: res.fileName,
            };
            if (res.replyfileType === 'location') {
              newMessage.replyMessage = replyContent.message.l_address;
            } else if (res.replyfileType === 'contact') {
              newMessage.replyMessage = replyContent.message.contactName;
            } else {
              newMessage.replyMessage = replyContent.message;
            };

            setReplyContent(null)
            socket.emit("send_message_emilo", newMessage);
            setMsgLoader(false)

            setLastMsg(newMessage);

            const updateUserList = (list) => {
              const updatedList = list.map((user) => {
                if (
                  user.chatId === newMessage.chatId &&
                  user.isGroupChat === "true"
                ) {
                  return {
                    ...user,
                    lastMessage: newMessage.message,
                    senderFname: newMessage.senderFname,
                    senderUserId: loggedInUserId,
                    createdTime: new Date(),
                  };
                } else if (
                  user.chatId === newMessage.chatId &&
                  user.isGroupChat === "false"
                ) {
                  return {
                    ...user,
                    lastMessage: newMessage.message,
                    createdTime: new Date(),
                  };
                }
                return user;
              });

              updatedList.sort(
                (a, b) => new Date(b.createdTime) - new Date(a.createdTime)
              );

              return updatedList;
            };

            setChatUsers((prev) => updateUserList(prev));
            setPinnedUser((prev) => updateUserList(prev));
            setHideUsers((prev) => updateUserList(prev));
            setArrivalMsg((list) => [...list, newMessage]);
            setSendMessage("");
            setMsgLoader(false)
            setReplyContent(null);
            // setLastMessage(newMessage);
          });
        } else if (replyContent === null) {
          setMsgLoader(true);

          selectedFiles.length > 0 
          ?
          selectedFiles.map(({ file, msgType }) => {
            return createEmiloMessage(sendMessage, chatId, file, msgType, false)
              .then(async (res) => {
                const newMessage = {
                  message: res.message,
                  senderUserId: res.senderUserId,
                  receiverUserId: res.receiverUserId,
                  side: res.side,
                  createdTime: res.createdTime,
                  chatId: res.chatId,
                  loading: true,
                  messageId: res.messageId,
                  senderFname: res.senderFname,
                  isSeen: "0",
                  fileUrl: res.fileUrl,
                  fileName: res.fileName,
                  type: msgType, // set msgType based on the file
                  thumbnail: res.thumbnail
                };

                socket.emit("send_message_emilo", newMessage);
                setMsgLoader(false)
                const updateUserList = (list, chatId) => {
                  const userExists = list.some((user) => user.chatId === chatId);
                  let updatedList;

                  if (!userExists) {
                    updatedList = [
                      ...list,
                      {
                        chatId,
                        lastMessage: newMessage.message,
                        senderFname: newMessage.senderFname,
                        senderUserId: newMessage.senderUserId,
                        createdTime: new Date(newMessage.createdTime),
                      },
                    ];
                  } else {
                    updatedList = list.map((user) => {
                      if (user.chatId === chatId) {
                        return {
                          ...user,
                          lastMessage: newMessage.message,
                          senderFname: user.isGroupChat === "true" ? newMessage.senderFname : user.senderFname,
                          senderUserId: user.isGroupChat === "true" ? newMessage.senderUserId : user.senderUserId,
                          createdTime: new Date(newMessage.createdTime),
                        };
                      }
                      return user;
                    });
                  }

                  // Sort the updated list by createdTime
                  updatedList.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));

                  return updatedList;
                };

                setChatUsers((prev) => {
                  const updatedList = updateUserList(prev, newMessage.chatId);
                  return updatedList.filter((user) => !user.isPinned && !user.isHidden);
                });

                setPinnedUser((prev) => {
                  const updatedList = updateUserList(prev, newMessage.chatId);
                  return updatedList.filter((user) => user.isPinned);
                });

                setHideUsers((prev) => {
                  const updatedList = updateUserList(prev, newMessage.chatId);
                  return updatedList.filter((user) => user.isHidden);
                });

                setArrivalMsg((list) => [...list, newMessage]);
                setSendMessage("");
                setSelectedFiles([]);
                setMsgLoader(false)

              })
              .catch((error) => {
                console.error("Error sending message:", error);
                toast.error("Failed to send message");
                setMsgLoader(false)
                setReplyContent(null)
              });
          })
          :
          createEmiloMessage(sendMessage, chatId, '', msgType, false)
          .then(async (res) => {
            const newMessage = {
              message: res.message,
              senderUserId: res.senderUserId,
              receiverUserId: res.receiverUserId,
              side: res.side,
              createdTime: res.createdTime,
              chatId: res.chatId,
              loading: true,
              messageId: res.messageId,
              senderFname: res.senderFname,
              isSeen: "0",
              fileUrl: res.fileUrl,
              fileName: res.fileName,
              type: msgType
            };
            socket.emit("send_message_emilo", newMessage);

            const updateUserList = (list, chatId) => {
              const userExists = list.some((user) => user.chatId === chatId);
              let updatedList;

              if (!userExists) {
                updatedList = [
                  ...list,
                  {
                    chatId,
                    lastMessage: newMessage.message,
                    senderFname: newMessage.senderFname,
                    senderUserId: newMessage.senderUserId,
                    createdTime: new Date(newMessage.createdTime),
                  },
                ];
              } else {
                updatedList = list.map((user) => {
                  if (user.chatId === chatId) {
                    return {
                      ...user,
                      lastMessage: newMessage.message,
                      senderFname:
                        user.isGroupChat === "true"
                          ? newMessage.senderFname
                          : user.senderFname,
                      senderUserId:
                        user.isGroupChat === "true"
                          ? newMessage.senderUserId
                          : user.senderUserId,
                      createdTime: new Date(newMessage.createdTime),
                    };
                  }
                  return user;
                });
              }

              // Sort the updated list by createdTime
              updatedList.sort(
                (a, b) => new Date(b.createdTime) - new Date(a.createdTime)
              );

              return updatedList;
            };

            setChatUsers((prev) => {
              const updatedList = updateUserList(prev, newMessage.chatId);
              return updatedList.filter(
                (user) => !user.isPinned && !user.isHidden
              );
            });

            setPinnedUser((prev) => {
              const updatedList = updateUserList(prev, newMessage.chatId);
              return updatedList.filter((user) => user.isPinned);
            });

            setHideUsers((prev) => {
              const updatedList = updateUserList(prev, newMessage.chatId);
              return updatedList.filter((user) => user.isHidden);
            });

            setArrivalMsg((list) => [...list, newMessage]);
            setSendMessage("");
            setMsgLoader(false)
            setReplyContent(null)

          })
          .catch((error) => {
            setMsgLoader(false)
            console.error("Error sending message:", error);
            toast.error("Failed to send message");
          });
        };
      } else {               // when user is offline, directly add the message to arrivalMsg
        if (sendMessage.trim() !== "") {
          const temporaryMessageId = Math.random().toString(36).substr(2, 9);
          const newMessage = {
            message: sendMessage.trim(),
            senderUserId: userId,
            receiverUserId: chatId,
            side: "left", // Assuming it's the sender's message
            createdTime: new Date().toISOString(),
            chatId: chatId,
            loading: false,
            isSeen: "0",
            messageId: temporaryMessageId,
          };

          setArrivalMsg((list) => [...list, newMessage]);
          setSendMessage("");
        } else {
          toast.error("Please write a message");
        };
      }
    } else {
      editMessage(replyContent && replyContent.messageId, sendMessage, config)
      .then(() => {
        replyContent.message = sendMessage; 



        socket.emit("send_message_emilo", replyContent);
        setSelectedMsg([]);
        setArrivalMsg((msgs) =>
          msgs.map((msg) => {
            if (msg.messageId === replyContent.messageId || msg.replyMessageId === replyContent.messageId) {
              return { ...msg, message: sendMessage, isEdited: "1" };
            }
            return msg;
          })
        );
        setSendMessage("")
        setReplyContent(null)
        setMsgLoader(false)
        setIsEdit(false)
      })
      .catch((error) => {
        setIsEdit(false)
        setMsgLoader(false)
        console.error(`Error deleting message :`, error);
      });
    };
  };

  const handleCheck = () => {
    if (chatProfile.isAdmincanMsg === 'true' && chatProfile.iamAdmin === 'true') {
      return false
    }
    else if (chatProfile.isAdmincanMsg === 'true' && chatProfile.iamAdmin === 'false') {
      return true
    }
    else {
      return false
    }
  };

  return (
    <>
      <div className="chat-footer border position-fixed shadow bg-white rounded-pill px-2 py-1 d-flex align-items-center">
        {/* Emoji */}
        <>
          {
            !handleCheck() 
            && 
            <img className="ms-1 ms-sm-2 cursor-pointer" width='23px' alt="emoji" 
              onClick={() => setOpenEmoji(!openEmoji)} src={`https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f60a.png`} 
            />
          }

          <span className="position-absolute" style={{ bottom: '47px' }}>
            {openEmoji && <EmojiPicker onEmojiClick={(res) => handleEmojiPick(res)} autoFocusSearch={false} EmojiPicker />}
          </span>
        </>

        {/* Message */}
        <>
          {
            handleCheck() 
            ?
            <textarea className="border-0 flex-grow-1 mx-2 mx-sm-3 resize-none z-10" dir="auto" rows='1' name="message" id="message" placeholder={'Only admin can message...'} readOnly></textarea>
            :
            <textarea className="border-0 flex-grow-1 mx-2 mx-sm-3 resize-none z-10" dir="auto" rows='1' value={sendMessage} onChange={handleChange} name="message" id="message" placeholder='Message...'></textarea>
          }
        </>

        <>
          {
            showUsers 
            && 
            <div className="chatusers" ref={optionShowHide}>
              {
                tempUsers && tempUsers.length > 0 
                && 
                tempUsers.map((e, i) => (
                  <div key={i} className="d-flex gap-2 my-1 cp">
                    <img src={`${BASE_URL_STATIC}/profile-img1.jpg`} className="rounded-pill" width={25} alt="chatuser" />
                    <small onClick={() => handleTempusers(e)}>{e.username || e.fname}</small>
                  </div>
                ))
              }
            </div>
          }
        </>

        {/* Typing */}
        <>
          {
            typeChatId === chatId 
            && 
            <div className="typeStyle"
              style={{
                display: `${typing ? "block" : "none"}`,
                margin: `${!typing ? "50px 0px" : "0px"}`,
              }}
            >
              <span className="text-light">
                {
                  isGroupchat === "true"
                  ? 
                    typerFname && typerFname.length > 10
                    ? 
                    typerFname.substr(0, 10) + "..."
                    : 
                    typerFname
                  : 
                  "Typing"
                }
              </span>
              <span className="typingLoader">
                <ThreeDots visible={true} height="20" width="20" color="#ffffff" radius="9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass="" />
              </span>
            </div>
          }
        </>

        {/* Select Files or clip */}
        {!handleCheck() && <img src="/images/icons/clip.svg" className="me-3 me-lg-4 cursor-pointer" onClick={handleFileClick} style={{ width: '20px' }} alt="clip" />}

        {/* Select Files Options */}
        <>
          {
            (showFilesOption && selectedFiles.length === 0)
            &&
            <div className="position-absolute bg-white rounded-3 shadow-sm d-flex justify-content-evenly py-2 z-4" style={{ bottom: '47px', width: '96%', zIndex: "10" }} ref={popupShowHideRef}>
              {/* Camera */}
              <div className="d-flex flex-column align-items-center" onClick={handleCameraClick}>
                <img src="/images/icons/chat-camera-w.svg" className="bg-info p-2 rounded-circle mb-1" style={{ width: '40px' }} alt="gallary" />
                <small>Camera</small>

                <input type="file" accept="image/*,video/*" ref={cameraInputRef} onChange={handleCameraChange} style={{ display: 'none' }} />
              </div>

              {/* Gallary */}
              <div className="d-flex flex-column align-items-center" onClick={handleGalleryClick}>
                <img src="/images/icons/chat-gallary.svg" className="bg-success p-2 rounded-circle mb-1" style={{ width: '40px' }} alt="gallary" />
                <small>Gallery</small>

                <input
                  type="file"
                  accept="image/*, video/*"
                  ref={gallaryInputRef}
                  onChange={handleGallaryChange}
                  style={{ display: 'none' }}
                  multiple
                />
              </div>


              {/* Audio */}
              <div className="d-flex flex-column align-items-center" onClick={handleAudioClick}>
                <img src="/images/icons/chat-audio.svg" className="bg-danger p-2 rounded-circle mb-1" style={{ width: '40px' }} alt="audio" />
                <small>Audio</small>

                <input type="file" accept="audio/*" ref={audioInputRef} onChange={handleAudioChange} style={{ display: 'none' }} multiple />
              </div>

              {/* Document */}
              <div className="d-flex flex-column align-items-center" onClick={handleDocumentClick}>
                <img src="/images/icons/chat-document.svg" className="bg-warning p-2 rounded-circle mb-1" style={{ width: '40px' }} alt="document" />
                <small>Document</small>

                <input type="file" accept=".pdf, .zip" ref={documentInputRef} onChange={handleDocumentChange} style={{ display: 'none' }} multiple />
                {/* <input type="file" accept=".pdf, .zip, .xlsx, .xls, .ppt, .pptx, .doc, .docx" ref={documentInputRef} onChange={handleDocumentChange} style={{ display: 'none' }} multiple /> */}
              </div>
            </div>
          }
        </>

        {/* Show Selected Files */}
        <>
          {
            selectedFiles && selectedFiles.length > 0
            &&
            <div className='position-absolute bg-white rounded-3 px-1 py-2 shadow-sm d-flex align-items-start justify-content-start flex-wrap' style={{ bottom: '47px', width: '95%' }} ref={popupShowHideRef}>
              {
                selectedFiles.map((selectedFile, index) => (
                  <div key={index}>
                    {/* Image */}
                    <>
                      {
                        (selectedFile.msgType === 'img_file')
                        &&
                        <div className="position-relative d-inline rounded-1 me-2 mb-2" key={index} onClick={() => setMsgType("img_file")}>
                          <img src={URL.createObjectURL(selectedFile.file)} className="rounded-2 shadow-sm object-fit-contain bg-secondary-subtle" style={{ height: '60px', width: '60px' }} alt="selectedImage" />

                          <span className="position-absolute end-0 bg-secondary rounded-circle me-1 mt-1 text-center text-white cursor-pointer"
                            style={{ width: '13px', fontSize: '10px' }}
                            onClick={() => handleRemoveSelectedFile(index)}
                          >x</span>
                        </div>
                      }
                    </>

                    {/* Video */}
                    <>
                      {
                        (selectedFile.msgType === 'video_file')
                        &&
                        <div className="position-relative d-inline rounded-1 me-2 mb-2" key={index} onClick={() => setMsgType("video_file")}>
                          <video className="rounded-2 shadow-sm object-fit-contain bg-secondary-subtle" style={{ height: '60px', width: '60px' }}>
                            <source src={URL.createObjectURL(selectedFile.file)} type="video/mp4" />
                          </video>

                          <span className="position-absolute end-0 bg-secondary rounded-circle me-1 mt-1 text-center text-white cursor-pointer"
                            style={{ width: '13px', fontSize: '10px' }}
                            onClick={() => handleRemoveSelectedFile(index)}
                          >x</span>
                        </div>
                      }
                    </>

                    {/* Audio */}
                    <>
                      {
                        (selectedFile.msgType === 'audio_file')
                        &&
                        <div className="position-relative me-sm-3" key={index} onClick={() => setMsgType("audio_file")}>
                          {/* <audio className="" style={{ height: '60px' }} controlsList="nodownload" controls>
                            <source src={URL.createObjectURL(selectedFile.file)} type="audio/mpeg" />
                          </audio> */}

                          <span className="position-absolute end-0 bg-secondary rounded-circle me-1 mt-1 text-center text-white cursor-pointer"
                            style={{ width: '13px', fontSize: '10px' }}
                            onClick={() => handleRemoveSelectedFile(index)}
                          >x</span>
                          <MsgAudioPlayer isOnChat={false} fileName={selectedFile.file.name} audioSrc={URL.createObjectURL(selectedFile.file)} />

                        </div>
                      }
                    </>

                    {/* Document */}
                    <>
                      {
                        selectedFile.msgType === "pdf_file"
                        &&
                        <div className="position-relative me-3 mb-2 d-flex flex-column" key={index} onClick={() => setMsgType("pdf_file")}>
                          <img src={`/images/icons/chat-pdf.svg`} className="object-fit-contain" style={{ height: '60px', width: '60px' }} alt="selectedImage" />
                          <small className="text-truncate" style={{ width: '60px' }}>{selectedFile.name}</small>

                          <span className="position-absolute rounded-circle text-center start-0 ms-2 mt-1 bg-white fw-bold cursor-pointer"
                            style={{ width: '13px', fontSize: '9px' }}
                            onClick={() => handleRemoveSelectedFile(index)}
                          >x</span>
                        </div>
                      }
                    </>

                    {/* Zip */}
                    {/* <>
                      {
                        selectedFile.type.includes('zip')
                        &&
                        <div className="position-relative me-3 mb-2 d-flex flex-column" key={index}>
                          <img src={`/images/icons/chat-zip.svg`} className="object-fit-contain" style={{ height: '60px', width: '60px' }} alt="selectedImage" />
                          <small className="text-truncate" style={{ width: '60px' }}>{selectedFile.name}</small>

                          <span className="position-absolute rounded-circle text-center start-0 ms-2 mt-1 bg-white fw-bold cursor-pointer"
                            style={{ width: '13px', fontSize: '9px' }}
                            onClick={() => handleRemoveSelectedFile(index)}
                          >x</span>
                        </div>
                      }
                    </> */}
                  </div>
                ))
              }
            </div>
          }
        </>

        {/* Camera */}
        {!handleCheck() && <>
          {
            (sendMessage === '' && selectedFiles.length === 0)
            &&
            <>
              <img src={`${BASE_URL_STATIC}/icon/camera.svg`} className="me-3 me-lg-4 cursor-pointer" style={{ width: '25px' }} onClick={handleCameraClick} alt="camera" />

              <input type="file" accept="image/*, video/*" capture="environment" ref={cameraInputRef} onChange={handleCameraChange} style={{ display: 'none' }} />
            </>
          }
        </>}

        {/* Record & Send */}
        <>
          {
            (sendMessage !== '' || selectedFiles.length !== 0)
            &&
            <div className="border bg-primary rounded-circle d-flex justify-content-center align-items-center cursor-pointer" style={{ height: '37px', width: '37px' }} 
              onClick={handleChatSubmit}
            >
              {
                msgLoader 
                ? 
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden"></span>
                </div> 
                : 
                <img src={`${BASE_URL_STATIC}/icon/send.svg`} style={{ width: '17px' }} alt="send" />
              }
            </div>
          }
        </>

        {/* Record */}
        {/* <>
          {
            showRecord
            &&
            <div className="bg-white position-absolute shadow-sm rounded-3 p-1" style={{ bottom: '47px', width: '97%' }}>
              <h1>Record</h1>
            </div>
          }
        </> */}

        {/* Selected Message For Reply Area */}
        <>
          {
            replyContent
            &&
            <div className={`chat-reply ${replyContent.messageId ? "transition" : ""}`}>
              <div className="chat-reply-content">
                {/* {replyContent.message} */}

                {/* types of files */}
                <div className='reply-box-1'>
                  {/* text messsage */}
                  <>
                    {
                      (replyContent.type !== 'location' && replyContent.type !== 'contact') 
                      && 
                      <span className='fs-12 w-100 text-truncate'>{replyContent.message}</span>
                    }
                  </>

                  {/* img file */}
                  <>
                    {
                      replyContent.type === "img_file" 
                      &&
                      <img src={`${AwsFileUrl + replyContent.fileUrl}`} alt="" style={{ maxWidth: " 200px" }} />
                    }
                  </>

                  {/* video file */}
                  <>
                    {
                      replyContent.type === "video_file" 
                      &&
                      <img src={`${AwsFileUrl + replyContent.thumbnail}`} alt="" style={{ maxWidth: " 200px" }} />
                    }
                  </>

                  {/* audio file */}
                  <>
                    {
                      replyContent.type === "audio_file" 
                      &&
                      <img src={`${AwsFileUrl + 'images/icon/audio.svg'}`} alt="" style={{ maxWidth: " 200px" }} />
                    }
                  </>

                  {/* location */}
                  <> 
                    {
                      replyContent.type === "location" 
                      &&
                      <div className='d-flex flex-column' style={{ maxWidth: "200px" }}>
                        <img src={AwsFileUrl + 'images/location.png'} alt="" width='45px' />
                        {replyContent.type === 'location' && <span className='fs-12 mt-2'>{replyContent.message && replyContent.message.l_address}</span>}
                      </div>
                    }
                  </>

                  {/* PDF file */}
                  <>
                    { 
                      replyContent.type === "pdf_file" 
                      && 
                      <div className='d-flex justify-content-between align-items-center'>
                        <img src="/images/icons/chat-pdf.svg" alt="" width={50} />
                        <span className='fs-12'>{replyContent.fileName}</span>
                      </div>
                    }
                  </>

                  {/* contact */}
                  <>
                    {
                      replyContent.type === "contact" 
                      && 
                      <div className='msg_contact_relpy' >
                        <img src={AwsFileUrl + 'default/user.webp'} alt="" width='45px' />
                        <small>{replyContent.contactName}</small>
                      </div>
                    }
                  </>
                </div>
              </div>

              <span className="chat-reply-close"
                onClick={() => {
                  setSelectedPhotos(null);
                  setReplyContent("");
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </span>
            </div>
          }
        </>
      </div>
    </>
  );
};

export default ChatFooter;
