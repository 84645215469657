import React, { useContext, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { ScApiContext } from '../../useContext/softCornerContext';
import { BASE_URL_STATIC } from '../../../../config';
// import WorkLocation from '../../../Emilo/Popup/CreateNewPost/WorkLocation';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';


const AddSpecialDays = () => {

  const {
    specialDay, setSpecialDay,
    specialDays,
    showAddSpecialDaysPopup, setShowAddSpecialDaysPopup,
    createSpecialDay,
  } = useContext(ScApiContext);

  // --- Google Map Plateform ---

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAvczQH2_uIOGDXy2pRIpCUiDUnRd_OU60',
    libraries: ['places'],
  });

  const addAutocompleteRef = useRef(null);

  // Handle autocomplete load while adding
  const handleAddAutocompleteLoad = (autocomplete) => {
    addAutocompleteRef.current = autocomplete;
  };

  // Handle place changed while adding
  const handleAddPlaceChanged = () => {
    const place = addAutocompleteRef.current.getPlace();

    if (place && place.geometry) {
      setSpecialDay((prevState) => ({
        ...prevState,
        location: place.formatted_address,
        lat: place.geometry.location.lat(), 
        lng: place.geometry.location.lng(), 
      }));
    } else {
      alert('Please select a valid location from the suggestions.');
    }
  };


  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;

  //   if (type === "checkbox") {

  //     const newSetAlerts = checked
  //       ? [...specialDay.setAlerts, name]
  //       : specialDay.setAlerts.filter((alert) => alert !== name);

  //     setSpecialDay((prevformData) => ({
  //       ...prevformData,
  //       setAlerts: newSetAlerts,
  //     }));

  //   } else {
  //     setSpecialDay((prevformData) => ({
  //       ...prevformData,
  //       [name]: value,
  //     }));
  //   }
  // };


  // set location
  // const setLocation = (location) => {
  //   setSpecialDay((preState) => ({ ...preState, location: location }));
  // };

  // set place id
  // const setPlaceId = (placeId) => {
  //   setSpecialDay((preState) => ({ ...preState, placeId: placeId }));
  // };

  const categories = [
    "First Date",
    "Anniversary",
    "Proposal",
    "Wedding Day",
    "First Vacation Together",
    "Engagement Anniversary",
    "Moving In Together",
    "First Home Purchase"
  ];

  const setAlerts = [
    { label: "1 Hour", key: "one_hour" },
    { label: "1 Day", key: "one_day" },
    { label: "1 Week", key: "one_week" },
    { label: "1 Month", key: "one_month" },
  ];

  // handle set alerts checkboxes change
  const handleCheckboxChange = (key) => {
    setSpecialDay((prevState) => ({
      ...prevState,
      [key]: prevState[key] === 'true' ? 'false' : 'true',
    }));
  };

  // handle set alert custom
  const handleSetAlertCustom = () => {
    setSpecialDay((prevState) => ({
      ...prevState,
      setCustomAlert: prevState.setCustomAlert === 'true' ? 'false' : 'true',
    }));
  
    if (specialDay.setCustomAlert === 'true') {
      // Clear custom date and time when going back
      setSpecialDay((prevState) => ({
        ...prevState,
        customDate: '',
        customTime: '',
      }));
    } else {
      // Clear selected checkboxes when switching to custom alert
      setSpecialDay((prevState) => ({
        ...prevState,
        one_hour: 'false',
        one_day: 'false',
        one_week: 'false',
        one_month: 'false',
      }));
    }
  };


  const disabledButton = (
    specialDay.title === ''  || specialDay.location === '' || specialDay.lat === '' || specialDay.lng === '' || specialDay.note === '' 
    ||
    (
      specialDay.category === 'Other'
      ?
      specialDay.categoryOther === ''
      :
      specialDay.category === ''
    )
    ||
    ( 
      specialDay.setCustomAlert === 'true' 
      ? 
      (specialDay.customDate === '' || specialDay.customTime === '') 
      : 
      (specialDay.one_hour === 'false' && specialDay.one_day === 'false' && specialDay.one_week === 'false' && specialDay.one_month === 'false')
    )
  );

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      <Modal show={showAddSpecialDaysPopup} onHide={() => {setShowAddSpecialDaysPopup(false); setSpecialDay(specialDays[0])}} size='md' centered backdrop="static" keyboard={false} className="create-call">
      {/* <Modal show={true} onHide={() => setShowAddSpecialDaysPopup(false)} size='md' centered backdrop="static" keyboard={false} className="create-call"> */}
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          {
            specialDay
            &&
            <section className=''>
              <h5 className='mb-3'>Special Days</h5>

              <section className='border overflow-y-scroll p-1' style={{ height: '80vh' }}>
                {/* Title */}
                <>
                  <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-title.png`} className='me-1' style={{ height: '20px' }} alt="sc-title" />
                    <span className='text-white'>Title</span>
                  </div>

                  <input type="text" className='form-control rounded-pill' name='title' placeholder='Enter Title...' 
                    value={specialDay.title} onChange={(e) => setSpecialDay(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} 
                  /> <br />
                </>

                {/* Category */}
                <div className='mb-3'>
                  <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-category.png`} className='me-1' style={{ height: '20px' }} alt="sc-category" />
                    <span className='text-white'>Category</span>
                  </div>

                  <select className='form-control rounded-pill' id='category' name='category' required
                    value={specialDay.category} onChange={(e) => setSpecialDay(prevState => ({ ...prevState, [e.target.name]: e.target.value, categoryOther: '' }))} 
                  >
                    <option value="" disabled>Select Category...</option>

                    {
                      categories.map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                      ))
                    }

                    <option value="Other">Other</option>
                  </select>

                  {/* Other Category */}
                  <div>
                    {
                      specialDay.category === "Other" 
                      && 
                      <input type="text" className='form-control rounded-pill mt-3' name='categoryOther' placeholder='Enter Other Category...'
                        value={specialDay.categoryOther} onChange={(e) => setSpecialDay(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}

                      />
                    }
                  </div>
                </div>

                {/* Location */}
                <>
                  <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-location.png`} className='me-1' style={{ height: '20px' }} alt="sc-location" />
                    <span className='text-white'>Location</span>
                  </div>

                  <Autocomplete
                    className="mb-3"
                    onLoad={handleAddAutocompleteLoad}
                    onPlaceChanged={handleAddPlaceChanged}
                  >
                    <input type="search" className="form-control rounded-pill" placeholder="Search places here..." 
                      value={specialDay.location}
                      onChange={(e) => {
                        setSpecialDay((prev) => ({
                          ...prev,
                          location: e.target.value,
                          lat: '',
                          lng: '',
                        }))
                      }}
                    />
                  </Autocomplete>

                  {/* <div className={`${specialDay.location && 'mb-3'}`}>
                    {
                      specialDay._id === ''
                      ?
                      <WorkLocation location={specialDay.location} setLocation={setLocation} setPlaceId={setPlaceId} />
                      :
                      <input type="text" className='form-control rounded-pill' value={specialDay.location} disabled={true} />
                    }
                  </div> */}
                </>

                {/* Date & Time */}
                <div className='mb-3'>
                  <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-DateAndTime.png`} className='me-1' style={{ height: '20px' }} alt="sc-DateAndTime" />
                    <span className='text-white'>Date & Time</span>
                  </div>

                  <div className='d-flex gap-3 flex-column flex-sm-row'>
                    {/* Date */}
                    <input type="date" className='form-control rounded-pill' name='date' max={new Date().toISOString().split('T')[0]} disabled={specialDay._id !== ''}
                      value={specialDay.date} onChange={(e) => setSpecialDay((prevState) => ({ ...prevState, [e.target.name]: e.target.value, time: '', customTime: '', customDate: '' }))}
                    />

                    {/* Time */}
                    <input type="time" className='form-control rounded-pill' name='time' disabled={specialDay._id !== '' || specialDay.date === ''}
                      max={new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })} 
                      value={specialDay.time} onChange={(e) => setSpecialDay((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
                    />
                  </div>
                </div>

                {/* Set Alerts */}
                <div className='mb-3'>
                  <div className='d-flex align-items-center justify-content-between mb-2'>
                    <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                      <img src={`${BASE_URL_STATIC}/SoftCorner/sc-alert.png`} className='me-1' style={{ height: '20px' }} alt="sc-alert" />
                      <span className='text-white'>Set Alert</span>
                    </div>

                    <span className='d-inline-block px-3 py-1 rounded-pill mb-2 text-white cursor-pointer' style={{ backgroundColor: '#FF6969' }}
                      onClick={handleSetAlertCustom}
                    >{specialDay.setCustomAlert === 'true' ? 'Back' : 'Custom'}</span>
                  </div>

                  {/* Default/Custom */}
                  <>
                    {
                      specialDay.setCustomAlert === 'true'
                      ?
                      // Custom
                      <div className='d-flex gap-3 flex-column flex-sm-row'>
                        {/* Date */}
                        <input type="date" className='form-control rounded-pill' name='customDate' min={specialDay.date} disabled={specialDay.date === '' || specialDay.time === ''}
                          value={specialDay.customDate} onChange={(e) => setSpecialDay((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
                        />

                        {/* Time */}
                        <input type="time" className='form-control rounded-pill' name='customTime' disabled={specialDay.customDate === ''}
                          value={specialDay.customTime} onChange={(e) => setSpecialDay((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))}
                        />
                      </div>
                      :
                      // Default
                      <div className="ms-3">
                        {
                          setAlerts.map(({ label, key }, index) => (
                            <div className="form-check form-check-inline float-none" key={index}>
                              <input className="form-check-input mt-1" type="checkbox" id={key} checked={specialDay[key] === 'true'} onChange={() => handleCheckboxChange(key)} />
                              <label className="form-check-label fs-6" htmlFor={key}>{label}</label>
                            </div>
                          ))
                        }
                      </div>
                    }
                  </>
                </div>

                {/* Notes */}
                <div className='mb-3'>
                  <div className='d-inline-block px-3 py-1 rounded-pill mb-2' style={{ backgroundColor: '#FF6969' }}>
                    <img src={`${BASE_URL_STATIC}/SoftCorner/sc-notes.png`} className='me-1' style={{ height: '20px' }} alt="sc-notes" />
                    <span className='text-white'>Notes</span>
                  </div> 

                  <textarea className='form-control' id='note' name='note' rows={5} cols={40} placeholder='Write something here...'
                    value={specialDay.note} onChange={(e) => setSpecialDay(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}
                  />
                </div>

                {/* Add/Update Button */}
                <button type='button' className='btn text-white w-100 rounded-pill' style={{ backgroundColor: "#FF6969" }} onClick={createSpecialDay} disabled={disabledButton}
                >{specialDay._id === '' ? 'Add' : 'Update'}</button>
              </section>
            </section>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSpecialDays;