import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  // delete_everyone,
  delete_me_Message,
  deleted_sc_everyOne_Message,
  scmessageSeen,
  // editMessage_sc,
} from "../../Emilo/UseContext/ScApiHelpers";
import { useInView } from "react-intersection-observer";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { faBan, faCheck, faReply, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

const ScMessageCard = ({
  isEdit,
  setIsEdit,
  socket,
  SCUserId,
  setReplyMsg,
  setSendMessage,
  scConfig,
  index,
  chatId,
  arrivalMsg,
  setArrivalMsg,
  msg,
  addMessages,
  setDisplayPop,
  setClickedReplyIndex,
  setSelectedMessages,
  selectedMessages,
  togglePopover,
}) => {
  const [seen, setSeen] = useState(false);
  const [show, setShow] = useState(false);


  const {ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  const popoverRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleFocusOut);
    return () => {
      document.removeEventListener("mousedown", handleFocusOut);
    };
  }, []);

  const handleFocusOut = (event) => {
    if (!popoverRef.current || !popoverRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  
  useMemo(() => {
    if (msg.senderSCId !== SCUserId && inView && !seen) {
      scmessageSeen(
        msg.senderSCId,
        msg.chatId,
        msg.messageId || msg.replyMessageId,
        scConfig
      ).then((res) => {
        setSeen(true);
        console.log("data" , res)
        socket &&
          socket.emit("message_seen_sc", {
            senderUserId: msg.senderSCId,
            receiverUserId: msg.receiverSCId,
            chatId: msg.chatId,
            messageId: msg.messageId,
            isSeen: "1",
          });
      });
    }
  }, [inView, msg]);

  useEffect(() => {
    socket &&
      socket.on("message_viewed_sc", (data) => {
        console.log("datas" , data)
        if (data.chatId === chatId) {
          setArrivalMsg((list) =>
            list.map((msg) =>
              (msg.messageId || msg.replyMessageId) === data.messageId
                ? { ...msg, isSeen: data.isSeen, side: "left" }
                : msg
            )
          );
        }
      });
    socket &&
      socket.on("sc_message_deleted", (data) => {
        if (data.messageId) {
          setArrivalMsg((msgs) =>
            msgs.map((msg) => {
              if (
                msg.messageId === data.messageId ||
                msg.replyMessageId === data.messageId
              ) {
                return {
                  ...msg,
                  message: "This message was deleted",
                  isEdited: "2",
                };
              }
              return msg;
            })
          );
        }
      });

    socket &&
      socket.on("sc_message_edit", (data) => {
        if (data.messageId) {
          setArrivalMsg((msgs) =>
            msgs.map((msg) => {
              if (
                msg.messageId === data.messageId ||
                msg.replyMessageId === data.messageId
              ) {
                return { ...msg, message: data.newMessage, isEdited: "1" };
              }
              return msg;
            })
          );
        }
      });
  }, [chatId, socket]);

  function formatTime(timeString) {
    const time = new Date(timeString);
    const hours = time.getHours();
    const minutes = time.getMinutes();

    const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

    return formattedTime;
  }

  const handleDelete = (messageId, receiverSCId, msg) => {
    const data = { messageId, receiverSCId };
    socket && socket.emit("sc_message_dlt_everyone_req", data);

    deleted_sc_everyOne_Message(messageId, scConfig)
      .then(() => {
        setSelectedMessages([]);
        setDisplayPop(true);

        // const newmessage = {...msg , message : "This message was deleted"}

        setArrivalMsg((msgs) =>
          msgs.map((msg) => {
            if (
              msg.messageId === messageId ||
              msg.replyMessageId === messageId
            ) {
              return {
                ...msg,
                message: "You deleted this message",
                isEdited: "2",
              };
            }
            return msg;
          })
        );
      })
      .catch((error) => {
        console.error(`Error deleting message ${messageId}:`, error);
      });
  };

  // const handleEdit = (messageId, receiverSCId) => {
  //   const data = { messageId, receiverSCId };
  //   socket && socket.emit("sc_message_edit_everyone_req", data);

  //   editMessage_sc(messageId, scConfig)
  //     .then(() => {
  //       setSelectedMessages([]);
  //       setDisplayPop(true);

  //       setArrivalMsg((msgs) =>
  //         msgs.map((msg) => {
  //           if (
  //             msg.messageId === messageId ||
  //             msg.replyMessageId === messageId
  //           ) {
  //             return { ...msg, message: msg.message, isEdited: "2" };
  //           }
  //           return msg;
  //         })
  //       );
  //     })
  //     .catch((error) => {
  //       console.error(`Error deleting message ${messageId}:`, error);
  //     });
  // };

  const handleMeDelete = (messageId) => {
    delete_me_Message(messageId, scConfig).then(() => {
      setArrivalMsg((prev) =>
        prev.filter(
          (msg) => (msg.messageId || msg.replyMessageId) !== messageId
        )
      );
    });
  };

  const elementsRef = useRef({});
  console.log("elementsRef", elementsRef)

  // Assuming you populate this ref with your message elements

  const handleScrollToElement = (msg) => {

    const highlightedElement = document.getElementById(msg.replyMessageId);

    if (highlightedElement) {

      highlightedElement.classList.add("highlight");

      highlightedElement.scrollIntoView({ behavior: "smooth", block: "center" });

      setTimeout(() => {
        highlightedElement.classList.remove("highlight");
      }, 4000);
    }
  };

  const popover = (msg, index) => {
    if (msg.isEdited === "2") {
      return <></>;
    }
    return (
      <Popover id="popover-basic">
        <Popover.Body>
          <ul className="sc_popover_li">
            <li
              onClick={() => {
                setReplyMsg(msg);
                togglePopover(index);
                setClickedReplyIndex(null);
                setDisplayPop(false);
              }}
            >
              <FontAwesomeIcon icon={faReply} /> &nbsp; Reply
            </li>
            <li
              onClick={() => {
                addMessages(msg);
                setDisplayPop(false);
              }}
            >
              <FontAwesomeIcon icon={faCheck} /> &nbsp; Select
            </li>
            {msg.senderSCId === SCUserId && (
              <li
                onClick={() => {
                  setIsEdit(true);
                  setReplyMsg(msg);
                  setSendMessage(msg.message);
                  setDisplayPop(false);
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} /> &nbsp; Edit
              </li>
            )}
            <li
              onClick={() => {
                setDisplayPop(false);
                handleMeDelete(msg.messageId || msg.replyMessageId);
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> &nbsp; Delete for me
            </li>
            {msg.senderSCId === SCUserId && (
              <li
                className="option-del"
                onClick={() => {
                  handleDelete(
                    msg.replyMessageId || msg.messageId,
                    msg.receiverSCId,
                    msg
                  );
                  setDisplayPop(false);
                }}
              >
                <FontAwesomeIcon icon={faTrash} /> &nbsp; Delete for everyone
              </li>
            )}
          </ul>
        </Popover.Body>
      </Popover>
    );
  };

  return (

    <>

      <div
        key={index}
        style={{ position: "relative" }}
        ref={ref}
        className={`scChatBoxMsg chatBox ${SCUserId === msg.senderSCId ? "right" : "m-2"
          }  ${selectedMessages.includes(msg.messageId) ? "sc-selected" : ""}`}
      >
        {msg.message && (
          <OverlayTrigger
            trigger="click"
            show={show}
            placement={msg.side === "right" ? "right" : "left"}
            overlay={popover(msg, index)}
            rootClose
          >

            <span
              // Set ID for scrolling

              style={{ cursor: "pointer", fontSize: "13px" }}
              className={`px-3 py-2 ${msg.senderSCId === SCUserId
                ? "bg-danger-subtle SC-right-Chat-L"
                : "bg-secondary-subtle  SC-right-Chat-R"
                }`}
              onClick={() => {
                selectedMessages.length >= 1 && addMessages(msg);
                setShow(!show);

              }}
            >
              {/* current msg */}

              {msg.replyMessageId && (
                <p className="replyMessageBox"
                  onClick={() => handleScrollToElement(msg)}
                >
                  {msg.replyMessage && msg.replyMessage.length > 50
                    ? `${msg.replyMessage.substr(0, 50)}...`
                    : msg.replyMessage}
                </p>
              )}

              {msg.isEdited !== "2" ?
                <span className="i" id={msg.messageId}  >
                  {msg.message}
                </span>
                :
                <div>
                  {msg && (msg.type !== "location" && msg.type !== "contact") && <span className='fs-12 fst-italic'><FontAwesomeIcon icon={faBan} /> {msg.message}</span>}
                </div>
              }


              {msg.isEdited === "1" && <sup className="fst-italic"> &nbsp; Edited</sup>}

              <div className="deliver-time text-end">
                <time>{msg.isEdited !== "2" && formatTime(msg.createdTime)}</time>

                {msg.isEdited !== "2" && msg.senderSCId === SCUserId && (
                  <img
                    className="ms-1"
                    src={`${msg.isSeen === "1"
                      ? "/images/double-check.svg"
                      : "/images/double-gray.svg"
                      }`}
                    alt=""
                  />
                )}
              </div>
            </span>

          </OverlayTrigger>
        )}
      </div>
    </>
  );
};

export default ScMessageCard;
