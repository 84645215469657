import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GallaryPopup from "./GallaryPopup";
import { AwsFileUrl, BASE_URL_STATIC } from "../../../config";
import { ApiContext } from "../../Emilo/UseContext/ApiCall";

const Gallary = () => {
  const [todayPost, setTodayPosts] = useState([]);
  const [yesterdayPost, setYesterdayPosts] = useState([]);
  const [olderPost, setOlderPosts] = useState([]);
  const [openPopup, setOpenPopup] = useState(false)
  const [data, setData] = useState(null)

  const { scAllposts } = useContext(ApiContext)



  console.log("scAllpost" , scAllposts)

  useEffect(() => {
    if (scAllposts) {
      const currentDate = new Date();
      const today = scAllposts.filter((post) => {
        const postDate = new Date(post.createdAt);
        return postDate.toDateString() === currentDate.toDateString();
      });
      setTodayPosts(today);

      const yesterday = scAllposts.filter((post) => {
        const postDate = new Date(post.createdAt);
        const yesterdayDate = new Date(currentDate - 86400000);
        return postDate.toDateString() === yesterdayDate.toDateString();
      });
      setYesterdayPosts(yesterday);

      const older = scAllposts.filter((post) => {
        const postDate = new Date(post.createdAt);
        return (
          postDate < currentDate &&
          postDate.toDateString() !== currentDate.toDateString()
        );
      });
      setOlderPosts(older);
    }
  }, [scAllposts]);

  console.log("todys", todayPost)

  return (
    <>
      {data && <GallaryPopup open={openPopup} data={data} close={() => setOpenPopup(false)} />}

      <section className="sc-gallary">
        <section className="sc-gallery-right">
          {/* Navbar */}
          <div className="sc-gallary-nav">
            <Link to={`/SoftCorner/Gallary`} className="sc-gallary-nav-div">
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-photos.png`} alt="sc-gallary-photos" height={30} width={30} />
              <span>Photos</span>
            </Link>

            <Link to={`/SoftCorner/Gallary-Album`} className="sc-gallary-nav-div">
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-album.png`} alt="sc-gallary-album" height={30} width={30} />
              <span>Album</span>
            </Link>

            <Link to={`/SoftCorner/Gallary-Explore`} className="sc-gallary-nav-div" >
              <img src={`${BASE_URL_STATIC}/SoftCorner/sc-gallary-explore.png`} alt="sc-gallary-explore" height={30} width={30} />
              <span>Explore</span>
            </Link>
          </div>

          <section>
            
                <>
                  {/* Today */}
                  {todayPost && todayPost.length > 0 && (
                    <div className="sc-gallary-older">
                      <h6 className="trending_head mb-4">Today</h6>

                      <div className="older-posts">
                        {todayPost.map((post, index) => (
                          <span key={index} className="older-posts-span" onClick={() => { setOpenPopup(true); setData(post) }}>
                            {post.mediaUrls && post.mediaUrls[0].filetype === 'image' ? (
                              <img src={AwsFileUrl + post.mediaUrls[0].url} alt="" />
                            )
                              :
                              <img src={AwsFileUrl + post.mediaUrls[0].thumbnail} alt="" />
                            }
                          </span>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Yesterday */}
                  {yesterdayPost && yesterdayPost.length > 0 && (
                    <div className="sc-gallary-older" >
                      <h6 className="trending_head mb-4">Yesterday</h6>
                      <div className="older-posts">
                        {yesterdayPost.map((post, index) => (
                          <span key={index} className="older-posts-span" onClick={() => { setOpenPopup(true); setData(post) }}>
                            {post.mediaUrls && post.mediaUrls[0].filetype === 'image' ? (
                              <img src={AwsFileUrl + post.mediaUrls[0].url} alt="" />
                            )
                              :
                              <img src={AwsFileUrl + post.mediaUrls[0].thumbnail} alt="" />
                            }
                          </span>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Older */}
                  {olderPost && olderPost.length > 0 && (
                    <div className="sc-gallary-older" >
                      <h6 className="trending_head mb-4">Older</h6>
                      <div className="older-posts">
                        {olderPost.map((post, index) => (
                          <span key={index} className="older-posts-span" onClick={() => { setOpenPopup(true); setData(post) }}>
                            {post.mediaUrls && post.mediaUrls[0].filetype === 'image' ? (
                              <img src={AwsFileUrl + post.mediaUrls[0].url} alt="" />
                            )
                              :
                              <img src={AwsFileUrl + post.mediaUrls[0].thumbnail} alt="" />
                            }
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </>
            
          </section>
        </section>
      </section>
    </>
  );
};

export default Gallary;
