import React, { useContext, useMemo, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { change_sc_pin, check_sc_pin, loginSoftCorner } from '../../Emilo/UseContext/ScApiHelpers';
import { ApiContext } from '../../Emilo/UseContext/ApiCall';
import { AwsFileUrl, BASE_URL_STATIC, BASEAPIURL } from '../../../config';
import toast from 'react-hot-toast';
import { faArrowRight, faCircleNotch, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getProfileUser } from '../../Emilo/UseContext/ApiHelpers';
import Countdown from 'react-countdown-now';
import axios from 'axios';

const CheckSoftCornerPin = () => {

  const [checkSoftCornerPin, setCheckSoftCornerPin] = useState('');
  const [isOtp, setIsOtp] = useState(false)
  const [confirmPin, setconfirmPin] = useState('')
  const [isForget, setIsForget] = useState(true);
  const [isPass, setIsPass] = useState(false)
  const [isNewPin, setIsNewPin] = useState(false)
  const [passShow, setPassShow] = useState()
  const [btnLoader, setBtnLoader] = useState(false)
  const [userData, setUserData] = useState(null)
  const [passInput, setPassInput] = useState('')
  const [OTP, setOTP] = useState("");
  const [newPin, setnewPin] = useState("");
  const [resendTimer, setResendTimer] = useState(11);
  const [countdownKey, setCountdownKey] = useState(Date.now());

  const navigate = useNavigate();
  const { setSwipeHeader, loggedInUserId, config } = useContext(ApiContext)

  useMemo(() => {
    getProfileUser(loggedInUserId, config).then((res) => {
      setUserData(res.data.user);
    })
  }, [loggedInUserId])

  const verifyOtp = async () => {

    try {
      await axios.post(`${BASEAPIURL}/user/otp-verify`, { mobile: userData.mobile, otp: OTP }).then((res) => {
        toast.success(res.data.message)
        handleChange("isNewPin")
      })
    } catch (error) {
      toast.error("Failed to verify OTP")
    }
  };

  const handlePinChange = async () => {
    setBtnLoader(true)
    if (newPin !== confirmPin) {
      setBtnLoader(false)
      return toast.error("Pin didn't matched")
    }
    change_sc_pin(newPin, config).then((res) => {
      if (res.status === 1) {
        toast.success(res.message)
        setBtnLoader(false)
        handleChange('isForget')
      }
      else if (res.status === 0) {
        setBtnLoader(false)
        toast.error(res.message)
      }
    }).catch(() => {
      setBtnLoader(false)
      toast.error("Failed to change Pin")
    })
  }

  const reSendOtp = async () => {
    try {
      await axios.post(`${BASEAPIURL}/user/otp-send`, { mobile: userData.mobile }).then((res) => {
        setCountdownKey(Date.now());
        toast.success(res.data.message)
        toast.success(res.data.otp)
        setResendTimer(11)
      })
    } catch (error) {
      toast.error("Failed to send otp")
    }
  };

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      return <span className='cp' onClick={() => reSendOtp()}>Resend OTP</span>;
    } else {
      return <span className='op-8'> {`Resend OTP in 00 : ${seconds < 10 ? `0${seconds}` : seconds}`}</span>;
    }
  };

  const handleChange = (value) => {
    setIsForget(value === 'isForget');
    setIsPass(value === 'isPass');
    setIsOtp(value === 'isOtp');
    setIsNewPin(value === 'isNewPin');
  };

  const fg_pin = () => {
    setBtnLoader(true)
    if (passInput.length > 0) {
      check_sc_pin(passInput, config).then((res) => {
        if (res.status === 0) {
          setBtnLoader(false)
          toast.error("Password didn't matched")
        }
        else if (res.status === 1) {
          setBtnLoader(false)
          toast.success(res.otp)
          handleChange("isOtp")
        }
      }).catch((err) => {
        setBtnLoader(false)
        toast.error("Password didn't matched")
      })
    }
    else {
      setBtnLoader(false)
      toast.error("Please enter password")
    }
  }

  // To handle check soft corner pin
  const handleCheckSoftCornerPin = (checkSoftCornerPin) => {
    loginSoftCorner(checkSoftCornerPin, config).then(res => {
      localStorage.setItem("SCtoken", res.data.token);
      localStorage.setItem("SCuserId", res.data.scUserId);
      if (res.status === 201) {
        navigate('/SoftCorner');
        setSwipeHeader(true);
        setCheckSoftCornerPin('');
      };
    })
      .catch(err => {
        if (err.response.data.message === 'wrong password') {
          toast.error('Wrong Password')
        };
      });
  };

  const handleForget = () => {
    switch (true) {
      //-------------------- 1st phase (user enters SC pin)-----------------------------
      case isForget:
        return (
          <>
            <div className="checkSoftCornerPin-area-data w-100">
              <h2 className="checkSoftCornerPin-area-data-head">Enter Soft Corner Pin</h2>
              <p className="checkSoftCornerPin-area-data-message mb-3">Enter 4-digit security PIN</p>
              <OtpInput
              inputType='password'
                value={checkSoftCornerPin}
                onChange={setCheckSoftCornerPin}
                numInputs={4}
                renderSeparator={<pre> </pre>}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="checkSoftCornerPin-area-data-pin mt-3"
                    onKeyDown={(e) =>
                      e.key === "Enter" && handleCheckSoftCornerPin(checkSoftCornerPin)
                    }
                  />
                )}
              />
            </div>

            <p
              className="text-center text-primary my-3 cp"
              onClick={() => handleChange("isPass")}
            >
              Forgot Pin
            </p>

            <button
              className="checkSoftCornerPin-area-btn"
              type="button"
              onClick={() => handleCheckSoftCornerPin(checkSoftCornerPin)}
            >
              Submit
            </button>
          </>
        );

      //-------------------- 2nd phase (user enters Emilo password)-----------------------------
      case isPass:
        return (
          <div className="checkSoftCornerPin-area-data w-100">
            <h2 className="checkSoftCornerPin-area-data-head">Enter Emilo Password</h2>

            {userData && (
              <div className="col-12 col-lg-5">
                <div className="onboardingUser-area-data-user">
                  <img
                    src={AwsFileUrl + userData.imageId}
                    alt="user-icon3"
                    height={50}
                    width={50}
                  />
                  <div className="onboardingUser-area-data-userName">
                    <h5 className="fw-bold ">{userData.fname}</h5>
                    <p className="fs-12 op-8">{userData.username}</p>
                  </div>
                </div>
              </div>
            )}

            <div className="form-group position-relative d-flex col-12 col-lg-5 fg_input">
              <input
                type={passShow ? "text" : "password"}
                onChange={(e) => setPassInput(e.target.value)}
                className="form-control"
                placeholder="Enter emilo password"
              />

              <small
                className="fp_eye cp"
                onClick={() => setPassShow(!passShow)}
              >
                {passShow ? (
                  <FontAwesomeIcon icon={faEye} />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash} />
                )}
              </small>

              <button
                type="button"
                className="btn btn-success"
                onClick={() => fg_pin()}
              >
                {btnLoader ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  <FontAwesomeIcon icon={faArrowRight} />
                )}
              </button>
            </div>

            <p
              className="text-danger col-12 col-lg-5 fs-14 text-start text-underline cp mt-2"
              onClick={() => handleChange("isForget")}
            >
              cancel
            </p>
          </div>
        );

      //-------------------- 3rd phase (user enters OTP sent to mobile)-----------------------------
      case isOtp:
        return (
          <section className="col-12 col-lg-6 p-0">
            <h4 className="text-center">Verify OTP</h4>
            <p className="text-center text-secondary">
              Enter One Time Password Sent to {" "}
              {userData && (
                <strong>
                  {(() => {
                    const mobile = userData.mobile.toString();
                    const withoutCountryCode = mobile.startsWith("91")
                      ? mobile.slice(2)
                      : mobile;
                    const visibleStart = withoutCountryCode.substr(0, 2);
                    const visibleEnd = withoutCountryCode.slice(-2);
                    const maskedLength = withoutCountryCode.length - 4;
                    const masked = "x".repeat(maskedLength);
                    return `${visibleStart}${masked}${visibleEnd}`;
                  })()}
                </strong>
              )}
            </p>

            <div className="my-3 d-flex justify-content-center">
              <OtpInput
                value={OTP}
                inputType='password'
                onChange={setOTP}
                numInputs={4}
                renderSeparator={<pre> </pre>}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="onboardingOTP-area-data-inputOTP"
                    style={{ width: "70px" }}
                  />
                )}
              />
            </div>

            <button
              type="button"
              className="btn btn-primary rounded-pill w-50 mx-auto d-block fs-5 py-1 py-md-2"
              onClick={verifyOtp}
            >
              Next
            </button>

            <p className="text-center mt-3">
              Didn’t get the OTP? <b className="text-primary">
                <Countdown
                  key={countdownKey}
                  date={Date.now() + resendTimer * 1000}
                  renderer={renderer}
                />
              </b>
            </p>
          </section>
        );

      //-------------------- 4th phase (user enters new SC pin)-----------------------------
      case isNewPin:
        return (
          <>
            <div className="checkSoftCornerPin-area-data w-100">
              <h2 className="checkSoftCornerPin-area-data-head">Enter New Soft Corner Pin</h2>
              <p className="checkSoftCornerPin-area-data-message mt-3">Enter 4-digit security PIN</p>
              <OtpInput
                value={newPin}
                inputType='password'
                onChange={setnewPin}
                numInputs={4}
                renderSeparator={<pre> </pre>}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="checkSoftCornerPin-area-data-pin mt-3 text-black"
                    />
                )}
                />
              <p className="checkSoftCornerPin-area-data-message mt-3">Re-enter 4-digit security PIN</p>
              <div className="my-3 d-flex justify-content-center">
                <OtpInput
                  value={confirmPin}
                  inputType='password'
                  onChange={setconfirmPin}
                  numInputs={4}
                  renderSeparator={<pre> </pre>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className="onboardingOTP-area-data-inputOTP"
                      style={{ width: "70px" }}
                    />
                  )}
                />
              </div>
            </div>

            <button
              className="checkSoftCornerPin-area-btn mt-3"
              type="button"
              onClick={() => handlePinChange(newPin)}
            >
              Next
            </button>
          </>
        );

      default:
        return null;
    }
  };


  return (
    <>
      <section className='onboardingPin'>
        <div className='onboardingPin-area'>
          {/* Emilo Logo */}
          <div className='checkSoftCornerPin-area-emilo-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/emilo-logo.png`} alt="emilo-logo" height={60} width={160} />
          </div>

          {/* Soft Corner Logo */}
          <div className='checkSoftCornerPin-area-softcorner-logo'>
            <img src={`${BASE_URL_STATIC}/SoftCorner/softcorner-logo.png`} alt="softcorner-logo" height={57} width={65} />
            <h3>Soft Corner</h3>
          </div>

          {handleForget()}

        </div>
      </section>
    </>
  )
}


export default CheckSoftCornerPin

