import React, { useState, useEffect, createContext, useMemo } from "react";
import axios from "axios";
import { ApiVersionCode, BASEAPIURL, BASEURL, headerUserAPI } from "../../../config";
import { io } from "socket.io-client";
import toast from "react-hot-toast";
import { askForPermissionToReceiveNotifications } from "../../../firebaseConfig";
import { generateThumbnail } from "../Chats/Chat/generateThumbnail ";
import { createNotification, getRelation } from "./ApiHelpers";


export const ApiContext = createContext();

// firebase msg   
askForPermissionToReceiveNotifications();


const ApiCall = ({ children }) => {

  const [loggedInUser, setLoggedInUser] = useState();
  const [loggedInUserId, setLoggedInUserId] = useState();
  const [loggedInUserName, setLoggedInUserName] = useState();
  const [loggedInUserImage, setLoggedInUserImage] = useState();
  const [loggedInUserGender, setloggedInUserGender] = useState()
  const [lastMsg, setLastMsg] = useState(null)
  const [checkinType, setCheckinType] = useState("check-in")
  const [gifType, setGifType] = useState("gif")
  const [username, setusername] = useState('')
  const [allDataPost, setallDataPost] = useState([]);
  const [scImage, setScImage] = useState(null)

  const [loggedInUserFollower, setLoggedInUserFollower] = useState();
  const [loggedInUserFollowing, setLoggedInUserFollowing] = useState();

  const [loggedInUserPost, setLoggedInUserPost] = useState();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [otpEmail, setOtpEmail] = useState(null);

  const [notification, setnotification] = useState([])
  const [reqStatus, setreqStatus] = useState(null);

  const [isGroupchat, setIsgroupChat] = useState("false")
  const [chatUsers, setChatUsers] = useState([]);
  const [pinnedUsers, setPinnedUser] = useState([])
  const [hideUsers, setHideUsers] = useState([])

  const [selectedAddLocation, setSelectedAddLocation] = useState('')
  const [selectedData, setSelectedData] = useState([]);  // to store selected image


  const [isnewGroupAdded, setisnewGroupAdded] = useState(false);

  const [sg_isMobileVerified, set_sg_isMobileVerified] = useState(false)
  const [sg_isEmailVerified, set_sg_isEmailVerified] = useState(false)

  const [chatProfile, setChatProfile] = useState(null);
  const [switchAbout, setSwitchAbout] = useState(false)
  const [scAllposts, setscAllposts] = useState([]);


  const [PageContent, setPageContent] = useState("")

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    middleName: "",
    profileImage: "",
    lastName: "",
    phoneNumber: "",
    countryCode: "",
    email: "",
    password: "",
    username: "",
    gender: "",
    dob: ""
  })

  const [iamAmdmin, setiamAdmin] = useState('false')
  const [postType, setPostType] = useState('')
  const [posts, setPosts] = useState([])

  const [searchList, setSearchList] = useState([])


  const [chatData, setChatData] = useState([])
  const [rightReload, setRightReload] = useState(false)
  const [allPosts, setAllPosts] = useState([])
  const [allMemes, setAllMemes] = useState([])
  const [allReels, setAllReels] = useState([])
  const [allBestClips, setAllBestClips] = useState([])

  const [centerBlockPage, setCenterBlockPage] = useState(1);
  const [centerBlockData] = useState([]);


  const [showHeader, setShowHeader] = useState(true)

  const [profileUser, setProfileUser] = useState();
  const [profileUserPostNumber, setProfileUserPostNumber] = useState();
  const [profilePost, setProfilePost] = useState([]);
  const [profileLoader, setProfileLoader] = useState(false)
  const [followerList, setFollowerList] = useState();
  const [followingList, setFollowingList] = useState();

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [profileBestClips, setProfileBestClips] = useState([]);

  const [users, setUsers] = useState([]);
  const [otherUserId, setOtherUserId] = useState();
  const [loader, setLoader] = useState();
  const [postCount, setPostCount] = useState();
  const [swipeHeader, setSwipeHeader] = useState(false)
  const [headerChange, setHeaderChange] = useState(false)
  const [registeredImage, setRegisteredImage] = useState(null);

  const [isFavouriteUser, setIsFavouriteUser] = useState(false);
  const [isUserSnooze, setIsUserSnooze] = useState(false);

  const [favouritePeoples, setFavouritePeoples] = useState();

  const [isInSoftCorner, setIsInSoftCorner] = useState(false);

  const [selectDevice, setSelectDevice] = useState("");
  const [admincanMsg, setAdmincanMsg] = useState('false')
  const [suggestedFlixIndex, setSuggestedFlixIndex] = useState(null)
  const [trendingfeed, setTrendingfeed] = useState(null)
  const [partnerId, setPartnerId] = useState("")

  const [Unhide, setunHide] = useState([])

  const fbToken = localStorage.getItem('fbToken');  

  // Config
  const config = { headers: headerUserAPI({ versioncode: ApiVersionCode }) };
  // {
  //   Authorization: `Bearer ${localStorage.getItem("token")}`,
  //   device: localStorage.getItem('deviceDetail') ? localStorage.getItem('deviceDetail') : 'unknown',
  //   userip: localStorage.getItem('deviceIp') ? localStorage.getItem('deviceIp') : 0,
  //   devicetype: localStorage.getItem('deviceOs') ? localStorage.getItem('deviceOs') : 'unknown',
  //   versioncode: ApiVersionCode,
  //   locuserlong: localStorage.getItem('LocUserLong') ? localStorage.getItem('LocUserLong') : 0,
  //   locuserlat: localStorage.getItem('LocUserLat') ? localStorage.getItem('LocUserLat') : 0,
  //   locusermsg: localStorage.getItem('LocUserMsg') ? localStorage.getItem('LocUserMsg') : 'NA',
  //   locusercity: localStorage.getItem('localityCity') ? localStorage.getItem('localityCity') : 'unknown',
  // },

  // Read More
  const ReadMore = ({ children }) => {
    // Ensure text is always a string, even if children is undefined or invalid
    const text = React.isValidElement(children) && typeof children.props.children === 'string'
      ? children.props.children
      : (children || '').toString();

    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    return (
      <p>
        {isReadMore ? text.slice(0, 100) : text}
        {text.length > 100 && (
          <span onClick={toggleReadMore} className="read-more" style={{ cursor: 'pointer', color: 'blue' }}>
            {isReadMore ? '... More' : '... Less'}
          </span>
        )}
      </p>
    );
  };


  // Read More
  // const ReadMore = ({ children }) => {
  //   const text = children || ''; // Ensure that 'children' is not undefined
  //   const [isReadMore, setIsReadMore] = useState(true);

  //   const toggleReadMore = () => {
  //     setIsReadMore(!isReadMore);
  //   };

  //   return (
  //     <p>
  //       {isReadMore ? text.slice(0, 100) : text}
  //       {text.length > 100 && ( // Add a condition to render 'Read More' only if text is longer than 100 characters
  //         <span onClick={toggleReadMore} className="read-more">
  //           {isReadMore ? '... More' : '... Less'}
  //         </span>
  //       )}
  //     </p>
  //   );
  // };





  // ***** Notifications *****

  const [notificationPage, setNotificationPage] = useState(1);
  const [oldNotifications, setOldNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const [showNewNotificationDot, setShowNewNotificationDot] = useState(false);

  const [isNotificationStopped, setIsNotificationStopped] = useState(false);
    const [showClosefriendPopup, setShowCloseFriendPopup] = useState(false)



  const [inputValue, setinputValue] = useState('')


  if (showNewNotificationDot) {
    localStorage.setItem('showNewNotificationDot', 'true');
  };

  useEffect(() => {
    if (localStorage.getItem('showNewNotificationDot')) {
      setShowNewNotificationDot(true);
    } else {
      setShowNewNotificationDot(false);
    };
  }, []);


  //*****Boost Ads *****










  // ***** Comments *****

  const [comments, setComments] = useState([]);
  const [parentSpinner, setParentSpinner] = useState(false);
  const [createComment, setCreateComment] = useState('');
  const [openEmoji, setOpenEmoji] = useState(false);


  //----------------------------Skeleton Loaders State------------------------------------------
  const [RightSectionLoader, setRightSectionLoader] = useState(false);
  const [ProfileImageLoader, setProfileImageLoader] = useState(false);
  const [LeftBlockLoader, setLeftBlockLoader] = useState(false);
  const [AllUsersLoader, setAllUsersLoader] = useState(false);
  const [StoryUrlLoader, setStoryUrlLoader] = useState(false);
  const [FollowStatusLoader, setFollowStatusLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false)
  const [isUpdate, setisUpdate] = useState(false)

  const token = localStorage.getItem('token');
  const profileUserId = localStorage.getItem("profileUserId");

  //  const loggedInUserId =  localStorage.setItem("loggedInUserId", loggedInUserId);

  // const userId = localStorage.getItem("profileUserId")




  // ********************   Emilo Chat   ********************

  const [chatId, setChatId] = useState();
  const [allChatUsers, setAllChatUsers] = useState([])

  const [chatUserData, setChatUserData] = useState();
  const [onlineStatus, setOnlineStatus] = useState(null)

  const [socket, setSocket] = useState();

  // console.log('socket : ', socket);

  const socketFun = () => {
    const sock = io.connect(BASEAPIURL);

    setSocket(sock);
  };

  // To create emilo chat connection
  const createEmiloChatConnection = async (userId, isGroupChat, userData) => {
    await axios.post(`${BASEAPIURL}/user/createemchatconnection`, { userId, isGroupChat }, config)
      .then(res => {
        setChatId(res.data.chatId);
        setChatUserData(userData);
      })
      .catch(err => {
        return null;
      });
  };

  // create emilo message
  const createEmiloMessage = async (sendMessage, chatId, file, type, isForwarded, fileName) => {
    let upload_msg_file = null;
    let thumbnailFile = null;
    if (typeof file === 'object') {
      // Generate thumbnail if the file is a video
      if (file.type.startsWith('video/')) {
        try {
          thumbnailFile = await generateThumbnail(file);
        } catch (error) {
          console.error('Error generating thumbnail:', error);
        }
      }
      // Upload file if it is not a thumbnail
      const formData = new FormData();
      formData.append('image', file);
      formData.append('thumbnail', thumbnailFile)
      formData.append('fileType', type)
      try {
        upload_msg_file = await axios.post(`${BASEAPIURL}/user/upload_msg_file`, formData, config);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    const data = {
      chatId: chatId,
      message: type === 'location' ? JSON.stringify(sendMessage) : sendMessage,
      fileName: typeof file === 'object' ? file.name : fileName,
      type: type,
      fileUrl: typeof file === 'object' ? upload_msg_file && upload_msg_file.data.getUrl : file,
      isForwarded: isForwarded,
      thumbnail: thumbnailFile ? upload_msg_file.data.get_thumbnail_url : null
    };

    try {
      const res = await axios.post(`${BASEAPIURL}/user/createemmessage`, data, config);
      return res.data;
    } catch (error) {
      console.error('Error creating message:', error);
    }
  };


  // To get message
  // const getEmiloMessage = async (chatId, config) => {
  //  const res =  await axios.post(`${BASEAPIURL}/user/getchatemmessage`, { chatId: chatId }, config)
  //  return res.data.message
  // };


  // To handle update user profile data like (email, fname, mname, lname, mobile, bio, dob, gender, maritial, zodiac, hobbies, favouriteMovie, favouritePlace, favouriteFood)
  const updateUserProfile = (gender, dob, mobile) => {
    axios.post(`${BASEAPIURL}/user/updateuserprofile`, { gender: gender, dob: dob, mobile: mobile }, config)
      .then(res => {
        toast.success("Welcome To Emilo");
        window.location.href = BASEURL;
      })
      .catch(err => {

      });
  };


  const getEmiloMessage = async (page, chatId, config) => {
    const res = await axios.post(`${BASEAPIURL}/user/getchatemmessage?page=${page}`, { chatId: chatId }, config)

    return res.data.message;
  };

  // Get logged in user data
  const getLoggedInUser = async () => {
    axios.post(`${BASEAPIURL}/user/myprofile`, {}, config)
      .then((res) => {
        // console.log("config", res)
        setLeftBlockLoader(false)
        setLoggedInUser(res.data.user);
        setLoggedInUserId(res.data.user.userId);
        localStorage.setItem("loggedInUserId", res.data.user.userId)
        setLoggedInUserName(res.data.user.fname);
        setLoggedInUserImage(res.data.user.profileImg.imageId)
        setloggedInUserGender(res.data.user.gender)
        setusername(res.data.user.username)
      })
      .catch((err) => {
        return null;
      });
    setLeftBlockLoader(true)
  };

  //post count
  const countPost = async (postId) => {
    try {
      await axios.post(`${BASEAPIURL}/post/postviewcountcreate`, { postId }, config)
    }
    catch (error) {
      return null;
    }
  };

  const countReel = async (reelId) => {
    try {
      await axios.post(`${BASEAPIURL}/post/reelviewcountcreate`, { reelId }, config)
    }
    catch (error) {
      return null;
    }
  };

  //get post count
  const getPostCount = async (postId) => {
    try {
      if (!requestInProgress) {
        await axios.post(`${BASEAPIURL}/post/postviewcountget`, { postId }, config)
      }
    } catch (error) {
      return null;
    }
    finally {
      setRequestInProgress(false);
    };
  };

  // Get profile user data
  const getProfileUser = async (profileUserId) => {
    await axios.post(`${BASEAPIURL}/user/getoneuser`, { userId: profileUserId }, config)
      .then(res => {
        setProfileUser(res.data.user);
      })
      .catch(err => {
        return null
      });
  };

  // To get profile image
  const getProfileImage = async (userId, setProfileImage) => {
    setProfileImageLoader(true)
    await axios.post(`${BASEAPIURL}/user/getprofileimage`, { userId: userId })
      .then(res => {
        setProfileImage(res.data.message);
      })
      .catch(err => {
        return null;
      });
    setProfileImageLoader(false)
  };

  // To get user post
  const getUserPosts = async () => {
    try {
      axios.post(`${BASEAPIURL}/post/allpost`)
        .then(res => {
          setAllUsersLoader(true)
          setUsers(res.data.listpost);
        })
      setAllUsersLoader(false);
    } catch (error) {
      setAllUsersLoader(false);
    } finally {
      setAllUsersLoader(false);
    };
  };

  // To update profile image
  const updateProfileImage = async ({ formData, config }) => {
    setProfileImageLoader(true)
    axios.post(`${BASEAPIURL}/user/uploadprofileimg`, formData, config)
    setProfileImageLoader(false);
  };

  // Fetching follow status
  const fetchFollowStatus = async (otherUserId, setStatus) => {
    await axios
      .post(
        `${BASEAPIURL}/user/checkfollwstatus`,
        { userId: otherUserId },
        config
      )
      .then((res) => {
        //  console.log("res.data.status")
        setStatus(res.data.status);
      })
      .catch((err) => {
        return null;
      });

    setTimeout(() => {
      setFollowStatusLoader(true);
    }, 1200);
  };

  // Follower
  const follower = async (loggedInUserId) => {
    await axios
      .post(`${BASEAPIURL}/user/followercount`, {
        followingID: loggedInUserId,
      })
      .then((res) => {
        setLoggedInUserFollower(res.data.follower);
      })
      .catch((err) => {
        return null
      });

  };

  // Show follower list in profile page
  const getFollowerList = (profileUserId) => {
    axios.post(`${BASEAPIURL}/user/userallfollowerlist?page=1&limit=20&sort=1`, { followingID: profileUserId })
      .then(res => {
        setFollowerList(res.data.follower);
      })
      .catch(err => {
        return null
      })
  };

  // Following
  const following = async (loggedInUserId) => {
    await axios
      .post(`${BASEAPIURL}/user/followingcount`, {
        followerID: loggedInUserId,
      })
      .then((res) => {
        setLoggedInUserFollowing(res.data.following);
      })
      .catch((err) => {
        return null

      });
  };

  // Show following list in profile page
  const getFollowingList = (profileUserId) => {
    axios.post(`${BASEAPIURL}/user/userallfollowinglist`, { followerID: profileUserId })
      .then(res => {

        setFollowingList(res.data.following);
      })
      .catch(err => {
        return null
      })
  };

  // Logged in user post
  const post = async (loggedInUserId) => {
    await axios
      .post(`${BASEAPIURL}/post/countpost`, {
        userId: loggedInUserId,
      })
      .then((res) => {

        setLoggedInUserPost(res.data.posts);

      })
      .catch((err) => {
        return null;
      });
  };

  // Profile user post count
  const profileUserPostCount = async (profileUserId) => {
    await axios
      .post(`${BASEAPIURL}/post/countpost`, {
        userId: profileUserId,
      })
      .then((res) => {
        setProfileUserPostNumber(res.data.posts);

      });
  };


  // Profile user post
  const profileUserPost = async (profileUserId) => {
    setProfileLoader(true)

    await axios.post(`${BASEAPIURL}/post/getalluserpost`, { userId: profileUserId })
      .then((res) => {

        setProfilePost(res.data.listpost.reverse());
      })
      .catch((err) => {
        return null
      });

    setProfileLoader(false);
  };

  // To get profile best clips
  const getProfileBestClips = (profileUserId, config) => {
    axios.post(`${BASEAPIURL}/post/getuserbestclip`, { userId: profileUserId }, config)
      .then(res => {

        setProfileBestClips(res.data.listBestclip.reverse());
      })
      .catch(err => {
        return null;
      });
  };

  // To get center block all data (post, reel, bestclip, meme)
  const getCenterBlockData = () => {
    // axios.post(`${BASEAPIURL}/post/allcheck?page=${centerBlockPage}`, {}, config)
    //   .then(res => {
    //     

    //     res.data.allData.map(data => {
    //       setCenterBlockData(prevData => [...prevData, data]);
    //       return null;
    //     });

    //     setCenterBlockPage(centerBlockPage + 1);
    //   })
    //   .catch(err => {
    //     return null;
    //   });
  };

  // To handle show onboarding or enter pin page when user click on softcorner logo of header 
  const handleSoftCornerEntry = (config) => {
    axios.post(`${BASEAPIURL}/softcorner/checkmeinsc`, {}, config)
      .then(res => {

        setIsInSoftCorner(res.data.message);
      })
      .catch(err => {
        return null;
      });
  };


  // -------------------- Notifications --------------------

  // To get new or runtime notification
  const handleShowNewNotificationDot = (socket) => {
    if (socket) {
      socket.on("receive_message_emilo_notification", (data) => {

        // To show new notification dot in icon
        if (data.action === 'like') {
          setShowNewNotificationDot(true);
        } else if (data.action === 'dislike') {
          setShowNewNotificationDot(false);
        };
      });
    };
  };

  // To get new or runtime notification
  const getNewNotification = (socket) => {
    if (socket) {
      socket.on("receive_message_emilo_notification", (data) => {


        // ***** like *****
        if (data.type === 'like' && data.action === 'like') {
          setNewNotifications((notifications) => [data, ...notifications]);
        } else if (data.type === 'like' && data.action === 'dislike') {
          if (data.postId) {
            setNewNotifications((list) => list.filter(e => e.postId !== data.postId));
          } else if (data.bestclipId) {
            setNewNotifications((list) => list.filter(e => e.bestclipId !== data.bestclipId));
          } else if (data.reelId) {
            setNewNotifications((list) => list.filter(e => e.reelId !== data.reelId));
          } else if (data.memeId) {
            setNewNotifications((list) => list.filter(e => e.memeId !== data.memeId));
          };
        };

        // ***** follow *****
        if (data.type === 'follow' && data.action === 'follow') {
          setNewNotifications((notifications) => [data, ...notifications]);
        } else if (data.type === 'follow' && data.action === 'unfollow') {
          setNewNotifications((list) => list.filter(e => e.followId !== data.followId));
        };

        // ***** Comment *****
        if (data.type === "parentcomment") {
          setNewNotifications((notifications) => [data, ...notifications]);
        } else if (data.type === "childcomment") {
          setNewNotifications((notifications) => [data, ...notifications]);
        };

        // ***** Tag *****
        if (data.type === "tag") {
          setNewNotifications((notifications) => [data, ...notifications]);
        };

        // ***** SoftCorner *****
        if (data.type === "softcorner") {
          setNewNotifications((notifications) => [data, ...notifications]);
        };

        // ***** Relation *****
        if (data.type === "relation") {
          setNewNotifications((notifications) => [data, ...notifications]);
        };
      });
    };
  };

  // To get old notifications
  const getNotifications = (config) => {
    axios.post(`${BASEAPIURL}/user/getemnotification?page=${notificationPage}`, {}, config)
      .then(res => {
        const mergeNotification = [];

        res.data.message.map(notification => {
          if (notification.type === 'like') {
            if (notification.postId) {
              const existPost = mergeNotification.find(item => item.postId === notification.postId);

              if (!existPost) {
                mergeNotification.push({
                  postId: notification.postId,
                  postImg: notification.postImg,
                  message: notification.message,
                  createdAt: notification.createdAt,
                  data: [notification],
                  type: notification.type,
                });
              } else {
                const index = mergeNotification.findIndex(item => item.postId === notification.postId)

                mergeNotification[index].data.push(notification);
              };
            } else if (notification.reelId) {
              const existPost = mergeNotification.find(item => item.reelId === notification.reelId);

              if (!existPost) {
                mergeNotification.push({
                  reelId: notification.reelId,
                  postImg: notification.postImg,
                  message: notification.message,
                  createdAt: notification.createdAt,
                  data: [notification],
                  type: notification.type,
                });
              } else {
                const index = mergeNotification.findIndex(item => item.reelId === notification.reelId)

                mergeNotification[index].data.push(notification);
              };
            } else if (notification.bestclipId) {
              const existPost = mergeNotification.find(item => item.bestclipId === notification.bestclipId);

              if (!existPost) {
                mergeNotification.push({
                  bestclipId: notification.bestclipId,
                  postImg: notification.postImg,
                  message: notification.message,
                  createdAt: notification.createdAt,
                  data: [notification],
                  type: notification.type,
                });
              } else {
                const index = mergeNotification.findIndex(item => item.bestclipId === notification.bestclipId)

                mergeNotification[index].data.push(notification);
              };
            } else if (notification.memeId) {
              const existPost = mergeNotification.find(item => item.memeId === notification.memeId);

              if (!existPost) {
                mergeNotification.push({
                  memeId: notification.memeId,
                  postImg: notification.postImg,
                  message: notification.message,
                  createdAt: notification.createdAt,
                  data: [notification],
                  type: notification.type,
                });
              } else {
                const index = mergeNotification.findIndex(item => item.memeId === notification.memeId)

                mergeNotification[index].data.push(notification);
              };
            };
          } else if (notification.type === 'follow') {
            const existPost = mergeNotification.find(item => item.type === notification.type);

            if (!existPost) {
              mergeNotification.push({
                followId: notification.followId,
                receiverUserId: notification.receiverUserId,
                senderUserId: notification.senderUserId,
                message: notification.message,
                createdAt: notification.createdAt,
                type: notification.type,
                data: [notification]
              });
            } else {
              const index = mergeNotification.findIndex(item => item.type === notification.type)

              mergeNotification[index].data.push(notification);
            };
          } else if (notification.type === 'parentcomment') {
            mergeNotification.push({
              parentCommentId: notification.parentCommentId,
              receiverUserId: notification.receiverUserId,
              senderUserId: notification.senderUserId,
              message: notification.message,
              createdAt: notification.createdAt,
              type: notification.type,
              data: [notification]
            });
          } else if (notification.type === 'childcomment') {
            mergeNotification.push({
              parentCommentId: notification.parentCommentId,
              childCommentId: notification.childCommentId,
              repliedUserId: notification.repliedUserId,
              repliedfname: notification.repliedfname,
              receiverUserId: notification.receiverUserId,
              senderUserId: notification.senderUserId,
              message: notification.message,
              createdAt: notification.createdAt,
              type: notification.type,
              data: [notification]
            });
          } else if (notification.type === 'softcorner') {
            mergeNotification.push({
              receiverUserId: notification.receiverUserId,
              message: notification.message,
              createdAt: notification.createdAt,
              type: notification.type,
              data: [notification]
            });
          } else if (notification.type === 'tag') {
            mergeNotification.push({
              senderUserId: notification.senderUserId,
              receiverUserId: notification.receiverUserId,
              message: notification.message,
              createdAt: notification.createdAt,
              type: notification.type,
              data: [notification]
            });
          } else if (notification.type === 'relation') {
            if (notification.message !== "User relation not found") {
              mergeNotification.push({
                senderUserId: notification.userId,
                receiverUserId: notification.secondUserId,
                message: notification.message,
                createdAt: notification.createdAt,
                type: notification.type,
                data: [notification]
              });
            }
          };

          return null;
        });


        setOldNotifications(prevNotifications => [...prevNotifications, ...mergeNotification]);

        setNotificationPage(notificationPage + 1);

        setShowNewNotificationDot(false);
      })
      .catch(err => {
        return null;
      });
  };

  // To get stop all notification is set to (true or false)
  const getIsNotificationStopped = (config) => {
    axios.post(`${BASEAPIURL}/user/isNotificationStopped`, {}, config)
      .then(res => {

        setIsNotificationStopped(res.data.message);
      })
      .catch(err => {

      });
  };

  // To handle change stop all notification
  const handleChangeStopAllNotification = (config) => {
    axios.post(`${BASEAPIURL}/user/changeStopAllNotifications`, { action: !isNotificationStopped }, config)
      .then(res => {

        setIsNotificationStopped(!isNotificationStopped);
        getIsNotificationStopped(config);
      })
      .catch(err => {

      });
  };







  // ***** Comments *****

  // To get post comments
  // const getPostComment = async (postId, config) => {
  //   setParentSpinner(true);


  //   await axios.post('http://localhost:3001/post/getAllcomments', {postId }, config)
  //     .then(res => {
  //       setComments(res.data.commentList);
  //       setParentSpinner(false);
  //     })
  //     .catch(err => {
  //       return null;
  //     });
  // };

  // To handle emoji picker
  const handleEmojiPick = (e) => {
    console.log("resp", e)
    setCreateComment(prev => prev + e.emoji)
  };


  // ***** Image Compression ***** 

  // Profile image (sign up, left block, profile)
  const profileOption = {
    maxSizeMB: 0.1, // 5
    maxWidthOrHeight: 175, // 1280
    useWebWorker: true,
  };

  // Story 2 upload image option
  const storyOption = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 175,
    useWebWorker: true,
  };

  // Create photo and video option
  const postOption = {
    maxSizeMB: 0.1, // 5
    maxWidthOrHeight: 175, // 1280
    useWebWorker: true,
  };


  // ---------- Post three dot popup ----------

  // To check other user is added to logged in user favourite or not
  const checkFavouriteUser = (postUserId, config) => {
    axios.post(`${BASEAPIURL}/user/checkfavuser`, { userId: postUserId }, config)
      .then(res => {

        setIsFavouriteUser(res.data.added);
      })
      .catch(err => {
        return null;
      });
  };

  // To handle create favourite user
  const handleCreateFavouriteUser = (postUserId, config) => {
    const action = isFavouriteUser ? 'add' : 'remove';

    axios.post(`${BASEAPIURL}/user/createfavouriteuser`, { favouriteUserId: postUserId, action: action }, config)
      .then(res => {
        setIsFavouriteUser(!isFavouriteUser);
      })
      .catch(err => {
        return null;
      });
  };

  // To check snooze user
  const checkSnoozeUser = (postUserId, config) => {
    axios.post(`${BASEAPIURL}/post/checksnoozeuser`, { snoozeUserId: postUserId }, config)
      .then(res => {

        setIsUserSnooze(res.data.message);
      })
      .catch(err => {
        return null;
      });
  };

  // To put other user snooze for 30 days
  const putSnooze = (postUserId, config) => {
    const action = isUserSnooze ? true : false;

    axios.post(`${BASEAPIURL}/post/snoozeuser`, { snoozeUserId: postUserId, action: action }, config)
      .then(res => {
        checkSnoozeUser(postUserId, config);
      })
      .catch(err => {
        return null;
      });
  };

  // To handle hide post
  const handleHidePost = (postId) => {
    if (localStorage.getItem('hide') === null) {
      localStorage.setItem('hide', [postId]);
    } else {
      localStorage.setItem('hide', [localStorage.getItem('hide'), postId]);
    };

    axios.post(`${BASEAPIURL}/post/hidepost`, { postId: postId }, config)
      .then(res => {
        profileUserPost(res.data.message.postedBy);
      })
      .catch(err => {
        return null;
      });
  };

  // To handle hide post
  const handleHideReel = (reelId) => {

    if (localStorage.getItem('hide') === null) {
      localStorage.setItem('hide', [reelId]);
    } else {
      localStorage.setItem('hide', [localStorage.getItem('hide'), reelId]);
    };

    axios.post(`${BASEAPIURL}/post/hidepost`, { reelId: reelId, type: 'reel' }, config)
      .then(res => {
        // profileUserPost(res.data.message.postedBy);
      })
      .catch(err => {
        return null;
      });
  };

  // To handle hide post
  const handleHideBestClip = (bestClipId) => {

    if (localStorage.getItem('hide') === null) {
      localStorage.setItem('hide', [bestClipId]);
    } else {
      localStorage.setItem('hide', [localStorage.getItem('hide'), bestClipId]);
    };

    axios.post(`${BASEAPIURL}/post/hidepost`, { bestClipId: bestClipId, type: 'bestclip' }, config)
      .then(res => {
        // profileUserPost(res.data.message.postedBy);
      })
      .catch(err => {
        return null;
      });
  };

  // To handle hide post
  const handleHideMeme = (memeId) => {

    if (localStorage.getItem('hide') === null) {
      localStorage.setItem('hide', [memeId]);
    } else {
      localStorage.setItem('hide', [localStorage.getItem('hide'), memeId]);
    };

    axios.post(`${BASEAPIURL}/post/hidepost`, { memeId: memeId, type: 'meme' }, config)
      .then(res => {

        // profileUserPost(res.data.message.postedBy);
      })
      .catch(err => {
        return null;
      });
  };






  // ----------- Profile > Setting > Favourite People -----------

  // To get favourite people
  const getFavouritePeople = (config) => {
    axios.post(`${BASEAPIURL}/user/getfavouriteuser`, {}, config)
      .then(res => {

        setFavouritePeoples(res.data.list);
      })
      .catch(err => {
        return null;
      });
  };

  // To remove favourite user
  const handleRemoveFavouritePeople = (postUserId, config) => {
    axios.post(`${BASEAPIURL}/user/createfavouriteuser`, { favouriteUserId: postUserId, action: 'remove' }, config)
      .then(res => {

        getFavouritePeople(config);
      })
      .catch(err => {
        return null;
      });
  };

  // ----------- Repost (Post, Reel, Bestclip, Meme, user) -----------

  const [showReportPostPopup, setShowReportPostPopup] = useState(false);
  const [showReportReelPopup, setShowReportReelPopup] = useState(false);
  const [showReportBestClipPopup, setShowReportBestClipPopup] = useState(false);
  const [showReportMemePopup, setShowReportMemePopup] = useState(false);
  const [showReportUserPopup, setShowReportUserPopup] = useState(false);

  const [reportPost, setReportPost] = useState();
  const [reportReel, setReportReel] = useState();
  const [reportBestClip, setreportBestClip] = useState();
  const [reportMeme, setReportMeme] = useState();
  const [reportUser, setreportUser] = useState()

  // To handle report ---> Post || Flix || Meme || Bestclip || User || Servive post || Service profile
  const handleReportPost = (targetID, targetType, userId, reportPost) => {
    axios.post(`${BASEAPIURL}/post/reportPost`, { targetID, targetType, userId, reportPost }, config)
      .then(res => {

        setShowReportPostPopup(false);
        setShowReportReelPopup(false);
        setShowReportMemePopup(false);
        setShowReportBestClipPopup(false);
        setshowServiceProfilePopup(false)
        setShowReportUserPopup(false)
        setShowServicePopup(false)
        setService("")
        setReportPost("");
        setReportMeme("")
        setreportBestClip("")
        setReportReel("")
        toast.success(res.data.message);
      })
      .catch(err => {
        console.log(err)

      });
  };

  // ----------- Repost Service (Post, Reel, Bestclip, Meme) -----------

  const [showServicePopup, setShowServicePopup] = useState(false);
  const [showServiceProfilePopup, setshowServiceProfilePopup] = useState(false);
  const [service, setService] = useState();

  // ----------- Unfollow (Post, Reel, Bestclip, Meme) -----------

  const [isUnfollow, setIsUnfollow] = useState(false);
  const [unfollowList, setUnfollowList] = useState([]);
  const [unfollowUsers, setUnfollowUsers] = useState([]);

  // To handle unfollow (post, reel, bestclip, meme)
  const checkUnfollow = (otherUserId) => {
    axios.post(`${BASEAPIURL}/post/checkUnfollow`, { otherUserId }, config)
      .then(res => {

        setIsUnfollow(res.data.message);
      })
      .catch(err => {

      });
  };

  // To handle unfollow (post, reel, bestclip, meme)
  const handleUnfollow = (unfollowedUserId) => {
    axios.post(`${BASEAPIURL}/post/handleUnfollow`, { unfollowedUserId }, config)
      .then(res => {
        console.log("res.data.message", res.data)
        toast.success(res.data.message);
        setIsUnfollow(res.data.value);
        getUnfollowUsers(config);
      })
      .catch(err => {

      });
  };

  // To get unfollow list (logged in user ne jisko unfollow kiya hai uski list) (post, reel, bestclip, meme) (only unfollowedUserId)
  const getUnfollowList = () => {
    axios.post(`${BASEAPIURL}/post/unfollowList`, {}, config)
      .then(res => {

        setUnfollowList(res.data.message);
      })
      .catch(err => {

      });
  };

  // To get unfollow list (logged in user ne jisko unfollow kiya hai uski list) (post, reel, bestclip, meme) (img, name, username) 
  const getUnfollowUsers = (config) => {
    axios.post(`${BASEAPIURL}/post/unfollowUsers`, {}, config)
      .then(res => {

        setUnfollowUsers(res.data.message);
      })
      .catch(err => {

      });
  };







  // ----------- Visibility (Following, Everyone) -----------

  const [showVisibilityPostPopup, setShowVisibilityPostPopup] = useState(false);
  const [visibility, setVisibility] = useState('everyone');



  const checkVisibility = (postId, config) => {
    axios.post(`${BASEAPIURL}/post/checkVisibility`, { postId: postId }, config)
      .then(res => {

        setVisibility(res.data.message.visibility);
      })
      .catch(err => {

      });
  };

  const handleVisibility = (postId, visibility, config) => {
    axios.post(`${BASEAPIURL}/post/handleVisibility`, { postId, visibility }, config)
      .then(res => {

        toast.success(res.data.message);
        setShowVisibilityPostPopup(false);
      })
      .catch(err => {

      });
  };


  // ----------- Relation (Profile > Edit > Relation) -----------

  const [showRealtionButton, setShowRealtionButton] = useState(true);

  const handleAcceptRelation = async (relationId, setRelationsData) => {
    await axios.post(`${BASEAPIURL}/user/siblingApproved`, { siblingId: relationId }, config)
      .then(res => {
        toast.success(res.data.message);
        setShowRealtionButton(false);
        getRelation(loggedInUserId).then((res) => {
          setRelationsData(res)
        })
      })
      .catch(err => {

      });
  };

  const handleRejectRelation = (relationId) => {
    axios.post(`${BASEAPIURL}/user/siblingReject`, { siblingId: relationId }, config)
      .then(res => {
        toast.success(res.data.message);
        setShowRealtionButton(false);
      })
      .catch(err => {

      });
  };




  // ----------- Close Friends -----------

  const closeFriends = () => {
    axios.post(`${BASEAPIURL}/user/closeFriends`, { name: 'siddharth' }, config)
      .then(res => {
      }).catch(err => {

      });
  };





  // ----------- Block -----------

  const [isBlocked, setIsBlocked] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [blockSearchUsers, setBlockSearchUsers] = useState([]);
  const [blockFilterSearchUsers, setBlockFilterSearchUsers] = useState([]);
  const [showBlockSearchList, setShowBlockSearchList] = useState(false);
  const [showBlockFilterSearchList, setShowBlockFilterSearchList] = useState(false);


  // To get blocked users list
  const getBlokedUsers = () => {
    axios.post(`${BASEAPIURL}/user/getBlockedUsers`, {}, config)
      .then(res => {


        setBlockedUsers(res.data.list);
      }).catch(err => {

      });
  };

  // To check block (logged in user ne kisko block kiya hai)
  const checkBlocked = (otherUserId) => {
    axios.post(`${BASEAPIURL}/user/checkBlocked`, { otherUserId: otherUserId }, config)
      .then(res => {

        setIsBlocked(res.data.message);
      })
      .catch(err => {

      });
  };

  // To handle block or unblock user

  // To handle block
  const handleBlock = async (blockedUserId) => {
    axios.post(`${BASEAPIURL}/user/handleBlockUnblock`, { otherUserId: blockedUserId, action: 'block' }, config)
      .then(res => {


        toast.success(res.data.message);

        // Find the user from both (blockSearchUsers and blockFilterSearchUsers) list
        const blockedUser = blockSearchUsers.find(user => user._id === blockedUserId) || blockFilterSearchUsers.find(user => user._id === blockedUserId);

        if (blockedUser) {
          // Remove the user from both (blockSearchUsers and blockFilterSearchUsers) lists
          setBlockSearchUsers(prevUsers => prevUsers.filter(user => user._id !== blockedUserId));
          setBlockFilterSearchUsers(prevUsers => prevUsers.filter(user => user._id !== blockedUserId));

          // Add the user to blockedUsers list
          setBlockedUsers(prevUsers => [...prevUsers, blockedUser]);
        }
      })
      .catch(err => {

      });
  };

  // To handle unblock
  const handleUnblock = async (blockedUserId) => {
    axios.post(`${BASEAPIURL}/user/handleBlockUnblock`, { otherUserId: blockedUserId, action: 'unblock' }, config)
      .then(res => {


        toast.success(res.data.message);

        // Find the unblocked user
        const unblockedUser = blockedUsers.find((user) => user._id === blockedUserId);

        // Remove the user from the blockedUsers list
        setBlockedUsers((prevUsers) => prevUsers.filter((user) => user._id !== blockedUserId));

        // Add the user to blockSearchUsers list
        if (blockSearchUsers.length > 0) {
          setBlockSearchUsers((prevUsers) => [...prevUsers, unblockedUser]);
        };
      })
      .catch(err => {

      });
  };

  // To handle block search focus 
  const handleBlockFocus = () => {
    if (blockSearchUsers.length === 0) {
      axios.post(`${BASEAPIURL}/user/handleBlockFocus`, {}, config)
        .then(res => {


          setBlockSearchUsers(res.data.list);
        }).catch(err => {

        });
    };

    setShowBlockSearchList(true);
  };

  // To handle block search change
  const handleBlockChange = (e) => {
    const { value } = e.target;

    // Filter the users according to search bar value
    const filteredUsers = blockSearchUsers.filter(user =>
      user.name.toLowerCase().includes(value.toLowerCase())
    );

    // Set the filter users to filtered list
    setBlockFilterSearchUsers(filteredUsers);

    setShowBlockSearchList(false);
    setShowBlockFilterSearchList(true);
  };



  // ----------- Restricted -----------

  const [resrictUser, setResrictUser] = useState({
    restrictedUserId: '',
    like: '',
    comment: '',
    share: '',
    tag: '',
    mention: '',
    chat: '',
  });



  const [restrictSearchUsers, setRestrictSearchUsers] = useState([]);
  const [showRestrictSearchUsers, setShowRestrictSearchUsers] = useState(false);
  const [showRestrictFilterSearchUsers, setShowRestrictFilterSearchUsers] = useState(false);

  const [showRestrictUserPopup, setShowRestrictUserPopup] = useState(false);

  // To handle restrict or unrestrict
  const handleRestrictUnrestrict = () => {
    axios.post(`${BASEAPIURL}/user/handleRestrictUnrestrict`, { name: 'sidd' }, config)
      .then(res => {
      }).catch(err => {

      });
  };

  // To handle focus restrict search bar
  const handleRestrictFocus = () => {
    axios.post(`${BASEAPIURL}/user/handleRestrictFocus`, {}, config)
      .then(res => {

        setRestrictSearchUsers(res.data.list);

        setShowRestrictSearchUsers(true);
      }).catch(err => {

      });
  };





  // ----------- Stories -----------

  const [showCreateStoriesPopup, setshowCreateStoriesPopup] = useState(false);
  const [showStoryTextPopup, setShowStoryTextPopup] = useState(false);
  const [showStoryPhotoVideoPopup, setShowStoryPhotoVideoPopup] = useState(false);
  const [showStoriesEmiloGalleryPopup, setShowStoriesEmiloGalleryPopup] = useState(false);
  const [showStoriesAdvanceSettingPopup, setShowStoriesAdvanceSettingPopup] = useState(false);
  const [showViewStoriesPopup, setShowViewStoriesPopup] = useState(false);
  const [showAddToStoriesBtn, setShowAddToStoriesBtn] = useState(false);
  const [showStoriesInsightsPopup, setShowStoriesInsightsPopup] = useState(false);

  const [currentStory, setCurrentStory] = useState({});
  const [storyComment, setStoryComment] = useState('');
  const [storyText, setStoryText] = useState('');
  const [photoVideos, setPhotoVideos] = useState([]);
  const [userStories, setUserStories] = useState([]);
  const [userStoryData, setuserStoryData] = useState([]);
  const [storiesFollowingUsers, setStoriesFollowingUsers] = useState([]);
  const [previewBackground, setPreviewBackground] = useState('');
  const [insightStories, setInsightStories] = useState([]);
  //humbaleimages
  const [selectedImage, setSelectedImage] = useState([])

  const [selectedInsightStory, setSelectedInsightStory] = useState({
    story: {},
    views: [],
    likes: [],
    comments: [],
  });

  const [showInsightStory, setShowInsightStory] = useState({
    views: true,
    likes: false,
    comments: false,
  });

  const [storiesAdvanceSetting, setStoriesAdvanceSetting] = useState({
    visibility: 'public',
    expire: '24',
    like: 'on',
    comment: 'on',
  });

  // To create user stories
  const createUserStories = async (formData, type, taggedUsers, isGallery) => {
    try {
      let mediaUrls;
      let uploadResponse
      // Step 1: Upload media files to AWS
      if (isGallery === 'gallery') {
        mediaUrls = [{
          url: formData.mediaUrl,
          filetype: formData.filetype,
          thumbnail: formData.thumbnail,
          audio: formData.audio || "",
          start: formData.start || "",
          end: formData.end || "",
          tags: Array.isArray(taggedUsers) ?
            taggedUsers.map(tag => ({
              tagUserId: tag.userId,
              x: tag.x,
              y: tag.y,
              fname: tag.fname,
              username: tag.username,
              profileImg: tag.profileImg,
              postedBy: loggedInUserId,
            })) : []
        }];
      }

      else {
        uploadResponse = await axios.post(`${BASEAPIURL}/post/postdataurl`, formData, config);

        mediaUrls = Array.isArray(uploadResponse.data.mediaUrls) && uploadResponse.data.mediaUrls.map((mediaUrl, index) => {
          return {
            url: mediaUrl.url,
            filetype: mediaUrl.filetype,
            thumbnail: mediaUrl.thumbnail,
            audio: mediaUrl.audio || "",
            start: mediaUrl.start || "",
            end: mediaUrl.end || "",
            tags: Array.isArray(taggedUsers) ?
              taggedUsers.map(tag => ({
                tagUserId: tag.userId,
                x: tag.x,
                y: tag.y,
                fname: tag.fname,
                username: tag.username,
                profileImg: tag.profileImg,
                postedBy: loggedInUserId,
              })) : []
          };
        });
      }

      // Step 3: Post story data to MongoDB
      const response = await axios.post(`${BASEAPIURL}/post/createmediapost`, {
        maintype: 'story',
        type,
        mediaUrls,
        postTumbnailUrl: isGallery === 'gallery' ? formData.postTumbnailUrl : uploadResponse.data.postTumbnailUrl,
        storiesAdvanceSetting, // Assuming this is an object with visibility and other settings
        visibility: storiesAdvanceSetting.visibility
      }, config);

      const post = response.data.post

      if (mediaUrls[0].tags && mediaUrls[0].tags.length > 0) {
        mediaUrls[0].tags.forEach((e) => {
          const data = {
            targetId: e.profileImg._id,
            userType: "user",
            targetEvent: "mention",
            targetType: 'story',
            targetEventId: post._id,
            notificationType: "emilo",
            isSeen: 0,
          };
          createNotification(data, config); // Assuming this function is performing side effects
        });
      }



      toast.success('Story posted successfully');
    } catch (err) {
      console.error('Error posting story: ', err);
    }
  };


  // To get user whom i follow to show in stories section
  const getStoriesFollowingUsers = async (id) => {
    const res = await axios.post(`${BASEAPIURL}/post/getStoriesFollowingUsers`, { id }, config)
    return res.data.users
  }

  // view story
  const viewStory = (story) => {
    axios.post(`${BASEAPIURL}/post/viewStory`, { storyId: story._id, postedBy: story.postedBy }, config)
      .then(res => {
      }).catch(err => {

      });
  };

  // Like dislike story
  const likeDislikeStory = () => {
    axios.post(`${BASEAPIURL}/post/likeDislikeStory`, { storyId: currentStory._id, postedBy: currentStory.postedBy }, config)
      .then(res => {
      }).catch(err => {

      });
  };

  // comment on a story
  const commentOnStory = () => {
    axios.post(`${BASEAPIURL}/post/commentOnStory`, { storyId: currentStory._id, postedBy: currentStory.postedBy, comment: storyComment }, config)
      .then(res => {

        toast.success(res.data.message);

        setStoryComment('');
      }).catch(err => {

      });
  };


  // ----------- Stories > Insights -----------

  // get insights stories
  const getInsightStories = () => {
    axios.post(`${BASEAPIURL}/post/getInsightStories`, { name: 'sid' }, config)
      .then(res => {


        setInsightStories(res.data.insightStories);

        setSelectedInsightStory(preState => ({
          ...preState,
          story: res.data.insightStories[0]
        }));
      }).catch(err => {

      });
  };

  // get insights story views, likes, comments 
  const getInsightsStoryViewsLikesComments = (type) => {
    if (type && selectedInsightStory.story._id) {
      axios.post(`${BASEAPIURL}/post/getInsightsStoryViewsLikesComments`, { type, storyId: selectedInsightStory.story._id }, config)
        .then(res => {


          setSelectedInsightStory(preState => ({
            ...preState,
            [type]: res.data.usersList
          }));
        }).catch(err => {

        });
    };
  };

  // change/update story advance setting (audience, expire time, like & comment)
  const updateStoryAdvanceSetting = (storyId, key, value) => {
    axios.post(`${BASEAPIURL}/post/updateStoryAdvanceSetting`, { storyId, key, value }, config)
      .then(res => {
      }).catch(err => {

      });
  };

  // delete story
  const deleteStory = () => {
    axios.post(`${BASEAPIURL}/post/deleteStory`, { storyId: selectedInsightStory.story._id }, config)
      .then(res => {


        toast.success(res.data.message);

        // Remove from front end
        setInsightStories(prevStories =>
          prevStories.filter(story => story._id !== selectedInsightStory.story._id)
        );

        setSelectedInsightStory({
          story: {},
          views: [],
          likes: [],
          comments: [],
        });
      }).catch(err => {

      });
  };




  // ----------- eShow (ZegoCloud) (Live Streaming) -----------

  // live start
  const liveStart = (roomId) => {
    console.log('start : ', roomId);

    axios.post(`${BASEAPIURL}/user/liveStart`, { roomId }, config)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);

      const payload = {
        roomId
      };

      socket.emit('cs-live-start', payload);
    }).catch(err => {
      console.log('err : ', err);
    });
  };

  // live end
  const liveEnd = (roomId) => {
    console.log('end : ', roomId);

    axios.post(`${BASEAPIURL}/user/liveEnd`, { roomId }, config)
    .then(res => {
      // console.log('res : ', res.data);

      toast.success(res.data.message);
    }).catch(err => {
      console.log('err : ', err);
    });
  };




  // ----------- Voice Call (ZegoCloud) -----------

  const [showVoiceCallGoingPopup, setShowVoiceCallGoingPopup] = useState(false);
  const [showVoiceCallComingPopup, setShowVoiceCallComingPopup] = useState(false);

  // voice call going
  const voiceCallStart = (roomId, hostName, participantId, type) => {
    socket.emit('cs-voice-call-start', { roomId, hostName, participantId, type });
  };

  // voice call end
  const voiceCallEnd = (roomId, participantId, type) => {
    socket.emit('cs-voice-call-end', { roomId, participantId, type });
  };




  // ----------- Video Call (ZegoCloud) -----------

  const [showVideoCallGoingPopup, setShowVideoCallGoingPopup] = useState(false);
  const [showVideoCallComingPopup, setShowVideoCallComingPopup] = useState(false);

  // video call going
  const videoCallStart = (roomId, hostName, participantId, type) => {
    socket.emit('cs-video-call-start', { roomId, hostName, participantId, type });
  };

  // video call end
  const videoCallEnd = (roomId, participantId, type) => {
    socket.emit('cs-video-call-end', { roomId, participantId, type });
  };




  useEffect(() => {
    getCenterBlockData();

    getUnfollowList();

    handleRestrictUnrestrict();
  }, []);

  useMemo(() => {
    getProfileUser(profileUserId);

    getFollowerList(profileUserId);
    getFollowingList(profileUserId);

    profileUserPostCount(profileUserId);
    profileUserPost(profileUserId);

    getProfileBestClips(profileUserId, config);
  }, [profileUserId]);

  useEffect(() => {
    getLoggedInUser().then(() => {
      localStorage.setItem("loggedInUserId", loggedInUserId)
    });
  }, [otherUserId]);

  useEffect(() => {
    follower(loggedInUserId);
    following(loggedInUserId);
    post(loggedInUserId);
  }, [loggedInUser, loggedInUserId]);

  useEffect(() => {
    handleShowNewNotificationDot(socket);
  }, [socket]);



  const value = {

    token,
    fbToken,
    config,
    profilePost,
    isUserSnooze,
    followerList,
    followingList,
    profileUserId,
    isInSoftCorner,
    loggedInUserId,
    postOption,
    storyOption,
    profileOption,
    centerBlockData,
    loggedInUserName,
    profileBestClips,
    profileUserPostNumber,


    // ----- Video Call (ZegoCloud) -----

    showVideoCallGoingPopup, setShowVideoCallGoingPopup,
    showVideoCallComingPopup, setShowVideoCallComingPopup,
    videoCallStart, videoCallEnd,


    // ----- Voice Call (ZegoCloud) -----

    showVoiceCallGoingPopup, setShowVoiceCallGoingPopup,
    showVoiceCallComingPopup, setShowVoiceCallComingPopup,
    voiceCallStart, voiceCallEnd,


    // ------ eShow (ZegoCloud) (Live Streaming) ------

    liveStart, liveEnd,


    // ----- Stories -----
    showCreateStoriesPopup, setshowCreateStoriesPopup,
    showViewStoriesPopup, setShowViewStoriesPopup,
    showAddToStoriesBtn, setShowAddToStoriesBtn,
    storiesFollowingUsers, setStoriesFollowingUsers,
    storiesAdvanceSetting, setStoriesAdvanceSetting,
    showStoriesInsightsPopup, setShowStoriesInsightsPopup,
    showStoriesAdvanceSettingPopup, setShowStoriesAdvanceSettingPopup,
    showStoriesEmiloGalleryPopup, setShowStoriesEmiloGalleryPopup,
    showStoryPhotoVideoPopup, setShowStoryPhotoVideoPopup,
    previewBackground, setPreviewBackground,
    showInsightStory, setShowInsightStory,
    showStoryTextPopup, setShowStoryTextPopup,
    notification, setnotification,
    selectedInsightStory, setSelectedInsightStory,
    currentStory, setCurrentStory,
    photoVideos, setPhotoVideos,
    scImage, setScImage,
    userStories, setUserStories,
    userStoryData, setuserStoryData,
    insightStories, setInsightStories,
    storyText, setStoryText,
    storyComment, setStoryComment,
    createUserStories,
    getStoriesFollowingUsers,
    likeDislikeStory,
    getInsightStories,
    getInsightsStoryViewsLikesComments,
    viewStory,
    commentOnStory,
    updateStoryAdvanceSetting,
    deleteStory,


    // ----- Restrict -----
    resrictUser, setResrictUser,
    restrictSearchUsers, setRestrictSearchUsers,
    showRestrictSearchUsers, setShowRestrictSearchUsers,
    showRestrictFilterSearchUsers, setShowRestrictFilterSearchUsers,
    showRestrictUserPopup, setShowRestrictUserPopup,
    handleRestrictUnrestrict,
    handleRestrictFocus,


    // ----- Block -----
    isBlocked, setIsBlocked,
    blockedUsers, setBlockedUsers,
    blockSearchUsers, setBlockSearchUsers,
    blockFilterSearchUsers, setBlockFilterSearchUsers,
    showBlockSearchList, setShowBlockSearchList,
    showBlockFilterSearchList, setShowBlockFilterSearchList,
    getBlokedUsers,
    checkBlocked,
    handleBlock,
    handleUnblock,

    handleBlockFocus,
    handleBlockChange,


    // ----- Close Friends -----
    closeFriends,

    handleAcceptRelation,
    handleRejectRelation,

    checkVisibility,
    handleVisibility,

    checkUnfollow,
    handleUnfollow,
    getUnfollowUsers,

    handleReportPost,

    getFavouritePeople,
    handleRemoveFavouritePeople,
    handleHidePost,
    handleHideReel,
    handleHideBestClip,
    handleHideMeme,
    checkFavouriteUser,
    handleCreateFavouriteUser,
    getNewNotification,
    handleSoftCornerEntry,
    getNotifications,
    putSnooze,
    checkSnoozeUser,
    // getPostComment,
    handleEmojiPick,
    countReel,
    ReadMore,
    getCenterBlockData,
    post, countPost, getPostCount,
    getUserPosts,
    profileUserPost,
    getFollowerList,
    getProfileImage,
    fetchFollowStatus,
    updateProfileImage,
    getProfileBestClips,
    profileUserPostCount,
    getFollowingList,
    allDataPost, setallDataPost,
    handleChangeStopAllNotification,
    getIsNotificationStopped,
    selectedAddLocation, setSelectedAddLocation,
    updateUserProfile,

    showRealtionButton, setShowRealtionButton,
    gifType, setGifType,
    postType, setPostType,

    visibility, setVisibility,
    showVisibilityPostPopup, setShowVisibilityPostPopup,
    lastMsg, setLastMsg,
    isUnfollow, setIsUnfollow,
    unfollowList, setUnfollowList,
    unfollowUsers, setUnfollowUsers,
    checkinType, setCheckinType,

    showServiceProfilePopup, setshowServiceProfilePopup,
    showServicePopup, setShowServicePopup,
    service, setService,
    scAllposts, setscAllposts,
    selectedData, setSelectedData,
    partnerId, setPartnerId,

    admincanMsg, setAdmincanMsg,
    username, setusername,
    iamAmdmin, setiamAdmin,

    posts, setPosts,

    showReportPostPopup, setShowReportPostPopup,
    showReportReelPopup, setShowReportReelPopup,
    showReportBestClipPopup, setShowReportBestClipPopup,
    showReportMemePopup, setShowReportMemePopup,
    showReportUserPopup, setShowReportUserPopup,


    reportPost, setReportPost,
    reportReel, setReportReel,
    reportBestClip, setreportBestClip,
    reportMeme, setReportMeme,
    reportUser, setreportUser,

    favouritePeoples, setFavouritePeoples,
    isNotificationStopped, setIsNotificationStopped,

    phoneNumber, setPhoneNumber,
    otpEmail, setOtpEmail,
    isFavouriteUser, setIsFavouriteUser,
    showNewNotificationDot, setShowNewNotificationDot,
    newNotifications, setNewNotifications,
    oldNotifications, setOldNotifications,
    comments, setComments,
    rightReload, setRightReload,
    createComment, setCreateComment,
    parentSpinner, setParentSpinner,
    openEmoji, setOpenEmoji,
    centerBlockPage, setCenterBlockPage,
    registeredImage, setRegisteredImage,
    saveLoader, setSaveLoader,
    showHeader, setShowHeader,
    userInfo, setUserInfo,
    profileUser, getProfileUser,
    profileLoader, setProfileLoader,
    swipeHeader, setSwipeHeader,
    loader, setLoader,
    switchAbout, setSwitchAbout,
    allPosts, setAllPosts,
    allMemes, setAllMemes,
    allReels, setAllReels,
    allBestClips, setAllBestClips,
    users, setUsers,
    selectDevice, setSelectDevice,
    postCount, setPostCount,
    otherUserId, setOtherUserId,
    headerChange, setHeaderChange,
    loggedInUser, getLoggedInUser,
    setLoggedInUser,
    sg_isMobileVerified, set_sg_isMobileVerified, sg_isEmailVerified, set_sg_isEmailVerified,
    setLoggedInUserName, setLoggedInUserId,
    loggedInUserPost, setLoggedInUserPost,
    loggedInUserImage, setLoggedInUserImage,
    loggedInUserFollower, setLoggedInUserFollower,
    loggedInUserFollowing, setLoggedInUserFollowing,
    loggedInUserGender, setloggedInUserGender,
    PageContent, setPageContent,


    // ***** Emilo Chat *****

    socket,
    chatId,
    chatUserData,
    isUpdate, setisUpdate,
    onlineStatus, setOnlineStatus,
    chatUsers, setChatUsers,
    hideUsers, setHideUsers,

    pinnedUsers, setPinnedUser,
    isGroupchat, setIsgroupChat,
    isnewGroupAdded, setisnewGroupAdded,
    chatProfile, setChatProfile,
    socketFun,
    getEmiloMessage,
    createEmiloMessage,
    createEmiloChatConnection,
    chatData, setChatData,
    allChatUsers, setAllChatUsers,


    //-----------------------------------------------------Skeleton Loader states-------------------------------------
    RightSectionLoader, setRightSectionLoader,
    ProfileImageLoader, setProfileImageLoader,
    LeftBlockLoader, setLeftBlockLoader,
    AllUsersLoader, setAllUsersLoader,
    StoryUrlLoader, setStoryUrlLoader,
    FollowStatusLoader, setFollowStatusLoader,
    suggestedFlixIndex, setSuggestedFlixIndex,
    trendingfeed, setTrendingfeed,
    //--------BoostPost--------------


    //-----threedotes--------
     Unhide, setunHide, 
     reqStatus , setreqStatus, 
     showClosefriendPopup, setShowCloseFriendPopup, 
     selectedImage, setSelectedImage, 
     inputValue, setinputValue,
     searchList, setSearchList

  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export { ApiCall };
