import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CDropdown, CDropdownToggle, CDropdownItem, CDropdownMenu, } from "@coreui/react";
import { AwsFileUrl, BASEAPIURL, BASEURL, BASE_URL_STATIC, LogoutUser } from "../../../../config";
import { HiMiniXMark } from "react-icons/hi2";
import 'react-loading-skeleton/dist/skeleton.css'
import { ApiContext } from "../../UseContext/ApiCall";
import HeaderSkeleton from "../../Skeleton/RightSection/HeaderSkeleton";
import CreateNewPostPopup from "../../Popup/CreateNewPost/CreateNewPostPopup";
import FavouritePeoplePopup from "../../Popup/FavouritePeoplePopup"
import axios from "axios";
import { businessContext } from "../../../Business/UseContext/BusinessContext";
import StoryforSearch from "../CenterBlock/Stories/StoryforSearch";
import { getNotification } from "../../UseContext/ApiHelpers";
import Closefriend from "../../Popup/Profile/ProfileUser/Closefriend";
import { IoIosSearch } from "react-icons/io";

const Header = () => {


 
  const [recentSearch, setrecentSearch] = useState([])
  const [showrecentSearch, setShowrecentSearch] = useState(false)



  const [showCreatePostPopup, setShowCreatePostPopup] = useState(false);
  const [showFavouritePeoplePopup, setShowFavouritePeoplePopup] = useState(false);

  const {
    config, loader, setHeaderChange, setSelectedAddLocation, setLoader, showHeader, loggedInUserId, setPostType, notification, setnotification,
    setSwipeHeader, socketFun, socket, isInSoftCorner, handleSoftCornerEntry, setPageContent, setShowCloseFriendPopup,  inputValue, setinputValue, 
    searchList, setSearchList
  } = useContext(ApiContext);

  const { myPages, getMyPages } = useContext(businessContext);

  const navigate = useNavigate();

  const urls = ['login', 'signup', 'basic-info', 'business'];

  const handleLogout = LogoutUser;

  const handleSearch = async (e) => {
    setinputValue(e.target.value)
    if (e.target.value.length > 0) {
      const res = await axios.post(`${BASEAPIURL}/user/getalluserv2?search=${e.target.value}&skip=0&limit=10&sort=1&city=Raipur&minage=56&maxage=57&fixedLat=21.2329772&fixedLon=81.6582886`, {}, config);
   

      setSearchList(res.data.newlist)
      setShowrecentSearch(false)
    }
    else {
      setSearchList([])
      setinputValue('')
      setTimeout(() => {
        setinputValue('')
      }, 500);
    }
  };

  const createRecentSearch = async (userId) => {
    setrecentSearch([])
    setSearchList([]);
    setinputValue('')
    await axios.post(`${BASEAPIURL}/user/createrecentsearch`, { searchUserId: userId }, config)
  };

  const getRecentSearch = async () => {
    setShowrecentSearch(true)
    const res = await axios.post(`${BASEAPIURL}/user/getrecentsearch`, {}, config)
    setrecentSearch(res.data.recentSearch)
  };

  const clearSearch = async () => {
    await axios.post(`${BASEAPIURL}/user/deleteallrecentsearch`, {}, config)
    setrecentSearch([])
    setShowrecentSearch(false)
  };

  const deleteOneSearch = async (userId) => {
    await axios.post(`${BASEAPIURL}/user/deleterecentsearch`, { searchUserId: userId }, config).then(() => getRecentSearch())
  };

  // Hide search box when click on outside
  const searchboxRef = useRef(null);

  const handleClickOutside = (event) => {
    if (searchboxRef.current && !searchboxRef.current.contains(event.target)) {
      searchboxRef.current.style.display = 'none';
    };
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  // Hide recent search when click on outside
  const searchRecentRef = useRef(null);

  const handleClickOutsideRec = (event) => {
    if (searchRecentRef.current && !searchRecentRef.current.contains(event.target)) {
      setShowrecentSearch(false)
    };
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('click', handleClickOutsideRec);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('click', handleClickOutsideRec);
    };
  }, []);

  const handleBusinessNav = (page) => {
    navigate(`/business/${page._id}`)
    localStorage.setItem("businessUserId", page._id)
  }

  // To connect socket
  useEffect(() => {
    socketFun();

    setLoader(true);

    setTimeout(() => {
      setLoader(false)
    }, 1000);

    handleSoftCornerEntry(config);

    getMyPages();

    getNotification(config).then((res) => {
      setnotification(res)
    })
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit("online_emilo", loggedInUserId);
    };
  }, [loggedInUserId]);


  return (
    <>
      {/* Popup */}

      {/* Create New Post Popup */}
      <CreateNewPostPopup show={showCreatePostPopup} onHide={() => setShowCreatePostPopup(false)} showCreatePostPopup={showCreatePostPopup} setShowCreatePostPopup={setShowCreatePostPopup} />

      {/* Favourite People Popup */}
      <FavouritePeoplePopup showFavouritePeoplePopup={showFavouritePeoplePopup} setShowFavouritePeoplePopup={setShowFavouritePeoplePopup} />
      <Closefriend />

      {
        <section style={{ display: `${showHeader ? "block" : "none"}` }}>
          {/* // <section className="d-none"> */}
          {
            (!loader)
              ?
              <header className={`${urls.some(url => window.location.href.includes(url)) ? 'd-none' : 'd-lg-block'} header-area header-emilo-width shadow-sm`}>
                <div className="container-fluid ">
                  <div className="header-nav-box">
                    <div className="row align-items-center">
                      {/* Logo */}
                      <div className="col-lg-3 col-12" onClick={() => setSwipeHeader(false)}>
                        <div className="logo-box">
                          <Link to="/" >
                            <img src={`${BASE_URL_STATIC}/logo.png`} alt="" />
                          </Link>
                        </div>
                      </div>

                      {/* Search */}
                      <div className="col-lg-3 col-6 headerSearch">
                        <div className="input-box">
                          <form action="#">
                            <input type="search" placeholder="Search" onFocus={getRecentSearch} onChange={handleSearch} value={inputValue} />
                            <button type="button">
                              <i className="far fa-search" />
                            </button>
                          </form>
                        </div>

                        {
                          showrecentSearch
                          &&
                          <div ref={searchRecentRef} className="searchList" >
                            <div className="flex mb-3" style={{ display: `${!showrecentSearch && "hidden"}` }}>
                              <span className="text-muted">Recent Search</span>
                              <span className="clearSearch" onClick={clearSearch}>clear all</span>
                            </div>

                            {
                              recentSearch.map((user, index) => (
                                <div className="flex mb-2" style={{ display: `${!showrecentSearch && "hidden"}` }}>

                                  <Link key={index} onClick={() => createRecentSearch(user.userId)} to={`${BASEURL}/profile/${user.userId}`} style={{ color: 'black' }}>
                                    <div>
                                      <img className="searchImage" src={user.profileImg ? (AwsFileUrl + user.profileImg.imageId) : `${BASE_URL_STATIC}/dummy.png`} alt="profile" onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`} />
                                    </div>


                                    <div className="userSearchList">
                                      <span >{user.fname}</span>
                                      <div>{user.username ? <span>{user.username}</span> : <span>{user.fname}</span>}</div>
                                    </div>
                                  </Link>









                                  <Link key={index} onClick={() => createRecentSearch(user.userId)} to={`${BASEURL}/profile/${user.userId}`} style={{ color: 'black' }}>
                                    <div>
                                      <img className="searchImage" src={user.profileImg ? (AwsFileUrl + user.profileImg.imageId) : `${BASE_URL_STATIC}/dummy.png`} alt="profile" onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`} />
                                    </div>


                                    <div className="userSearchList">
                                      <span >{user.fname}</span>
                                      <div>{user.username ? <span>{user.username}</span> : <span>{user.fname}</span>}</div>
                                    </div>
                                  </Link>


                                  <div style={{ cursor: "pointer" }}
                                    onClick={() => deleteOneSearch(user.userId)}><HiMiniXMark size={20} /></div>
                                </div>
                              ))
                            }
                          </div>
                        }

                        {
                          inputValue
                          &&
                          <div ref={searchboxRef} className="searchList">
                            {
                              searchList && Array.isArray(searchList) && searchList.length > 0 && 
                           
                              (
                                <>
                                 <div className="mb-2 d-flex"  style={{ color: 'black' }}>
                                   
                                   



                                  <div>
                                  <IoIosSearch className="searchImage"/>
                                   </div>
                                   


                                   <Link to={`${BASEURL}/search/${inputValue}`}>
                                     <div className="userSearchList">
                                       <span >{inputValue}</span>
                                       <div> <span>{searchList[0].fname}</span></div>
                                     

                                     </div>
                                   </Link>


                                 </div>
                                
                             {   searchList.map((user, index) => (
                               
                                 
                                  <div className="mb-2 d-flex" key={index} onClick={() => createRecentSearch(user.userId)} style={{ color: 'black' }}>
                                   
                                   



                                    {user.story ? <StoryforSearch userId={user._id} /> : <div>
                                      <img className="searchImage" src={user.imageId ? (AwsFileUrl + user.imageId) : `${BASE_URL_STATIC}/dummy.png`} alt="profile" onError={(e) => e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`} />
                                    </div>
                                    }


                                    <Link to={`${BASEURL}/profile/${user._id}`}>
                                      <div className="userSearchList">
                                        <span >{user.fname}</span>
                                        <div>{user.username ? <span>{user.username}</span> : <span>{user.fname}</span>}</div>
                                        {user.resultSize > 0 ? <small>{user.resultSize} follower</small> : ''}

                                      </div>
                                    </Link>


                                  </div>
                                
                              )) }
                              </>)
                            }
                          </div>
                        }

                        {/* {
                          inputValue.length > 1 && searchList.length === 0
                          &&
                          <small ref={searchboxRef} className="searchList">
                            <i style={{ color: "#ff6863" }}>No user found</i>
                          </small>
                        } */}
                      </div>

                      {/* Create New Post */}
                      <div className="col-lg-2 ">
                        <div className="btn-box text-center ">
                          <span onClick={() => setShowCreatePostPopup(true)} className="new-post p-md-1 p-lg-2 ">
                            <i className="fal fa-plus-circle" />
                            <span className="sm-new-post" onClick={() => { setPostType('postType'); setPageContent(''); setSelectedAddLocation('') }}>Create New Post</span>
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-4 col-12">
                        <div className="notify-box">
                          {/* Notification */}
                          <Link to="/notifications" className="bell position-relative">
                            <img src={`${BASE_URL_STATIC}/top-icon1.png`} alt="" />
                            {
                              Array.isArray(notification) && notification.length > 0
                              &&
                              <span className="notify_dot"></span>
                            }
                          </Link>

                          {/* Chats */}
                          <Link to={`/chats`}>
                            <img src={`${BASE_URL_STATIC}/top-icon2.png`} alt="" />
                          </Link>

                          {/* SoftCorner */}
                          <>
                            {
                              isInSoftCorner
                                ?
                                <Link to="/SoftCorner/checkSoftCornerPin" onClick={() => setHeaderChange(true)} >
                                  <img src={`${BASE_URL_STATIC}/top-icon3.png`} alt="" />
                                </Link>
                                :
                                <Link to="/SoftCorner/OnboardingUser" onClick={() => setHeaderChange(true)} >
                                  <img src={`${BASE_URL_STATIC}/top-icon3.png`} alt="" />
                                </Link>
                            }
                          </>

                          {/* Drop Down */}
                          <CDropdown>
                            <CDropdownToggle>
                              <img src={`${BASE_URL_STATIC}/top-icon4.png`} alt="top-icon4" />
                            </CDropdownToggle>

                            <CDropdownMenu className="pe-3">
                              <>
                                {
                                  myPages.businessPages.length > 0
                                  &&
                                  myPages.businessPages.map((page, index) => (
                                    <CDropdownItem className="mb-2 cursor-pointer"
                                      onClick={() => handleBusinessNav(page)}
                                      key={index}>
                                      <div className="header-toggle-name">
                                        <img className="header-dd-img" src={`/images/business/icons/business.svg`} alt="business" />
                                        <span>{page.fname}</span>
                                      </div>
                                    </CDropdownItem>
                                  ))
                                }
                              </>

                              <>
                                {
                                  myPages.publicFigurePages.length > 0
                                  &&
                                  myPages.publicFigurePages.map((page, index) => (
                                    <CDropdownItem className="mb-2 cursor-pointer" onClick={() => navigate(`/public-figure/${page._id}`)} key={index}>
                                      <div className="header-toggle-name">
                                        <img className="header-dd-img" src={`/images/business/icons/public-figure.svg`} alt="public figure" />
                                        <span>{page.fname}</span>
                                      </div>
                                    </CDropdownItem>
                                  ))
                                }
                              </>

                              <CDropdownItem className="mb-2">
                                <Link to={`${BASEURL}/${loggedInUserId}/favourite`} className="header-toggle-name">
                                  <img className="header-dd-img" src={`${BASE_URL_STATIC}/star.png`} alt="star" />
                                  <span>Favourite</span>
                                </Link>
                              </CDropdownItem>

                              <CDropdownItem className="mb-2">
                                <div onClick={() => setShowCloseFriendPopup(true)} className="header-toggle-name">
                                  <img className="header-dd-img" src={`${BASE_URL_STATIC}/star.png`} alt="star" />
                                  <span>Close Friends</span>
                                </div>
                              </CDropdownItem>

                              <CDropdownItem className="mb-2">
                                <Link to={`${BASEURL}/${loggedInUserId}/tagged`} className="header-toggle-name">
                                  <img className="header-dd-img" src={`${BASE_URL_STATIC}/tag.png`} alt="tag" />
                                  <span>Tagged</span>
                                </Link>
                              </CDropdownItem>

                              <CDropdownItem className="mb-2">
                                <Link className="header-toggle-name" onClick={() => handleLogout()}>
                                  <img className="header-dd-img" src={`/images/business/icons/lb-logout.svg`} alt="tag" />
                                  <span>Logout</span>
                                </Link>
                              </CDropdownItem>

                              {/* <CDropdownItem><Link to={`${BASEURL}/${loggedInUserId}/liked`} className="header-toggle-name" >Liked</Link></CDropdownItem> */}
                              {/* <CDropdownItem><Link to={`${BASEURL}/profile/${loggedInUserId}/profile-setting`} className="header-toggle-name" >Personal Details</Link></CDropdownItem> */}
                              {/* <CDropdownItem><Link to={``} onClick={() => setShowFavouritePeoplePopup(true)} className="header-toggle-name" >Favourite People</Link></CDropdownItem> */}

                              {/* <CDropdownItem className="col-lg-12">
                              <Link onClick={() => handleLogout()} className="header-toggle-name" >
                                <div className="iconBox d-flex col-lg-12">
                                  <div className="col-lg-6">Logout</div>
                                  <div className="col-lg-6 d-flex" style={{ justifyContent: "center" }} >
                                    <i className="fal fa-sign-out" />
                                  </div>
                                </div>
                              </Link>
                            </CDropdownItem> */}

                            </CDropdownMenu>
                          </CDropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              :
              <HeaderSkeleton />
          }
        </section>
      }
    </>
  );
};

export default Header;