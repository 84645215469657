import React from 'react'
import Modal from 'react-bootstrap/Modal';

const ScErrorPost = ({ open, close }) => {
    return (
        <div>
            <Modal show={open} onHide={close}>
                <Modal.Body className='bg_sc'>
                    <div className=' p-3 text-justify fs-11 text-white bg_sc'>
                        <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat aspernatur delectus consequuntur corrupti, aperiam pariatur quae sed nobis quaerat dolorem iure? Atque consequuntur fugiat aliquam nobis quibusdam, molestiae nisi facere, nam natus, similique aspernatur! Reiciendis, quisquam voluptates praesentium, iste earum, ea reprehenderit voluptas quidem distinctio eveniet aut laborum dolorum! Tempore?</span>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ScErrorPost