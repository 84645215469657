import React, { useContext, useEffect, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../../UseContext/ApiCall';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';


const VoiceCallGoingPopup = () => {

  const {
    showVoiceCallGoingPopup, setShowVoiceCallGoingPopup,
    loggedInUser,
    chatProfile,
    voiceCallStart, voiceCallEnd,
  } = useContext(ApiContext);

  const containerRef = useRef(null);

  const initZegoCloud = (element) => {
    if (!loggedInUser) {
      console.error('Missing loggedInUser');
      return;
    };
  
    const appID = 1632643095;  // Your AppID
    const serverSecret = 'fcb29e34aa8bba2eecf90cd9f3cdc81d';  // Your AppSign
    const roomId = `${loggedInUser.userId}-${Math.random().toString(36).substring(2, 8)}`;
  
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomId,
      loggedInUser.userId,
      loggedInUser.username || 'Host'
    );
  
    const zegoInstance = ZegoUIKitPrebuilt.create(kitToken);
  
    if (zegoInstance && typeof zegoInstance.joinRoom === 'function') {
      zegoInstance.joinRoom({
        container: element,
        scenario: {
          mode: ZegoUIKitPrebuilt.VoiceCall,
          config: {
            role: 'Host',
            audio: true,
            video: false,
          },
        },
        onJoinRoom: () => {
          // console.log(`call start`);
          voiceCallStart(roomId, loggedInUser.username, chatProfile.userId, 'voice');
        },
        onLeaveRoom: () => {
          // console.log(`call end`);
          voiceCallEnd(roomId, chatProfile.userId, 'voice');
        },
      });
    } else {
      console.error('Failed to create ZegoUIKitPrebuilt instance');
    }
  };
  

  useEffect(() => {
    if (containerRef.current) {
      initZegoCloud(containerRef.current);
    };

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = ''; // Clear the container
      };
    };
  }, [loggedInUser, showVoiceCallGoingPopup]);

  return (
    <>
      <Modal show={showVoiceCallGoingPopup} onHide={() => setShowVoiceCallGoingPopup(false)} className="main-modal" size="lg" centered backdrop="static" keyboard={false}  >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h6 className='mb-2 text-center'>Voice Calling to {chatProfile.fname}...</h6>
            <div id="zego-container" style={{ width: '100%', maxHeight: '600px' }} ref={containerRef}></div>
          </section>    
        </Modal.Body>
      </Modal>
    </>
  )
}

export default VoiceCallGoingPopup;