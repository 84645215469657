import React, { useContext, useEffect, useState } from "react";
import StoryCircle from "./StoryCircle";
import { ApiContext } from "../../../UseContext/ApiCall";
import { AwsFileUrl } from "../../../../../config";
import { useNavigate } from "react-router-dom";
import { getUserStories } from "../../../UseContext/ApiHelpers";

const StoryforSearch = ({ userId }) => {

  const navigate = useNavigate();
  const {
    setShowViewStoriesPopup,
    getStoriesFollowingUsers,
    config,
    setUserStories,
  } = useContext(ApiContext);
  const [storiesData, setStoriesData] = useState([]);

  // Function to map story type to color
  const getStoryRingColor = (mediaPosts) => {
    const colorMap = {
      text: "blue",
      post: "blue",
      flix: "red",
      memes: "orange",
      bestclips: "green", // Add more types if needed
    };

    // Collect unique colors based on mediaPosts type
    const colors =
      mediaPosts &&
      mediaPosts.length > 0 &&
      mediaPosts.map((post) => colorMap[post.type] || "gray"); // Default to gray for unknown types
    return Array.from(new Set(colors)); // Remove duplicates
  };
  const getImageUrl = (data) => {
    let b = data[data.length - 1];
    let c = b.mediaUrls[0];

    if (c.filetype === "image") {
      return c.url;
    } else {
      return b.postTumbnailUrl;
    }
  };

  useEffect(() => {
    // Assuming getStoriesFollowingUsers is a function that returns a promise
    const fetchStories = async () => {
      try {
        const data = await getStoriesFollowingUsers(userId);
        console.log("data  sto", data);
        setStoriesData(data);
      } catch (error) {
        console.error("Error fetching stories:", error);
      }
    };

    fetchStories();

    // Cleanup function (executed when the component unmounts or when userId changes)
  }, [userId]);

  const getUserStories1 = async (id) => {
    try {
      const data = await getUserStories(id, config);
      setUserStories(data.stories);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
    

      {storiesData &&
        storiesData.length > 0 &&
        storiesData.map((story, index) => (
          <div
            key={index}
            className="story d-flex"
            onClick={() => getUserStories1(story._id)}
          >
            <div className="story-image">
              {/* Dynamic story ring with all media types */}
              <StoryCircle
                colors={getStoryRingColor(story.mediaPosts)}
                imageUrl={story.mediaPosts && getImageUrl(story.mediaPosts)}
              />
              <img
                src={AwsFileUrl + story.imageId}
                className="story-profile position-relative cursor-pointer object-fit-cover"
                onClick={() => setShowViewStoriesPopup(true)}
                alt="profile"
                style={{ left: "19px" }}
              />
            </div>
            <span
              className="story-name cursor-pointer text-truncate"
              onClick={() => navigate(`/profile/${story._id}`)}
            ></span>
          </div>
        ))}
    </>
  );
};

export default StoryforSearch;
