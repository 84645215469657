import React, { useContext, useEffect, useRef, useState } from "react";
import BackToTop from "../Helper/BackToTop.js";
import { getDataTypePost, getExplorePost, AdvenceSearchapi } from "../UseContext/ApiHelpers";
import { AwsFileUrl, BASE_URL_STATIC, BASEURL } from "../../../config.js";
import { ApiContext } from "../UseContext/ApiCall.js";
import ProfilePostPopup from "../Popup/Profile/ProfileShow/ProfilePostPopup.js";
import Loader from "../../Loader.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import StoryforSearch from "../Home/CenterBlock/Stories/StoryforSearch.js";
import GooglemapApi from "./GooglemapApi.js";



const AdvenceSearch = () => {

  const [allPosts, setAllPosts] = useState([]);
  const [postData, setPostData] = useState(null);
  const [showPostsPopup, setShowPostsPopup] = useState(false);
  const [postId, setPostId] = useState('');
  const [dataType, setDataType] = useState('all');
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(2);
  const [isVideo, setIsVideo] = useState(false);
  const [Accountpepole , setAccountpepole] = useState(false);
  const [userplace,  setuserplace] = useState(false)
  const { searchTerm } = useParams()
    console.log(searchTerm)

  const { config, inputValue,  searchList, } = useContext(ApiContext);

  document.title = "Emilo-explore";

  // use this section for infinite scroll

  //------------------------------------------------------------------------------------------------

  const scrollContainerRef = useRef(null);

  const handleInfiniteScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        const currentPage = page;

        //---------------- Working Area------------------------

        if (dataType === "all") {
          getExplorePost(currentPage, 10, config).then((res) => {
            if (res.status === 200) {
              setAllPosts((prevPosts) => [...prevPosts, ...res.data.posts]);
              setPage(currentPage + 1);
            }
          });
        } else {
          getDataTypePost(dataType, 'postType', "", currentPage, config, 10).then((res) => {
            if (res.status === 200) {
              setAllPosts((prevPosts) => [...prevPosts, ...res.data.posts]);
              setPage(currentPage + 1);
            }
          });
        }
        //-----------------------------------------------------------------

      }
    }
  };



  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleInfiniteScroll);
      return () => {
        container.removeEventListener("scroll", handleInfiniteScroll);
      };
    }
  }, [dataType, page,]);

  //------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (dataType === "all") {
      setLoader(true);

      AdvenceSearchapi(inputValue, config).then((res) => {
        setAllPosts(res);
        setLoader(false);

      });
    } else {
      setLoader(true);
      getDataTypePost(dataType, 'postType', "", 1, config, 20, isVideo).then((res) => {
        if (res.status === 200) {
          setAccountpepole(false)
          setAllPosts(res.data.posts);
          setLoader(false);
        }
      });
    }
  }, [dataType, isVideo, inputValue,]);

   const HandleSearch = () =>{
    setAccountpepole(true)
   }
    
  const Handleplace = () =>{
    setuserplace(true); 

  }
   

  




  return (
    <>
      <section>
        <ProfilePostPopup showPostsPopup={showPostsPopup} setShowPostsPopup={setShowPostsPopup} postsPopupData={postData} postId={postId} />
        <section className="vh-100 overflow-y-scroll scrollbar-hidden py-5" ref={scrollContainerRef}>
          <div className="mt-3 mt-lg-5">
            <ul className="flex gap-3 px-1 px-lg-5">
              <li className={`flex gap-1 justify-content-center w-50 border cp ${dataType === "all" && 'selected-users'}  rounded-2 py-1`} onClick={() => { setDataType('all'); setIsVideo(false); }}>
                <FontAwesomeIcon icon={faBarsStaggered} style={{ fontSize: "25px" }} />
                <span className="d-none d-lg-inline fs-14">All</span>
              </li>
              <li className={`flex gap-1 justify-content-center w-50 border cp ${dataType === "memes" && 'selected-users'}  rounded-2 py-1`} onClick={HandleSearch}>
                <img className="pn-img" src={`${BASE_URL_STATIC}/icon/meme.svg`} alt="" height="40" width="40" />
                <span className="d-none d-lg-inline fs-14">People</span>
              </li>
              <li className={`flex gap-1 justify-content-center w-50 border cp ${dataType === "bestclips" && 'selected-users'}  rounded-2 py-1`} onClick={() => {Handleplace()}}>
                <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-bestClip-1.svg`} alt="" />
                <span className="d-none d-lg-inline fs-14">Place</span>
              </li>
              <li className={`flex gap-1 justify-content-center w-50 border cp ${dataType === "post" && !isVideo && 'selected-users'}  rounded-2 py-1`} onClick={() => { setDataType('post'); setIsVideo(false); }}>
                <img className="pn-img" src={`${BASE_URL_STATIC}/icon/pofile-post.png`} alt="" style={{ objectFit: "contain" }} />
                <span className="d-none d-lg-inline fs-14">Posts</span>
              </li>
              <li className={`flex gap-1 justify-content-center w-50 border cp ${dataType === "post" && isVideo && 'selected-users'}  rounded-2 py-1`} onClick={() => { setDataType('post'); setIsVideo(true) }}>
                <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-video-1.svg`} alt="" style={{ objectFit: "contain" }} />
                <span className="d-none d-lg-inline fs-14">Videos</span>
              </li>
              <li className={`flex gap-1 justify-content-center w-50 border cp ${dataType === "flix" && 'selected-users'}  rounded-2 py-1`} onClick={() => { setDataType("flix"); setIsVideo(false); }}>
                <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-flix-1.svg`} alt="" />
                <span className="d-none d-lg-inline fs-14">Flix</span>
              </li>
              <li className={`flex gap-1 justify-content-center w-50 border cp ${dataType === "memes" && 'selected-users'}  rounded-2 py-1`} onClick={() => { setDataType("memes"); setIsVideo(false); }}>
                <img className="pn-img" src={`${BASE_URL_STATIC}/icon/meme.svg`} alt="" height="40" width="40" />
                <span className="d-none d-lg-inline fs-14">Memes</span>
              </li>
              <li className={`flex gap-1 justify-content-center w-50 border cp ${dataType === "bestclips" && 'selected-users'}  rounded-2 py-1`} onClick={() => { setDataType("bestclips"); setIsVideo(false); }}>
                <img className="pn-img" src={`${BASE_URL_STATIC}/icon/profile-bestClip-1.svg`} alt="" />
                <span className="d-none d-lg-inline fs-14">Bestclips</span>
              </li>

            </ul>
          </div>

          {loader ? (
            <Loader />
          ) :
           Accountpepole ?
              (
                <div className="mb-2  text-center">
                  {searchList.map((user, index) => (
                    <div className="mb-2" key={index}  style={{ color: 'black' }}>

                      {/* Conditional Rendering for Story or Image */}
                      {user.story ? (
                        <StoryforSearch userId={user._id} />
                      ) : (
                        <div>
                          <img
                            className="searchImage"
                            src={user.imageId ? `${AwsFileUrl}${user.imageId}` : `${BASE_URL_STATIC}/dummy.png`}
                            alt="profile"
                            onError={(e) => (e.target.src = `${BASE_URL_STATIC}/profile-img1.jpg`)}
                          />
                        </div>
                      )}

                      {/* Profile Link and User Details */}
                      <Link to={`${BASEURL}/profile/${user._id}`}>
                        <div className="userSearchList">
                          <span>{user.fname}</span>
                          <div>{user.username ? <span>{user.username}</span> : <span>{user.fname}</span>}</div>
                          {user.resultSize > 0 && <small>{user.resultSize} follower{user.resultSize > 1 ? 's' : ''}</small>}
                        </div>
                      </Link>

                    </div>
                  ))}
                </div>

              ) :
              userplace ? 
              
              (
                <GooglemapApi/>

              ):
                (
                <div className="explore-sec mt-3 mt-sm-5 mt-lg-5">
                  {Array.isArray(allPosts) &&
                    allPosts.map((post, index) => (
                      <div className="rounded-4 position-relative cursor-pointer" style={{ height: '200px' }} onClick={() => { setShowPostsPopup(true); setPostId(post._id); setPostData(post); }} key={index}>
                        {post.mediaUrls && post.mediaUrls.length > 0 && post.mediaUrls[0] && !isVideo && post.mediaUrls[0].filetype === 'image' ? (
                          <img src={AwsFileUrl + post.mediaUrls[0].url} className="h-100 w-100 rounded-4 object-fit-cover shadow-sm" alt="explore" />
                        ) : (
                          <img
                            src={AwsFileUrl + (post.mediaUrls && post.mediaUrls[0] ? post.mediaUrls[0].thumbnail : post.postTumbnailUrl)}
                            className="h-100 w-100 rounded-4 object-fit-cover shadow-sm"
                            alt="explore"
                          />

                        )}

                        <div className="px-2 pb-1 rounded-3 position-absolute top-0 end-0 mt-3 me-3" style={{ backgroundColor: '#e2e3e591' }}>
                          {post.mediaUrls[0] && post.mediaUrls[0].filetype === 'image' ? (
                            <img src={`${BASE_URL_STATIC}/icon/white-post.svg`} alt="post" />
                          ) : (
                            <img src={`${BASE_URL_STATIC}/icon/white-flix.svg`} alt="post" />
                          )}
                        </div>

                        <div className="hover-info position-absolute top-50 start-50 translate-middle h-100 w-100 d-flex align-items-center justify-content-center">
                          <span className="me-2">
                            <img src={`${BASE_URL_STATIC}/explore-icon4.png`} className="me-1" alt="Views" />
                            <span className="text-white">{post._id && post.viewcount}</span>
                          </span>

                          <span className="me-2">
                            <img src={`${BASE_URL_STATIC}/explore-icon5.png`} className="me-1" alt="Likes" />
                            <span className="text-white">{post._id && post.likecount}</span>
                          </span>

                          <span className="me-2">
                            <img src={`${BASE_URL_STATIC}/explore-icon6.png`} className="me-1" alt="Comments" />
                            <span className="text-white">{post._id && post.commentcount}</span>
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              )}
        </section>

        <BackToTop />
      </section>
    </>
  );
};

export default AdvenceSearch;
