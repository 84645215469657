import React, { createContext, useContext, useEffect, useState } from "react";
import { ApiContext } from "./ApiCall";
import toast from 'react-hot-toast';
import { 
  createValue, createUser, deleteUser, getSearched,
} from "./SettingHelper";
import axios from "axios";
import { BASEAPIURL } from "../../../config";
import { updateNotification } from "./ApiHelpers";


export const settingContext = createContext();


const SettingContext = ({ children }) => {

  const { config, loggedInUserId } = useContext(ApiContext);




  // ----------- Account Privacy -----------

  const [isAccountPrivate, setIsAccountPrivate] = useState('public');
  const [isOtherUserAccountPrivate, setIsOtherUserAccountPrivate] = useState(false);
  

  // To check account is private or public
  const checkAccountPrivacy = (config) => {
    axios.post(`${BASEAPIURL}/setting/checkAccountPrivacy`, {}, config)
    .then(res => {
      // console.log('res : ', res.data.message);
      setIsAccountPrivate(res.data.message);
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };

  // To handle change account private or public
  const handleChangeAccountPrivacy = (config) => {

    const action = isAccountPrivate === 'public' ? 'private' : 'public';

    axios.post(`${BASEAPIURL}/setting/handleChangeAccountPrivacy`, { action: action }, config)
      .then(res => {

        checkAccountPrivacy(config);

        updateNotification(config)

        // It will update on front end
        setValue(preState => ({
          ...preState,

          // --- Post ---
          postView: action === 'public' ? 'public' : 'followers',
          postComment: action === 'public' ? 'public' : 'followers',
          postShare: action === 'public' ? 'public' : 'followers',
          postDownload: action === 'public' ? 'public' : 'followers',

          // --- Flix ---
          flixView: action === 'public' ? 'public' : 'followers',
          flixComment: action === 'public' ? 'public' : 'followers',
          flixShare: action === 'public' ? 'public' : 'followers',
          flixDownload: action === 'public' ? 'public' : 'followers',

          // --- Bestclip ---
          bestclipView: action === 'public' ? 'public' : 'followers',
          bestclipComment: action === 'public' ? 'public' : 'followers',
          bestclipShare: action === 'public' ? 'public' : 'followers',
          bestclipDownload: action === 'public' ? 'public' : 'followers',

          // --- Meme ---
          memeView: action === 'public' ? 'public' : 'followers',
          memeComment: action === 'public' ? 'public' : 'followers',
          memeShare: action === 'public' ? 'public' : 'followers',
          memeDownload: action === 'public' ? 'public' : 'followers',

          // --- Stories ---
          storiesView: action === 'public' ? 'public' : 'followers',
          storiesReplies: action === 'public' ? 'public' : 'followers',
          storiesShare: action === 'public' ? 'public' : 'followers',
          storiesShareToStory: action === 'public' ? 'public' : 'followers',
          storiesDownload: action === 'public' ? 'public' : 'followers',
        }));
      })
      .catch(err => {
        console.log('err : ', err);
      });
  };

  // To check other user accout privacy that logged in user can see other user profile or not 
  const checkOtherUserAccountPrivacy = (otherUserId, config) => {
    if (otherUserId === loggedInUserId) return setIsOtherUserAccountPrivate(false);

    axios.post(`${BASEAPIURL}/setting/checkOtherUserAccountPrivacy`, { otherUserId: otherUserId }, config)
      .then(res => {
        // console.log('other : ', res.data.message);
        setIsOtherUserAccountPrivate(res.data.message);
      })
      .catch(err => {
        console.log('err : ', err);
      });
  };



  // ---------- Value ----------

  const [value, setValue] = useState({});

  // console.log('value : ', value);

  //  To handle option change (profile info value)
  const handleOptionChange = (e, settingNav) => {
    const { name, value } = e.target;

    console.log(name, value);
    
    //  It will update the view (post, flix, bestclip, meme, stories) value in front end
    setValue(prevState => ({
      ...prevState,
      [name]: value
    }));

    // It will update the view (post, flix, bestclip, meme, stories) value in back end
    createValue(settingNav, name, value, config);

    // ----- Post, Flix, Bestclip, Meme, Stories -----
    if(name === 'postView') {
      //  It will update the post (comment, share, download) value in front end
      setValue(preState => ({
        ...preState,
        postComment: value,
        postShare: value,
        postDownload: value,
      }));

      // It will update the post (comment, share, download) value in back end
      const settingKeys = ['postComment', 'postShare', 'postDownload']; 

      settingKeys.forEach(settingKey => {
        createValue(settingNav, settingKey, value, config);
      });
    } else if (name === 'flixView') {
      //  It will update the flix (comment, share, download) value in front end
      setValue(preState => ({
        ...preState,
        flixComment: preState.flixComment && value,
        flixShare: preState.flixShare && value,
        flixDownload: preState.flixDownload && value,
      }));

      // It will update the flix (comment, share, download) value in back end
      const settingKeys = ['flixComment', 'flixShare', 'flixDownload']; 

      settingKeys.forEach(settingKey => {
        createValue(settingNav, settingKey, value, config);
      });
    } else if (name === 'bestclipView') {
      //  It will update the bestclip (comment, share, download) value in front end
      setValue(preState => ({
        ...preState,
        bestclipComment: preState.bestclipComment && value,
        bestclipShare: preState.bestclipShare && value,
        bestclipDownload: preState.bestclipDownload && value,
      }));

      // It will update the bestclip (comment, share, download) value in back end
      const settingKeys = ['bestclipComment', 'bestclipShare', 'bestclipDownload']; 

      settingKeys.forEach(settingKey => {
        createValue(settingNav, settingKey, value, config);
      });
    } else if (name === 'memeView') {
      //  It will update the meme (comment, share, download) value in front end
      setValue(preState => ({
        ...preState,
        memeComment: preState.memeComment && value,
        memeShare: preState.memeShare && value,
        memeDownload: preState.memeDownload && value,
      }));

      // It will update the meme (comment, share, download) value in back end
      const settingKeys = ['memeComment', 'memeShare', 'memeDownload']; 

      settingKeys.forEach(settingKey => {
        createValue(settingNav, settingKey, value, config);
      });
    } else if (name === 'storiesView') {
      //  It will update the meme (comment, share, download) value in front end
      setValue(preState => ({
        ...preState,
        storiesReplies: preState.storiesReplies && value,
        storiesShare: preState.storiesShare && value,
        storiesShareToStory: preState.storiesShareToStory && value,
        storiesDownload: preState.storiesDownload && value,
      }));

      // It will update the meme (comment, share, download) value in back end
      const settingKeys = ['storiesReplies', 'storiesShare', 'storiesShareToStory', 'storiesDownload']; 

      settingKeys.forEach(settingKey => {
        createValue(settingNav, settingKey, value, config);
      });
    };
  };

  
  
  // ---------- Input ----------

  const [showList, setShowList] = useState({});
  const [list1, setList1] = useState({});
  const [list2, setList2] = useState({});
  const [selected, setSelected] = useState({});
  const [added, setAdded] = useState({});

  // console.log('----------');

  // console.log('show : ', showList);
  // console.log('list 1 : ', list1);
  // console.log('list 2 : ', list2);
  // console.log('selected : ', selected);
  // console.log('added : ', added);


  // To handle Focus
  const handleFocus = (settingNav, settingKey, settingType) => {
    if(!list1[settingKey]) {
      getSearched(settingNav, settingKey, settingType, config)
      .then(res => {
        // To set follower list 1
        setList1(preState => ({
          ...preState,
          [settingKey]: res.data.users,
        }));
      })
      .catch(err => {
        console.log('err : ', err);
      });
    };

    // To show followers except list
    setShowList({
      [settingKey]: true
    });
  };

  // To handle blur
  const handletBlur = (settingKey) => {
    // To hide list
    setShowList({
      [settingKey]: false
    });

    if(list1[settingKey].length && list1[settingKey].length === 0) {
      setList1(preState => ({
        ...preState,
        [settingKey]: [],
      }));
    };

    if(list2[settingKey]) {
      setList2(preState => ({
        ...preState,
        [settingKey]: [],
      }));
    };
  };

  // To handle change
  const handleChange = (e, settingKey) => {
    setList2(preState => ({
      ...preState,
      [settingKey]: list1[settingKey].filter((user) => user.otherUserName.toLowerCase().includes(e.target.value.toLowerCase()))
    }));
  };

  // To handle select follower except
  const handleSelect = (settingKey, user) => {
    // Remove the selected users from list1
    setList1(preState => ({
      ...preState,
      [settingKey]: list1[settingKey].filter(item => item.otherUserId !== user.otherUserId)
    }));

    // Remove the selected user from list2
    if(list2[settingKey]) {
      setList2(preState => ({
        ...preState,
        [settingKey]: list2[settingKey].filter(item => item.otherUserId !== user.otherUserId)
      }));
    };

    // Move selected user from list to selected    
    if(selected[settingKey] && selected[settingKey].length > 0) {
      setSelected(preState => ({
        ...preState,
        [settingKey]: [...selected[settingKey], user]
      }));
    } else {
      setSelected(preState => ({
        ...preState,
        [settingKey]: [user]
      }));
    };
  };

  // To handle remove
  const handleRemove = (settingKey, user) => {
    // Remove the selected user from selected
    setSelected(preState => ({
      ...preState,
      [settingKey]: selected[settingKey].filter(item => item.otherUserId !== user.otherUserId)
    }));

    // Move selected user from selected to list1
    setList1(preState => ({
      ...preState,
      [settingKey]: [...list1[settingKey], user]
    }));
  };

  // To handle create/add selected user
  const handleCreate = (settingNav, settingKey, settingType) => {
    createUser(settingNav, settingKey, settingType, selected[settingKey], config)
    .then(res => {
      toast.success(res.data.message);

      // Move selected user from selected to added
      if(added[settingKey]) {
        setAdded(prevState => ({
          ...prevState,
          [settingKey]: [ ...added[settingKey], ...selected[settingKey] ],
        }));
      } else {
        setAdded(prevState => ({
          ...prevState,
          [settingKey]: selected[settingKey],
        }));
      };
      
      // To clear selected followers
      setSelected(preState => {
        // Create a copy of the previous state
        const newState = { ...preState };

        // Remove the settingKey from the new state
        delete newState[settingKey];

        // Return the updated state
        return newState;
      });
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };

  // To handle delete added user
  const handleDelete = (settingNav, settingKey, settingType, follower) => {
    deleteUser(settingNav, settingKey, settingType, follower, config)
    .then(res => {
      toast.success(res.data.message);

      if(list1[settingKey]) {
        // move added to list1
        setList1(preState => ({
          ...preState,
          [settingKey]: [...list1[settingKey], follower]
        }));
      }

      // Remove the added user from added 
      setAdded(preState => ({
        ...preState,
        [settingKey]: added[settingKey].filter(item => item.otherUserId !== follower.otherUserId)
      }));
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };



  // To handle quick apply setting
  const handleQuickApply = () => {
    // It will update on front end
    setValue(preState => ({
      ...preState,

      // --- Flix ---
      flixView: value.postView,
      flixComment: value.postView,
      flixShare: value.postView,
      flixDownload: value.postView,

      // --- Bestclip ---
      bestclipView: value.postView,
      bestclipComment: value.postView,
      bestclipShare: value.postView,
      bestclipDownload: value.postView,

      // --- Meme ---
      memeView: value.postView,
      memeComment: value.postView,
      memeShare: value.postView,
      memeDownload: value.postView,

      // --- Stories ---
      storiesView: value.postView,
      storiesReplies: value.postView,
      storiesShare: value.postView,
      storiesShareToStory: value.postView,
      storiesDownload: value.postView,
    }));

    axios.post(`${BASEAPIURL}/setting/quickApply`, { name: 'Siddharth Dongre' }, config)
    .then(res => {
      console.log('res : ', res);
      toast.success(res.data.message);
    }).catch(err => {
      console.log('err : ', err);
    });
  };



  useEffect(() => {
    checkAccountPrivacy(config);
  }, []);



  const Value = {

    // ---------- Account Privacy ----------

    isAccountPrivate, setIsAccountPrivate,
    isOtherUserAccountPrivate, setIsOtherUserAccountPrivate,

    checkOtherUserAccountPrivacy,
    checkAccountPrivacy,
    handleChangeAccountPrivacy,


    // ---------- Value ----------

    value, setValue,

    handleOptionChange,


    // ---------- Input ----------

    showList, setShowList,
    list1, setList1,
    list2, setList2,
    selected, setSelected,
    added, setAdded,

    handleFocus,
    handletBlur,
    handleChange,
    handleSelect,
    handleRemove,
    handleCreate,
    handleDelete,


    handleQuickApply,
  };
  

  return (<settingContext.Provider value={Value}>{children}</settingContext.Provider>);
}

export default SettingContext


