import React, { useEffect, useState, useContext } from "react";
import { MdCancel } from "react-icons/md";
import { TbDeviceDesktopUp } from "react-icons/tb";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { Categories, ActionButton, radioOptions } from "./Api/ApiforCategories";
import GoogleMapApi from "./Api/GoogleMapApi";
import { selectCountry, selectStates, selectCities } from "../../Emilo/UseContext/ApiHelpers";
import Slider from "./Api/Slider";
import axios from "axios";
import { businessContext } from "../UseContext/BusinessContext";
import BoostPostmediu from "./Api/BoostPostmediu";
// import { CiLocationOn } from "react-icons/ci";


const BusinessBoostPost1 = () => {

  const { pageConfig,  BoostPostId,  paramsPageId, checkIsItMyPage }= useContext(businessContext);

  // console.log("Post Data:1", BoostPostId);

  const [centerPosition, setCenterPosition] = useState(null);

  const [value, setValue] = useState([20, 80]);

  const [formdata, setFormdata] = useState({
    categories: "",
    subcategories: "",
    select_save_audience: "",
    adDescription: "",
    ActionButton: "",
    webUrl : "", 
    Location: "",
    customer_selection: "",
    country: "",
    States: [],
    city: [],
    cityExclude: [],
    age: value,
    gender: '',
    StartDate : '', 
    EndDate : '', 
    estimatedBudget: 0,
    customBudget: 0,
    costPerReach: 0,
    deleted: false,
    deletedAt: null,
    lat : '', 
    lng : '', 
    Approve: 0,
    AdspostId  : BoostPostId._id, 
    
  });
  const [Country, setCountry] = useState([]);
  const [State, setState] = useState([]);
  const [city, setCity] = useState([]);

  const handleSliderChange = (newValue) => {
    setValue(newValue);
    setFormdata({
      ...formdata, // Spread the previous formdata to keep other values intact
      age: newValue, // Update only the categories field
    });
  };

  const handleCategoryChange = (event) => {
    setFormdata({
      ...formdata, // Spread the previous formdata to keep other values intact
      categories: event.target.value, // Update only the categories field
    });
  };

  const handleSubCategoryChange = (event) => {
    setFormdata({
      ...formdata, // Spread the previous formdata to keep other values intact
      subcategories: event.target.value, // Update only the categories field
    });
  };

  useEffect(() => {
    const fachtCountryrdata = async () => {
      const data = await selectCountry();
      setCountry(data);
    };
    fachtCountryrdata();
  }, []);

  useEffect(() => {
    setFormdata({
      ...formdata, // Spread the previous formdata to keep other values intact
      lat : centerPosition ? centerPosition.lat : '',
      lng : centerPosition ?  centerPosition.lng : '',  // Update only the categories field
    });
    // console.log("formdata", formdata);


  }, [centerPosition]);


  const HandleSelectCountry = async (event) => {
    setFormdata({
      ...formdata,
      country: event.target.value,
    });
    const data = await selectStates(event.target.value);
    setState(data);
  };

  const HandleSelectState = async (event) => {
    const newState = event.target.value;

    setFormdata((prevFormdata) => {
      if (prevFormdata.States && !prevFormdata.States.includes(newState)) {
        return {
          ...prevFormdata,
          States: [...prevFormdata.States, newState],
        };
      }

      return prevFormdata;
    });

    const data = await selectCities(formdata.country, newState);

    setCity((prev) => {
      return {
        ...prev,
        data: [
          ...(Array.isArray(prev.data) ? prev.data : []), // Ensure prev.data is an array
          ...(Array.isArray(data) ? data : []), // Ensure data is an array
        ],
      };
    });
  };


  const HandleSelectCity = (event) => {
    const newState = event.target.value;


    setFormdata((prevFormdata) => {
      if (prevFormdata.city && !prevFormdata.city.includes(newState)) {
        return {
          ...prevFormdata,
          city: [...prevFormdata.city, newState],
        };
      }
      return prevFormdata;
    });
  };

  const HandleSelectcityExclude = (event) => {
    const newState = event.target.value;


    setFormdata((prevFormdata) => {
      if (prevFormdata.cityExclude && !prevFormdata.cityExclude.includes(newState)) {
        return {
          ...prevFormdata,
          cityExclude: [...prevFormdata.cityExclude, newState],
        };
      }
      return prevFormdata;
    });
  };

  const handleGender = (event) =>{
  setFormdata({
    ...formdata,
    gender: event.target.value, 
  });
  };

  const HandlestartDate =(event) =>{
    setFormdata({
      ...formdata,
      StartDate: event.target.value, 
    });
  }

  const HandlendDate =(event) =>{
    setFormdata({
      ...formdata,
      EndDate: event.target.value, 
    });
  };
  
  const  handleSubmit = async( event) =>{
  event.preventDefault();
    try {
      console.log("formdata submit time", formdata)
      const   response =  await axios.post('http://localhost:3001/page/adsboostpost', {formdata}, pageConfig); 
      console.log("response", response)

    
    } catch (error) {
        console.log("adsboostpost", error); 
    
    }
  };

  useEffect(() => {
    checkIsItMyPage(paramsPageId);
  }, [paramsPageId]);

  const HandlButtonType = (event) =>{
    setFormdata({
      ...formdata,
      ActionButton: event.target.value, 
    });
  };

  const HandleUrls = ( event) =>{
    setFormdata({
      ...formdata,
      webUrl: event.target.value, 
    });
  };

  const handleRemove = () => { };

  return (
    <>
      <section
        className="business-rb h-100 overflow-y-scroll scrollbar-hidden px-3 px-sm-4 px-xl-5"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div
          className="shadow-sm btn-xs rounded  mt-3 mb-3 me-3 ms-2 rounded-lg d-flex align-items-center"
          style={{ borderRadius: "16px" }}
        >
          <h4 className="p-2 flex-grow-1 unnamed-character-style-1 font16">
            Boost Post
          </h4>
          <button className="btn btn-outline-secondary btn-sm me-2 d-flex gap-2 align-items-center">
            <TbDeviceDesktopUp style={{ fontSize: "20px" }} />
            Publish Report
          </button>{" "}
        </div>
        <form onSubmit={handleSubmit}>
        <div className="row g-4">
          <div className="col-md-7   h-100 overflow-y-scroll scrollbar-hidden">
            <div
              className="   p-3 shadow-sm rounded-4  w-100 h-100"
              style={{ background: "#fff" }}
            >
              <div className=" mx-2  mt-3 mb-2 p-3 shadow-none p-3 mb-5 rounded-3 borderSec bg-white">
                <p className="font18 fw-semibold">Categories</p>
                <small className="text-secondary py-2 font14">
                  Select category which is applicable
                </small>

                <select
                  className="form-select mt-1"
                  aria-label="Default select example"
                  value={formdata.categories}
                  onChange={handleCategoryChange}
                >
                  {Categories.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" mx-2  mt-3 mb-2 p-3 shadow-none p-3 mb-5 rounded-3 borderSec bg-white">
                <p className="font18 fw-semibold"> Sub Categories</p>
                <small className="font14 text-secondary py-2">
                  {" "}
                  Select the Categories that best describe what this campaign
                  will adverites
                </small>

                <select
                  class="form-select mt-1"
                  aria-label="Default select example"
                  value={formdata.subcategories}
                  onChange={handleSubCategoryChange}
                >
                  {Categories.filter(
                    (item) => item.name === formdata.categories
                  ).map((item, index) =>
                    item.subcategories.map((subcategory, subIndex) => (
                      <option key={subIndex} value={subcategory}>
                        {subcategory}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className=" mx-2  mt-3 mb-2 p-3 shadow-none p-3 mb-5 bg-white rounded-3 borderSec">
                <p className="font18 fw-semibold">Select Saved Aoudience</p>
                <small className="font14 text-secondary py-2">
                  {" "}
                  Select the Categories that best describe what this campaign
                  will adverites
                </small>

                <select
                  class="form-select mt-1"
                  aria-label="Default select example"
                >
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>

              <div className=" mx-2  mt-3 p-3 shadow-none p-3 mb-5 bg-white borderSec text-center rounded-3">
                <h4>Create Audience</h4>
                <small>
                  You don't have any save audience{" "}
                  <span className="text-primary"> click here </span> to create
                  audience <br />
                  You will redicreate to emilo business mangaement page
                </small>
              </div>

              {/* Action Button */}
              <div className=" mx-2  p-3 shadow-none p-3 mb-5 bg-white borderSec rounded-3">
                <p className="font-18 fw-semibold">Action Button</p>
                <small className="font-14 text-secondary py-2">
                  {" "}
                  Select the Categories that best describe what this campaign
                  will adverites
                </small>
                <select
                  class="form-select mt-1"
                  aria-label="Default select example"
                 onChange={HandlButtonType}
                >
                  {ActionButton.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>

                <div className="input-group mt-2 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your website URL..."
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                   onChange={HandleUrls}
                  />
                </div>
              </div>
              {/* Location  */}
              <div className=" mx-2  mt-3 p-3 shadow-none p-3 bg-white borderSec rounded-3">
                <p className="font18 fw-semibold">Location</p>
                <small className="font14 text-secondary py-2">
                  {" "}
                  Select the Categories that best describe what this campaign
                  will adverites
                </small>
                <br />

                <input type="radio" className="m-1" />
                <p className="fs-6 fw-1 d-inline-block">Redius Selection</p>
                <br />
                <small className="text-secondary py-2 ">
                  {" "}
                  this campaign will adverites
                </small>

                <div class="text-center">
                  <GoogleMapApi centerPosition={centerPosition}   setCenterPosition={setCenterPosition} />
                </div>

                <input type="radio" className="m-1" />
                <p className="fs-6 fw-1 d-inline-block">Custom Selection</p>
                <br />
                <small className="text-secondary py-2 ">
                  this campaign will adverites
                </small>

                <p className="fs-6 fw-1 mt-1">Country</p>

                <div class="input-group flex-nowrap">
                  {/* <span class="input-group-text">
             
                  <CiLocationOn className="input-icon text-primary" />
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="selectcountry"
                    aria-label="selectcountry"
                    aria-describedby="addon-wrapping"
                    onChange={HandleSelectCountry}
                    
                  
            
                  />  */}

                  <select
                    class="form-select mt-1"
                    aria-label="Default select example"
                    value={formdata.country}
                    onChange={HandleSelectCountry}
                  >
                    { Country && Country.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <p className="fs-6 fw-1 mt-1">State</p>

                <div class="input-group flex-nowrap">


                  <select
                    class="form-select mt-1"
                    aria-label="Default select example"
                    value={formdata.States}
                    onChange={HandleSelectState}
                  >
                    {  State && State.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <Stack
                  className="row row-cols-4 row-cols-lg-4   row-cols-md-3 row-cols-sm-2 g-2 g-3 mt-1"
                  direction="horizontal"
                  gap={2}
                >
                  {formdata.States.map((state, index) => (
                    <Badge bg="primary col cd" key={index}>
                      {state}
                      <span
                        className="cp"
                        onClick={() => handleRemove(state, index)}
                      >
                        <MdCancel />
                      </span>
                    </Badge>
                  ))}
                </Stack>

                <p className="fs-6 fw-1 mt-1">City</p>

                <select
                  class="form-select"
                  aria-label="Default select example"
                  onClick={HandleSelectCity}

                >
                  {Array.isArray(city.data) &&
                    city.data.map((e, i) =>
                      <option value={e.name} key={i} >{e.name}</option>)
                  }
                </select>


                <Stack
                  className="row row-cols-4 row-cols-lg-4   row-cols-md-3 row-cols-sm-2 g-2 g-3 mt-1"
                  direction="horizontal"
                  gap={2}
                >
                  {formdata.city.map((city, index) => (
                    <Badge bg="primary col cd" key={index}>
                      {city}
                      <span
                        className="cp"
                        onClick={() => handleRemove(city, index)}
                      >
                        <MdCancel />
                      </span>
                    </Badge>
                  ))}
                </Stack>

                <p className="fs-6 fw-1 mt-1">City (Exclude)</p>

                <select
                  class="form-select"
                  aria-label="Default select example"
                  onClick={HandleSelectcityExclude}

                >
                  {Array.isArray(city.data) &&
                    city.data.map((e, i) =>
                      <option value={e.name} key={i} >{e.name}</option>)
                  }
                </select>

                <Stack
                  className="row row-cols-4 row-cols-lg-4   row-cols-md-3 row-cols-sm-2 g-2 g-3 mt-1"
                  direction="horizontal"
                  gap={2}
                >
                  {formdata.cityExclude.map((city, index) => (
                    <Badge bg="danger col cd" key={index}>
                      {city}
                      <span
                        className="cp"
                        onClick={() => handleRemove(city, index)}
                      >
                        <MdCancel />
                      </span>
                    </Badge>
                  ))}
                </Stack>

              </div>

              <div className=" mx-2 borderSec  mt-3 p-3 shadow-none p-3 bg-white rounded-3">
                <p className="font18 fw-semibold">Age</p>

                <Slider value={value} setValue={setValue} handleSliderChange={handleSliderChange} />


              </div>

              <div className=" mx-2  mt-3 p-3 shadow-none p-3 bg-white rounded-3">
                <p className="fs-5 fw-1">Gender</p>
                <div className="d-flex mt-2">
                  {radioOptions.map((option, index) => (
                    <div
                      key={index}
                      className="bg-white borderSec px-4 py-2 rounded-3 me-3 text-nowrap cursor-pointer"
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id={option.id}
                        value={option.value}
                        onChange={handleGender}
                      />
                      {option.label}
                    </div>
                  ))}
                </div>
              </div>

              <div className=" mx-2  mt-3 mb-2 p-3 p-3 mb-5 bg-white borderSec rounded-3">
                <p className="font18 fw-semibold">Shcedule</p>
                <small className="font14 text-secondary py-2">
                  {" "}
                  Select the Categories that best describe what this campaign
                  will adverites
                </small>

                <p className="font18 fw-1 mt-2">Start Date</p>

                <div className="input-group mt-2 mb-3">
                  <input
                    type="datetime-local"
                    className="bg-white borderSec px-5 py-2 rounded-3 me-3 text-nowrap cursor-pointer "
                    onChange={HandlestartDate}
                  />

                  

                  <div className="bg-white borderSec px-5 py-2 rounded-3 me-3 text-nowrap cursor-pointer ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />{" "}
                    Set End Date
                  </div>
                </div>

                <p className="fs-5 fw-1">End Date</p>

                <div className="input-group mt-2 mb-3">
                  <input
                    type="datetime-local"
                    className="bg-white borderSec px-5 py-2 rounded-3 me-3 text-nowrap cursor-pointer "
                    onChange={HandlendDate}
                  />
                </div>
              </div>

              <div className="row g-4">
                <div className="col-md-6">
                  <button type="button" className="btn btn-success mt-1 ml-1">
                    {" "}
                    Save Draft
                  </button>
                </div>

                <div className="col-md-3 d-flex justify-content-end ">
                  <button type="button" className="btn btn-secondary mt-1 ml-1">
                    Cancel
                  </button>
                </div>

                <div className="col-md-3 d-flex justify-content-start">
                  <button className="btn btn-primary mt-1 ml-1"   type="submit">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* section-2 */}
          <div className="col-md-5 h-100  overflow-y-scroll scrollbar-hidden">
            <div className="  p-3 borderSec bg-white rounded-4 w-100 h-100">
              <p className="font18 fw-semibold">Budget</p>

              <div className=" mx-2  mt-3 mb-2 p-3 p-3 bg-white borderSec rounded-3">
                <div className="d-flex justify-content-between">
                  <p className="font18 fw-semibold d-inline-block d-flex-grow-1">
                    {" "}
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      className=""
                    />{" "}
                    <span className="text-primary">eMilo</span> Estimated Budget{" "}
                  </p>
                  <small className="text-primary d-flex-grow-1">
                    Add custom Budget
                  </small>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        className="form-control p-4  mt-2"
                        placeholder="2000rs"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <select
                        className="form-select mt-2"
                        aria-label="Default select example"
                        style={{ padding: "12px" }} // Applying inline style
                      >
                        <option value="" disabled selected>
                          Daily Budget
                        </option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                </div>

                <p className="font14 text-secondary text-left">
                  {" "}
                  you will spend an avrage of 200rs per day, you maximum day
                  spend is 250rs and you maximum weekly spend 1400rs{" "}
                  <span className="text-primary">learn more</span>
                </p>
                <p className="font18 mt-3 fw-semibold d-inline-block d-flex-grow-1">
                  {" "}
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    className="mt-1"
                  />{" "}
                  Custom Budget{" "}
                </p>
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 mb-3">
                      <input
                        type="text"
                        className="form-control p-4 mt-2"
                        placeholder="2000rs"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      // style={{ padding: "40px" }} // Applying inline style
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <select
                        className="form-select mt-2"
                        aria-label="Default select example"
                        style={{ padding: "12px" }} // Applying inline style
                      >
                        <option value="" disabled selected>
                          Daily Budget
                        </option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                </div>

                <p className="font14 text-secondary">
                  {" "}
                  you will spend an avrage of 200rs per day, you maximum day
                  spend is 250rs and you maximum weekly spend 1400rs{" "}
                  <span className="text-primary">learn more</span>
                </p>

                <p className="font18 mt-3 fw-semibold"> Estimate Reach </p>

                <div className="d-flex justify-content-evenly align-items-center mt-2 mb-1">
                  <span class="badge text-bg-primary ">Reach</span>
                  <span class="badge text-bg-secondary">Leads</span>
                  <span class="badge text-bg-secondary">impression</span>
                  <span class="badge text-bg-secondary">click</span>
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <p className="font18 fw-semibold d-inline-block d-flex-grow-1">
                    {" "}
                    <span className="text-primary">eMilo</span> Reach{" "}
                  </p>
                  <p className="font18 fw-semibold d-flex-grow-1">
                    {" "}
                    Cost Per Reach (CPR)
                  </p>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col-md-8 mb-3">
                      <input
                        type="range"
                        className="form-control p-4 mt-2"
                        id="customRange1"

                      // style={{ padding: "12px" }}
                      ></input>
                    </div>
                    <div className="col-md-4 mb-3">
                      <select
                        className="form-select mt-2"
                        aria-label="Default select example"
                        style={{ padding: "12px" }} // Applying inline style
                      >
                        <option value="" disabled selected>
                          Daily Budget
                        </option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            <BoostPostmediu BoostPostId= {BoostPostId}/>
            </div>
          </div>
        </div>
        </form>
      </section>
    </>
  );
};

export default BusinessBoostPost1;
