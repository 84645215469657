import React, { useState, useContext } from 'react';
import axios from 'axios';
import { ApiContext } from '../../UseContext/ApiCall';
import { BASE_URL_STATIC, BASEAPIURL } from '../../../../config';

const FavouritePostButton = ({ postId, isfavorite }) => {
  const [isPostFavourite, setIsPostFavourite] = useState(isfavorite === 1);
  const { config } = useContext(ApiContext);

  // To add/remove post to/from favourite
  const createFavouritePost = async(postId, config) => {
    const action = isPostFavourite ? 'remove' : 'favorite';

    await axios.post(`${BASEAPIURL}/post/postEvent`, { postId, eventType: action }, config)
      .then(res => {
        console.log(res.data.message);
        setIsPostFavourite(!isPostFavourite); // Toggle the state
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <img
      className='favouritePostBtn'
      src={`${BASE_URL_STATIC}/icon/${isPostFavourite ?   'favourite-2.svg' :'favourite-1.svg'}`}
      alt="favourite"
      onClick={() => createFavouritePost(postId, config)}
    />
  );
};

export default FavouritePostButton;
