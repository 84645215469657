import React, { useContext, useEffect, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { ApiContext } from '../../../../UseContext/ApiCall';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';


const VideoCallGoingPopup = () => {

  const {
    showVideoCallGoingPopup, setShowVideoCallGoingPopup,
    loggedInUser,
    chatProfile,
    videoCallStart, videoCallEnd,
  } = useContext(ApiContext);

  const containerRef = useRef(null);

  const initZegoCloud = (element) => {
    if (!loggedInUser) {
      console.error('Missing loggedInUser');
      return;
    };
  
    const appID = 1632643095;  // Your AppID
    const serverSecret = 'fcb29e34aa8bba2eecf90cd9f3cdc81d';  // Your AppSign
    const roomId = `${loggedInUser.userId}-${Math.random().toString(36).substring(2, 8)}`;
  
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomId,
      loggedInUser.userId,
      loggedInUser.username || 'Host'
    );
  
    const zegoInstance = ZegoUIKitPrebuilt.create(kitToken);
  
    if (zegoInstance && typeof zegoInstance.joinRoom === 'function') {
      zegoInstance.joinRoom({
        container: element,
        scenario: {
          mode: ZegoUIKitPrebuilt.VideoCall,
          config: {
            role: 'Host',
            audio: true,
            video: true,
          },
        },
        onJoinRoom: () => {
          console.log(`call start`);
          videoCallStart(roomId, loggedInUser.username, chatProfile.userId, 'video');
        },
        onLeaveRoom: () => {
          console.log(`call end`);
          videoCallEnd(roomId, chatProfile.userId, 'video');
        },
      });
    } else {
      console.error('Failed to create ZegoUIKitPrebuilt instance');
    }
  };
  
  useEffect(() => {
    if (containerRef.current) {
      initZegoCloud(containerRef.current);
    };

    // Clean up resources on unmount
    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = ''; // Reset the container
      }
    };
  }, [loggedInUser, showVideoCallGoingPopup]);

  return (
    <>
      <Modal show={showVideoCallGoingPopup} onHide={() => setShowVideoCallGoingPopup(false)} className="main-modal" size="lg" centered backdrop="static" keyboard={false} >
        <Modal.Header closeButton>&nbsp;</Modal.Header>
        <Modal.Body>
          <section>
            <h6 className='mb-2 text-center'>Video Calling to {chatProfile.fname}...</h6>
            <div id="zego-container" style={{ width: '100%', maxHeight: '600px' }} ref={containerRef}></div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default VideoCallGoingPopup
